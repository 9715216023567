import { XMarkIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "lucide-react";
import React, { useRef } from "react";
import EmptyState from "./EmptyState";
import {
    isBinaryVideo,
    isFileIsPdf,
    isPdf,
    isVideo,
} from "../ReusableFunctions";
import { toast } from "react-toastify";
import PDFViewer from "./PDFViewer";
import useIsMobile from "../../hooks/useIsMobile";

const FileUploader = ({
    imageAssets,
    setImageAssets,
    multiple,
    readonly,
    isImageOnly,
}) => {
    const inputRef = useRef();

    return (
        <div>
            <div
                className={`lg:flex ${
                    imageAssets?.length > 1 ? "gap-5" : "gap-0"
                }`}
            >
                {imageAssets && imageAssets?.length > 0 ? (
                    <UploadedImageMapper
                        imageAssets={imageAssets}
                        setImageAssets={setImageAssets}
                        inputRef={inputRef}
                        multiple={multiple}
                        readonly={readonly}
                    />
                ) : (
                    !readonly && (
                        <EmptyState
                            label="Upload Image of type (PNG, JPG, JPEG)"
                            handleFunction={() => inputRef?.current?.click()}
                        />
                    )
                )}
            </div>

            <input
                type="file"
                ref={inputRef}
                hidden
                accept={`${isImageOnly ? ".jpeg,.jpg,.png,.webp" : "*"}`}
                multiple={multiple}
                onChange={(e) => {
                    if (e.target.files) {
                        for (let i = 0; i < e.target.files.length; i++) {
                            let fileSize = e.target.files[i].size;

                            if (fileSize > 5242880) {
                                e.target.value = "";
                                toast.error("File size must be 5MB or less");
                                return;
                            }
                        }

                        setImageAssets &&
                            setImageAssets((prevImages) => [
                                ...(prevImages || []),
                                ...e.target.files,
                            ]);

                        setTimeout(() => {
                            e.target.value = "";
                        }, 100);
                    }
                }}
            />
        </div>
    );
};

export const UploadedImageMapper = ({
    imageAssets,
    setImageAssets,
    readonly,
    inputRef,
    multiple,
}) => {
    const { isMobile } = useIsMobile();

    const handleDelete = async (file) => {
        const updatedImages = imageAssets.filter((item) => item !== file);
        setImageAssets && setImageAssets(updatedImages);
    };

    return (
        <div className="flex flex-col items-center w-full gap-5">
            {imageAssets?.map((file, id) => (
                <div className="relative w-full" key={id}>
                    {typeof file === "string" ? (
                        isVideo({ url: file}) ? (
                            <video
                                controls
                                className="h-fit w-full rounded-md object-cover max-h-[450px]"
                            >
                                <source src={`${file}?${Date.now()}`} />
                            </video>
                        ) : isPdf({ url: file }) ? (
                            <PDFViewer
                                pagesToShow={1}
                                resumeUrl={file}
                                scale={isMobile ? 0.55 : 1.22}
                            />
                        ) : (
                            <img
                                src={`${file}?${Date.now()}`}
                                className="w-[100%] rounded-md object-cover max-h-[450px] min-h-[450px]"
                                key={id}
                                alt="File"
                            />
                        )
                    ) : isBinaryVideo({ file }) ? (
                        <video
                            controls
                            className="h-fit w-full rounded-md object-cover max-h-[450px]"
                        >
                            <source src={URL.createObjectURL(file)} />
                        </video>
                    ) : isFileIsPdf({ file }) ? (
                        <div className="w-full h-fit rounded-md object-cover">
                            <PDFViewer
                                pagesToShow={1}
                                resumeUrl={file}
                                scale={isMobile ? 0.55 : 1.22}
                            />
                        </div>
                    ) : (
                        <img
                            src={URL.createObjectURL(file)}
                            className="w-[100%] rounded-md object-cover max-h-[450px] min-h-[450px]"
                            key={id}
                            alt="File"
                        />
                    )}

                    {!readonly && (
                        <div className="absolute top-3 right-3 z-10">
                            <button
                                className="text-black bg-white rounded-full p-1"
                                onClick={() => handleDelete(file)}
                            >
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>
                    )}
                </div>
            ))}

            {!readonly && multiple && (
                <div className="flex flex-col gap-2 items-center">
                    <PlusIcon
                        className="w-10 h-10 text-main-blue bg-blue-200 p-2 rounded-full cursor-pointer"
                        onClick={() => inputRef?.current?.click()}
                    />
                    <p className="text-sm">Add More</p>
                </div>
            )}
        </div>
    );
};

export default FileUploader;
