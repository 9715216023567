import React from "react";
import CreditImage from "../../assets/Listing/Credit.png";

const CreditLogo = () => {
	return (
		<div>
			<img src={CreditImage} alt="Credit" />
		</div>
	);
};

export default CreditLogo;
