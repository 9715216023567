import React from "react";
import { RadioGroup, Radio } from "@nextui-org/react";
import { useField } from "formik";

const FormRadioGroup = ({ label, name, options }) => {
    const [, meta, helpers] = useField(name);

    return (
        <div>
            <RadioGroup
                name={name}
                label={label}
                value={meta.value}
                onValueChange={(value) => helpers.setValue(value)}
                size="sm"
                classNames={{
                    label: "text-sm",
                }}
            >
                {options?.map((option) => (
                    <Radio
                        value={option.value}
                        size="sm"
                        description={option?.description}
                    >
                        {option.name}
                    </Radio>
                ))}
            </RadioGroup>
            {meta.error && meta.touched && (
                <div className="text-xs tracking-wide text-pink-200 text-start mt-1">
                    <p className=" text-xs text-red-600">{meta?.error}</p>
                </div>
            )}
        </div>
    );
};

export default FormRadioGroup;
