import React from "react";
import ActiveUser from "./components/ActiveUser";
import GeoDistribution from "./components/GeoDistribution";
import IndividualRolesChart from "./components/IndividualRolesChart";
import LineChart from "./components/LineChart";
import LivePeople from "./components/LivePeople";
import PageWiseTimeSpent from "./components/PageWiseTimeSpent";
import StackChart from "./components/StackChart";

const MonitoringDashboard = () => {
    return (
        <div className="p-4 space-y-4">
            <div className="flex flex-row gap-4  ">
                <ActiveUser />
                {/* <ActiveUserByRoles /> */}
                <IndividualRolesChart />
            </div>
            <div className="flex flex-row gap-4  ">
                <LivePeople />
                <div className="space-y-4">
                    <div className="flex flex-row gap-4">
                        <PageWiseTimeSpent />
                        <GeoDistribution />
                    </div>
                    <div className="flex flex-row gap-4">
                        <StackChart />
                        <LineChart />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonitoringDashboard;
