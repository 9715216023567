import { createSlice } from "@reduxjs/toolkit";
import navlinks from "../pages/Landing/components/Navbar/navlinks";

const initialState = {
    theme: "light",
    activeNavigationTab: navlinks,
    isSidebarOpen: true,
    isAdminLoggedIn: false,
    creditActionData: {}
};

const customSlice = createSlice({
    name: "custom",
    initialState,
    reducers: {
        addGraph: (state) => {
            state.graphs = [...state.graphs, state.graphs.length.toString()];
        },
        changeActiveNavigationTab: (state, action) => {
            state.activeNavigationTab = state.activeNavigationTab.map(
                (tab) => ({
                    ...tab,
                    status: tab.name === action.payload ? "Active" : "Inactive",
                })
            );
        },
        setIsSidebarOpen: (state, action) => {
            state.isSidebarOpen = action.payload;
        },
        setIsAdminLoggedIn: (state, action) => {
            state.isAdminLoggedIn = action.payload;
        },
        setCreditActionData: (state, action) => {
            state.creditActionData = action.payload;
        },
        setTheme: (state, action) => {
            state.theme = action.payload;
        }
    },
});

export const customActions = customSlice.actions;

export default customSlice.reducer;
