import { motion } from "framer-motion";
import React from "react";
import Possibilities1 from "../../../assets/LandingPage/Possibilities1.png";
import Possibilities1_1 from "../../../assets/LandingPage/Possibilities1_1.png";
import Possibilities2 from "../../../assets/LandingPage/Possibilities2.png";
import Possibilities3 from "../../../assets/LandingPage/Possibilities3.png";
import Possibilities4 from "../../../assets/LandingPage/Possibilities4.png";
import Possibilities5 from "../../../assets/LandingPage/Possibilities5.png";
import Possibilities6 from "../../../assets/LandingPage/Possibilities6.png";

const Possibilities = () => {
    // const variants = {
    //     visible: { opacity: 1, y: 0 },
    //     hidden: { opacity: 0, y: 50 },
    // };
    return (
        <motion.div
            className="w-full py-8 md:py-12 px-5 sm:px-6 lg:px-8"
            // initial="hidden" // Set initial state to "hidden"
            // whileInView="visible" // Trigger "visible" when in view
            // viewport={{ once: true, amount: 0.1 }} // Trigger only once when 50% is in view
            // variants={variants} // Define animations for "hidden" and "visible"
            // transition={{ duration: 0.8, delay: 0.8 }}
        >
            <div className="max-w-7xl mx-auto">
                <h3 className="text-primary-purple text-xs sm:text-sm uppercase font-semibold text-start sm:text-center mb-3 px-1 sm:px-0">
                    Possibilities
                </h3>
                <h1 className="text-lg sm:text-3xl md:text-4xl font-semibold uppercase text-start sm:text-center mb-8 md:mb-12 px-1 sm:px-0">
                    Explore endless possibilities
                </h1>
                <div className="flex flex-col space-y-5">
                    <div className="flex flex-col lg:flex-row gap-5">
                        <div className="flex-1 min-w-0 bg-[#C3B2E7] rounded-[50px] p-5 sm:p-6 relative max-w-lg">
                            <p className="text-lg md:text-xl font-bold text-start">
                                More then 50 <br /> categories
                            </p>
                            <div className="h-[300px] sm:h-[400px] mt-5">
                                <img
                                    src={Possibilities1}
                                    className="object-contain max-h-full w-auto mx-auto"
                                    alt="Possibility 1"
                                />
                                <img
                                    src={Possibilities1_1}
                                    className="absolute sm:bottom-14 left-0 sm:-left-20 w-[110%] max-h-40 object-contain"
                                    alt="Possibility 1.1"
                                />
                            </div>
                            <p className="text-sm md:text-base mt-20 font-light text-start">
                                From Web design to graphic design PortfoliOOo
                                helps you do every thing.
                            </p>
                        </div>

                        <div className="flex-1 min-w-0 flex flex-col gap-5">
                            <div className="bg-[#F682A5] rounded-[50px] p-5 sm:p-6">
                                <div className="flex flex-col sm:flex-row  gap-5 items-end">
                                    <div className="text-center sm:text-left">
                                        <h1 className="text-lg md:text-2xl font-bold mb-2">
                                            Get the work done timely
                                        </h1>
                                        <p className="text-sm font-light">
                                            Plan, collaborate, and deliver
                                            projects on time with ease.
                                        </p>
                                    </div>
                                    <img
                                        src={Possibilities2}
                                        className="w-full sm:max-h-80 sm:scale-150 object-contain"
                                        alt="Possibility 2"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row gap-5">
                                <div className="flex-1 bg-[#C9DA8F] rounded-[50px] p-5 relative">
                                    <img
                                        src={Possibilities3}
                                        className="w-full mx-auto absolute -top-5 left-1/2 -translate-x-1/2"
                                        alt="Possibility 3"
                                    />
                                    <div className="mt-[250px] sm:mt-[200px] text-center">
                                        <h1 className="text-lg md:text-xl font-bold mb-2">
                                            Earn & Redeem Effortlessly
                                        </h1>
                                        <p className="text-xs md:text-sm font-light">
                                            Get paid, save, and spend with total
                                            flexibility.
                                        </p>
                                    </div>
                                </div>

                                <div className="flex-1 bg-[#FEDF6F] rounded-[50px] p-5 relative overflow-hidden">
                                    <div className="text-center mb-[200px] sm:mb-[150px]">
                                        <h1 className="text-lg md:text-xl font-bold mb-2">
                                            Make groups of freelancers
                                        </h1>
                                        <p className="text-xs md:text-sm font-light">
                                            Collaborate effortlessly by forming
                                            freelancer teams for projects
                                        </p>
                                    </div>
                                    <img
                                        src={Possibilities4}
                                        className="w-full max-h-60 sm:max-h-full mx-auto absolute bottom-0 left-1/2 -translate-x-1/2"
                                        alt="Possibility 4"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col lg:flex-row gap-5">
                        <div className="flex-1 bg-[#F9A474] rounded-[50px] h-[368px] relative overflow-hidden">
                            <div className="flex flex-col sm:flex-row h-full">
                                <div className="p-5 sm:p-6 flex flex-col justify-end">
                                    <h1 className="text-xl font-bold mb-2">
                                        Companies we <br /> work with.
                                    </h1>
                                    <p className="text-sm font-light">
                                        Top companies works with us....
                                    </p>
                                </div>
                                <div className="flex-shrink-0">
                                    <img
                                        src={Possibilities5}
                                        className="w-full h-full sm:max-w-[550px] object-fill sm:absolute sm:right-0"
                                        alt="Possibility 5"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-1 max-w-full lg:max-w-[370px] bg-[#B8CEDC] rounded-[50px] p-5 sm:p-6 relative overflow-hidden">
                            <div className="mb-[200px]">
                                <h1 className="text-lg md:text-xl font-bold mb-2">
                                    Automate & Get Things Done
                                </h1>
                                <p className="text-xs md:text-sm font-light">
                                    Let us handle the reminders while you focus
                                    on what matters.
                                </p>
                            </div>
                            <img
                                src={Possibilities6}
                                className="absolute bottom-0 right-0 w-full max-w-[320px] h-auto"
                                alt="Possibility 6"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Possibilities;
