import React, { useMemo } from "react";
import { useSelector } from "react-redux";

export const bgColor = {
    freelancer: "bg-[#E4FFF3] text-[#00A884]",
    client: "bg-[#FFEABB] text-[#F4900C]",
    employee: "bg-[#E5F4FF] text-[#5B8EFB]",
    hr: "bg-[#F1E5FF] text-[#665BFB]"
};

export const badgeColor = {
    freelancer: "bg-[#00A884]",
    client: "bg-[#F4900C]",
    employee: "bg-[#5B8EFB]",
    hr: "bg-[#665BFB]",
};

const UserRoleBadge = ({ userDetails }) => {
    const userRole = useMemo(
        () => userDetails?.role?.[0],
        [userDetails?.role]
    );

    return (
        <div
            className={`flex items-center gap-2 ${bgColor?.[userRole]} py-1 px-2 rounded-lg text-xs`}
        >
            <div
                className={`w-2 h-2 ${badgeColor?.[userRole]} rounded-full`}
            ></div>
            <span className="text-sm capitalize">{userRole === "hr" ? "recruiter" : userRole}</span>
        </div>
    );
};

export default UserRoleBadge;
