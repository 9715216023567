import moment from "moment-timezone";

export const formatDayAgo = (dateString = "2024-11-09T11:44:59.344Z") => {
    if (!dateString) return "";
    const date = moment(dateString, moment.ISO_8601);
    if (!date.isValid()) {
        console.error("Invalid date format:", dateString);
        return "Invalid date";
    }

    const now = moment().tz("Asia/Kolkata");
    const duration = now.diff(date, "hours");

    if (duration < 24) {
        // Show time in hh:mm A format
        return date.tz("Asia/Kolkata").format("hh:mm A");
    } else if (duration < 48) {
        // Show "Yesterday"
        return "Yesterday";
    } else {
        // Show date in format DD MMM YYYY
        return date.tz("Asia/Kolkata").format("DD MMM YYYY");
    }
};

export const formatText = (str) => {
    if (!str) {
        return "";
    }
    return str
        .replace(/[-_]+/g, " ") // Replace hyphens/underscores with spaces
        ?.toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};
