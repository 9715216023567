import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	profileDetails: {},
	userProfileLoading: true,
};

const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		setProfileDetails: (state, action) => {
			state.profileDetails = action.payload;
		},
		setUserProfileLoading: (state, action) => {
			state.userProfileLoading = action.payload
		}
	},
});

export const profileActions = profileSlice.actions;

export default profileSlice.reducer;
