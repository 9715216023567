export default function Milestones() {
    const milestones = [
        {
            amount: "50.00$",
            status: "Paid",
            title: "Milestone 1: Discovery and Planning",
            deliverables: [
                "Initial consultation with the client to understand requirements.",
                "Create a project plan with timelines, deliverables, and milestones.",
                "Provide a sitemap and wireframe/mockup of key pages.",
            ],
            timeline: "1-2 weeks",
            payment: "15-20% of the total budget",
        },
        {
            amount: "50.00$",
            status: "Paid",
            title: "Milestone 2: Design",
            deliverables: [
                "High-fidelity designs for all pages (Home, About, Services, Contact, etc.).",
                "Revisions based on client feedback (limited to 2-3 rounds).",
                "Approval of final design before moving to development.",
            ],
            timeline: "2-3 weeks",
            payment: "20-25% of the total budget",
        },
        {
            amount: "50.00$",
            status: "Paid",
            title: "Milestone 3: Development",
            deliverables: [
                "Develop the website structure (HTML/CSS/JavaScript or CMS like WordPress, Webflow, etc.).",
                "Responsive design for mobile, tablet, and desktop.",
                "Functional implementation of dynamic elements (forms, animations, etc.).",
            ],
            timeline: "3-4 weeks",
            payment: "25-30% of the total budget",
        },
    ];

    return (
        <div className="bg-white p-6 w-full">
            <h2 className="text-2xl font-bold mb-6">Milestone Time-line</h2>
            <div className="border-l-2 border-gray-300 pl-6">
                {milestones.map((milestone, index) => (
                    <div key={index} className="relative space-x-5 mb-6">
                        <div className="absolute -left-9 top-0 w-6 h-6 bg-white border-2 border-green-500 rounded-full flex items-center justify-center">
                            <span className="w-3 h-3 bg-green-500 rounded-full"></span>
                        </div>

                        <p className="text-gray-600 text-sm space-x-5">
                            {milestone.amount}{" "}
                            <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs">
                                {milestone.status}
                            </span>
                        </p>
                        <h3 className="text-lg font-semibold mt-2">
                            {milestone.title}
                        </h3>
                        {milestone.deliverables.length > 0 && (
                            <ul className="list-disc list-inside text-gray-600 mt-2">
                                {milestone.deliverables.map((item, i) => (
                                    <li key={i}>{item}</li>
                                ))}
                            </ul>
                        )}
                        <p className="text-gray-500 text-sm mt-2">
                            Timeline: {milestone.timeline}
                        </p>
                        <p className="text-gray-500 text-sm">
                            Payment: {milestone.payment}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}
