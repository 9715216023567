import { Icon } from "@iconify/react";
import React from "react";

const SidebarItemType = {
    Nest: "Nest",
};

// Sidebar Items
export const items = [
    {
        key: "overview",
        href: "/dashboard/overview",
        icon: "mage:box-3d",
        title: "Overview",
    },
    {
        key: "subscription-plans",
        href: "/dashboard/subscription-plans",
        icon: "solar:card-linear",
        title: "Subscription Plans",
    },
    {
        key: "credit-actions",
        href: "/dashboard/credit-actions",
        icon: "tabler:settings-2",
        title: "Credit Actions",
    },
    {
        key: "requests",
        href: "/dashboard/requests",
        icon: "icon-park-outline:transaction-order",
        title: "Requests",
    },
    // {
    //     key: "Analytics",
    //     href: "/dashboard/analytics",
    //     icon: "solar:user-id-outline",
    //     title: "Analytics",
    // },
];

// Section Items with Teams
export const sectionItemsWithTeams = [...items];

// Brand Items
export const brandItems = [
    {
        key: "overview",
        title: "Overview",
        items: [
            {
                key: "home",
                href: "#",
                icon: "solar:home-2-linear",
                title: "Home",
            },
            {
                key: "projects",
                href: "#",
                icon: "solar:widget-2-outline",
                title: "Projects",
                endContent: (
                    <Icon className="text-primary-foreground/60" icon="solar:add-circle-line-duotone" width={24} />
                ),
            },
        ],
    },
];

// Section Nested Items
export const sectionNestedItems = [
    {
        key: "home",
        href: "#",
        icon: "solar:home-2-linear",
        title: "Home",
    },
    {
        key: "cap_table",
        title: "Cap Table",
        icon: "solar:pie-chart-2-outline",
        type: SidebarItemType.Nest,
        items: [
            {
                key: "shareholders",
                icon: "solar:users-group-rounded-linear",
                href: "#",
                title: "Shareholders",
            },
        ],
    },
];
