import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";

export default function EmptyState({ label, handleFunction }) {
  return (
    <button
      type="button"
      className="relative center-vertical sm:h-80 w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={handleFunction}
    >
      <ArrowUpTrayIcon className="w-10 h-10 opacity-60" />
      <span className="mt-2 block text-sm font-medium text-gray-900">
        {label}
      </span>
    </button>
  );
}
