import React from "react";

const GeoDistribution = () => {
    const data = [
        { city: "Mumbai", activeUsers: 10034 },
        { city: "Bangalore", activeUsers: 3423 },
        { city: "Pune", activeUsers: 1134 },
        { city: "Others", activeUsers: 3456 },
    ];

    return (
        <div className="w-full min-w-[30vw] sm:max-w-[30vw] h-[300px]  bg-white dark:bg-dark-normal dark:shadow-dark-light-gray dark:shadow-sm dark:text-white p-3 rounded-2xl drop-shadow-sm flex flex-col max-h-[445px] shadow-sm hover:shadow-md border dark:border-0">
            <div className="flex justify-between items-center border-b border-gray-200 pb-2 mb-4">
                <h2 className="text-lg font-bold">Geo graphic Distribution</h2>
                <div className="text-sm text-gray-500 flex items-center space-x-2">
                    <span>Refreshed 5 min ago</span>
                    <button className="text-gray-400 hover:text-gray-600">
                        <i className="fas fa-sync-alt"></i>{" "}
                        {/* Replace with an appropriate refresh icon */}
                    </button>
                </div>
            </div>
            <div className="text-sm text-gray-500 mb-2">Default</div>
            <table className="w-full text-left border-collapse">
                <thead>
                    <tr className="border-b border-gray-200 text-gray-600 text-sm">
                        <th className="py-2 px-4">City</th>
                        <th className="py-2 px-4 text-right">Active users</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr
                            key={index}
                            className="border-b border-gray-200 hover:bg-gray-50 text-xs"
                        >
                            <td className="py-2 px-4">{row.city}</td>
                            <td className="py-2 px-4 text-right">
                                {row.activeUsers}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default GeoDistribution;
