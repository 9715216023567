import { commonValidations } from "../utils/formValidations";
import * as Yup from "yup";

const fieldTypeValidations = {
    input: (field) => commonValidations({ fieldName: field?.fieldName ?? field?.name }).stringValidation,
};

export const generateValidationSchema = (fields) => {
    const schema = fields?.reduce((acc, field) => {
        if (field?.required && fieldTypeValidations[field.fieldType]) {
            acc[field.name] = fieldTypeValidations[field.fieldType](field);
        }
        return acc;
    }, {});

    return Yup.object().shape(schema);
};
