import React from "react";
import LogoImage from "../assets/Logo.svg";
import { useNavigate } from "react-router-dom";

const Logo = ({ className }) => {
	const navigate = useNavigate();

	return (
		<img
			src={LogoImage}
			className={className}
			alt="Logo"
			onClick={() => {
				navigate("/");
			}}
		/>
	);
};

export default Logo;
