import { User } from "iconsax-react";
import React from "react";
import FormMultiSelect from "../../../common/components/FormFields/FormMultiselect";
import FormTextarea from "../../../common/components/FormFields/FormTextarea";
import FormInputField from "../../../common/components/FormInputField";
import useMaster from "../../../hooks/useMaster";

const ResumePersonalInfo = () => {
    const { data: skills } = useMaster({
        type: "Skills",
        queryKeys: ["master", "Skills"],
    });

    return (
        <div>
            <div className="flex items-center gap-3">
                <div className="bg-[#5B8EFB33] text-blue-500 p-2 rounded-lg">
                    <User />
                </div>
                <h1 className="text-2xl font-semibold">Personal Information</h1>
            </div>

            <div className="space-y-5 mt-5">
                <FormInputField
                    name={"name"}
                    label={"Name"}
                    placeholder={"Enter your name"}
                />

                <FormInputField
                    name={"title"}
                    label={"Title"}
                    placeholder={"Enter your title"}
                />

                <div>
                    <FormMultiSelect
                        name="skills"
                        label="Skills"
                        placeholder="Select Your Skills"
                        options={skills}
                        maxLabelCount={2}
                    />
                </div>

                <FormTextarea
                    name={"description"}
                    label={"About You"}
                    placeholder={"Enter details about you"}
                    className={"!w-80 overflow-hidden !truncate"}
                />
            </div>
        </div>
    );
};

export default ResumePersonalInfo;
