import { Icon } from "@iconify/react/dist/iconify.js";
import { Badge, Button, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import React, { useState } from "react";
// import useGlobalQuery from "../../../hooks/useGlobalQuery";
// import { ROUTES } from "../../../Routes";
import NotificationCard from "./NotificationCard";
import { ROUTES } from "../../../../Routes";
import useGlobalQuery from "../../../../hooks/useGlobalQuery";

const Notifications = () => {
    const [activeTab, setActiveTab] = useState("all");

    const { queryData, isLoading } = useGlobalQuery({
        url: ROUTES.NOTIFICATION.MY + `?limit=1000`,
        methodType: "POST",
        data: {
            filter:
                activeTab === "transactions"
                    ? {
                          type: { $in: ["CREDITS_ADDED", "CREDITS_DEDUCTED"] },
                      }
                    : {},
        },
        queryKey: ["all-notifications", activeTab],
    });

    return (
        <Popover offset={12} placement="bottom-end">
            <PopoverTrigger>
                <Button disableRipple isIconOnly className="overflow-visible" radius="full" variant="light">
                    <Badge
                        color="primary"
                        content={queryData?.unreadCount > 100 ? "50+" : queryData?.unreadCount}
                        showOutline={false}
                        size="sm"
                        hidden={queryData?.unreadCount > 0 ? false : true}
                    >
                        <Icon className="" icon="solar:bell-linear" width={22} />
                    </Badge>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="max-w-[90vw] p-0 sm:max-w-[380px]">
                <NotificationCard
                    className="w-full shadow-none"
                    data={queryData}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    isLoading={isLoading}
                />
            </PopoverContent>
        </Popover>
    );
};

export default Notifications;
