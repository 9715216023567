"use client";

import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { useField } from "formik";
import { useEffect, useState, useRef } from "react";
import Spinner from "../Spinner";

export default function FormSelectMenu({
    label,
    name,
    placeholder,
    options,
    disabled,
    hidden,
    isLoading,
}) {
    const [, meta, helpers] = useField(name);

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(options ?? []);
    const [selected, setSelected] = useState(meta.value ?? "");
    const [openUpward, setOpenUpward] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setFilteredOptions(
            options?.filter((person) =>
                person.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery, options]);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
        helpers.setValue(selected);
    }, [selected, helpers]);

    useEffect(() => {
        const checkOverflow = () => {
            if (dropdownRef.current) {
                const rect = dropdownRef.current.getBoundingClientRect();
                const spaceBelow = window.innerHeight - rect.bottom;
                const spaceAbove = rect.top;

                if (spaceBelow < 200 && spaceAbove > spaceBelow) {
                    setOpenUpward(true);
                } else {
                    setOpenUpward(false);
                }
            }
        };

        window.addEventListener("resize", checkOverflow);
        checkOverflow();

        return () => window.removeEventListener("resize", checkOverflow);
    }, []);

    return (
        <Listbox
            value={selected}
            onChange={setSelected}
            as="div"
            className={`space-y-2 ${disabled && "opacity-50"}`}
            disabled={disabled}
            ref={dropdownRef}
            hidden={hidden}
        >
            <Label className="input-label">{label}</Label>
            <div className="relative">
                <ListboxButton className="relative input text-start">
                    <span className="block truncate text-xs sm:text-sm">
                        {selected || placeholder}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                            aria-hidden="true"
                            className="size-5 text-gray-400"
                        />
                    </span>
                </ListboxButton>

                <ListboxOptions
                    transition
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                    <div className="sticky -top-1 bg-white p-2 z-20">
                        <input
                            type="text"
                            className="w-full rounded-md border dark:text-gray-500 border-gray-300 px-3 py-2 text-xs sm:text-sm outline-none"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    {isLoading ? (
                        <Spinner />
                    ) : (
                        filteredOptions?.map((person) => (
                            <ListboxOption
                                key={person.name}
                                value={person.value}
                                className="text-xs sm:text-sm group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-blue-600 data-[focus]:text-white"
                            >
                                <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                    {person.name}
                                </span>

                                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                    <CheckIcon
                                        aria-hidden="true"
                                        className="size-5"
                                    />
                                </span>
                            </ListboxOption>
                        ))
                    )}
                </ListboxOptions>
            </div>
            {meta.error && meta.touched && (
                <div className="text-xs tracking-wide text-pink-200 text-start">
                    <p className=" text-xs text-red-600">{meta?.error}</p>
                </div>
            )}
        </Listbox>
    );
}
