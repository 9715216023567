import React from "react";
import "./Loader.css";

const Loader = () => {
    return (
        <div className="w-full h-screen drop-shadow-md flex items-center justify-center flex-col">
            <div className="loader flex gap-1">
                {/* <div class="justify-content-center jimu-primary-loading"></div> */}
            </div>
			<h1 className="font-light text-gray-500 mt-2">Looking For Results...</h1>
        </div>
    );
};

export default Loader;
