import { Chip, Tab, Tabs } from "@nextui-org/react";
import { Edit, More } from "iconsax-react";
import { Plus } from "lucide-react";
import React, { useMemo, useState } from "react";
import CommonButton from "../../common/components/CommonButton";
import OverlayFragment from "../../common/components/OverlayFragment";
import Loader from "../../common/Loader/Loader";
import useGlobalQuery from "../../hooks/useGlobalQuery";
import { ROUTES } from "../../Routes";
import AddSubscriptionPlan from "./AddSubscriptionPlan";
import ExpandableDiv from "../../common/components/ExpandableDiv";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import DeleteModal from "../../common/components/DeleteModal";

const SubscriptionPlans = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { queryData: subscriptionPlans, isLoading: subscriptionPlansLoading } = useGlobalQuery({
        url: ROUTES.SUBSCRIPTION.CRUD,
        methodType: "GET",
        queryKey: ["subscription-plans"],
    });

    const planWiseFilteredData = useMemo(() => {
        return subscriptionPlans?.data?.[0]?.subscriptions;
    }, [subscriptionPlans]);

    const isEmpty = useMemo(() => {
        return planWiseFilteredData?.length === 0;
    }, [planWiseFilteredData]);

    if (subscriptionPlansLoading) {
        return <Loader />;
    }

    return (
        <div className="border-l p-5">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-semibold text-gray-900">Subscription plan</h1>
                {/* 
                <div className="flex items-center gap-5">
                    <CommonButton
                        title={"Add New Subscription Plan"}
                        isGradient={true}
                        isRounded={true}
                        isWithIcon={true}
                        Icon={Plus}
                        buttonStyle={"!w-60 !text-xs"}
                        handleFunction={() => setIsOpen(true)}
                    />
                </div> */}
            </div>

            <div className="flex w-full flex-wrap gap-6 pb-10">
                {planWiseFilteredData?.map((plan) => (
                    <Card plan={plan} />
                ))}
            </div>

            <OverlayFragment
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                children={<AddSubscriptionPlan setIsOpen={setIsOpen} />}
                title={"Add Subscription Plan"}
                size={"2xl"}
            />
        </div>
    );
};

const Card = ({ plan }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    return (
        <div key={plan.name} className="border rounded-lg p-5 bg-white shadow-sm w-96">
            <div className="flex justify-between items-start mb-4">
                <h2 className="text-lg flex items-center gap-3 font-medium text-gray-900">
                    {plan.name}{" "}
                    {plan?.isPopular && (
                        <Chip color="primary" size="sm">
                            Popular
                        </Chip>
                    )}
                </h2>

                <Dropdown>
                    <DropdownTrigger>
                        <button className="p-1.5 rounded-full bg-gray-100">
                            <More className="size-5 text-gray-500 hover:text-black" />
                        </button>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Static Actions">
                        <DropdownItem key="edit" onPress={() => setIsOpen(true)}>
                            Edit Plan
                        </DropdownItem>
                        <DropdownItem
                            key="delete"
                            className="text-danger"
                            color="danger"
                            onPress={() => setIsDelete(true)}
                        >
                            Delete Plan
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>

            <div className="space-y-2 text-sm">
                <div className="flex justify-between items-center">
                    <span className="text-gray-500">Price</span>
                    <span className="text-gray-700">₹{plan?.price}</span>
                </div>

                <div className="flex justify-between items-center">
                    <span className="text-gray-500">Credit</span>
                    <span className="text-gray-700">{plan?.credits}</span>
                </div>

                {plan?.options && (
                    <div className="flex flex-col">
                        <span className="text-gray-500">Options</span>
                        <div className="space-y-1 mt-2">
                            {plan?.options?.map((option) => (
                                <p className="text-gray-700">{option}</p>
                            ))}
                        </div>
                    </div>
                )}

                <div className="flex flex-col">
                    <span className="text-gray-500">Description</span>
                    <ExpandableDiv text={plan?.description} />
                </div>
            </div>

            <OverlayFragment
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                children={<AddSubscriptionPlan isUpdate={true} data={plan} setIsOpen={setIsOpen} />}
                title={"Add Subscription Plan"}
                size={"2xl"}
            />

            <OverlayFragment
                isOpen={isDelete}
                setIsOpen={setIsDelete}
                children={
                    <DeleteModal
                        url={ROUTES.SUBSCRIPTION.CRUD}
                        data={{ id: plan?._id }}
                        queriesToInvalidate={["subscription-plans"]}
                        title={"Delete Plan"}
                        type={"Plan"}
                        setIsOpen={setIsDelete}
                    />
                }
            />
        </div>
    );
};

export default SubscriptionPlans;
