import { Modal, ModalBody, ModalContent, ModalHeader } from "@nextui-org/react";

export default function OverlayFragment({
    isOpen,
    setIsOpen,
    children,
    title,
    size,
}) {
    return (
        <>
            <Modal
                isOpen={isOpen}
                onOpenChange={setIsOpen}
                size={size}
                isDismissable={false}
                scrollBehavior="inside"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            {title && (
                                <ModalHeader className="flex flex-col gap-1 border-b text-xl">
                                    {title}
                                </ModalHeader>
                            )}

                            <ModalBody
                                className={`max-h-[78vh] overflow-auto ${
                                    !title && "mt-5"
                                }`}
                            >
                                {children}
                            </ModalBody>
                            {/* <ModalFooter className="border-t">
								<Button
									variant="light"
									onPress={onClose}
									size="sm"
									className="bg-white border border-blue-500 rounded-full"
								>
									Close
								</Button>
								<Button
									color="primary"
									size="sm"
									className="gradient-blue rounded-full"
									type="submit"
								>
									Submit
								</Button>
							</ModalFooter> */}
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
