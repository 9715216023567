import React, { useEffect, useMemo, useState } from "react";
import {
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    NavbarMenuToggle,
    NavbarMenu,
    NavbarMenuItem,
    Link,
    Button,
    Avatar,
} from "@nextui-org/react";
import Logo from "../../../../common/Logo";
import { useDispatch, useSelector } from "react-redux";
import { customActions } from "../../../../store/custom.slice";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import {
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
} from "@nextui-org/react";
import { profileActions } from "../../../../store/profile.slice";
import useGetAccessToken from "../../../../hooks/Authentication/useGetAccessToken";
import { ROLES } from "../../../../constant/constants";
import { Coin1, Message, Notification } from "iconsax-react";
import { useQueryClient } from "@tanstack/react-query";
import { clearAllCookies } from "../../../../common/ReusableFunctions";
import Notifications from "./Notifications";
import OverlayFragment from "../../../../common/components/OverlayFragment";
import AddRating from "../../../../common/components/Rating/AddRating";
import CommonButton from "../../../../common/components/CommonButton";
import useIsMobile from "../../../../hooks/useIsMobile";
import DarkModeToggler from "../../../../common/DarkModeToggler";

export default function NavigationBar() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeNavigationTab = useSelector(
        (state) => state.custom.activeNavigationTab
    );
    const { profileDetails } = useSelector((state) => state.profile);
    const { removeCookie } = useGetAccessToken();
    const { pathname } = useLocation();
    const { isMobile } = useIsMobile();
    const queryClient = useQueryClient();

    const handleTabClick = (tabName) => {
        dispatch(customActions.changeActiveNavigationTab(tabName));
        const href = activeNavigationTab?.find(
            (tab) => tab.name === tabName
        ).href;
        navigate(href);
        setIsMenuOpen(false);
    };

    const handleLogout = () => {
        dispatch(profileActions.setProfileDetails({}));
        removeCookie("accessToken");
        clearAllCookies();
        queryClient.clear();
        navigate("/");
    };

    const href = useMemo(() => {
        switch (profileDetails?.role?.[0]) {
            case ROLES.CLIENT:
                return "/profile/client";
            case ROLES.EMPLOYEE:
                return "/profile/employee";
            case ROLES.HR:
                return "/profile/hr";
            case ROLES.FREELANCER:
                return "/profile/freelancer";
            default:
                return null;
        }
    }, [profileDetails]);

    useEffect(() => {
        switch (pathname) {
            case "/":
                dispatch(customActions.changeActiveNavigationTab("Home"));
                break;
            case "/jobs":
                dispatch(customActions.changeActiveNavigationTab("Jobs"));
                break;
            case "/freelancers":
                dispatch(
                    customActions.changeActiveNavigationTab("Freelancers")
                );
                break;
            case "/talents":
                dispatch(customActions.changeActiveNavigationTab("Talents"));
                break;
            case "/gigs":
                dispatch(customActions.changeActiveNavigationTab("Gigs"));
                break;
            default:
                break;
        }
    }, [dispatch, pathname]);

    return (
        <Navbar
            isMenuOpen={isMenuOpen}
            onMenuOpenChange={setIsMenuOpen}
            className="sm:px-10 bg-white"
            maxWidth="full"
            isBordered
        >
            <NavbarContent>
                <NavbarMenuToggle
                    aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                    className="sm:hidden"
                />
                <NavbarBrand>
                    <Logo className="hover:scale-110 ease-in-out duration-300 cursor-pointer transition-all" />
                </NavbarBrand>
            </NavbarContent>

            <NavbarContent className="hidden sm:flex gap-10" justify="center">
                {activeNavigationTab?.map((item) => (
                    <NavbarItem
                        key={item.name}
                        isActive={item.status === "Active"}
                        className={`${
                            item.status === "Active"
                                ? "border-b-blue-600"
                                : "border-none"
                        }
                        border-b-4 pt-3 pb-2 transition-all duration-100`}
                    >
                        <p
                            className="flex flex-col items-center gap-1 text-sm cursor-pointer "
                            color={
                                item.status === "Active"
                                    ? "primary"
                                    : "foreground"
                            }
                            onClick={() => handleTabClick(item.name)}
                        >
                            <item.icon size={20} />
                            {item.name}
                        </p>
                    </NavbarItem>
                ))}
            </NavbarContent>

            {Object.keys(profileDetails)?.length > 0 ? (
                <NavbarContent justify="end">
                    <NavbarItem className="flex gap-2 sm:gap-5 items-center">
                        <div>
                            {!isMobile && (
                                <CommonButton
                                    title={"Add Credits"}
                                    isGradient={true}
                                    isRounded={true}
                                    handleFunction={() =>
                                        navigate("/subscription")
                                    }
                                    isWithIcon={true}
                                    Icon={Coin1}
                                    buttonStyle={
                                        "!text-xs !bg-gradient-to-r !from-pink-400 !to-blue-500"
                                    }
                                />
                            )}
                        </div>

                        {profileDetails?.role?.[0] === ROLES.CLIENT && (
                            <div>
                                <CommonButton
                                    title={"Post Gig"}
                                    isGradient={true}
                                    isRounded={true}
                                    handleFunction={() =>
                                        navigate("/opportunity/gig")
                                    }
                                    buttonStyle={"!text-xs"}
                                />
                            </div>
                        )}

                        {profileDetails?.role?.[0] === ROLES.HR && (
                            <div>
                                <CommonButton
                                    title={"Post Job"}
                                    isGradient={true}
                                    isRounded={true}
                                    handleFunction={() =>
                                        navigate("/opportunity/job")
                                    }
                                    buttonStyle={"!text-xs"}
                                />
                            </div>
                        )}
                        {/* <DarkModeToggler /> */}
                        <Message
                            className="cursor-pointer"
                            onClick={() => navigate("/chats")}
                        />
                        <Notifications />
                        <Dropdown>
                            <DropdownTrigger>
                                <Avatar
                                    isBordered
                                    as="button"
                                    className="transition-transform"
                                    src={`${
                                        profileDetails?.imgUrl
                                            ? `${
                                                  profileDetails?.imgUrl
                                              }?${Date.now()}`
                                            : `https://ui-avatars.com/api/?name=${
                                                  profileDetails?.firstname +
                                                  " " +
                                                  profileDetails?.lastname
                                              }&background=random`
                                    }`}
                                    size="sm"
                                />
                            </DropdownTrigger>
                            <DropdownMenu aria-label="Static Actions">
                                <DropdownItem
                                    key="new"
                                    // onClick={() => {
                                    // 	if (
                                    // 		profileDetails?.role?.includes(ROLES.FREELANCER) ||
                                    // 		profileDetails?.role?.includes(ROLES.EMPLOYEE)
                                    // 	) {
                                    // 		navigate("/profile/freelancer");
                                    // 	} else if (
                                    // 		profileDetails?.role?.includes(ROLES.CLIENT) ||
                                    // 		profileDetails?.role?.includes(ROLES.HR)
                                    // 	) {
                                    // 		navigate("profile/client");
                                    // 	}
                                    // }}
                                    href={href}
                                >
                                    Your Profile
                                </DropdownItem>
                                <DropdownItem
                                    key="delete"
                                    className="text-danger"
                                    color="danger"
                                    onClick={handleLogout}
                                >
                                    Logout
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </NavbarItem>
                </NavbarContent>
            ) : (
                <NavbarContent justify="end">
                    <NavbarItem className="hidden lg:flex">
                        <Link href="/register">Sign up</Link>
                    </NavbarItem>
                    <NavbarItem>
                        <Button
                            as={Link}
                            className="gradient-blue text-white rounded-full"
                            href="#"
                            variant="flat"
                            onClick={() => navigate("/login")}
                            size={isMobile ? "sm" : "md"}
                        >
                            Log In
                        </Button>
                    </NavbarItem>
                </NavbarContent>
            )}

            {/* Mobile Menu */}
            <NavbarMenu>
                {activeNavigationTab?.map((item) => (
                    <NavbarMenuItem key={item.name}>
                        <Link
                            color={
                                item.status === "Active"
                                    ? "primary"
                                    : "foreground"
                            }
                            className="w-full flex items-center gap-2"
                            size="lg"
                            onClick={() => handleTabClick(item.name)}
                        >
                            <item.icon size={20} />
                            {item.name}
                        </Link>
                    </NavbarMenuItem>
                ))}
            </NavbarMenu>

            {/* <Button onPress={() => setOpenRating(true)}>Add Rating</Button> */}
        </Navbar>
    );
}
