export const featureData = [
    {
        title: "Artist-Focused Social Network",
        description:
            "Instantly connect with available artists for faster hiring.",
    },
    {
        title: "Over 700 categories",
        description:
            "Get results from skilled freelancers from all over the world, for every task, at any price point.",
    },
    {
        title: "Clear, transparent pricing",
        description:
            "Pay per project or by the hour (Pro).Payments only get released when you approve.",
    },
    {
        title: "AI-Powered Recommendations",
        description:
            "Discover the best artists for your needs with smart matching technology.",
    },
];
