import { Warning2 } from "iconsax-react";
import React from "react";
import useGlobalMutation from "../../hooks/useGlobalMutation";
import CommonButton from "./CommonButton";
import ErrorMessage from "./ErrorMessage";

const DeleteModal = ({
    data,
    url,
    setIsOpen,
    queriesToInvalidate,
    title,
    type,
}) => {
    const { runMutation, mutationLoading, mutationError } = useGlobalMutation({
        url,
        methodType: "DELETE",
        closePopup: setIsOpen,
        queriesToInvalidate,
        data,
    });

    return (
        <div className="pb-3">
            <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 dark:bg-dark-light sm:mx-0 sm:h-10 sm:w-10">
                    <Warning2
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                    />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <div
                        as="h3"
                        className="text-base dark:text-dark-text-white capitalize font-semibold leading-6 text-gray-900"
                    >
                        {title}
                    </div>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Are you sure you want to delete this{" "}
                            <span className="capitalize">
                                {type}? This {type}
                            </span>{" "}
                            will be permanently removed from our servers
                            forever. This action cannot be undone.
                        </p>
                    </div>
                </div>
            </div>
            <div className="p-2 ml-10">
                {mutationError && <ErrorMessage message={mutationError} />}
            </div>
            <div className="flex items-center justify-end gap-3 mt-3">
                <CommonButton
                    title={"Cancel"}
                    width={"w-10"}
                    handleFunction={() => setIsOpen(false)}
                />
                <CommonButton
                    title={"Delete"}
                    buttonStyle={"!bg-red-500 text-white"}
                    handleFunction={() => {
                        runMutation();
                    }}
                    disabled={mutationLoading}
                    loading={mutationLoading}
                    width={"w-10"}
                />
            </div>
        </div>
    );
};

export default DeleteModal;
