import React from "react";

const Stepper = ({ steps, activeStep, setActiveStep }) => {
	const handleSetActiveStep = (stepName) => {
		setActiveStep(stepName);
	};

	return (
		<div>
			<h2 className="sr-only">Steps</h2>

			<div>
				<ol className="grid grid-cols-1  divide-gray-100 overflow-hidden rounded-lg border border-gray-300 text-sm text-gray-500 sm:grid-cols-3">
					{steps?.map((step, index) =>
						index === 0 ? (
							step === activeStep ? (
								<Active0IndexStep
									stepName={step}
									handleSetActiveStep={handleSetActiveStep}
								/>
							) : (
								<Inactive0IndexStep
									stepName={step}
									handleSetActiveStep={handleSetActiveStep}
								/>
							)
						) : step === activeStep ? (
							<ActiveStep
								stepName={step}
								handleSetActiveStep={handleSetActiveStep}
							/>
						) : (
							<InactiveStep
								stepName={step}
								handleSetActiveStep={handleSetActiveStep}
							/>
						)
					)}
				</ol>
			</div>
		</div>
	);
};

const Active0IndexStep = ({ stepName, handleSetActiveStep }) => {
	return (
		<li
			className="relative flex items-center justify-center gap-2 bg-blue-500 text-white p-4 cursor-pointer"
			onClick={() => handleSetActiveStep(stepName)}
		>
			{/* <span className="absolute -left-16 top-1/2 hidden size-20 -translate-y-1/2 rotate-45 border-gray-100 sm:block border-t border-r bg-white z-20"></span> */}

			<span className="absolute -right-2 top-1/2 hidden size-20 -translate-y-1/2 rotate-45 border border-blue-500 sm:block border-t border-r bg-blue-500 z-20"></span>

			<p className="leading-none text-sm uppercase z-30">{stepName}</p>
		</li>
	);
};

const Inactive0IndexStep = ({ stepName, handleSetActiveStep }) => {
	return (
		<li
			className="relative flex items-center justify-center border-none bg-white p-4 cursor-pointer"
			onClick={() => handleSetActiveStep(stepName)}
		>
			<p className="leading-none text-sm uppercase">{stepName}</p>
		</li>
	);
};

const ActiveStep = ({ stepName, handleSetActiveStep }) => {
	return (
		<li
			className="relative flex items-center justify-center gap-2 bg-blue-500 text-white p-4 cursor-pointer" 
			onClick={() => handleSetActiveStep(stepName)}
		>
			<span className="absolute -left-16 top-1/2 hidden size-20 -translate-y-1/2 rotate-45 border-gray-100 sm:block border-t border-r bg-white z-20"></span>

			<span className="absolute -right-2 top-1/2 hidden size-20 -translate-y-1/2 rotate-45 border border-blue-500 sm:block border-t border-r bg-blue-500 z-20"></span>

			<p className="leading-none text-sm uppercase z-30">{stepName}</p>
		</li>
	);
};

const InactiveStep = ({ stepName, handleSetActiveStep }) => {
	return (
		<li
			className="relative flex items-center justify-center gap-2 p-4 bg-white cursor-pointer"
			onClick={() => handleSetActiveStep(stepName)}
		>
			<span className="absolute -left-16 top-1/2 hidden size-16 -translate-y-1/2 rotate-45 border-gray-300 sm:block border-t border-r bg-white"></span>
			<p className="leading-none text-sm uppercase">{stepName}</p>
		</li>
	);
};

// const InactiveLastStep = ({ stepName, handleSetActiveStep }) => {
// 	return (
// 		<li
// 			className="relative flex items-center justify-center gap-2 p-4 cursor-pointer"
// 			onClick={() => handleSetActiveStep(stepName)}
// 		>
// 			<span className="absolute -left-16 top-1/2 hidden size-20 -translate-y-1/2 rotate-45 border-gray-100 sm:block border-t border-r bg-white z-20"></span>

// 			<p className="leading-none text-sm uppercase">{stepName}</p>
// 		</li>
// 	);
// };

export default Stepper;
