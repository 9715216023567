import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useField } from "formik";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import Spinner from "../Spinner";

export default function FormMultiSelect({
    label,
    options,
    placeholder,
    disabled,
    name,
    maxLabelCount,
    isLoading,
}) {
    const [, meta, helpers] = useField(name);

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredPeople, setFilteredPeople] = useState(options ?? []);
    const [selected, setSelected] = useState(meta?.value ?? []);

    useEffect(() => {
        setFilteredPeople(
            options?.filter((person) =>
                person?.name?.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery, options]);

    useEffect(() => {
        setFilteredPeople(options);
    }, [options]);

    const removeFromSelected = (optionToRemove) => {
        const filteredOptions = [...selected]?.filter(
            (option) => option !== optionToRemove
        );
        setSelected(filteredOptions);
    };

    useEffect(() => {
        helpers.setValue(selected);
    }, [selected, helpers]);

    return (
        <Listbox
            disabled={disabled}
            value={selected}
            onChange={setSelected}
            multiple
            as={"div"}
        >
            <Label className="input-label">{label}</Label>
            <div className="relative mt-1">
                <ListboxButton className="relative input text-start">
                    <span className="block truncate">
                        {selected?.length === 0 ? (
                            placeholder
                        ) : (
                            <div className="flex flex-wrap gap-2 items-center">
                                {selected &&
                                    selected
                                        ?.slice(0, maxLabelCount)
                                        ?.map((item) => (
                                            <span
                                                key={item}
                                                className="text-xs bg-gray-100 text-black dark:text-white font-semibold border px-3 py-1 rounded-full flex gap-2 items-center"
                                            >
                                                {item}
                                                <X
                                                    className="size-4 hover:bg-gray-500 p-0.5 rounded-full text-white bg-gray-400"
                                                    onClick={() =>
                                                        removeFromSelected(item)
                                                    }
                                                />
                                            </span>
                                        ))}
                                {selected?.length > maxLabelCount && (
                                    <span className="text-xs bg-gray-200 text-black dark:text-white font-semibold border px-3 py-1 rounded-full">
                                        +{selected?.length - maxLabelCount}
                                    </span>
                                )}
                            </div>
                        )}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                        />
                    </span>
                </ListboxButton>

                <ListboxOptions className="absolute z-20 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm">
                    <div className="sticky -top-1 bg-white dark:bg-dark-light p-2 z-20">
                        <input
                            type="text"
                            className="w-full rounded-md border dark:text-gray-500 border-gray-300 px-3 py-2 text-xs sm:text-sm outline-none"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    {isLoading ? (
                        <Spinner />
                    ) : (
                        filteredPeople?.map((person) => (
                            <ListboxOption
                                key={person?.name}
                                value={person?.value}
                                className={({ active, selected }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active
                                            ? "bg-blue-600 dark:bg-dark-light text-white"
                                            : "text-gray-900 dark:text-white"
                                    }`
                                }
                            >
                                {({ selected, active }) => (
                                    <>
                                        <span
                                            className={`block truncate text-xs sm:text-sm ${
                                                selected
                                                    ? "font-semibold"
                                                    : "font-normal"
                                            }`}
                                        >
                                            {person?.name}
                                        </span>
                                        {selected ? (
                                            <span
                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                    active
                                                        ? "text-white"
                                                        : "text-blue-600"
                                                }`}
                                            >
                                                <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </ListboxOption>
                        ))
                    )}
                </ListboxOptions>
            </div>
            {meta.error && meta.touched && (
                <div className="text-xs tracking-wide text-pink-200 text-start mt-1">
                    <p className=" text-xs text-red-600">{meta?.error}</p>
                </div>
            )}
        </Listbox>
    );
}
