import { Facebook, Figma, Instagram, Link, TickCircle } from "iconsax-react";
import { ChevronRight, ExternalLink } from "lucide-react";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import useGlobalQuery from "../../../hooks/useGlobalQuery";
import { ROUTES } from "../../../Routes";

const FreelancerLeftSidePanel = () => {
    const { profileDetails } = useSelector((state) => state.profile);

    const { queryData, isLoading } = useGlobalQuery({
        url: ROUTES.AUTHENTICATION.PROFILE_COMPLETION,
        methodType: "GET",
        data: {},
        queryKey: ["profile-completion"],
        staleTime: 0,
        cacheTime: 0,
    });

    // Memoized calculation of profile completion percentage
    const profileCompletion = useMemo(() => {
        if (!queryData) return "0";

        const data = queryData.data || {};
        const totalFields = Object.keys(data).length;
        const completedFields = Object.values(data).filter(
            (value) => value === true
        )?.length;
        const percentage = (completedFields / totalFields) * 100;
        return percentage?.toFixed(0) ?? 0;
    }, [queryData]);
    
    return (
        <div className="max-w-[292px] min-w-[292px] w-full rounded-xl bg-white border p-6 space-y-6 shadow-sm">
            <section>
                <div className="flex justify-between items-center mb-2">
                    <h2 className="font-medium">Complete your profile</h2>
                </div>
                <div className="flex justify-between items-center gap-5">
                    <div className="h-3 bg-gray-200 rounded-full w-full">
                        <div
                            className="h-full rounded-full bg-gradient-to-r from-pink-400 to-blue-500 "
                            style={{ width: `${profileCompletion}%` }}
                        />
                    </div>
                    <span className="text-blue-500 font-medium text-xs text-nowrap">
                        {profileCompletion} %
                    </span>
                </div>
            </section>

            <section>
                <h2 className="font-medium mb-2">Language Known</h2>
                <div className="flex flex-wrap gap-1">
                    {profileDetails?.languagesKnown?.map((item, index) => (
                        <div className="text-gray-600 text-xs" key={index}>
                            {item}
                            {index < profileDetails.languagesKnown.length - 1 &&
                                ","}
                        </div>
                    ))}
                </div>
            </section>

            <section>
                <div className="flex items-center justify-between">
                    <h2 className="font-medium">Status</h2>
                    <div className="flex items-center text-emerald-700 text-sm">
                        <TickCircle className="w-4 h-4 mr-1 fill-green-700 text-white" />
                        <span>Available</span>
                    </div>
                </div>
            </section>

            {/* <section>
                <div className="flex items-center justify-between cursor-pointer">
                    <h2 className="font-medium">Resume</h2>
                    <ChevronRight className="w-5 h-5 text-gray-400" />
                </div>
            </section> */}

            <div>
                <h2 className="font-medium mb-3">On the web</h2>
                <div className="border rounded-lg divide-y last:border-none">
                    {profileDetails?.webLinks?.map((item) => (
                        <div className="flex items-center justify-between p-2">
                            <div className="flex items-center text-sm">
                                {item?.type === "instagram" ? (
                                    <Instagram className="size-7 mr-3 bg-black text-white rounded-full p-1" />
                                ) : item?.type === "facebook" ? (
                                    <Facebook className="size-7 mr-3 bg-black text-white rounded-full p-1" />
                                ) : item?.type === "figma" ? (
                                    <Figma className="size-7 mr-3 bg-black text-white rounded-full p-1" />
                                ) : (
                                    <Link className="size-7 mr-3 bg-black text-white rounded-full p-1" />
                                )}
                                <span className="capitalize">{item.type}</span>
                            </div>
                            <p
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() =>
                                    window.open(
                                        item?.link?.startsWith("http")
                                            ? item?.link
                                            : `https://${item?.link}`,
                                        "_blank"
                                    )
                                }
                            >
                                <ExternalLink className="w-4 h-4 cursor-pointer text-gray-500" />
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FreelancerLeftSidePanel;
