import {
	Dialog,
	DialogBackdrop,
	DialogPanel,
	Menu
} from "@headlessui/react";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Slider } from "@nextui-org/react";
import { useContext, useEffect, useState } from "react";
import FormikStateMonitor from "../../../common/FormikStateMonitor";
import FormCheckbox from "../../../common/components/FormFields/FormCheckbox";
import FormMultiSelect from "../../../common/components/FormFields/FormMultiselect";
import FormInputField from "../../../common/components/FormInputField";
import {
	JobExperienceLevel,
	Skills,
	TypeOfGigs,
} from "../../../constant/constants";
import FormikFormGenerator from "../../../utils/FormikFormGenerator";
import { ProjectDurationOptions } from "../../Home/components/FilterList";
import { useLocation } from "react-router-dom";

export default function Filters({
    children,
    filterContainerClassname,
    context,
}) {
    const { setFilters } = useContext(context);
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
    const [budget, setBudget] = useState(5000);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const search = queryParams.get("search");

    useEffect(() => {
        setFilters((prev) => {
            return {
                ...prev,
                jobCostEstimate: budget,
            };
        });
    }, [budget, setFilters]);

    return (
        <div className="bg-white">
            <div>
                {/* Mobile filter dialog */}
                <Dialog
                    open={mobileFiltersOpen}
                    onClose={setMobileFiltersOpen}
                    className="relative z-50 lg:hidden"
                >
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                    />

                    <div className="fixed inset-0 z-40 flex">
                        <DialogPanel
                            transition
                            className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 sm:pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
                        >
                            <div className="flex items-center justify-between px-4">
                                <h2 className="text-lg font-medium text-gray-900">
                                    Filters
                                </h2>
                                <button
                                    type="button"
                                    onClick={() => setMobileFiltersOpen(false)}
                                    className="relative -mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                >
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="h-6 w-6"
                                    />
                                </button>
                            </div>

                            {/* Mobile Filters */}
                            <form className="mt-4 border-t border-gray-200">
                                <FormikFormGenerator
                                    initialValues={{
                                        jobTitle: "",
                                        location: "",
                                        talentBadge: "",
                                        jobScope: "",
                                        skillsRequired: [],
                                        jobCostEstimate: "",
                                        jobExperienceLevel: "",
                                        jobCategory: "",
                                    }}
                                    formClassName={"space-y-5 p-5"}
                                >
                                    <FormikStateMonitor onChange={setFilters} />

                                    <FormInputField
                                        name="jobTitle"
                                        label={"Project Title"}
                                        placeholder={"Ex. Logo Design"}
                                    />

                                    <FormInputField
                                        name="location"
                                        label={"Preferred Job Location"}
                                        placeholder={"Enter location"}
                                    />

                                    <FormCheckbox
                                        name={"jobExperienceLevel"}
                                        label={"Experience Level"}
                                        options={JobExperienceLevel}
                                    />

                                    <Slider
                                        size="md"
                                        step={5000}
                                        color="primary"
                                        label="Budget Range"
                                        showSteps={true}
                                        maxValue={50000}
                                        minValue={0}
                                        defaultValue={5000}
                                        className="max-w-md"
                                        value={budget}
                                        onChange={setBudget}
                                    />

                                    <FormCheckbox
                                        name={"jobScope"}
                                        label={"Project Duration"}
                                        options={ProjectDurationOptions}
                                    />

                                    <div>
                                        <FormMultiSelect
                                            name="skillsRequired"
                                            label={"Skills Required"}
                                            placeholder={
                                                "Select required skills"
                                            }
                                            options={Skills}
                                            maxLabelCount={2}
                                        />
                                    </div>

                                    <FormCheckbox
                                        name={"jobCategory"}
                                        label={"All Categories"}
                                        options={TypeOfGigs}
                                    />
                                </FormikFormGenerator>
                            </form>
                        </DialogPanel>
                    </div>
                </Dialog>

                <main className="mx-auto max-w-[90vw] relative">
                    <div className="flex items-baseline justify-between border-gray-200">
                        <div className="flex items-center justify-end">
                            <button
                                type="button"
                                onClick={() => setMobileFiltersOpen(true)}
                                className=" text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden absolute right-5 top-5"
                            >
                                <span className="sr-only">Filters</span>
                                <FunnelIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                />
                            </button>
                        </div>
                    </div>

                    <section
                        aria-labelledby="products-heading"
                        className="min-h-screen"
                    >
                        <div className="flex gap-x-8 py-5">
                            {/* Filters */}
                            <div className="hidden lg:block   max-w-xs w-full">
                                <h3 className="sr-only">Categories</h3>

                                <div
                                    className={`sticky top-20 border p-4 rounded-xl ${filterContainerClassname}`}
                                >
                                    <FormikFormGenerator
                                        initialValues={{
                                            jobTitle: "",
                                            location: "",
                                            talentBadge: "",
                                            jobScope: "",
                                            skillsRequired: [],
                                            jobCostEstimate: "",
                                            jobExperienceLevel: "",
                                            jobCategory: "",
                                        }}
                                        formClassName={`space-y-5 ${filterContainerClassname}`}
                                    >
                                        <FormikStateMonitor
                                            onChange={setFilters}
                                        />

                                        <FormInputField
                                            name="jobTitle"
                                            label={"Project Title"}
                                            placeholder={"Ex. Logo Design"}
                                        />

                                        <FormInputField
                                            name="location"
                                            label={"Preferred Job Location"}
                                            placeholder={"Enter location"}
                                        />

                                        <FormCheckbox
                                            name={"jobExperienceLevel"}
                                            label={"Experience Level"}
                                            options={JobExperienceLevel}
                                        />

                                        <Slider
                                            size="md"
                                            step={5000}
                                            color="primary"
                                            label="Budget Range"
                                            showSteps={true}
                                            maxValue={50000}
                                            minValue={0}
                                            defaultValue={5000}
                                            className="max-w-md"
                                            value={budget}
                                            onChange={setBudget}
                                        />

                                        <FormCheckbox
                                            name={"jobScope"}
                                            label={"Project Duration"}
                                            options={ProjectDurationOptions}
                                        />

                                        <div>
                                            <FormMultiSelect
                                                name="skillsRequired"
                                                label={"Skills Required"}
                                                placeholder={
                                                    "Select required skills"
                                                }
                                                options={Skills}
                                                maxLabelCount={2}
                                            />
                                        </div>

                                        <FormCheckbox
                                            name={"jobCategory"}
                                            label={"All Categories"}
                                            options={TypeOfGigs}
                                        />
                                    </FormikFormGenerator>
                                </div>
                            </div>

                            {children}
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
}
