import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const StackChart = () => {
    const ref = useRef();
    const options = {
        chart: {
            type: "column",
        },

        title: {
            text: "Olympic Games all-time medal table, grouped by continent",
            align: "left",
        },

        xAxis: {
            categories: ["Gold", "Silver", "Bronze"],
        },

        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: "Count medals",
            },
        },

        tooltip: {
            format:
                "<b>{key}</b><br/>{series.name}: {y}<br/>" +
                "Total: {point.stackTotal}",
        },

        plotOptions: {
            column: {
                stacking: "normal",
            },
        },

        series: [
            {
                name: "Norway",
                data: [148, 133, 124],
                stack: "Europe",
            },
            {
                name: "Germany",
                data: [102, 98, 65],
                stack: "Europe",
            },
            {
                name: "United States",
                data: [113, 122, 95],
                stack: "North America",
            },
            {
                name: "Canada",
                data: [77, 72, 80],
                stack: "North America",
            },
        ],
    };

    return (
        <div className="w-full min-w-[30vw] sm:max-w-[30vw] h-[300px]  bg-white dark:bg-dark-normal dark:shadow-dark-light-gray dark:shadow-sm dark:text-white p-3 rounded-2xl drop-shadow-sm flex flex-col max-h-[445px] shadow-sm hover:shadow-md border dark:border-0">
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={ref}
            />
        </div>
    );
};

export default StackChart;
