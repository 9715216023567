import { configureStore } from "@reduxjs/toolkit";
import customSlice from "./custom.slice";
import profileSlice from "./profile.slice";
import messageSlice from "./message.slice";

const store = configureStore({
    reducer: {
        custom: customSlice,
        profile: profileSlice,
        message: messageSlice,

    },
});

export default store;
