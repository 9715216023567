import React from "react";

const ChatCardLoader = () => {
    return (
        <div class="flex items-center gap-5 max-w-xs cursor-pointer p-2 rounded-lg animate-pulse">
            <div class="flex items-center gap-4">
                <div class="h-10 w-10 bg-gray-200 rounded-full"></div>
            </div>
            <div className="w-full space-y-2">
                <div class="h-4 bg-gray-200 rounded w-2/3"></div>
                <div class="h-4 bg-gray-200 rounded w-1/3"></div>
            </div>
        </div>
    );
};

export default ChatCardLoader;
