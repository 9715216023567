import React, { useEffect, useMemo, useState } from "react";
import {
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
    Button,
} from "@nextui-org/react";
import { ArrowDown2 } from "iconsax-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounceEffect } from "../../../common/ReusableFunctions";
import useGlobalMutation from "../../../hooks/useGlobalMutation";
import { ROUTES } from "../../../Routes";

const SearchBar = ({ maxWidth }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchKey = queryParams.get("search");
    const [searchType, setSearchType] = useState();
    const [search, setSearch] = useState(searchKey || "");

    const { runMutation } = useGlobalMutation({
        url: ROUTES.FEATURE.TRENDING_SEARCH,
        methodType: "POST",
        data: {
            title: search,
        },
    });

    useEffect(() => {
        switch (location.pathname) {
            case "/gigs":
                return setSearchType("Gigs");
            case "/jobs":
                return setSearchType("Jobs");
            case "/freelancers":
                return setSearchType("Freelancers");
            case "/talents":
                return setSearchType("Talents");
            default:
                return setSearchType("Jobs");
        }
    }, [location.pathname]);

    const navigate = useNavigate();

    const handleSearch = () => {
        if (!searchType) return;
        runMutation();
        navigate(`/${searchType.toLowerCase()}?search=${search}`);
    };

    useEffect(() => {
        setSearch(searchKey || "");
    }, [searchKey]);

    return (
        <div
            className={`flex items-center bg-gray-100 rounded-full  py-1 pl-4 pr-1  sm:py-1 w-full ${maxWidth}`}
        >
            <input
                type="text"
                placeholder="What are you looking for?"
                className="bg-transparent flex-1 focus:outline-none text-gray-700 placeholder-gray-400 
                           border-r-2 border-gray-300 pr-2 sm:pr-4 text-xs sm:text-sm"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            />

            <Dropdown>
                <DropdownTrigger>
                    <Button
                        variant="light"
                        size="sm"
                        className="flex items-center text-xs sm:text-sm"
                    >
                        {searchType} <ArrowDown2 className="ml-1 size-3" />
                    </Button>
                </DropdownTrigger>
                <DropdownMenu
                    aria-label="Select Search Type"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={new Set([searchType])}
                    onSelectionChange={(keys) =>
                        setSearchType(Array.from(keys)[0])
                    }
                >
                    <DropdownItem key="Jobs">Jobs</DropdownItem>
                    <DropdownItem key="Gigs">Gigs</DropdownItem>
                    <DropdownItem key="Freelancers">Freelancers</DropdownItem>
                    <DropdownItem key="Talents">Talents</DropdownItem>
                </DropdownMenu>
            </Dropdown>

            <button
                className="gradient-blue text-white rounded-full p-1.5 sm:p-2 ml-2 flex items-center justify-center"
                onClick={handleSearch}
            >
                <svg
                    className="w-5 h-5 sm:w-6 sm:h-6"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
            </button>
        </div>
    );
};

export default SearchBar;
