import React, { useState } from "react";
import useGlobalQuery from "../../hooks/useGlobalQuery";
import { ROUTES } from "../../Routes";
import Loader from "../../common/Loader/Loader";
import {
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
    Button,
} from "@nextui-org/react";
import { formatText } from "../../utils/common";
import { More } from "iconsax-react";
import OverlayFragment from "../../common/components/OverlayFragment";
import DeleteModal from "../../common/components/DeleteModal";
import useGlobalMutation from "../../hooks/useGlobalMutation";
import Spinner from "../../common/components/Spinner";

const RequestTable = () => {
    const { queryData, isLoading } = useGlobalQuery({
        url: ROUTES.REQUEST.GET_ALL,
        methodType: "GET",
        queryKey: [ROUTES.REQUEST.GET_ALL],
    });

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="border-l p-5">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-semibold text-gray-900">
                    Requests
                </h1>
            </div>

            <div className="w-full max-w-xl rounded-lg border border-gray-200 bg-white">
                {/* Table Header */}
                <div className="grid grid-cols-3 items-center border-b px-6 py-3 text-sm font-medium text-gray-500">
                    <div className="text-left">EMAIL</div>
                    <div className="text-center">STATUS</div>
                    <div className="text-right">ACTION</div>
                </div>

                {/* Table Rows */}
                <div className="divide-y divide-gray-200 max-h-[63vh] overflow-auto">
                    {queryData?.data?.map((action, index) => (
                        <Card data={action} key={index} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const Card = ({ data }) => {
    const { runMutation, mutationLoading } = useGlobalMutation({
        url: ROUTES.REQUEST.GET_ALL + `/${data?._id}/status`,
        methodType: "PUT",
        queriesToInvalidate: [ROUTES.REQUEST.GET_ALL],
    });

    return (
        <div
            key={data?._id}
            className="grid grid-cols-3 items-center px-6 py-4 text-sm"
        >
            <div className="text-gray-600 text-left truncate">
                {data?.email}
            </div>
            <div className="text-gray-600 text-center capitalize">
                {mutationLoading ? <Spinner /> : data?.status}
            </div>
            {data?.status === "open" && (
                <div className="flex justify-end">
                    <Dropdown>
                        <DropdownTrigger>
                            <button className="p-1.5 rounded-full bg-gray-100 size-8">
                                <More className="size-5 text-gray-500 hover:text-black" />
                            </button>
                        </DropdownTrigger>
                        <DropdownMenu
                            aria-label="Static Actions"
                            disabledKeys={
                                mutationLoading && ["accept", "reject"]
                            }
                        >
                            <DropdownItem
                                key="accept"
                                onPress={() => {
                                    runMutation({
                                        isPriorityDataAvailable: true,
                                        priorityData: {
                                            status: "approved",
                                        },
                                    });
                                }}
                            >
                                Accept
                            </DropdownItem>
                            <DropdownItem
                                key="reject"
                                color="danger"
                                onPress={() => {
                                    runMutation({
                                        isPriorityDataAvailable: true,
                                        priorityData: {
                                            status: "rejected",
                                        },
                                    });
                                }}
                            >
                                Reject
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            )}
        </div>
    );
};

export default RequestTable;
