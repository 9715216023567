import { ROUTES } from "../../Routes";
import useGlobalMutation from "../useGlobalMutation";
import useGlobalQuery from "../useGlobalQuery";

export const useRegistration = ({ data }) => {
    const { runMutation, mutationLoading, mutationData, mutationError } = useGlobalMutation({
        url: ROUTES.AUTHENTICATION.REGISTER,
        methodType: "POST",
        data,
    });
    return { runMutation, mutationLoading, mutationData, mutationError };
};

export const useValidateEmail = ({ data }) => {
    const { runMutation, mutationLoading, mutationData, mutationError } = useGlobalMutation({
        url: ROUTES.AUTHENTICATION.VALIDATE_EMAIL,
        methodType: "POST",
        data,
    });
    return { runMutation, mutationLoading, mutationData, mutationError };
};

export const useLogin = ({ data }) => {
    const { runMutation, mutationLoading, mutationData, mutationError, isMutationSucceeded } = useGlobalMutation({
        url: ROUTES.AUTHENTICATION.LOGIN,
        methodType: "POST",
        data,
    });
    return { runMutation, mutationLoading, mutationData, mutationError, isMutationSucceeded };
};

export const useSendOtp = ({ data }) => {
    const { runMutation, mutationLoading, mutationData, mutationError } = useGlobalMutation({
        url: ROUTES.AUTHENTICATION.SEND_OTP,
        methodType: "PUT",
        data,
    });
    return { runMutation, mutationLoading, mutationData, mutationError };
};

export const useResetPassword = ({ data }) => {
    const { runMutation, mutationLoading, mutationData, mutationError } = useGlobalMutation({
        url: ROUTES.AUTHENTICATION.RESET_PASSWORD,
        methodType: "PUT",
        data,
    });
    return { runMutation, mutationLoading, mutationData, mutationError };
};

export const useGetMyProfile = () => {
    const { queryData, isLoading } = useGlobalQuery({
        url: ROUTES.AUTHENTICATION.MY_PROFILE,
        methodType: "GET",
        queryKey: ["my-profile"],
    });

    return { queryData, isLoading };
};

export const useUpdateMyProfile = ({ data, isFormData, excludedIndexKeys }) => {
    const { runMutation, mutationLoading, mutationData, mutationError, isMutationSucceeded } = useGlobalMutation({
        url: ROUTES.AUTHENTICATION.MY_PROFILE,
        methodType: "PUT",
        data,
        isFormData,
        excludedIndexKeys,
        queriesToInvalidate: ["userProfile"],
    });
    return { runMutation, mutationLoading, mutationData, mutationError, isMutationSucceeded };
};
