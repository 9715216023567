import { Textarea } from "@nextui-org/react";
import { useField } from "formik";
import { Eye, EyeSlash } from "iconsax-react";
import { useState } from "react";

const FormInputDescription = ({
    label,
    labelStyle,
    name,
    placeholder,
    type,
    disabled,
    required,
    hidden,
    className,
    main_type,
}) => {
    const [togglePassword, setTogglePassword] = useState(false);
    const [, meta, helpers] = useField(name);

    const handleToggle = () => {
        setTogglePassword(!togglePassword);
    };

    const validateInput = (value) => {
        helpers.setValue(value);
    };

    return (
        <div>
            <div className="relative sm:space-y-1" hidden={hidden}>
                <label
                    htmlFor={name}
                    className={` text-xs sm:text-sm text-gray-600 block mb-1 text-start ${labelStyle}`}
                >
                    {label} {required && <span className="text-red-400">*</span>}
                </label>
                <Textarea
                    variant="bordered"
                    disableAutosize
                    name={name}
                    value={meta.value}
                    placeholder={placeholder}
                    disabled={disabled ?? false}
                    classNames={{
                        input: "h-32 resize-y !transition-none border-gray-300 rounded-lg text-gray-900 bg-white  ",
                        inputWrapper:
                            "border rounded-lg shadow-none focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm hover:border-black",
                    }}
                    className="bg-white"
                    onChange={(e) => validateInput(e.target.value)}
                    onWheel={(e) => {
                        if (e.target instanceof HTMLInputElement) {
                            e.target.blur();
                        }
                    }}
                />
                {meta.error && meta.touched && (
                    <div className="text-xs tracking-wide text-pink-200 text-start">
                        <p className=" text-xs text-red-600">{meta?.error}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormInputDescription;
