import briefcase from "../assets/FreelancerProfile/Briefcase.svg";
import employment from "../assets/FreelancerProfile/Employment.svg";
import testimonial from "../assets/FreelancerProfile/Testimonial.svg";
import trophy from "../assets/FreelancerProfile/Trophy.svg";
import credit from "../assets/Listing/Credit.png";
import google from "../assets/GoogleLogo.png";
import mainLogoBlack from "../assets/Logo.svg";
import mainLogoWhite from "../assets/LogoWhite.svg";
import badge from "../assets/UpworksBadge.png";
import largeLogo from "../assets/LargLogo.png";
import Carousal1 from "../assets/Footer/Carousal1.png";
import Carousal2 from "../assets/Footer/Carousal2.png";
import Carousal3 from "../assets/Footer/Carousal3.png";
import Carousal4 from "../assets/Footer/Carousal4.png";
import Carousal5 from "../assets/Footer/Carousal5.png";
import Carousal6 from "../assets/Footer/Carousal6.png";
import Carousal7 from "../assets/Footer/Carousal7.png";
import Carousal8 from "../assets/Footer/Carousal8.png";
import twitterLogo from "../assets/Footer/Twitter.png";
import fbLogo from "../assets/Footer/FB.png";
import instagramLogo from "../assets/Footer/instagram.png";
import pinterestLogo from "../assets/Footer/Pinterest.png";
import linkedinLogo from "../assets/Footer/linkedin.png";
import NoPostFoundImage from "../assets/NoPostFound.png"
import ResumeLogo from "../assets/Resume.png";
import ResumeTemplate1 from "../assets/ResumeTemplatesDummy/Template1.png";
import CertificateImage from "../assets/Certificate.png";
import LockGif from "../assets/Animation/Lock.gif";
import CoinGif from "../assets/Animation/Coin.gif"
import SentRequestGif from "../assets/Animation/Sent_Request.gif";
import NoContent from "../assets/NoContent.svg";
import DotIcon from "../assets/Dot.svg";
import ComingSoonBlurBg from "../assets/ComingSoon/Blur.png";
import ComingSoonConfettiBadge from "../assets/ComingSoon/Confetti_Badge.png";
import ComingSoonConfettiCircle from "../assets/ComingSoon/Confetti_Circle.png";
import ComingSoonConfettiDot from "../assets/ComingSoon/Confetti_Dot.png";
import ComingSoonConfettiRibbon from "../assets/ComingSoon/Confetti_Ribbon.png";
import ComingSoonConfettiSquare from "../assets/ComingSoon/Confetti_Square.png";
import ComingSoonPolygon from "../assets/ComingSoon/Polygon.png";
import LeftEyebrow from "../assets/LandingPage/LeftEyebrow.png";
import RightEyebrow from "../assets/LandingPage/RightEyebrow.png";

const logos = {
	briefcase,
	employment,
	testimonial,
	trophy,
	credit,
	google,
	mainLogoBlack,
	mainLogoWhite,
	badge,
	largeLogo,
	Carousal1,
	Carousal2,
	Carousal3,
	Carousal4,
	Carousal5,
	Carousal6,
	Carousal7,
	Carousal8,
	twitterLogo,
	fbLogo,
	instagramLogo,
	pinterestLogo,
	linkedinLogo,
	NoPostFoundImage,
	ResumeLogo,
	ResumeTemplate1,
	CertificateImage,
	LockGif,
	CoinGif,
	NoContent,
	DotIcon,
	ComingSoonBlurBg,
    ComingSoonConfettiBadge,
    ComingSoonConfettiCircle,
    ComingSoonConfettiDot,
    ComingSoonConfettiRibbon,
	ComingSoonConfettiSquare,
    ComingSoonPolygon,
	SentRequestGif,
	LeftEyebrow,
	RightEyebrow
};

export default logos;
