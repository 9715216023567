import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";

export const handleSavePdfToFile = async ({ ref }) => {
    if (ref?.current) {
        const canvas = await html2canvas(ref.current);
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

        // Store the PDF in the state as a File
        const pdfBlob = pdf.output("blob");
        const pdfFile = new File([pdfBlob], "resume.pdf", {
            type: "application/pdf",
        });

        return pdfFile;
    }
};

export const handleDownloadPdf = async ({ ref }) => {
    if (ref?.current) {
        const canvas = await html2canvas(ref.current);
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

        pdf.save("resume.pdf");
    }
};

export const PdfToImage = ({ pdfUrl }) => {
    const iframeRef = useRef(null);
    const canvasRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const convertPdfToImage = async () => {
            try {
                const response = await fetch(pdfUrl, { mode: "cors" });
                console.log("response", response)
                if (!response.ok) throw new Error("Failed to fetch PDF");

                const blob = await response.blob();
                const objectURL = URL.createObjectURL(blob);

                // Load PDF inside an iframe (as a workaround)
                const iframe = document.createElement("iframe");
                iframe.style.display = "none";
                iframe.src = objectURL;
                document.body.appendChild(iframe);

                iframe.onload = () => {
                    try {
                        const canvas = canvasRef.current;
                        const ctx = canvas.getContext("2d");

                        // Set canvas size to match PDF page
                        canvas.width = iframe.clientWidth;
                        canvas.height = iframe.clientHeight;

                        // Draw iframe content onto the canvas
                        ctx.drawImage(
                            iframe,
                            0,
                            0,
                            canvas.width,
                            canvas.height
                        );

                        // Convert canvas to image
                        const image = canvas.toDataURL("image/png");
                        setImageSrc(image);
                        console.log("Converted Image:", image);

                        // Cleanup
                        document.body.removeChild(iframe);
                    } catch (err) {
                        console.error("Error rendering PDF to canvas:", err);
                    }
                };
            } catch (error) {
                console.error("Error converting PDF to image:", error);
            }
        };

        convertPdfToImage();
    }, [pdfUrl]);

    console.log("imgSrc", imageSrc);

    return (
        <div>
            {/* Hidden iframe to load the PDF */}
            <iframe ref={iframeRef} src={pdfUrl} style={{ display: "none" }} title="Pdf" />

            {/* Hidden canvas to render PDF screenshot */}
            <canvas ref={canvasRef} style={{ display: "none" }} />

            {/* Display the converted image */}
            {imageSrc && <img src={imageSrc} alt="Converted PDF Page" />}
        </div>
    );
};

export default PdfToImage;
