import React from "react";
import { ROUTES } from "../Routes";
import { extractObjectFormMaster } from "../common/ReusableFunctions";
import useGlobalQuery from "./useGlobalQuery";

const useMaster = ({ type, queryKeys }) => {
    const { queryData: masterData, isLoading } = useGlobalQuery({
        url: ROUTES.MASTER + `?name=${type}`,
        methodType: "GET",
        queryKey: ["master", type, ...queryKeys],
    });

    const master = React.useMemo(() => {
        return extractObjectFormMaster({ data: masterData });
    }, [masterData]);

    return { isLoading, data: master };
};

export default useMaster;