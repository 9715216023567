import { ArrowUpRight } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { logo } from "../../../constant/constants";

const StatisticsCard = ({ isSticky, userDetails, isReadOnly }) => {
    const navigate = useNavigate();

    // Function to render star rating
    const renderStars = (rating) => {
        return Array.from({ length: 5 }).map((_, index) => (
            <span
                key={index}
                className={`text-xs sm:text-sm ${
                    index < Math.floor(rating)
                        ? "text-yellow-400"
                        : "text-gray-300"
                }`}
            >
                ★
            </span>
        ));
    };

    return (
        <div className={`gradient-blue text-white rounded-xl shadow-sm`}>
            <div className="p-4 sm:p-6 space-y-3 sm:space-y-4">
                {!isReadOnly && (
                    <StatRow
                        label="Total Credits left"
                        value={
                            <>
                                {userDetails.credits}
                                <img
                                    src={logo.credit}
                                    alt="Credit"
                                    className="w-4 h-4 sm:w-5 sm:h-5"
                                />
                            </>
                        }
                    />
                )}

                <StatRow
                    label="Total Project Done"
                    value={userDetails.projects?.length ?? 0}
                />
                <StatRow
                    label="Expertise Level"
                    value={
                        <div className="border border-white bg-blue-500 rounded-full px-2 py-1 text-xs flex items-center gap-1">
                            {userDetails?.experienceLevel} <ArrowUpRight className="size-4" />
                        </div>
                    }
                />
                <StatRow
                    label="Rating"
                    value={
                        <>
                            {userDetails.rating}{" "}
                            {renderStars(userDetails.rating)}
                        </>
                    }
                />
            </div>

            {!isReadOnly && (
                <button
                    className="w-full flex justify-center gap-2 text-center py-2.5 sm:py-3 px-3 sm:px-4 text-blue-600 text-sm border border-blue-100 rounded-b-xl bg-white hover:bg-blue-50 transition-colors font-semibold"
                    onClick={() => navigate("/subscription")}
                >
                    + Add more credit{" "}
                    <img
                        src={logo.credit}
                        alt="Credit"
                        className="w-4 h-4 sm:w-5 sm:h-5"
                    />
                </button>
            )}
        </div>
    );
};

export const StatRow = ({ label, value }) => (
    <div className="flex justify-between items-center">
        <span className="text-sm sm:text-sm">{label}</span>
        <span className="flex items-center gap-1 sm:gap-2 text-sm sm:text-sm">
            {value}
        </span>
    </div>
);

export default StatisticsCard;
