import { Avatar, Badge, Button, Input, Tooltip } from "@nextui-org/react";
import { Message, SearchNormal1 } from "iconsax-react";
import React, { useContext, useEffect, useState } from "react";
import { formatTimeFromTimestamp } from "../../../common/ReusableFunctions";
import useGlobalQuery from "../../../hooks/useGlobalQuery";
import { ROUTES } from "../../../Routes";
import { ChatContext } from "../ChatLayout";
import ChatCardLoader from "./ChatCardLoader";
import OverlayFragment from "../../../common/components/OverlayFragment";
import StartNewChat from "./StartNewChat";
import { useDispatch, useSelector } from "react-redux";
import { messageActions } from "../../../store/message.slice";
import useIsMobile from "../../../hooks/useIsMobile";

const ChatSidebar = () => {
    const { userList, activeChat } = useSelector((state) => state.message)
    // const { setActiveChat, userList, setUserList } = useContext(ChatContext);
    const [search, setSearch] = React.useState("");
    const [debouncedSearch, setDebouncedSearch] = React.useState("");
    const [isOpen, setIsOpen] = useState(false);
    
    const dispatch = useDispatch();
    const { isMobile } = useIsMobile();

    const { queryData, isLoading } = useGlobalQuery({
        url: ROUTES.CHAT.ALL_CHATS,
        methodType: "POST",
        queryKey: ["all-chats", debouncedSearch],
        data: { search: debouncedSearch },
    });

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        !isMobile && dispatch(messageActions.setActiveChat(queryData?.data?.[0]));
        dispatch(messageActions.setUserList(queryData?.data));
    }, [queryData, dispatch, isMobile]);

    return (
        <div className="max-w-xs w-full space-y-3 h-full">
            <div className="flex items-center justify-between">
                <div className="border-b dark:border-dark-100 px-5 py-[10px]">
                    <h1 className="text-xl font-bold">Chats</h1>
                    <p className="text-sm text-[#5F5F5F]">{queryData?.data?.length} Active Chats</p>
                </div>
                {/* <Tooltip content="Start New Chat" showArrow>
                    <Button
                        isIconOnly
                        className="bg-white dark:bg-dark-100 outline-none mr-3"
                        size="lg"
                        onPress={() => setIsOpen(true)}
                    >
                        <Message />
                    </Button>
                </Tooltip> */}
            </div>

            <div className="px-3">
                <Input
                    isClearable
                    classNames={{
                        base: "w-full",
                        inputWrapper: "border-1",
                    }}
                    placeholder="Search by name..."
                    size="md"
                    startContent={<SearchNormal1 className=" size-5" />}
                    value={search}
                    variant="bordered"
                    onClear={() => setSearch("")}
                    onValueChange={setSearch}
                />
            </div>

            {isLoading ? (
                <div className="space-y-5">
                    <ChatCardLoader />
                    <ChatCardLoader />
                    <ChatCardLoader />
                    <ChatCardLoader />
                </div>
            ) : (
                <div className="px-3 space-y-3  h-[64vh] overflow-auto">
                    {userList?.length > 0 ? (
                        userList?.map((data) => <ChatCard userData={data} />)
                    ) : (
                        <p className="center-vertical text-gray-600">No User Found</p>
                    )}
                </div>
            )}

            <OverlayFragment
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                children={<StartNewChat setIsOpen={setIsOpen} />}
                title={"Start New Chat"}
            />
        </div>
    );
};

const ChatCard = ({ userData }) => {
    // const { activeChat, setActiveChat } = useContext(ChatContext);
    const { activeChat } = useSelector((state) => state.message)
    const { profileDetails } = useSelector((state) => state.profile);

    const dispatch = useDispatch();

    const isUserActive = () => {
        if (!profileDetails || !userData || !activeChat) return false; // Prevent errors

        const loggedInEmail = profileDetails.email; // Current logged-in user's email
        const { senderEmail, receiverEmail } = userData; // Extract sender & receiver from userData

        // If logged-in user is the receiver, check against the sender
        if (loggedInEmail === receiverEmail) {
            return senderEmail === activeChat?.senderEmail || senderEmail === activeChat?.receiverEmail;
        }

        // If logged-in user is the sender, check against the receiver
        if (loggedInEmail === senderEmail) {
            return receiverEmail === activeChat?.senderEmail || receiverEmail === activeChat?.receiverEmail;
        }

        return false;
    };
    
    return (
        <div
            className={`flex items-start justify-between max-w-xs cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-100 p-2 rounded-lg ${
                isUserActive() && "bg-gray-100 dark:bg-dark-100"
            }`}
            onClick={() => dispatch(messageActions.setActiveChat(userData)) }
        >
            <div className="flex items-center gap-4">
                <Badge
                    color="success"
                    content=""
                    placement="bottom-right"
                    shape="circle"
                    isInvisible={userData?.online ? false : true}
                >
                    <Avatar
                        isDisabled={userData?.senderDetails?.imgUrl ? false : true}
                        src={userData?.senderDetails?.imgUrl}
                    />
                </Badge>

                <div>
                    <h1 className="text-base font-semibold">
                        {userData?.senderDetails?.firstname} {userData?.senderDetails?.lastname}
                    </h1>
                    <p className="text-sm text-gray-400 line-clamp-2 break-words overflow-hidden">{userData?.message}</p>
                </div>
            </div>

            <p className="text-xs text-[#666668] pt-1">{formatTimeFromTimestamp({ timestamp: userData?.updatedAt })}</p>
        </div>
    );
};

export default ChatSidebar;
