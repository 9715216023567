import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import InfiniteMovingCards from "../../common/animation/InfiniteMovingCards";
import { logo } from "../../constant/constants";
import Banner from "./components/Banner";
import FAQ from "./components/FAQ";
import Features from "./components/Features/Features";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Possibilities from "./components/Possibilities";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import TypeOfWorkOffer from "./components/TypeOfWorkOffer";
import { useCookies } from "react-cookie";
import useGetAccessToken from "../../hooks/Authentication/useGetAccessToken";

const footerCarousalList = [
    {
        title: "Illustration",
        img: logo.Carousal1,
    },
    {
        title: "Branding",
        img: logo.Carousal2,
    },
    {
        title: "Animation",
        img: logo.Carousal3,
    },
    {
        title: "Product Design",
        img: logo.Carousal4,
    },
    {
        title: "Mobile",
        img: logo.Carousal5,
    },
    {
        title: "Web Design",
        img: logo.Carousal6,
    },
    {
        title: "Print",
        img: logo.Carousal7,
    },
    {
        title: "Typography",
        img: logo.Carousal8,
    },
];

const Page = () => {
    return (
        <div className="">
            <div className="space-y-10 sm:space-y-16">
                {/* <AnimatedTitle /> */}
                <Hero />
                <Features />
                <Services />
                <Possibilities />
                <TypeOfWorkOffer />
                <Testimonials />
                <FAQ />
                <Banner />
                <div>
                    <InfiniteMovingCards
                        items={footerCarousalList}
                        direction="left"
                        speed="slow"
                    />
                </div>
            </div>
        </div>
    );
};

export default Page;
