import React, { forwardRef } from "react";
import { toast } from "react-toastify";

const FileInputField = forwardRef(({ setAssets, isImageOnly, multiple }, ref) => {
	return (
		<input
			type="file"
			ref={ref}
			hidden
			accept={`${
				isImageOnly
					? ".jpeg,.jpg,.png,.webp"
					: ".mp4,.webm"
			}`}
			multiple={multiple}
			onChange={(e) => {
				if (e.target.files) {
					var fileSize = e.target.files[0].size;
					if (fileSize > 5000000 && isImageOnly) {
						e.target.value = "";
						toast.error("File size too large. Maximum allowed size is 5MB");
						return;
					} else if (fileSize > 10000000 && !isImageOnly) {
						e.target.value = "";
						toast.error("File size too large. Maximum allowed size is 10MB");
						return;
					}

					setAssets &&
						setAssets((prevImages) => [
							...(prevImages || []),
							...e.target.files,
						]);
					e.target.value = "";
				}
			}}
		/>
	);
});

export default FileInputField;
