import { ArrowLeft, Location } from "iconsax-react";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../common/components/CommonButton";
import ExpandableDiv from "../../../common/components/ExpandableDiv";
import NoContent from "../../../common/components/NoContent";
import RevealContactButton from "../../../common/components/RevealContactButton";
import Loader from "../../../common/Loader/Loader";
import { GenerateProfileImg } from "../../../common/ReusableFunctions";
import { CreditActionTypes } from "../../../constant/constants";
import useGlobalMutation from "../../../hooks/useGlobalMutation";
import useGlobalQuery from "../../../hooks/useGlobalQuery";
import { ROUTES } from "../../../Routes";
import { ReviewProposalContext } from "./ReviewProposal";
import useGlobalInfiniteQuery from "../../../hooks/useGlobalInfiniteQuery";
import InfiniteScrollComponent from "../../../common/components/InfiniteScrollComponent";

const ReviewProposalsList = () => {
    const { jobData } = useContext(ReviewProposalContext);
    const [activeApplication, setActiveApplication] = useState();
    const [showList, setShowList] = useState(true);

    const [isMobile, setIsMobile] = useState(
        window.matchMedia("(max-width: 768px)").matches
    );

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 768px)");
        const handleChange = (event) => setIsMobile(event.matches);

        mediaQuery.addEventListener("change", handleChange);
        return () => mediaQuery.removeEventListener("change", handleChange);
    }, []);

    const { queryData, isLoading, fetchNextPage, hasNextPage } =
        useGlobalInfiniteQuery({
            url: ROUTES.JOB_APPLICATION.ALL_APPLICATIONS,
            methodType: "POST",
            data: {
                filter: {
                    jobId: jobData?._id,
                },
            },
            queryKey: ["all-proposal", jobData?._id],
        });

    useEffect(() => {
        if (!activeApplication) {
            setActiveApplication(queryData?.[0]);
        }
    }, [queryData, activeApplication]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
            {showList &&
                (queryData?.length > 0 ? (
                    <InfiniteScrollComponent
                        items={queryData}
                        fetchNextPage={fetchNextPage}
                        hasNextPage={hasNextPage}
                        isLoading={isLoading}
                        className={"space-y-3"}
                        props={{
                            handleFunction: (applicant) => {
                                if (isMobile) {
                                    setShowList(false);
                                    setActiveApplication(applicant);
                                } else {
                                    setActiveApplication(applicant);
                                }
                            },
                            activeApplicationId: activeApplication?._id,
                        }}
                    >
                        <InvitedUserCard />
                    </InfiniteScrollComponent>
                ) : (
                    <div className="col-span-full">
                        <NoContent />
                    </div>
                ))}

            {activeApplication && (!isMobile || (isMobile && !showList)) && (
                <ActiveInvitation
                    data={activeApplication}
                    setShowList={setShowList}
                    isMobile={isMobile}
                />
            )}
        </div>
    );
};

const InvitedUserCard = ({
    data,
    handleFunction,
    activeApplicationId,
}) => {
    return (
        <div
            className={`flex items-center p-3 rounded-lg border cursor-pointer 
                ${data?._id === activeApplicationId ? "bg-gray-100" : ""}`}
            onClick={() => handleFunction(data)}
        >
            <img
                src={GenerateProfileImg({
                    firstname: data?.userData?.firstname,
                    lastname: data?.userData?.lastname,
                    imgUrl: data?.userData?.imgUrl,
                })}
                alt="Profile"
                className="w-12 h-12 rounded-full object-cover"
            />

            <div className="ml-3 flex-grow">
                <h3 className="font-medium text-gray-900">
                    {data?.userData?.firstname}{" "}
                    {data?.userData?.lastname}
                </h3>
                <p className="text-sm text-gray-500 truncate">
                    {data?.userData?.title}
                </p>
                <div className="flex gap-1 items-center mt-1 text-xs text-gray-500">
                    <Location className="size-4" />
                    {data?.userData?.location ?? "Anywhere"}
                </div>
            </div>
        </div>
    );
};

const ActiveInvitation = ({ data, setShowList, isMobile }) => {
    const { jobData } = useContext(ReviewProposalContext);
    const { profileDetails } = useSelector((state) => state.profile);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        runMutation: startNewChat,
        mutationLoading: startingNewChat,
        isMutationSucceeded,
    } = useGlobalMutation({
        url: ROUTES.CHAT.START_NEW_CHAT,
        methodType: "POST",
        queriesToInvalidate: ["all-chats"],
    });

    const handleStartNewChat = () => {
        startNewChat({
            isPriorityDataAvailable: true,
            priorityData: {
                senderEmail: profileDetails.email,
                receiverEmail: data?.userData?.email,
            },
        });
    };

    useEffect(() => {
        isMutationSucceeded && navigate("/chats");
    }, [isMutationSucceeded, navigate]);

    return (
        <div className="col-span-full md:col-span-2 border rounded-lg p-5 space-y-5">
            <div className="flex flex-col md:flex-row  md:justify-between gap-5">
                {isMobile && (
                    <ArrowLeft
                        className="bg-gray-100 p-1 rounded-full size-7"
                        onClick={() => setShowList(true)}
                    />
                )}

                <div className="flex items-center">
                    <img
                        src={GenerateProfileImg({
                            firstname: data?.userData?.firstname,
                            lastname: data?.userData?.lastname,
                            imgUrl: data?.userData?.imgUrl,
                        })}
                        alt="Profile"
                        className="w-12 h-12 rounded-full object-cover"
                    />
                    <div className="ml-3">
                        <h3 className="font-medium text-gray-900">
                            {data?.userData?.firstname}{" "}
                            {data?.userData?.lastname}
                        </h3>
                        <p className="text-sm text-gray-500">
                            {data?.userData?.title}
                        </p>
                        <div className="flex items-center text-xs text-gray-500 gap-1">
                            <Location className="size-4" />
                            {data?.userData?.location ?? "Anywhere"}
                        </div>
                    </div>
                </div>

                <div className="flex items-center gap-2">
                    <div>
                        {data?.userData?.email && (
                            <CommonButton
                                title={"Start Chat"}
                                isGradient={true}
                                isRounded={true}
                                buttonStyle={"!text-xs"}
                                handleFunction={handleStartNewChat}
                                loading={startingNewChat}
                                disabled={startingNewChat}
                            />
                        )}
                    </div>

                    <RevealContactButton
                        creditActionType={CreditActionTypes?.["Review Invite"]}
                        data={data?.userData}
                        queriesToInvalidate={["all-proposal", jobData?._id]}
                    />
                </div>
            </div>

            <div className="space-y-3">
                <h1 className="text-lg font-semibold">Cover Letter</h1>
                <ExpandableDiv
                    text={data?.coverLetter}
                    titleStyle="text-base"
                />
            </div>

            <div className="space-y-5">
                <h1 className="text-lg font-semibold">Attachments</h1>
                {data?.attachment?.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                        {data?.attachment?.map((attachment, index) => (
                            <img
                                src={attachment}
                                className="rounded-lg w-full min-h-40 object-cover"
                                alt="Attachment"
                            />
                        ))}
                    </div>
                ) : (
                    <p className="text-sm text-gray-500">No Attachments</p>
                )}
            </div>

            <div className="space-y-5">
                <h1 className="text-lg font-semibold">Resume</h1>
                {data?.resume?.map((resumeUrl, index) => (
                    <div className="relative w-full md:w-80 h-full cursor-pointer transition-all duration-300 hover:shadow-sm">
                        <iframe
                            title="Resume Template"
                            src={`${resumeUrl}?t=${new Date().getTime()}#toolbar=0&navpanes=0`}
                            width="100%"
                            height="400px"
                            className="rounded-lg border"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ReviewProposalsList;
