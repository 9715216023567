import { useQueryClient } from "@tanstack/react-query";
import { Location } from "iconsax-react";
import { Bookmark } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ExpandableDiv from "../../../common/components/ExpandableDiv";
import {
    GenerateProfileImg,
    handleProfileRedirection,
    isApplicableToApply,
} from "../../../common/ReusableFunctions";
import { useUpdateMyProfile } from "../../../hooks/Authentication/AuthenticationHooks";

const FreelancerCard = ({ data }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const [savedFreelancerIds, setSavedFreelancerIds] = useState();

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { runMutation } = useUpdateMyProfile({});

    const isSaved = useMemo(() => {
        return savedFreelancerIds?.includes(data?._id);
    }, [savedFreelancerIds, data?._id]);

    const handleSavePost = (freelancerId) => {
        const dataToUpload = {
            savedUsers: [
                ...(Array.isArray(savedFreelancerIds)
                    ? savedFreelancerIds
                    : []),
                freelancerId,
            ],
        };

        runMutation({
            isPriorityDataAvailable: true,
            priorityData: dataToUpload,
        });

        queryClient.invalidateQueries({ queryKey: ["all-users"] });
        setSavedFreelancerIds((prevIds) => {
            if (prevIds && !prevIds?.includes(freelancerId)) {
                return [...prevIds, freelancerId];
            }
            return prevIds;
        });
    };

    const handleUnSavePost = (postId) => {
        const dataToUpload = {
            savedUsers: savedFreelancerIds?.filter((id) => id !== postId),
        };

        runMutation({
            isPriorityDataAvailable: true,
            priorityData: dataToUpload,
        });

        queryClient.invalidateQueries({ queryKey: ["all-users"] });

        setSavedFreelancerIds((prevIds) => {
            if (prevIds?.includes(postId)) {
                return prevIds.filter((id) => id !== postId);
            }
            return prevIds;
        });
    };

    useEffect(() => {
        setSavedFreelancerIds(profileDetails?.savedUsers);
    }, [profileDetails]);

    return (
        <div className="p-4 bg-white rounded-lg border shadow-sm">
            {/* Header Section */}
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-3 sm:mb-6">
                <div className="flex items-center gap-4">
                    {/* Profile Image and Basic Info */}
                    <div className="relative">
                        <img
                            src={GenerateProfileImg({
                                firstname: data?.firstname,
                                lastname: data?.lastname,
                                imgUrl: data?.imgUrl,
                            })}
                            alt="Profile"
                            className="size-10 sm:size-12 border rounded-full object-cover cursor-pointer"
                            onClick={() =>
                                handleProfileRedirection({
                                    role: data?.role?.[0],
                                    userId: data?._id,
                                    navigate,
                                })
                            }
                        />
                    </div>

                    <div>
                        <div className="flex items-center gap-2">
                            <h2
                                className="text-base sm:text-xl font-semibold cursor-pointer"
                                onClick={() =>
                                    handleProfileRedirection({
                                        role: data?.role?.[0],
                                        userId: data?._id,
                                        navigate,
                                    })
                                }
                            >
                                {data?.firstname} {data?.lastname}
                            </h2>
                        </div>
                        <div className="flex items-center text-gray-600 gap-1">
                            <Location className="w-4 h-4" />
                            <span className="text-xs sm:text-sm">
                                {data?.location ?? "Anywhere"}
                            </span>
                        </div>
                    </div>
                </div>

                {isApplicableToApply({
                    type: "freelancer",
                    role: profileDetails?.role?.[0],
                }) && (
                    <div className="flex items-center gap-3">
                        <button
                            className="hover:bg-gray-100 p-1.5 rounded-full border"
                            onClick={() => runMutation()}
                        >
                            <Bookmark
                                size={20}
                                className={`w-5 h-5 text-gray-500 ${
                                    isSaved && "fill-blue-500"
                                }`}
                                onClick={() => {
                                    isSaved
                                        ? handleUnSavePost(data?._id)
                                        : handleSavePost(data?._id);
                                }}
                            />
                        </button>
                    </div>
                )}
            </div>

            {/* Pricing Section */}
            <div className="flex gap-6 mb-3 sm:mb-6 text-base sm:justify-start ">
                {data?.hourlyRates && (
                    <div className="text-gray-700">
                        <span className="font-medium text-sm">
                            ₹{data?.hourlyRates}/hr
                        </span>
                    </div>
                )}

                <div className="text-gray-700">
                    <span className="font-medium text-sm">{data?.workType}</span>
                </div>
                <div className="text-gray-700">
                    <span className="font-medium text-sm">{data?.experienceLevel}</span>
                </div>
            </div>

            <div className="flex flex-col sm:flex-row sm:gap-5 mb-3 sm:mb-0">
                <div className="mb-3 sm:mb-6">
                    <img
                        src={data?.projects?.thumbnail}
                        alt="PortfoliOOo Preview"
                        className="rounded-lg bg-gray-100 w-full sm:max-w-xs sm:min-w-[320px] min-h-52 max-h-52 object-cover"
                    />
                </div>

                {/* Title and Features */}
                <div className="space-y-2 text-start">
                    <h1 className="text-sm sm:text-lg font-semibold">
                        {data?.title}
                    </h1>

                    <ExpandableDiv
                        text={data?.description}
                        lineClampStyle={"line-clamp-6"}
                    />
                </div>
            </div>

            <div className="flex flex-wrap gap-2">
                {data?.skills?.map((tag) => (
                    <span
                        key={tag}
                        className="text-xs px-2 py-1 bg-gray-100 rounded-full text-gray-700"
                    >
                        {tag}
                    </span>
                ))}
            </div>

            {/* Rating */}
            {/* <div className="flex items-center justify-between">
                <div className="flex items-center gap-1">
                    <span className="text-sm sm:text-lg">4.3</span>
                    {[1, 2, 3, 4].map((i) => (
                        <Star1
                            key={i}
                            className="size-4 sm:size-5 fill-yellow-400 text-yellow-400"
                        />
                    ))}
                    <Star1 className="size-4 sm:size-5 text-gray-300" />
                </div>
                <span className="text-gray-500 text-sm">2 hours ago</span>
            </div> */}
        </div>
    );
};

export default FreelancerCard;
