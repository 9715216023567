import { clsx } from "clsx";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import { CREDIT_ACTION, ROLES } from "../constant/constants";
import { Cookies } from "react-cookie";
import { useState, useEffect, useMemo } from "react";

export function isMobile() {
    const minWidth = 768;
    return window.innerWidth < minWidth;
}

export function getListOfYears() {
    const startYear = 1940;
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = currentYear; year >= startYear; year--) {
        years.push({ name: year?.toString(), value: year?.toString() });
    }

    return years;
}

export function getListOfExpectedGraduationYears(startYear) {
    const years = [];
    const endYear = parseInt(startYear) + 10;

    for (let year = endYear; year >= parseInt(startYear); year--) {
        years.push({ name: year.toString(), value: year.toString() });
    }

    return years;
}

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}

export const isVideo = ({ url }) => {
    const splitedUrl = url?.split(".");
    return ["MOV", "mp4", "mkv"]?.includes(splitedUrl?.[splitedUrl.length - 1]);
};

export const isBinaryVideo = ({ file }) => {
    const videoExtension = file?.type?.split("/")?.[1];

    return videoExtension === "mp4" || videoExtension === "webm";
};

export const isFileIsPdf = ({ file }) => {
    const pdfExtension = file?.type?.split("/")[1];
    return pdfExtension === "pdf";
};

export const isPdf = ({ url }) => {
    const splitedUrl = url.split(".");
    const splitedType = url.split("/");
    return (
        splitedUrl[splitedUrl.length - 1] === "pdf" ||
        splitedType[splitedType.length - 1] === "pdf"
    );
};

export const UserProfileImageUrl = () => {
    const { profileDetails } = useSelector((state) => state.profile);

    return profileDetails?.imgUrl
        ? `${profileDetails?.imgUrl}?${Date.now()}`
        : `https://ui-avatars.com/api/?name=${
              profileDetails?.firstname + " " + profileDetails?.lastname
          }&background=random`;
};

export function formatDate({ dateString }) {
    const today = new Date();
    const inputDate = new Date(dateString);
    const diffMilliseconds = Math.abs(today.getTime() - inputDate.getTime());
    const diffSeconds = Math.floor(diffMilliseconds / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffDays > 7) {
        return inputDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    } else if (diffDays >= 1) {
        return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    } else if (diffHours >= 1)
        return `${diffHours} hr${diffHours > 1 ? "s" : ""} ago`;
    else if (diffMinutes >= 1)
        return `${diffMinutes} min${diffMinutes > 1 ? "s" : ""} ago`;
    else return "just now";
}

export function getGreeting() {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
        return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
        return "Good Afternoon";
    } else if (currentHour >= 17 && currentHour < 21) {
        return "Good Evening";
    } else {
        return "Good Night";
    }
}

export const GenerateProfileImg = ({ imgUrl, firstname, lastname }) => {
    return imgUrl
        ? `${imgUrl}?${Date.now()}`
        : `https://ui-avatars.com/api/?name=${
              firstname + " " + lastname
          }&background=random`;
};

export const isApplicableToApply = ({ type, role }) => {
    if (type === "gigs" && role === ROLES.FREELANCER) {
        return true;
    } else if (type === "jobs" && role === ROLES.EMPLOYEE) {
        return true;
    } else if (type === "freelancer" && role === ROLES.CLIENT) {
        return true;
    } else if (type === "talent" && role === ROLES.HR) {
        return true;
    } else return false;
};

export const extractObjectFormMaster = ({ data }) => {
    return data?.master?.[0]?.values?.map((item) => {
        return {
            name: item,
            value: item,
        };
    });
};

export const filterObject = (obj) => {
    if (!obj || typeof obj !== "object") return {};

    return Object?.fromEntries(
        Object?.entries(obj).filter(
            ([_, value]) =>
                value !== "" &&
                value !== null &&
                value !== undefined &&
                !(Array.isArray(value) && value.length === 0)
        )
    );
};

export const clearAllCookies = () => {
    const cookies = new Cookies();
    const allCookies = cookies.getAll();

    Object.keys(allCookies).forEach((cookie) => {
        cookies.remove(cookie, { path: "/" });
    });
};

export function formatTimeFromTimestamp({ timestamp }) {
    // Check if 'T' exists in the timestamp
    if (!timestamp?.includes("T")) return null;

    try {
        // Create a Date object from the UTC timestamp
        const dateInUTC = new Date(timestamp);

        // Convert the UTC time to IST (UTC + 5:30)
        const offsetIST = 5.5 * 60 * 60 * 1000; // 5 hours 30 minutes in milliseconds
        const dateInIST = new Date(dateInUTC.getTime() + offsetIST);

        // Extract hours, minutes, and seconds from IST date
        const hours24 = dateInIST.getUTCHours();
        const minutes = dateInIST.getUTCMinutes();

        // Convert 24-hour time to 12-hour format and determine AM/PM
        const amPm = hours24 >= 12 ? "PM" : "AM";
        const hours12 = hours24 % 12 || 12; // Convert 0 or 12 to 12

        // Format minutes to always be two digits
        const formattedMinutes = String(minutes).padStart(2, "0");

        // Calculate today's, yesterday's, and tomorrow's dates
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        // Reset time of `dateInIST` for date comparison
        const dateOnlyInIST = new Date(dateInIST);
        dateOnlyInIST.setHours(0, 0, 0, 0);

        // Determine if the date is today, yesterday, or tomorrow
        let dayLabel;
        if (dateOnlyInIST.getTime() === today.getTime()) {
            dayLabel = `${hours12}:${formattedMinutes} ${amPm}`;
        } else if (dateOnlyInIST.getTime() === yesterday.getTime()) {
            dayLabel = `Yesterday`;
        } else {
            // Format the date as "DD/MM/YYYY"
            const day = String(dateInIST.getDate()).padStart(2, "0");
            const month = String(dateInIST.getMonth() + 1).padStart(2, "0"); // Months are 0-based
            const year = dateInIST.getFullYear();
            dayLabel = `${formatDate({ dateString: dateInIST })}`;
        }

        // Return the formatted date and time
        return dayLabel;
    } catch (error) {
        console.error("Invalid timestamp:", error);
        return null;
    }
}

export const renderStars = (rating) => {
    return (
        <div className="flex items-center">
            {rating}
            <div className="flex ml-1">
                {[...Array(4)].map((_, index) => (
                    <svg
                        key={index}
                        className={`w-3 h-3 ${
                            index < Math.floor(rating)
                                ? "text-yellow-400"
                                : "text-gray-300"
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                ))}
            </div>
        </div>
    );
};

export const handleProfileRedirection = ({ role, userId, navigate }) => {
    switch (role) {
        case ROLES.FREELANCER:
            return navigate(`/profile/freelancer/${userId}`);
        case ROLES.EMPLOYEE:
            return navigate(`/profile/employee/${userId}`);
        case ROLES.CLIENT:
            return navigate(`/profile/client/${userId}`);
        case ROLES.HR:
            return navigate(`/profile/hr/${userId}`);
        default:
            return null;
    }
};

export function calculateCompletionPercentage(data) {
    const values = Object.values(data);
    const totalFields = values.length;
    const completedFields = values.filter(Boolean).length;
    return ((completedFields / totalFields) * 100).toFixed(2);
}

export const useDebounceEffect = (callback, delay = 300, dependencies) => {
    useEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => clearTimeout(handler);
    }, [dependencies, delay, callback]);
};

export const GetCreditRequired = ({
    actionName,
    amount = 0,
    creditActionData,
}) => {
    const getCreditAction = () => {
        if (!creditActionData || !Array.isArray(creditActionData)) return null;
        return creditActionData.find(
            (creditAction) => creditAction.actionName === actionName
        );
    };

    const creditAction = getCreditAction();

    if (!creditAction) {
        console.warn(`Credit action for "${actionName}" not found.`);
        return 0;
    }

    const creditRequired = Number(creditAction.creditRequired);
    if (isNaN(creditRequired)) {
        console.error(
            `Invalid creditRequired value for action "${actionName}".`
        );
        return 0;
    }

    const validAmount = Number(amount);
    if (isNaN(validAmount) || validAmount < 0) {
        console.warn(`Invalid amount provided: ${amount}`);
        return 0;
    }

    if (creditAction.creditRequiredType === "PERCENTAGE") {
        const credit = (creditRequired / 100) * validAmount;
        return Math.ceil(credit);
    } else {
        return creditRequired;
    }
};

export const HaveSufficientCredits = ({ actionName, amount = 0 }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const { creditActionData } = useSelector((state) => state.custom);

    const creditRequired = GetCreditRequired({
        actionName,
        creditActionData,
        amount,
    });

    return profileDetails?.credits >= creditRequired;
};
