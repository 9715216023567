import { useQueryClient } from "@tanstack/react-query";
import { Location, SearchZoomIn } from "iconsax-react";
import { Bookmark } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    GenerateProfileImg,
    handleProfileRedirection,
    isApplicableToApply,
} from "../../../common/ReusableFunctions";
import ExpandableDiv from "../../../common/components/ExpandableDiv";
import { useUpdateMyProfile } from "../../../hooks/Authentication/AuthenticationHooks";
import { Document, Page } from "react-pdf";

const TalentCard = ({ data }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const [savedFreelancerIds, setSavedFreelancerIds] = useState();
    const [numPages, setNumPages] = useState(null);

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { runMutation } = useUpdateMyProfile({});

    const isSaved = useMemo(() => {
        return savedFreelancerIds?.includes(data?._id);
    }, [savedFreelancerIds, data?._id]);

    const handleSavePost = (freelancerId) => {
        const dataToUpload = {
            savedUsers: [
                ...(Array.isArray(savedFreelancerIds)
                    ? savedFreelancerIds
                    : []),
                freelancerId,
            ],
        };

        runMutation({
            isPriorityDataAvailable: true,
            priorityData: dataToUpload,
        });

        queryClient.invalidateQueries({ queryKey: ["all-users"] });
        setSavedFreelancerIds((prevIds) => {
            if (prevIds && !prevIds?.includes(freelancerId)) {
                return [...prevIds, freelancerId];
            }
            return prevIds;
        });
    };

    const handleUnSavePost = (postId) => {
        const dataToUpload = {
            savedUsers: savedFreelancerIds?.filter((id) => id !== postId),
        };

        runMutation({
            isPriorityDataAvailable: true,
            priorityData: dataToUpload,
        });

        queryClient.invalidateQueries({ queryKey: ["all-users"] });

        setSavedFreelancerIds((prevIds) => {
            if (prevIds?.includes(postId)) {
                return prevIds.filter((id) => id !== postId);
            }
            return prevIds;
        });
    };

    useEffect(() => {
        setSavedFreelancerIds(profileDetails?.savedUsers);
    }, [profileDetails]);

    return (
        <div className="p-4 bg-white rounded-lg border shadow">
            {/* Header Section */}
            <div className="flex items-start justify-between gap-4 mb-3 sm:mb-6">
                <div className="flex items-center gap-4">
                    {/* Profile Image and Basic Info */}
                    <div className="relative">
                        <img
                            src={GenerateProfileImg({
                                imgUrl: data?.imgUrl,
                                firstname: data?.firstname,
                                lastname: data?.lastname,
                            })}
                            alt="Profile"
                            className="size-10 sm:size-12 rounded-full object-cover cursor-pointer"
                            onClick={() =>
                                handleProfileRedirection({
                                    role: data?.role?.[0],
                                    userId: data?._id,
                                    navigate,
                                })
                            }
                        />
                    </div>

                    <div>
                        <div className="flex items-center gap-2">
                            <h2
                                className="text-base sm:text-xl font-semibold cursor-pointer"
                                onClick={() =>
                                    handleProfileRedirection({
                                        role: data?.role?.[0],
                                        userId: data?._id,
                                        navigate,
                                    })
                                }
                            >
                                {data?.firstname} {data?.lastname}
                            </h2>
                        </div>
                        <div className="flex items-center text-gray-600 gap-1">
                            <Location className="w-4 h-4" />
                            <span className="text-xs sm:text-sm">
                                {data?.location ?? "Anywhere"}
                            </span>
                        </div>
                    </div>
                </div>
                {isApplicableToApply({
                    type: "talent",
                    role: profileDetails?.role?.[0],
                }) && (
                    <div className="flex items-center gap-3">
                        <button
                            className="hover:bg-gray-100 p-1.5 rounded-full border"
                            onClick={() => runMutation()}
                        >
                            <Bookmark
                                size={20}
                                className={`w-5 h-5 text-gray-500 ${
                                    isSaved && "fill-blue-500"
                                }`}
                                onClick={() => {
                                    isSaved
                                        ? handleUnSavePost(data?._id)
                                        : handleSavePost(data?._id);
                                }}
                            />
                        </button>
                    </div>
                )}
            </div>

            {/* Stats Section */}
            <div className="flex flex-wrap gap-4 mb-3 sm:mb-6 text-xs">
                <div className="flex gap-2">
                    <p className="text-gray-600">Total Experience</p>
                    <p className="font-medium">2 years</p>
                </div>
                {data?.currentSalary && (
                    <div className="flex gap-2">
                        <p className="text-gray-600">Current salary</p>
                        <p className="font-medium">
                            ₹{data?.currentSalary ?? 0}
                        </p>
                    </div>
                )}
                {data?.experienceLevel && (
                    <div className="flex gap-2">
                        <p className="text-gray-600">Experience Level</p>
                        <p className="font-medium">{data?.experienceLevel}</p>
                    </div>
                )}
            </div>

            {/* Resume Section */}
            <div className="flex flex-col md:flex-row gap-3 sm:gap-6 mb-3 sm:mb-6 sm:w-full">
                {data?.resume?.resumeUrl && (
                    <div className="bg-gray-200 border rounded-lg relative overflow-hidden sm:max-w-xs sm:min-w-[320px] min-h-52 max-h-52 object-cover group">
                        <Document
                            file={data?.resume?.resumeUrl}
                            onLoadSuccess={({ numPages }) =>
                                setNumPages(numPages)
                            }
                        >
                            {Array.apply(null, Array(numPages))
                                .map((x, i) => i + 1)
                                .map((page) => (
                                    <Page
                                        pageNumber={page}
                                        renderAnnotationLayer={false}
                                        renderTextLayer={false}
                                        scale={0.54}
                                    />
                                ))}
                        </Document>

                        {/* Fade black background - hidden by default, shown on hover */}
                        <div className="absolute top-0 bg-black w-full z-10 h-full opacity-10 group-hover:opacity-40 transition-opacity duration-300"></div>

                        {/* Resume strip - hidden by default, shown on hover */}
                        <p className="absolute bottom-0 text-white z-10 bg-black w-full px-2 py-1 text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            Resume
                        </p>

                        {/* Magnifying icon - hidden by default, shown on hover */}
                        <button
                            className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-gray-500 bg-opacity-20 p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                            onClick={() =>
                                window.open(data?.resume?.resumeUrl, "_blank")
                            }
                        >
                            <SearchZoomIn className="text-white" />
                        </button>
                    </div>
                )}

                <div className="">
                    <h3 className="text-base sm:text-lg font-semibold mb-2">
                        {data?.title}
                    </h3>
                    <ExpandableDiv
                        text={data?.description}
                        // titleStyle={"text-xs"}
                        lineClampStyle={"line-clamp-6"}
                    />
                </div>
            </div>

            {/* Skills Tags */}
            <div className="flex flex-wrap gap-2">
                {data?.skills?.map((skill) => (
                    <span className="px-3 py-1 bg-gray-100 rounded-full text-xs sm:text-sm">
                        {skill}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default TalentCard;
