import React from "react";

const Link = ({ link, className }) => {
    return (
        <span
            className={`${className} font-normal cursor-pointer`}
            onClick={() =>
                window.open(
                    link.startsWith("http") ? link : `https://${link}`,
                    "_blank"
                )
            }
        >
            {link}
        </span>
    );
};

export default Link;
