import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useField } from "formik";
import * as React from "react";

const MUIFormDatePickerField = ({
	placeholder,
	name,
	type,
	value,
	disabled,
	label,
	readOnly,
	required,
	icon: IconComponent,
	maxDate,
	minDate,
	applyBaseColors,
	hidden,
	shouldDisableDate,
}) => {
	const [, meta, helpers] = useField(name);

	const validateInput = (value) => {
		if (maxDate && dayjs(value).isAfter(dayjs(maxDate))) {
			// If value is greater than maxDate, set maxDate as the value
			helpers.setValue(dayjs(maxDate).toISOString());
		} else if (minDate && dayjs(value).isBefore(dayjs(minDate))) {
			// If value is less than minDate, set minDate as the value
			helpers.setValue(dayjs(minDate).toISOString());
		} else {
			// Otherwise, set the provided value
			helpers.setValue(value ? dayjs(value).toISOString() : null);
		}
	};

	return (
		<div className="relative sm:space-y-1" hidden={hidden}>
			<label
				htmlFor="name"
				className=" text-xs sm:text-sm capitalize dark:text-gray-500"
			>
				{label} {required && <span className="text-red-400">*</span>}
			</label>

			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					disabled={disabled}
					value={value ? dayjs(value) : null}
					onChange={(newValue) => validateInput(newValue)}
					defaultValue={maxDate ? dayjs(maxDate) : undefined}
					views={["year", "month", "day"]}
					className={`block w-full z-50 ${
						applyBaseColors ? "h-[47px] bg-sky" : "h-[40px]"
					} input `}
					sx={{
						".MuiInputBase-root": {
							maxHeight: applyBaseColors ? "47px" : "40px",
							outline: "none",
							fontSize: "16px",
							boxShadow: "none",
							paddingTop: 0,
							borderColor: "#d1d5db",
							borderRadius: "8px",
						},
					}}
					maxDate={maxDate ? dayjs(maxDate) : undefined}
					minDate={minDate ? dayjs(minDate) : undefined}
					shouldDisableDate={shouldDisableDate ? shouldDisableDate : undefined}
				/>
			</LocalizationProvider>
			{meta.error && meta.touched && (
				<div className="text-xs tracking-wide text-pink-200 text-start">
					<p className=" text-xs text-red-600">{meta?.error}</p>
				</div>
			)}
		</div>
	);
};

export default MUIFormDatePickerField;
