import React from "react";
import { Book } from "iconsax-react";
import FormInputField from "../../../common/components/FormInputField";

const ResumeContactInfo = () => {
    return (
        <div>
            <div className="flex items-center gap-3">
                <div className="bg-[#5B8EFB33] text-blue-500 p-2 rounded-lg">
                    <Book />
                </div>
                <h1 className="text-2xl font-semibold">Contact Information</h1>
            </div>

            <div className="space-y-5 mt-5">
                <FormInputField
                    name={"phone"}
                    label={"Phone Number"}
                    placeholder={"Enter your phone number"}
                    type={"tel"}
                />

                <FormInputField
                    name={"email"}
                    label={"Email"}
                    placeholder={"Enter your email address"}
                />

                <FormInputField
                    name={"linkedInUrl"}
                    label={"LinkedIn Profile URL"}
                    placeholder={"Enter your linkedin profile url"}
                />

                <FormInputField
                    name={"githubUrl"}
                    label={"Github Profile URL"}
                    placeholder={"Enter your github profile url"}
                />
            </div>
        </div>
    );
};

export default ResumeContactInfo;
