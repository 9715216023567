import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeChat: {},
    userList: [],
    chatData: [],
};

const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        setActiveChat: (state, action) => {
            state.activeChat = action.payload;
        },
        setUserList: (state, action) => {
            state.userList = Array.isArray(action.payload)
                ? [...action.payload]
                : [];
        },
        setChatData: (state, action) => {
            state.chatData = Array.isArray(action.payload)
                ? [...action.payload]
                : [];
        },
    },
});

export const messageActions = messageSlice.actions;

export default messageSlice.reducer;
