import { Loader } from "lucide-react";

const Spinner = () => {
	return (
		<div className="flex justify-center items-center">
			<Loader className="animate-spin transition-all duration-150" />
		</div>
	);
};

export default Spinner;
