import React, { useEffect, useRef } from "react";
import { Formik } from "formik";

const FormikFormGenerator = ({
	initialValues,
	validationSchema,
	handleSubmitFunction,
	formClassName,
	children,
	isSubmitClicked
}) => {
	const submitButtonRef = useRef();

	useEffect(() => {
		if (isSubmitClicked === true) {
			submitButtonRef.current.click();
		}
	}, [isSubmitClicked])

	return (
		<div>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					handleSubmitFunction(values);
				}}
			>
				{({ handleSubmit, values }) => (
					<form className={formClassName} onSubmit={handleSubmit}>
						{children}

						<button hidden type="submit" ref={submitButtonRef}></button>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default FormikFormGenerator;
