import React from "react";
import SocialMedia from "../../../assets/LandingPage/SocialMedia.png";
import LogoDesign from "../../../assets/LandingPage/LogoDesign.png";
import VoiceOver from "../../../assets/LandingPage/VoiceOver.png";

const Services = () => {
    return (
        <div className="md:mt-20 w-full max-w-7xl mx-auto px-5 sm:px-0">
            <h3 className="text-primary-purple text-xs sm:text-sm uppercase font-semibold text-start">
                Services
            </h3>

            <div className="flex flex-col lg:flex-row lg:justify-between gap-8 mt-2 sm:mt-3">
                {/* Text Content */}
                <div className="text-start max-w-lg">
                    <p className="text-lg md:text-3xl lg:text-4xl font-semibold uppercase leading-tight">
                        ALL THE SERVICES YOU NEED AND{" "}
                        <span className="blue-gradient-text">MORE</span>{" "}
                    </p>
                    <p className="pt-3 md:pt-5 text-sm md:text-base text-gray-600">
                        Search confidently in over 700 categories for the new
                        talent, and gigs for freelancers. Our site has only one
                        goal. Getting things done fast, without complications
                        and within your budget 
                    </p>
                </div>

                {/* Services Icons Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-5 w-full lg:w-auto">
                    <ServicesIcon
                        img={SocialMedia}
                        bgColor="bg-[#F682A5]"
                        serviceName="Social Media"
                    />
                    <ServicesIcon
                        img={LogoDesign}
                        bgColor="bg-[#FEDF6F]"
                        serviceName="Logo Design"
                    />
                    <ServicesIcon
                        img={VoiceOver}
                        bgColor="bg-[#F9A474]"
                        serviceName="Voice Over"
                    />
                </div>
            </div>
        </div>
    );
};

const ServicesIcon = ({ img, bgColor, serviceName }) => {
    return (
        <div className={`${bgColor} p-3 md:p-4 rounded-lg`}>
            <img
                src={img}
                alt={serviceName}
                className="w-full h-32 sm:h-36 md:h-48 object-cover rounded-lg transition-transform hover:scale-105 duration-300 ease-in-out"
            />
            <p className="pt-2 md:pt-6 text-start font-bold text-xs md:text-sm uppercase">
                {serviceName}
            </p>
        </div>
    );
};

export default Services;
