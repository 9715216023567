import { Firstline, Image, Video } from "iconsax-react";
import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import OverlayFragment from "../../../common/components/OverlayFragment";
import AddSocialPost from "../../Post/pages/AddSocialPost";
import AddArticle from "../../Post/pages/AddArticle";
import FileInputField from "../../../common/components/FileInputField";
import { GenerateProfileImg } from "../../../common/ReusableFunctions";

const AddPost = () => {
    const { profileDetails } = useSelector((state) => state.profile);
    const [isOpen, setIsOpen] = useState(false);
    const [isAddArticleOpen, setIsAddArticleOpen] = useState(false);
    const [postAssets, setPostAssets] = useState();

    const imageAssetsRef = useRef();
    const videoAssetsRef = useRef();

    useEffect(() => {
        if (postAssets?.length > 0) {
            setIsOpen(true);
        }
    }, [postAssets, setIsOpen]);

    return (
        <div className="flex items-center gap-3">
            <img
                src={GenerateProfileImg({
                    imgUrl: profileDetails?.imgUrl,
                    firstname: profileDetails?.firstname,
                    lastname: profileDetails?.lastname,
                })}
                alt="Profile"
                className="size-10 rounded-full object-cover"
            />

            <div className="h-[45px] sm:h-14 p-0.5 w-full center-vertical border bg-gradient-to-br from-[#F8A8F9]  to-[#024EEA] text-sm text-gray-500 rounded-full">
                <div className="bg-white p-2.5 sm:p-4 mx-1 rounded-full flex justify-between items-center w-full">
                    <p
                        className="cursor-pointer hidden sm:block"
                        onClick={() => {
                            setPostAssets([]);
                            setIsOpen(true);
                        }}
                    >
                        Start posting what comes in your mind...:)
                    </p>

                    <div className="flex items-center justify-between gap-6">
                        <p
                            className="flex items-center gap-1 hover:text-black cursor-pointer"
                            onClick={() => imageAssetsRef?.current?.click()}
                        >
                            <Image className="size-4" />
                            Images
                        </p>
                        <p
                            className="flex items-center gap-1 hover:text-black cursor-pointer"
                            onClick={() => videoAssetsRef?.current?.click()}
                        >
                            <Video className="size-4" />
                            Videos
                        </p>
                        <p
                            className="flex items-center gap-1 hover:text-black cursor-pointer"
                            onClick={() => setIsAddArticleOpen(true)}
                        >
                            <Firstline className="size-4" />
                            Article
                        </p>
                    </div>
                </div>
            </div>

            <OverlayFragment
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={"Add Post"}
                children={
                    <AddSocialPost
                        setIsOpen={setIsOpen}
                        assets={postAssets}
                        setPostAssets={setPostAssets}
                    />
                }
                size={"3xl"}
            />

            <OverlayFragment
                isOpen={isAddArticleOpen}
                setIsOpen={setIsAddArticleOpen}
                title={"Add Article"}
                children={<AddArticle setIsOpen={setIsAddArticleOpen} />}
                size={"3xl"}
            />

            <FileInputField
                ref={imageAssetsRef}
                isImageOnly
                multiple
                setAssets={setPostAssets}
                key={"Image assets"}
            />
            <FileInputField
                ref={videoAssetsRef}
                setAssets={setPostAssets}
                key={"Video Assets"}
            />
        </div>
    );
};

export default AddPost;
