import { useField } from "formik";
import { Eye, EyeSlash } from "iconsax-react";
import { useState } from "react";

const FormInputField = ({
    label,
    labelStyle,
    name,
    placeholder,
    type,
    disabled,
    required,
    hidden,
    className,
    main_type,
}) => {
    const [togglePassword, setTogglePassword] = useState(false);
    const [, meta, helpers] = useField(name);

    const handleToggle = () => {
        setTogglePassword(!togglePassword);
    };

    const validateInput = (value) => {
        if (type === "text" && !main_type) {
            let filteredValue = value?.replace(/[^A-Za-z]/g, "");
            filteredValue = filteredValue?.replace(/\b\w/g, (char) =>
                char.toUpperCase()
            );
            helpers.setValue(filteredValue);
        } else if (type === "text" && main_type === "alphanumeric") {
            const filteredValue = value?.replace(/[^A-Za-z0-9\s]/g, "");
            helpers.setValue(filteredValue);
        } else if (type === "email") {
            const filteredValue = value?.trim().replace(/[^a-z0-9@._-]/gi, "");
            helpers.setValue(filteredValue);
        } else if (type === "number" && !main_type) {
            const filteredValue = value?.replace(/[^0-9]/g, "");
            helpers.setValue(filteredValue);
        } else if (main_type === "aadhaar") {
            let filteredValue = value?.replace(/\D/g, "");
            if (filteredValue.length <= 12) {
                helpers.setValue(filteredValue);
            }
        } else if (main_type === "pan") {
            const filteredValue = value?.replace(/[^A-Za-z0-9]/g, "");
            if (filteredValue.length <= 10) {
                helpers?.setValue(
                    typeof filteredValue === "string"
                        ? filteredValue?.toUpperCase()
                        : filteredValue
                );
            }
        } else if (main_type === "simplePhoneNumber") {
            const filteredValue = value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
            if (filteredValue.length <= 10) {
                helpers.setValue(filteredValue);
            }
        } else if (main_type === "pincode") {
            if (value?.length <= 6) {
                helpers.setValue(value);
            }
        } else {
            helpers.setValue(value);
        }
    };

    return (
        <div>
            <div className="relative sm:space-y-1" hidden={hidden}>
                <label
                    htmlFor={name}
                    className={` text-xs sm:text-sm text-gray-600 block mb-1 text-start ${labelStyle}`}
                >
                    {label}{" "}
                    {required && <span className="text-red-400">*</span>}
                </label>
                <input
                    placeholder={placeholder}
                    name={name}
                    type={togglePassword ? "text" : type === "number" ? "text" : type}
                    value={meta.value}
                    onChange={(e) => validateInput(e.target.value)}
                    multiple
                    className={`${
                        type === "password" && "!pr-10"
                    } input ${className}`}
                    disabled={disabled ?? false}
                    onWheel={(e) => {
                        if (e.target instanceof HTMLInputElement) {
                            e.target.blur();
                        }
                    }}
                />
                {type === "password" ? (
                    togglePassword ? (
                        <Eye
                            onClick={handleToggle}
                            className="h-4 cursor-pointer absolute top-9 sm:top-8 right-3 bg-white z-10"
                        />
                    ) : (
                        <EyeSlash
                            onClick={handleToggle}
                            className="h-4 cursor-pointer absolute top-9 sm:top-8 right-3"
                        />
                    )
                ) : null}
                {/* {IconComponent && (
					<IconComponent
						className={`absolute top-10 left-3 sm:top-9 sm:left-3 text-light-gray w-4 h-4 sm:w-6 sm:h-6 ${
							disabled && "opacity-60"
						} dark:text-dark-text-gray`}
					/>
				)} */}
                {meta.error && meta.touched && (
                    <div className="text-xs tracking-wide text-pink-200 text-start">
                        <p className=" text-xs text-red-600">{meta?.error}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormInputField;
