import { MedalStar } from "iconsax-react";
import { MapPin } from "lucide-react";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { UserProfileImageUrl } from "../../../common/ReusableFunctions";
import { ROLES } from "../../../constant/constants";

const statusColorMap = {
    [ROLES.CLIENT]: "bg-[#FFEABB] text-[#F4900C]",
    [ROLES.EMPLOYEE]: "bg-[#E5F4FF] text-blue-500",
    [ROLES.FREELANCER]: "bg-[#E4FFF3] text-green-500",
    [ROLES.HR]: "bg-[#F1E5FF] text-[#665BFB]",
};

const ProfileCard = ({ isSticky }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const roleName = profileDetails?.role?.[0];

    const roles = useMemo(() => {
        switch (profileDetails?.role?.[0]) {
            case ROLES.CLIENT:
                return [
                    ROLES.CLIENT,
                    ROLES.FREELANCER,
                    ROLES.HR,
                    ROLES.EMPLOYEE,
                ];
            case ROLES.EMPLOYEE:
                return [
                    ROLES.EMPLOYEE,
                    ROLES.CLIENT,
                    ROLES.FREELANCER,
                    ROLES.HR,
                ];
            case ROLES.FREELANCER:
                return [
                    ROLES.FREELANCER,
                    ROLES.CLIENT,
                    ROLES.HR,
                    ROLES.EMPLOYEE,
                ];
            case ROLES.HR:
                return [
                    ROLES.HR,
                    ROLES.CLIENT,
                    ROLES.FREELANCER,
                    ROLES.EMPLOYEE,
                ];
            default:
                return [];
        }
    }, [profileDetails]);

    return (
        <div
            className={`md:max-w-[292px] w-full rounded-xl bg-white border overflow-hidden shadow-sm ${
                isSticky && "sticky top-[85px]"
            } `}
        >
            <div className="h-20">
                {!profileDetails?.coverImgUrl ? (
                    <div className="w-full h-full bg-gradient-to-r from-purple-900 via-red-500 to-yellow-500 rounded-t-lg"></div>
                ) : (
                    <img
                        className="w-full h-full rounded-t-lg object-cover"
                        src={
                            profileDetails?.coverImgUrl
                                ? profileDetails?.coverImgUrl
                                : "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
                        }
                        alt="Avatar"
                    />
                )}
            </div>

            <div className="px-4 pb-3 -mt-12 text-start">
                <div className="relative inline-block">
                    <div className="absolute top-2 left-2 w-3 h-3 bg-emerald-500 rounded-full border-2 border-white z-10" />

                    <img
                        src={UserProfileImageUrl()}
                        alt="Profile"
                        className="w-20 h-20 rounded-full border-4 border-white object-cover"
                    />

                    <div className="absolute -bottom-2 right-2 bg-white rounded-full p-1">
                        <MedalStar className="size-5 text-yellow-400" />
                    </div>
                </div>

                <div>
                    <h2 className="text-base font-bold">
                        {profileDetails?.firstname} {profileDetails?.lastname}{" "}
                    </h2>

                    <div className="flex flex-col gap-1">
                        <p className="text-gray-600 text-xs">
                            {profileDetails?.title}
                        </p>

                        <div className="flex items-center text-gray-500">
                            <MapPin className="w-4 h-4 mr-1" />
                            <span className="text-xs">
                                {profileDetails?.location ?? "Anywhere"}
                            </span>
                        </div>
                    </div>

                    <hr className="my-3" />

                    <div className="flex flex-wrap gap-1 text-[10px]">
                        {roles?.map((role) => (
                            <span
                                className={`px-2 py-1 rounded-full capitalize ${
                                    role === profileDetails?.role?.[0] &&
                                    statusColorMap?.[role]
                                }`}
                            >
                                {role === profileDetails?.role?.[0] && "•"}{" "}
                                {role === ROLES.HR
                                    ? "recruiter"
                                    : role?.toLowerCase()}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
