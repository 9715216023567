import React, { useEffect, useMemo, useState } from "react";
import CreditLogo from "./CreditLogo";
import Spinner from "./Spinner";
import useGlobalMutation from "../../hooks/useGlobalMutation";
import { ROUTES } from "../../Routes";
import { Call, Message } from "iconsax-react";
import { useSelector } from "react-redux";
import { GetCreditRequired } from "../ReusableFunctions";
import { CREDIT_ACTION } from "../../constant/constants";
import { toast } from "react-toastify";

const RevealContactButton = ({ jobId, creditActionType, data, queriesToInvalidate }) => {
    const { creditActionData } = useSelector((state) => state.custom);

    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

    const { runMutation, mutationLoading, mutationError, isMutationSucceeded } =
        useGlobalMutation({
            url: ROUTES.SUBSCRIPTION.REVEAL_CONTACT,
            methodType: "POST",
            data: {
                id: data?._id,
                jobId,
                type: creditActionType,
            },
            queriesToInvalidate
        });

    const isContactIsRevealed = useMemo(() => {
        let isEmailExits =
            data?.email && (data?.email !== null || data?.email !== "");
        let isPhoneExits =
            data?.phoneNumber &&
            (data?.phoneNumber !== null || data?.phoneNumber !== "");
        return isEmailExits || isPhoneExits ? true : false;
    }, [data]);

    useEffect(() => {
        if (isSubmitButtonClicked && mutationError) {
            toast.error(mutationError);
            setIsSubmitButtonClicked(false);
        }
    }, [isSubmitButtonClicked, mutationError]);

    useEffect(() => {
        isMutationSucceeded && setIsSubmitButtonClicked(false);
    }, [isMutationSucceeded]);

    return (
        <div className="rounded-xl border border-blue-300">
            {isContactIsRevealed ? (
                <div className="p-3 space-y-2">
                    <h1 className="text-sm sm:text-base font-semibold">
                        Contact Details
                    </h1>
                    <div className="space-y-2">
                        {data?.phoneNumber && (
                            <p className="flex text-xs sm:text-sm items-center gap-2">
                                <Call className="size-4" /> {data?.phoneNumber}
                            </p>
                        )}
                        <p className="flex text-xs sm:text-sm items-center gap-2">
                            <Message className="size-4" /> {data?.email}
                        </p>
                    </div>
                </div>
            ) : (
                <>
                    <div className="text-base text-center font-semibold text-blue-600 px-4 py-2 border-b border-blue-300">
                        To See contact details
                    </div>
                    <div
                        className=" text-sm flex justify-center items-center rounded-b-xl text-white gap-2 p-2 gradient-blue cursor-pointer"
                        onClick={() => {
                            setIsSubmitButtonClicked(true);
                            runMutation();
                        }}
                    >
                        {mutationLoading ? (
                            <Spinner />
                        ) : (
                            <>
                                It will cost{" "}
                                <span className="text-base font-semibold flex items-center gap-2">
                                    {GetCreditRequired({
                                        actionName: CREDIT_ACTION.SHOW_CONTACT,
                                        creditActionData,
                                    })}{" "}
                                    <CreditLogo />
                                </span>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default RevealContactButton;
