import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InfiniteScrollComponent from "../../../common/components/InfiniteScrollComponent";
import NoPostFound from "../../../common/components/NoPostFound";
import useGlobalInfiniteQuery from "../../../hooks/useGlobalInfiniteQuery";
import { ROUTES } from "../../../Routes";
import PostCard from "../../Home/components/PostCard";
import PostTabs from "../components/PostTabs";

const tabs = ["Most Recent", "Saved Posts", "Your Posts"];

const PostListing = () => {
    const queryClient = useQueryClient();
    const { profileDetails } = useSelector((state) => state.profile);

    const [activeTab, setActiveTab] = useState("Most Recent");

    const { queryData, isLoading, fetchNextPage, hasNextPage } =
        useGlobalInfiniteQuery({
            url: ROUTES.POST.GET_ALL_POST,
            methodType: "POST",
            queryKey: ["all-posts", activeTab],
            data:
                activeTab === "Saved Posts"
                    ? { ids: profileDetails?.savedPosts, limit: 25, sort: { updatedAt: 1 }  }
                    : activeTab === "Your Posts"
                    ? { ids: profileDetails?.myPosts, limit: 25 }
                    : { limit: 25 },
        });

    useEffect(() => {
        queryClient?.invalidateQueries({ queryKey: ["all-posts"] });
    }, [activeTab, queryClient]);

    const renderComponent = useCallback(() => {
        switch (activeTab) {
            case "Most Recent":
                return (
                    <InfiniteScrollComponent
                        fetchNextPage={fetchNextPage}
                        hasNextPage={hasNextPage}
                        isLoading={isLoading}
                        items={queryData}
                        className={"space-y-5"}
                    >
                        <PostCard />
                    </InfiniteScrollComponent>
                );
            case "Saved Posts":
                return (
                    <div>
                        {profileDetails?.savedPosts?.length > 0 ? (
                            <InfiniteScrollComponent
                                fetchNextPage={fetchNextPage}
                                hasNextPage={hasNextPage}
                                isLoading={isLoading}
                                items={queryData}
                                className={"space-y-5"}
                            >
                                <PostCard />
                            </InfiniteScrollComponent>
                        ) : (
                            <NoPostFound />
                        )}
                    </div>
                );
            case "Your Posts":
                return (
                    <div>
                        {profileDetails?.myPosts?.length > 0 ? (
                            <InfiniteScrollComponent
                                fetchNextPage={fetchNextPage}
                                hasNextPage={hasNextPage}
                                isLoading={isLoading}
                                items={queryData}
                                className={"space-y-5"}
                            >
                                <PostCard />
                            </InfiniteScrollComponent>
                        ) : (
                            <NoPostFound />
                        )}
                    </div>
                );
            default:
                return null;
        }
    }, [activeTab, fetchNextPage, hasNextPage, isLoading, profileDetails, queryData]);

    return (
        <div className="space-y-5 w-full">
            <PostTabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />

            {renderComponent()}
        </div>
    );
};

export default PostListing;
