import { useField } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function FormTextarea({
    label,
    name,
    placeholder,
    className,
    height,
}) {
    const [, meta, helpers] = useField(name);

    const modules = {
        toolbar: [
            [{ font: [] }, { size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
        ],
    };

    return (
        <div>
            <label htmlFor="comment" className="input-label">
                {label}
            </label>

            <ReactQuill
                modules={modules}
                value={meta.value}
                onChange={(value) => helpers.setValue(value)}
                theme="snow"
                placeholder={placeholder}
                className={`mt-2 ${className}`}
            />

            {meta.error && meta.touched && (
                <div className="text-xs tracking-wide text-pink-200 text-start mt-1">
                    <p className=" text-xs text-red-600">{meta?.error}</p>
                </div>
            )}
        </div>
    );
}
