import React, { forwardRef, useContext } from "react";
import { ResumeBuilderContext } from "./ResumeBuilder";
import { Call, Sms } from "iconsax-react";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import ExpandableDiv from "../../../common/components/ExpandableDiv";

const ResumeTemplate = forwardRef((props, ref) => {
    const { formData } = useContext(ResumeBuilderContext);

    return (
        <div className="border rounded-lg max-w-3xl overflow-hidden bg-white" ref={ref}>
            <div className="">
                <div className="flex items-center justify-center w-full border-b-2 border-black pb-5">
                    <div className="sm:text-center mt-10 space-y-3">
                        <p className="font-poppins font-bold text-heading sm:text-4xl text-2xl">
                            {formData?.name || "Your Name"}
                        </p>
                        <p className="text-lg text-center">
                            {formData?.title || "Your Title"}
                        </p>
                    </div>
                </div>

                <div className="p-5">
                    <div className="flex flex-col sm:flex-row gap-10 sm:mt-5">
                        <div className="flex flex-col sm:w-1/3">
                            {/* My Contact  */}
                            <div className="">
                                <h2 className="text-lg font-poppins font-bold text-top-color">
                                    My Contact
                                </h2>
                                <div className="border-2 border-gray-200 my-3"></div>

                                <div className="space-y-2">
                                    <div className="flex items-center gap-2">
                                        <Call
                                            className="size-5"
                                            variant="Bold"
                                        />
                                        <div className="truncate text-sm leading-7">
                                            {formData?.phone ||
                                                "Your Phone Number"}
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-2">
                                        <Sms
                                            className="size-5"
                                            variant="Bold"
                                        />
                                        <div className="truncate text-sm leading-7">
                                            {formData?.email || "Your Email"}
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-2">
                                        <FaLinkedin className="size-5" />
                                        <div className="truncate text-sm leading-7">
                                            {formData?.linkedInUrl ||
                                                "Your LinkedIn URL"}
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-2">
                                        <FaGithub className="size-5" />
                                        <div className="truncate text-sm leading-7">
                                            {formData?.githubUrl ||
                                                "Your Github URL"}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Skills  */}
                            <div className="py-3 sm:order-none order-2">
                                <h2 className="text-lg font-poppins font-bold text-top-color">
                                    Skills
                                </h2>
                                <div className="border-2 border-gray-200 my-2"></div>

                                <div>
                                    {formData?.skills?.map((skill) => (
                                        <div className="">{skill}</div>
                                    ))}
                                </div>
                            </div>

                            {/* Educational Details  */}
                            <div className="py-3 sm:order-none order-1">
                                <h2 className="text-lg font-poppins font-bold text-top-color">
                                    Education Background
                                </h2>
                                <div className="border-2 border-top-color my-2"></div>

                                <div className="flex flex-col space-y-1">
                                    {formData?.education?.map(
                                        (data) =>
                                            Object?.keys(data)?.length > 0 && (
                                                <EducationData data={data} />
                                            )
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col sm:w-2/3">
                            {/* About Me  */}
                            <div>
                                <h2 className="text-lg font-poppins font-bold text-top-color">
                                    About Me
                                </h2>
                                <div className="border-2 border-gray-200  border-top-color my-3"></div>
                                <p>
                                    {formData?.description ? (
                                        <ExpandableDiv
                                            text={
                                                formData?.description ||
                                                "To get a career opportunity which would help me to utilize my academic background to assist me to gain experience, employ my excellent skills, and enable me to make positive contribution."
                                            }
                                        />
                                    ) : (
                                        "To get a career opportunity which would help me to utilize my academic background to assist me to gain experience, employ my excellent skills, and enable me to make positive contribution."
                                    )}
                                </p>
                            </div>

                            {/* Professional Experience  */}
                            <div className="py-3">
                                <h2 className="text-lg font-poppins font-bold text-top-color">
                                    Professional Experience
                                </h2>
                                <div className="border-2 border-top-color my-2"></div>
                                <div className="space-y-1">
                                    {formData?.employment?.map(
                                        (data) =>
                                            Object.keys(data)?.length > 0 && (
                                                <EmploymentData data={data} />
                                            )
                                    )}
                                </div>
                            </div>

                            {/* Projects  */}
                            <div className="py-3">
                                <h2 className="text-lg font-poppins font-bold text-top-color">
                                    Projects
                                </h2>
                                <div className="border-2 border-top-color my-2"></div>

                                <div className="flex flex-col space-y-1">
                                    {formData?.projects?.map(
                                        (data) =>
                                            Object.keys(data)?.length > 0 && (
                                                <ProjectData data={data} />
                                            )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

const EducationData = ({ data }) => {
    return (
        <div className="flex flex-col">
            <p className="font-semibold text-xs text-gray-700">
                {data?.from} - {data?.to}
            </p>
            <p className="text-sm font-medium">
                <span className="text-base font-semibold">{data?.degree}</span>
                <br />
                {data?.instituteName}
            </p>
            <p className="font-bold text-xs text-gray-700 mb-2">
                {data?.areaOfStudy}
            </p>
        </div>
    );
};

const EmploymentData = ({ data }) => {
    return (
        <div className="flex flex-col">
            <p className="text-lg font-bold text-gray-700">
                {data?.companyName} | {data?.title}
            </p>
            <p className="font-semibold text-sm text-gray-700">
                {data?.from_year}-
                {data?.isCurrentlyWorking ? "Present" : data?.to_year}
            </p>
            {/* <p className="font-semibold text-sm text-gray-700 mt-2 mb-1">
                Key Responsibilities
            </p>
            <ul className="text-sm list-disc pl-4 space-y-1">
                <li>Developed usable components</li>
                <li>Solving complex problems</li>
                <li>Solving critical bugs</li>
            </ul> */}
            <ExpandableDiv
                text={data?.description}
                lineClampStyle={"line-clamp-3"}
                titleStyle={"!leading-7"}
            />
        </div>
    );
};

const ProjectData = ({ data }) => {
    return (
        <div className="flex flex-col">
            <p className="text-lg font-semibold text-gray-700">{data?.title}</p>
            <ExpandableDiv text={data?.description} />
        </div>
    );
};

export default ResumeTemplate;
