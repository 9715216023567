import { Icon } from "@iconify/react";
import {
    Button,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Textarea,
    Tooltip,
} from "@nextui-org/react";
import Picker from "emoji-picker-react";
import React, { forwardRef, useContext, useRef, useState } from "react";
import { ChatContext } from "../ChatLayout";
import AudioRecorder from "./AudioRecorder";
import { useDispatch, useSelector } from "react-redux";
import { messageActions } from "../../../store/message.slice";

const ChatInput = ({ sendMessage }) => {
    // const { activeChat, sendMessage, setChatData } = useContext(ChatContext);
    const { activeChat, chatData } = useSelector((state) => state.message);
    const [message, setMessage] = useState("");
    const { profileDetails } = useSelector((state) => state.profile);

    const inputRef = useRef();
    const dispatch = useDispatch();

    const handleSendMessage = () => {
        if (message) {
            const receiverEmail =
                activeChat?.receiverEmail !== profileDetails?.email
                    ? activeChat?.receiverEmail
                    : activeChat?.senderEmail;

            const payload = `${receiverEmail}:${message}`;
            sendMessage(payload);
            // setChatData((prevData) => [
            //     ...(prevData ?? []),
            //     {
            //         senderEmail: profileDetails?.email,
            //         receiverEmail,
            //         message: message,
            //         updatedAt: new Date().toISOString(),
            //     },
            // ]);
            dispatch(
                messageActions.setChatData([
                    ...(chatData ?? []),
                    {
                        senderEmail: profileDetails?.email,
                        receiverEmail,
                        message: message,
                        updatedAt: new Date().toISOString(),
                    },
                ])
            );
            setMessage("");
        }
    };

    const handleResize = () => {
        if (inputRef.current) {
            inputRef.current.style.height = "auto"; // Reset the height
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`; // Set the height based on scrollHeight
        }
    };

    return (
        <Textarea
            ref={inputRef}
            aria-label="message"
            classNames={{
                innerWrapper: "items-center focus-visible-outline-none",
                label: "hidden",
                input: "py-0 text-medium focus-visible:outline-none outline-none",
                inputWrapper:
                    " rounded-none border-0 border-t-2 items-center !outline-none border-x-0 focus-visible:outline-none py-0",
            }}
            minRows={1}
            endContent={
                <div className="flex items-center">
                    {/* <Tooltip showArrow content="Speak">
                        <AudioRecorder />
                    </Tooltip> */}
                    <Tooltip showArrow content="Send message">
                        <div className="flex h-10 flex-col justify-center">
                            <Button
                                isIconOnly
                                className="h-[30px] w-[30px] min-w-[30px] bg-foreground leading-[30px]"
                                radius="lg"
                                onPress={handleSendMessage}
                                isDisabled={!message}
                            >
                                <Icon
                                    className="cursor-pointer text-default-50 [&>path]:stroke-[2px]"
                                    icon="carbon:send"
                                    width={20}
                                />
                            </Button>
                        </div>
                    </Tooltip>
                </div>
            }
            placeholder="Start Typing..."
            startContent={
                <Tooltip showArrow content="Add Emoji">
                    <Popover placement={"top"}>
                        <PopoverTrigger>
                            <Button isIconOnly radius="full" variant="light">
                                <Icon
                                    className="text-default-500"
                                    icon="ph:smiley-light"
                                    width={20}
                                />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <Picker
                                onEmojiClick={(emojiData) => {
                                    console.log("emojiData", emojiData);
                                    setMessage(
                                        (prev) => prev + emojiData.emoji
                                    );
                                }}
                                className="border-none"
                            />
                        </PopoverContent>
                    </Popover>
                </Tooltip>
            }
            value={message}
            variant="bordered"
            onValueChange={(value) => {
                setMessage(value);
                handleResize();
            }}
            // {...props}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                    handleSendMessage();
                }
            }}
        />
    );
};

export default ChatInput;
