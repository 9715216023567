import React, { createContext, useState } from "react";
import { useSelector } from "react-redux";
import useWebSocket from "react-use-websocket";
import ChatContainer from "./components/ChatContainer";
import ChatSidebar from "./components/ChatSidebar";
import useIsMobile from "../../hooks/useIsMobile";

export const ChatContext = createContext();

const ChatLayout = () => {
    // const [activeChat, setActiveChat] = React.useState();
    // const [userList, setUserList] = React.useState([]);
    // const [chatData, setChatData] = React.useState([]);
    const { activeChat, userList, chatData } = useSelector(
        (state) => state.message
    );
    const { profileDetails } = useSelector((state) => state.profile);
    const { isMobile } = useIsMobile();

    const WS_URL = `wss://dc713if9s3.execute-api.ap-south-1.amazonaws.com/prod/?email=${profileDetails.email}`;

    const { sendMessage, lastMessage } = useWebSocket(WS_URL, {
        shouldReconnect: () => true,
    });

    return (
        <div className="h-[84vh] w-[95%] mt-6 mx-auto overflow-hidden border dark:border-dark-100 rounded-lg flex">
            {isMobile ? (
                Object.keys(activeChat)?.length > 0 ? (
                    ""
                ) : (
                    <ChatSidebar />
                )
            ) : (
                <ChatSidebar />
            )}

            {isMobile ? (
                Object.keys(activeChat)?.length > 0 ? (
                    <ChatContainer
                        lastMessage={lastMessage}
                        sendMessage={sendMessage}
                    />
                ) : (
                    ""
                )
            ) : !activeChat ? (
                <div className="w-full border dark:border-dark-100 rounded-lg relative center-vertical">
                    No Chats Found
                </div>
            ) : (
                <ChatContainer
                    lastMessage={lastMessage}
                    sendMessage={sendMessage}
                />
            )}
        </div>
    );
};

export default ChatLayout;
