import React, { useMemo, useState } from "react";
import FormikFormGenerator from "../../utils/FormikFormGenerator";
import FormInputField from "../../common/components/FormInputField";
import FormSelectMenu from "../../common/components/FormFields/FormSelectMenu";
import FormTextarea from "../../common/components/FormFields/FormTextarea";
import { Switch } from "@nextui-org/react";
import { Add, Trash } from "iconsax-react";
import CommonButton from "../../common/components/CommonButton";
import useGlobalMutation from "../../hooks/useGlobalMutation";
import { ROUTES } from "../../Routes";

const AddSubscriptionPlan = ({ isUpdate, data, setIsOpen }) => {
    const [isPopular, setIsPopular] = React.useState(isUpdate ? data?.isPopular : false);
    const [options, setOptions] = useState(isUpdate ? data?.options ?? [] : []);

    useMemo(() => {
        if (isUpdate) {
            const filteredValues = data?.options?.map((value, index) => {
                return {
                    id: index + 1,
                    value: value,
                };
            });
            setOptions(filteredValues);
        }
    }, [data, isUpdate, setOptions]);

    const { runMutation, mutationLoading, isMutationSucceeded } = useGlobalMutation({
        url: ROUTES.SUBSCRIPTION.CRUD,
        methodType: isUpdate ? "PUT" : "POST",
        closePopup: setIsOpen,
    });

    const addOption = () => {
        setOptions([...options, { id: options?.length + 1, value: "" }]);
    };

    const removeOption = (index) => {
        const updatedOptions = [...options];
        updatedOptions.splice(index, 1);
        setOptions(updatedOptions);
    };

    const handleOptionChange = (index, value) => {
        const updatedOptions = [...options];
        updatedOptions[index].value = value;
        setOptions(updatedOptions);
    };

    return (
        <div>
            <FormikFormGenerator
                initialValues={{
                    name: isUpdate ? data?.name ?? "" : "",
                    description: isUpdate ? data?.description ?? "" : "",
                    price: isUpdate ? data?.price ?? "" : "",
                    type: isUpdate ? data?.type ?? "" : "",
                    credits: isUpdate ? data?.credits ?? "" : "",
                }}
                formClassName={"space-y-5"}
                handleSubmitFunction={(values) => {
                    runMutation({
                        isPriorityDataAvailable: true,
                        priorityData: {
                            ...values,
                            isPopular: isPopular,
                            options: options?.map((t) => t?.value),
                            id: isUpdate ? data?._id : null,
                        },
                    });
                }}
            >
                <FormSelectMenu
                    name={"type"}
                    label={"Type of the subscription plan"}
                    placeholder={"Select a type of the subscription plan"}
                    options={[
                        { name: "Basic", value: "Basic" },
                        { name: "Pro", value: "Pro" },
                    ]}
                />

                <FormInputField
                    name={"name"}
                    label={"Subscription Plan Name"}
                    placeholder={"Enter the name of the subscription plan"}
                />

                <FormInputField
                    name={"price"}
                    label={"Price of the plan in ₹"}
                    placeholder={"Enter the price of the subscription plan"}
                    type={"number"}
                />

                <FormInputField
                    name={"credits"}
                    label={"Credits of the plan"}
                    placeholder={"Enter the credits"}
                    type={"number"}
                />

                <FormTextarea
                    name={"description"}
                    label={"Description"}
                    placeholder={"Enter subscription plan description"}
                />

                <Switch isSelected={isPopular} onValueChange={setIsPopular} size="sm">
                    Is Popular
                </Switch>

                {options?.map((option, index) => (
                    <div key={option.id} className="flex items-center mb-4">
                        <input
                            type="text"
                            value={option.value}
                            onChange={(e) => handleOptionChange(index, e.target.value)}
                            className="input"
                            placeholder={`Option ${index + 1}`}
                        />
                        <button
                            onClick={() => removeOption(index)}
                            className=" text-red-500 border rounded-md px-3 py-2 ml-2"
                        >
                            <Trash className="w-5 h-5" />
                        </button>
                    </div>
                ))}

                <button
                    onClick={addOption}
                    className="w-full border-2 text-sm rounded-md px-3 py-2 flex items-center justify-center gap-1 font-semibold text-blue-900"
                    type="button"
                >
                    <Add className="w-5 h-5" />
                    Add Option
                </button>

                <div className="flex items-center gap-4 justify-end max-w-72 ml-auto">
                    <CommonButton title={"Cancel"} isRounded={true} handleFunction={() => setIsOpen(false)} />
                    <CommonButton
                        title={"Submit"}
                        isRounded={true}
                        isGradient={true}
                        loading={mutationLoading}
                        disabled={mutationLoading}
                        type={"submit"}
                    />
                </div>
            </FormikFormGenerator>
        </div>
    );
};

export default AddSubscriptionPlan;
