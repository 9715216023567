import { Accordion, AccordionItem, Tab, Tabs } from "@nextui-org/react";
import { Mail, MessageSquare, Phone, Search } from "lucide-react";
import React, { useMemo, useState } from "react";
import useGlobalQuery from "../../../hooks/useGlobalQuery";
import { ROUTES } from "../../../Routes";
import { GenerateProfileImg } from "../../../common/ReusableFunctions";
import { ROLES } from "../../../constant/constants";
import { useSelector } from "react-redux";

const MessageList = () => {
    const { profileDetails } = useSelector((state) => state.profile);

    const role = useMemo(() => {
        switch (profileDetails?.role?.[0]) {
            case ROLES.CLIENT:
                return "Client";
            case ROLES.EMPLOYEE:
                return "Employee";
            case ROLES.FREELANCER:
                return "Freelancer";
            case ROLES.HR:
                return "Recruiter";
            default:
                return null;
        }
    }, [profileDetails]);

    const [activeTab, setActiveTab] = useState("All Live People");
    const [activeUserType, setActiveUserType] = useState(role);

    const { queryData: allLivePeople, isLoading: livePeoplesLoading } =
        useGlobalQuery({
            url: ROUTES.CHAT.ALL_LIVE_PEOPLE,
            methodType: "POST",
            queryKey: ["all-live-people", activeUserType],
            data: {
                filter: {
                    "userDetails.role": {
                        $in:
                            activeUserType === "Employee"
                                ? [ROLES.EMPLOYEE]
                                : activeUserType === "Freelancer"
                                ? [ROLES.FREELANCER]
                                : activeUserType === "Recruiter"
                                ? [ROLES.HR]
                                : [ROLES.CLIENT],
                    },
                },
            },
        });

    const { queryData: allChats, isLoading: allChatsLoading } = useGlobalQuery({
        url: ROUTES.CHAT.ALL_CHATS,
        methodType: "POST",
        queryKey: ["all-chats"],
    });

    const AllLivePeopleTabs = ["Employee", "Freelancer", "Recruiter", "Client"];

    const tabs = [
        {
            id: "All Live People",
            title: "All Live People",
        },
        {
            id: "Message",
            title: "Message",
        },
        {
            id: "Groups",
            title: "Groups",
        },
    ];

    return (
        <div className="max-w-[300px] w-full bg-white rounded-lg border shadow-sm overflow-auto sticky top-20">
            <Accordion
                // selectedKeys={}
                defaultSelectedKeys={"all"}
                // defaultChecked={[0]}
                selectionMode="multiple"
                className="px-0"
                itemClasses={{
                    titleWrapper: " dark:text-black ",
                    title: "capitalize  text-black  text-sm dark:text-dark-text-white ",
                    trigger:
                        "px-2 mx-0 py-0 w-full  bg-white  dark:bg-dark-normal dark:border-2 dark:border-dark-light  h-12 flex items-center  border-gray-light   border-b-gray-light rounded-lg",
                    content:
                        " w-full py-0 dark:border-dark-light  shadow-none  text-sm  ",
                    indicator: "text-black dark:text-dark-text-white",
                }}
            >
                <AccordionItem
                    key="1"
                    aria-label="Accordion 1"
                    title={"Message"}
                    className="rounded-lg"
                    classNames={{
                        base: "rounded-lg",
                        // content: "rounded-lg",
                    }}
                >
                    <div className="min-h-72 overflow-x-hidden">
                        <div className="flex items-center py-2 px-2 ">
                            <div className="space-y-1 bg-gray-100 py-1 rounded-md">
                                <Tabs
                                    aria-label="Dynamic tabs"
                                    items={tabs}
                                    classNames={{
                                        tab: "text-sm px-2 h-7",
                                        cursor: "",
                                        tabContent:
                                            "group-data-[selected=true]:text-emerald-500",
                                    }}
                                    selectedKey={activeTab}
                                    onSelectionChange={setActiveTab}
                                >
                                    {(item) => (
                                        <Tab
                                            key={item.id}
                                            title={item.title}
                                        ></Tab>
                                    )}
                                </Tabs>
                                {activeTab === "All Live People" && (
                                    <div className="flex justify-between gap-2 px-0.5">
                                        {AllLivePeopleTabs?.map((tab) => (
                                            <button
                                                key={tab}
                                                onClick={() =>
                                                    setActiveUserType(tab)
                                                }
                                                className={`px-2 py-1 rounded-full text-xs transition-all duration-200 hover:bg-emerald-500 hover:text-white ${
                                                    activeUserType === tab
                                                        ? "bg-emerald-500 text-white "
                                                        : "bg-gray-100 text-gray-600"
                                                }`}
                                            >
                                                {tab}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="p-2">
                            <div className="relative">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
                                <input
                                    type="text"
                                    placeholder="Category"
                                    className="w-full pl-10 pr-4 py-2 bg-gray-100 rounded-lg text-xs focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                />
                            </div>
                        </div>

                        <div className="divide-y">
                            {activeTab === "All Live People" ? (
                                allLivePeople?.data?.length > 0 ? (
                                    allLivePeople?.data?.map(
                                        (contact, index) => (
                                            <LivePeople
                                                contact={contact}
                                                index={index}
                                                activeTab={activeTab}
                                            />
                                        )
                                    )
                                ) : (
                                    <p className="center-vertical mt-3 font-light">
                                        No Users Active Now
                                    </p>
                                )
                            ) : activeTab === "Message" ? (
                                allChats?.data?.length > 0 ? (
                                    allChats?.data?.map((contact, index) => (
                                        <AllPeople
                                            contact={contact}
                                            index={index}
                                        />
                                    ))
                                ) : (
                                    <p className="center-vertical mt-3 font-light">
                                        No Messages So Far
                                    </p>
                                )
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default MessageList;

const LivePeople = ({ contact, index, activeTab }) => {
    return (
        <div
            key={index}
            className="p-4 flex items-center justify-between hover:bg-gray-50"
        >
            <div className="flex items-center space-x-3">
                <div className="relative">
                    <img
                        src={GenerateProfileImg({
                            imgUrl: contact?.userDetails?.imgUrl,
                            firstname: contact?.userDetails?.firstname,
                            lastname: contact?.userDetails?.lastname,
                        })}
                        alt="Profile"
                        className="w-10 h-10 rounded-full"
                    />
                    <div className="absolute top-0 right-0 w-3 h-3 bg-emerald-500 rounded-full border-2 border-white" />
                </div>
                <div>
                    <div className="flex items-center justify-between w-full gap-16">
                        <div className="font-medium text-sm">
                            {contact.userDetails.firstname}{" "}
                            {contact.userDetails.lastname}
                        </div>

                        {/* all live people */}
                        {activeTab === "All Live People" && (
                            <div className="flex items-center space-x-1">
                                <button className="p-1.5 rounded-full hover:bg-gray-100">
                                    <MessageSquare className="size-4 text-blue-500" />
                                </button>
                                {contact?.userDetails?.phoneNumber && (
                                    <button
                                        className="p-1.5 rounded-full hover:bg-gray-100"
                                        onClick={() =>
                                            (window.location.href = `tel:${contact?.userDetails?.phoneNumber}`)
                                        }
                                    >
                                        <Phone className="w-4 h-4 text-gray-400" />
                                    </button>
                                )}

                                {contact?.userDetails?.email && (
                                    <button
                                        className="p-1.5 rounded-full hover:bg-gray-100"
                                        onClick={() =>
                                            (window.location.href = `mailto:${contact?.userDetails?.emailAddress}`)
                                        }
                                    >
                                        <Mail className="w-4 h-4 text-gray-400" />
                                    </button>
                                )}
                            </div>
                        )}
                    </div>

                    {/* all live people */}
                    {activeTab === "All Live People" && (
                        <div className="flex items-center justify-between">
                            <div className="text-xs text-gray-600">
                                {contact?.userDetails?.hourlyRates}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const AllPeople = ({ contact, index }) => {
    return (
        <div key={index} className="p-4 flex items-center hover:bg-gray-50">
            <div className="flex items-center justify-between space-x-3 w-full">
                <div className="flex items-center gap-2">
                    <div className="relative">
                        <img
                            src={GenerateProfileImg({
                                imgUrl: contact?.senderDetails?.imgUrl,
                                firstname: contact?.senderDetails?.firstname,
                                lastname: contact?.senderDetails?.lastname,
                            })}
                            alt="Profile"
                            className="w-10 h-10 rounded-full"
                        />
                        <div className="absolute top-0 right-0 w-3 h-3 bg-emerald-500 rounded-full border-2 border-white" />
                    </div>
                    <div className="font-medium text-sm">
                        {contact.senderDetails.firstname}{" "}
                        {contact.senderDetails.lastname}
                    </div>
                </div>

                <div>
                    {/* all live people */}
                    <div className="flex items-center justify-between space-x-1">
                        <button className="p-1.5 rounded-full hover:bg-gray-100">
                            <MessageSquare className="size-4 text-blue-500" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
