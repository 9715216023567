import { ArrowLeft } from "iconsax-react";
import React from "react";
import { useSelector } from "react-redux";
import { GenerateProfileImg } from "../../common/ReusableFunctions";
import { useNavigate } from "react-router-dom";
import JobPost from "../Proposal/components/JobPost";
import JobStats from "./JobStats";
import Milestones from "./Milestones";

const Timeline = () => {
    const { profileDetails } = useSelector((state) => state.profile);

    const navigate = useNavigate();

    return (
        <div>
            <div className="text-white gradient-blue mb-0 p-4 sm:p-10  pt-12 sm:pt-20 text-start sm:pl-40">
                <ArrowLeft
                    className="bg-white text-blue-500 p-2 size-8 rounded-full cursor-pointer hidden sm:block absolute top-36 left-28"
                    onClick={() => navigate(-1)}
                />
                <h1 className="text-xl sm:text-2xl font-semibold mb-2">
                    Client(Name of the Projects)nitial consultation with the
                    client to understand requirements...
                </h1>

                <div className="flex items-center gap-2">
                    <img
                        src={GenerateProfileImg({
                            imgUrl: profileDetails?.imgUrl,
                            firstname: profileDetails?.firstname,
                            lastname: profileDetails?.lastname,
                        })}
                        alt="Profile"
                        className="size-10 rounded-full"
                    />

                    <div className="font-light">
                        <p>Rohit Chaware</p>
                        <p>Full Stack Developer</p>
                    </div>
                </div>
            </div>

            <div className="max-w-6xl mx-auto">
                <JobStats />
                <Milestones />
            </div>
        </div>
    );
};

export default Timeline;
