import React from "react";

const IndividualRolesChart = () => {
    return (
        <div className="w-full flex flex-row items-center justify-between  min-w-[50vw] sm:max-w-[50vw]  bg-white dark:bg-dark-normal dark:shadow-dark-light-gray dark:shadow-sm dark:text-white p-3 rounded-2xl drop-shadow-sm  max-h-[445px] shadow-sm hover:shadow-md border dark:border-0">
            <IndividualChart percentage={50} />
            <IndividualChart percentage={60} />
            <IndividualChart percentage={70} />
            <IndividualChart percentage={70} />
        </div>
    );
};

export default IndividualRolesChart;

const IndividualChart = ({ percentage }) => {
    // Calculate the stroke-dashoffset based on the percentage

    const radius = 16;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;
    return (
        <div className="relative h-40 w-40 inline-block">
            <svg
                className="h-full w-full"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                xmlns="http://www.w3.org/2000/svg"
            >
                {/* Background circle */}
                <circle
                    cx="18"
                    cy="18"
                    r={radius}
                    fill="none"
                    className="stroke-current text-gray-200 dark:text-gray-700"
                    strokeWidth="3"
                ></circle>

                {/* Blue circle with dynamic stroke-dashoffset */}
                <g className="origin-center -rotate-90 transform">
                    <circle
                        cx="18"
                        cy="18"
                        r={radius}
                        fill="none"
                        className="stroke-current text-[#7297F5] dark:text-blue-500"
                        strokeWidth="3"
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeDashoffset}
                    ></circle>
                </g>
            </svg>

            {/* Percentage text */}
            <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                <span className="text-center text-2xl font-bold text-gray-800 dark:text-white">
                    {percentage}%
                </span>
            </div>
        </div>
    );
};
