import { Location } from "iconsax-react";
import React, { useContext, useMemo } from "react";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import UserProfileImg from "../../../common/components/UserProfileImg";
import { GenerateProfileImg } from "../../../common/ReusableFunctions";
import ExpandableDiv from "../../../common/components/ExpandableDiv";
import useGlobalMutation from "../../../hooks/useGlobalMutation";
import { ROUTES } from "../../../Routes";
import { ReviewProposalContext } from "./ReviewProposal";
import CommonButton from "../../../common/components/CommonButton";

const InviteFreelancerCard = ({ data }) => {
    const { jobData } = useContext(ReviewProposalContext);

    const { runMutation, mutationLoading } = useGlobalMutation({
        url: ROUTES.INVITE.CRUD,
        methodType: "POST",
        data: {
            jobId: jobData?._id,
            entityType: jobData?.type,
            approverId: data?._id,
            isAcceptedByClient: true,
        },
    });

    const isInviteAlreadySent = useMemo(() => {
        return jobData?.usersInvited?.includes(data?._id);
    }, [jobData, data]);

    return (
        <div className="p-4 md:p-6 bg-white rounded-xl shadow-sm border">
            <div className="flex flex-col gap-4">
                {/* Header Section */}
                <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-4">
                    {/* Profile Info */}
                    <div className="flex gap-4">
                        {/* Avatar with Status */}
                        <div className="relative shrink-0">
                            <div className="w-12 h-12 bg-gray-200 rounded-full overflow-hidden">
                                <img
                                    src={GenerateProfileImg({
                                        imgUrl: data?.imgUrl,
                                        firstname: data?.firstname,
                                        lastname: data?.lastname,
                                    })}
                                    loading="lazy"
                                    alt="Profile"
                                />
                            </div>
                            {/* <div className="absolute top-0 left-0 border-2 border-white">
                                <div className="relative">
                                    <div className="absolute top-1 -left-1 w-2.5 h-2.5 bg-green-500 rounded-full">
                                        <div className="absolute inset-0 rounded-full bg-green-500 animate-ping"></div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        {/* Name and Location */}
                        <div className="flex flex-col">
                            <h2 className="font-semibold text-gray-900 text-base sm:text-lg">
                                {data?.firstname} {data?.lastname}
                            </h2>
                            <div className="flex items-center text-gray-500 text-sm">
                                <Location className="w-4 h-4 mr-1" />
                                {data?.location ?? "Anywhere"}
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex flex-wrap items-center gap-2">
                        {/* <button className="p-2 text-gray-500 hover:bg-gray-50 rounded-full border transition-colors">
                            <BookmarkIcon className="w-5 h-5" />
                        </button> */}
                        <div className="flex gap-2 flex-1 sm:flex-none">
                            {/* <button className="flex-1 sm:flex-none px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-blue-300 rounded-full hover:bg-blue-50 transition-colors">
                                Hire
                            </button> */}

                            <CommonButton
                                title={isInviteAlreadySent ? "Invited" : "Send Invite"}
                                disabled={mutationLoading || isInviteAlreadySent}
                                loading={mutationLoading}
                                isGradient={true}
                                isRounded={true}
                                handleFunction={() => runMutation()}
                            />
                            {/* <button
                                className="flex-1 sm:flex-none px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-full hover:from-blue-600 hover:to-blue-700 transition-colors disabled:opacity-50"
                                onClick={() => runMutation()}
                                disabled={isInviteAlreadySent}
                            ></button> */}
                        </div>
                    </div>
                </div>

                {/* Stats Section */}
                <div className="flex flex-wrap gap-3 sm:gap-6 text-sm text-gray-600">
                    <div className="whitespace-nowrap">₹{data?.hourlyRates}/hr</div>
                    <div className="whitespace-nowrap">{data?.workType}</div>
                    <div className="whitespace-nowrap">$70K+ earned</div>
                </div>

                {/* Skills Section */}
                <div>
                    <ExpandableDiv text={data?.description} />

                    <div className="flex flex-wrap gap-2 mt-3">
                        {data?.skills.map((skill) => (
                            <span
                                key={skill}
                                className="px-3 py-1 bg-gray-100 text-gray-600 text-xs sm:text-sm rounded-full"
                            >
                                {skill}
                            </span>
                        ))}
                        {/* <span className="px-3 py-1 bg-gray-100 text-gray-600 text-xs sm:text-sm rounded-full">
                            +{remainingSkills} skills
                        </span> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InviteFreelancerCard;
