import React, { useContext, useMemo, useState } from "react";
import PostTabs from "../../Post/components/PostTabs";
import TalentCard from "./TalentCard";
import { useLocation } from "react-router-dom";
import useGlobalInfiniteQuery from "../../../hooks/useGlobalInfiniteQuery";
import { ROUTES } from "../../../Routes";
import Loader from "../../../common/Loader/Loader";
import InfiniteScrollComponent from "../../../common/components/InfiniteScrollComponent";
import { useSelector } from "react-redux";
import NoPostFound from "../../../common/components/NoPostFound";
import { ROLES } from "../../../constant/constants";
import {
    MobileFilters,
    UserFilterForm,
} from "../../FreelancerListing/components/FreelancerListing";
import { FunnelIcon } from "@heroicons/react/20/solid";

const AllTalents = ({ context, showFilter }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const [activeTab, setActiveTab] = React.useState("Best Matches");
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

    const { filters } = useContext(context);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get("search");

    const refinedFilters = useMemo(() => {
        let matchFilter = {};

        if (search) {
            matchFilter.$or = [
                { firstname: { $regex: search, $options: "i" } },
                { lastname: { $regex: search, $options: "i" } },
                { title: { $regex: search, $options: "i" } },
            ];
        }

        if (filters?.location)
            matchFilter.location = { $regex: filters.location, $options: "i" };

        if (filters?.title)
            matchFilter.title = { $regex: filters.title, $options: "i" };

        if (filters?.name) {
            const nameParts = filters.name.split(" ");

            matchFilter.$or = nameParts.map((part) => ({
                $or: [
                    { firstname: { $regex: part, $options: "i" } },
                    { lastname: { $regex: part, $options: "i" } },
                ],
            }));
        }

        if (filters?.skills?.length)
            matchFilter.skills = { $in: filters.skills };

        if (filters?.experienceLevel?.length)
            matchFilter.experienceLevel = { $in: filters.experienceLevel };

        if (filters?.educationLevel?.length) {
            matchFilter.educationDetails = {
                $elemMatch: {
                    degree: { $in: filters.educationLevel },
                },
            };
        }

        if (filters?.canBeJoinIn) {
            matchFilter.canBeJoinIn = { $lte: filters?.canBeJoinIn };
        }

        return matchFilter;
    }, [filters, search]);

    const { queryData, isLoading, fetchNextPage, hasNextPage, totalCount } =
        useGlobalInfiniteQuery({
            url: ROUTES.AUTHENTICATION.ALL_USERS,
            methodType: "POST",
            data:
                activeTab === "Saved Talents"
                    ? { ids: profileDetails?.savedUsers, limit: 25 }
                    : {
                          filter: {
                              role: ["employee"],
                              ...refinedFilters,
                          },
                          limit: 25,
                      },
            queryKey: ["all-users", refinedFilters, "employees", activeTab],
        });

    const refinedData = useMemo(() => {
        return queryData?.filter(
            (item) => item?.resume !== null && item?.resume?.resumeUrl
        );
    }, [queryData]);

    const renderComponent = useMemo(() => {
        switch (activeTab) {
            case "Saved Talents": {
                return (
                    <>
                        {profileDetails?.savedUsers?.length > 0 ? (
                            <InfiniteScrollComponent
                                fetchNextPage={fetchNextPage}
                                hasNextPage={hasNextPage}
                                isLoading={isLoading}
                                items={refinedData}
                                className={"space-y-5"}
                            >
                                <TalentCard />
                            </InfiniteScrollComponent>
                        ) : (
                            <NoPostFound />
                        )}
                    </>
                );
            }
            default: {
                return (
                    <>
                        {totalCount > 0 ? (
                            <InfiniteScrollComponent
                                fetchNextPage={fetchNextPage}
                                hasNextPage={hasNextPage}
                                isLoading={isLoading}
                                items={refinedData}
                                className={"space-y-5"}
                            >
                                <TalentCard />
                            </InfiniteScrollComponent>
                        ) : (
                            <NoPostFound />
                        )}
                    </>
                );
            }
        }
    }, [
        profileDetails,
        activeTab,
        fetchNextPage,
        hasNextPage,
        isLoading,
        refinedData,
        totalCount,
    ]);

    const tabs = useMemo(() => {
        switch (profileDetails?.role?.[0]) {
            case ROLES.HR:
                return ["Best Matches", "Most Recent", "Saved Talents"];
            default:
                return ["Best Matches", "Most Recent"];
        }
    }, [profileDetails?.role]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className={`w-full space-y-3 sm:space-y-5 -mt-3 sm:mt-0 ${showFilter && "mt-3"}`}>
            <div className="flex flex-row items-center justify-between">
                <h1 className="text-lg sm:text-xl font-semibold text-center sm:text-start">
                    Popular Talents{" "}
                    <span className="text-gray-500 text-lg">
                        ({refinedData?.length ?? 0})
                    </span>
                </h1>

                {showFilter && (
                    <button
                        type="button"
                        onClick={() => setMobileFiltersOpen(true)}
                        className=" text-gray-400 hover:text-gray-500 lg:hidden"
                    >
                        <span className="sr-only">Filters</span>
                        <FunnelIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                )}
            </div>

            <PostTabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />

            {renderComponent}

            <MobileFilters
                isOpen={mobileFiltersOpen}
                setIsOpen={setMobileFiltersOpen}
            >
                <UserFilterForm context={context} />
            </MobileFilters>
        </div>
    );
};

export default AllTalents;
