import React from "react";
import FormikFormGenerator from "../../utils/FormikFormGenerator";
import * as Yup from "yup";
import { generateValidationSchema } from "../../services/FormHandler";
import FormFieldsMapper from "../../utils/FormFieldsMapper";
import CommonButton from "../../common/components/CommonButton";
import useGlobalMutation from "../../hooks/useGlobalMutation";
import { ROUTES } from "../../Routes";
import { ACTION_REQUIRED_VALUE_TYPE, CREDIT_ACTION } from "../../constant/constants";

const AddCreditActionCost = ({ setIsOpen, isUpdate, data }) => {
    const { runMutation, mutationLoading } = useGlobalMutation({
        url: ROUTES.SUBSCRIPTION.CREDIT_ACTION.CRUD,
        methodType: isUpdate ? "PUT" : "POST",
        closePopup: setIsOpen,
        queriesToInvalidate: ["all-credit-actions"],
    });

    const actionNameOptions = [
        {
            name: CREDIT_ACTION.APPLY_JOB,
            value: CREDIT_ACTION.APPLY_JOB
        },
        {
            name: CREDIT_ACTION.POST_JOB,
            value: CREDIT_ACTION.POST_JOB
        },
        {
            name: CREDIT_ACTION.SHOW_CONTACT,
            value: CREDIT_ACTION.SHOW_CONTACT
        }
    ]

    const fields = [
        {
            fieldType: "select",
            name: "actionName",
            label: "Action Name",
            fieldName: "Action Name",
            options: actionNameOptions,
            placeholder: "Select credit action name",
            required: true,
            disabled: true
        },
        {
            fieldType: "select",
            name: "creditRequiredType",
            label: "Credit Type",
            fieldName: "Credit Type",
            placeholder: "Enter credit type",
            required: true,
            options: ACTION_REQUIRED_VALUE_TYPE,
        },
        {
            fieldType: "input",
            name: "creditRequired",
            label: "Credit Required",
            fieldName: "Credit",
            placeholder: "Enter credit required",
            required: true,
            type: "number",
        },
    ];

    return (
        <div>
            <FormikFormGenerator
                initialValues={{
                    actionName: isUpdate ? data?.actionName : "",
                    creditRequiredType: isUpdate ? data?.creditRequiredType : "",
                    creditRequired: isUpdate ? data?.creditRequired : "",
                }}
                validationSchema={generateValidationSchema(fields)}
                formClassName={"space-y-5"}
                handleSubmitFunction={(values) => {
                    runMutation({
                        isPriorityDataAvailable: true,
                        priorityData: {
                            ...values,
                            id: isUpdate ? data?._id : null,
                        },
                    });
                }}
            >
                <FormFieldsMapper fields={fields} />

                <div className="flex items-center gap-4 justify-end max-w-72 ml-auto">
                    <CommonButton title={"Cancel"} isRounded={true} handleFunction={() => setIsOpen(false)} />
                    <CommonButton
                        title={"Submit"}
                        isRounded={true}
                        isGradient={true}
                        loading={mutationLoading}
                        disabled={mutationLoading}
                        type={"submit"}
                    />
                </div>
            </FormikFormGenerator>
        </div>
    );
};

export default AddCreditActionCost;
