import React from "react";
import { ScrollShadow } from "@nextui-org/react";

const TermsAndConditions = () => {
    return (
        <ScrollShadow>
            <div>
                <p className="mb-6 text-gray-700">
                    Welcome to <strong>PortfoliOOo</strong>! These Terms and
                    Conditions ("Terms") govern your use of the PortfoliOOo
                    platform ("Platform"), including all services, features, and
                    content provided by PortfoliOOo. By signing up, accessing,
                    or using the Platform, you agree to be bound by these Terms.
                    If you do not agree to these Terms, you may not use the
                    Platform.
                </p>

                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    1. Acceptance of Terms
                </h2>
                <p className="mb-6 text-gray-700">
                    By creating an account on PortfoliOOo, you confirm that you:
                </p>
                <ul className="list-disc list-inside mb-6 text-gray-700">
                    <li>
                        Are at least 18 years old or the legal age of majority
                        in your jurisdiction.
                    </li>
                    <li>
                        Have the legal capacity to enter into a binding
                        agreement.
                    </li>
                    <li>
                        Agree to comply with these Terms and all applicable laws
                        and regulations.
                    </li>
                </ul>

                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    2. Account Registration
                </h2>
                <p className="mb-4 text-gray-700">
                    <strong>2.1 Account Creation:</strong> To use PortfoliOOo,
                    you must create an account by providing accurate, complete,
                    and up-to-date information. You are responsible for
                    maintaining the confidentiality of your account credentials
                    and for all activities under your account.
                </p>
                <p className="mb-4 text-gray-700">
                    <strong>2.2 Account Types:</strong> PortfoliOOo offers two
                    types of accounts:
                </p>
                <ul className="list-disc list-inside mb-4 text-gray-700">
                    <li>
                        <strong>Freelancer Account:</strong> For individuals
                        offering services.
                    </li>
                    <li>
                        <strong>Client Account:</strong> For individuals or
                        businesses seeking services.
                    </li>
                </ul>
                <p className="mb-4 text-gray-700">
                    <strong>2.3 Verification:</strong> PortfoliOOo may require
                    you to verify your identity or provide additional
                    information to access certain features.
                </p>
                <p className="mb-6 text-gray-700">
                    <strong>2.4 Prohibited Activities:</strong> You may not:
                </p>
                <ul className="list-disc list-inside mb-6 text-gray-700">
                    <li>Create multiple accounts without permission.</li>
                    <li>
                        Use another person’s account or impersonate someone
                        else.
                    </li>
                    <li>Provide false or misleading information.</li>
                </ul>

                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    3. User Responsibilities
                </h2>
                <p className="mb-4 text-gray-700">
                    <strong>3.1 Freelancers:</strong>
                </p>
                <ul className="list-disc list-inside mb-4 text-gray-700">
                    <li>
                        You are responsible for the quality and delivery of your
                        services.
                    </li>
                    <li>
                        You must comply with all agreements made with clients.
                    </li>
                    <li>
                        You may not engage in fraudulent, illegal, or unethical
                        activities.
                    </li>
                </ul>
                <p className="mb-4 text-gray-700">
                    <strong>3.2 Clients:</strong>
                </p>
                <ul className="list-disc list-inside mb-4 text-gray-700">
                    <li>
                        You are responsible for providing clear and accurate
                        project requirements.
                    </li>
                    <li>You must pay for services as agreed upon.</li>
                    <li>You may not harass, abuse, or exploit freelancers.</li>
                </ul>
                <p className="mb-6 text-gray-700">
                    <strong>3.3 Prohibited Content:</strong> Users may not post,
                    share, or promote content that is:
                </p>
                <ul className="list-disc list-inside mb-6 text-gray-700">
                    <li>Illegal, harmful, or offensive.</li>
                    <li>Infringing on intellectual property rights.</li>
                    <li>Spam, phishing, or malicious software.</li>
                </ul>

                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    4. Payments and Fees
                </h2>
                <p className="mb-4 text-gray-700">
                    <strong>4.1 Service Fees:</strong> PortfoliOOo charges a
                    service fee for transactions conducted on the Platform. Fees
                    will be clearly displayed before you confirm a transaction.
                </p>
                <p className="mb-4 text-gray-700">
                    <strong>4.2 Payment Processing:</strong> Payments are
                    processed through secure third-party payment gateways.
                    PortfoliOOo is not responsible for any issues arising from
                    payment processing.
                </p>
                <p className="mb-6 text-gray-700">
                    <strong>4.3 Refunds and Disputes:</strong> Refunds and
                    disputes will be handled in accordance with PortfoliOOo’s
                    Refund and Dispute Resolution Policy.
                </p>

                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    5. Intellectual Property
                </h2>
                <p className="mb-4 text-gray-700">
                    <strong>5.1 Freelancer Ownership:</strong> Freelancers
                    retain ownership of their work unless otherwise agreed upon
                    in writing with the client.
                </p>
                <p className="mb-4 text-gray-700">
                    <strong>5.2 Client Ownership:</strong> Clients own the
                    rights to work delivered by freelancers upon full payment.
                </p>
                <p className="mb-6 text-gray-700">
                    <strong>5.3 Platform Ownership:</strong> PortfoliOOo retains
                    ownership of all intellectual property related to the
                    Platform, including logos, designs, and software.
                </p>

                <h2 className="text-2xl font-semibold mt-6 mb-4">6. Privacy</h2>
                <p className="mb-6 text-gray-700">
                    Your use of the Platform is subject to PortfoliOOo’s Privacy
                    Policy, which explains how we collect, use, and protect your
                    personal information.
                </p>

                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    7. Termination
                </h2>
                <p className="mb-4 text-gray-700">
                    <strong>7.1 By You:</strong> You may deactivate your account
                    at any time by following the instructions on the Platform.
                </p>
                <p className="mb-4 text-gray-700">
                    <strong>7.2 By PortfoliOOo:</strong> PortfoliOOo reserves
                    the right to suspend or terminate your account if you
                    violate these Terms or engage in prohibited activities.
                </p>
                <p className="mb-6 text-gray-700">
                    <strong>7.3 Effect of Termination:</strong> Upon
                    termination, you will lose access to your account and any
                    remaining balances or data may be forfeited.
                </p>

                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    8. Limitation of Liability
                </h2>
                <p className="mb-6 text-gray-700">
                    PortfoliOOo is not liable for:
                </p>
                <ul className="list-disc list-inside mb-6 text-gray-700">
                    <li>
                        Any damages or losses resulting from your use of the
                        Platform.
                    </li>
                    <li>Disputes between freelancers and clients.</li>
                    <li>Third-party actions or content.</li>
                </ul>

                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    9. Dispute Resolution
                </h2>
                <p className="mb-4 text-gray-700">
                    <strong>9.1 Negotiation:</strong> Users are encouraged to
                    resolve disputes amicably.
                </p>
                <p className="mb-4 text-gray-700">
                    <strong>9.2 Mediation:</strong> If a dispute cannot be
                    resolved, PortfoliOOo may offer mediation services.
                </p>
                <p className="mb-6 text-gray-700">
                    <strong>9.3 Legal Action:</strong> Users may pursue legal
                    action if necessary, subject to the governing law.
                </p>

                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    10. Governing Law
                </h2>
                <p className="mb-6 text-gray-700">
                    These Terms are governed by the laws of [Your
                    Country/State], and any disputes will be resolved in the
                    courts of [Your Jurisdiction].
                </p>

                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    11. Changes to Terms
                </h2>
                <p className="mb-6 text-gray-700">
                    PortfoliOOo reserves the right to modify these Terms at any
                    time. Continued use of the Platform after changes
                    constitutes acceptance of the revised Terms.
                </p>

                <h2 className="text-2xl font-semibold mt-6 mb-4">
                    12. Contact Us
                </h2>
                <p className="mb-6 text-gray-700">
                    If you have any questions about these Terms, please contact
                    us at:
                </p>
                <ul className="list-disc list-inside mb-6 text-gray-700">
                    <li>Email: [Your Email Address]</li>
                    <li>Address: [Your Physical Address]</li>
                    <li>Phone: [Your Phone Number]</li>
                </ul>

                <p className="text-center text-gray-700">
                    By signing up for PortfoliOOo, you acknowledge that you have
                    read, understood, and agreed to these Terms and Conditions.
                </p>
            </div>
        </ScrollShadow>
    );
};

export default TermsAndConditions;
