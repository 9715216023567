import { useField } from "formik";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export default function FormCheckbox({
	options,
	label,
	name,
	defaultChecked,
	readOnly,
	className,
	disabled,
	hidden,
}) {
	const [, meta, helpers] = useField(name);

	const [selected, setSelected] = useState(
		Array.isArray(meta.value) ? meta.value : defaultChecked || []
	);

	useEffect(() => {
		helpers.setValue(selected);
	}, [selected, helpers]);

	const handleCheckboxChange = (options) => {
		if (selected?.includes(options)) {
			setSelected(selected.filter((item) => item !== options));
		} else {
			setSelected([...selected, options]);
		}
	};

	return (
		<div className="space-y-2" hidden={hidden}>
			<label className="text-sm">{label}</label>
			<fieldset>
				<div className="flex flex-col gap-3">
					{options.map((option, indx) => (
						<div className="relative flex items-start" key={indx}>
							<div className="flex h-6 items-center">
								<input
									id={option.name}
									name={option.name}
									type="checkbox"
									className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
									onChange={() =>
										(!readOnly || !disabled) &&
										handleCheckboxChange(option.value)
									}
									checked={
										typeof selected === "boolean"
											? selected === option.value
											: selected?.includes(option?.value)
									}
									disabled={disabled}
								/>
							</div>
							<div className="ml-3 text-sm leading-6">
								<label
									htmlFor={option.name}
									className={twMerge(
										className,
										"font-medium text-gray-900 dark:text-white text-xs sm:text-sm"
									)}
								>
									{option.name}
								</label>
							</div>
						</div>
					))}
				</div>
			</fieldset>
			{meta?.error && meta?.touched && (
				<div className="text-xs tracking-wide text-pink-200 text-start">
					<p className=" text-xs text-red-600">{meta?.error}</p>
				</div>
			)}
		</div>
	);
}
