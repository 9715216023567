import React from "react";
import TestimonialImage from "../../../assets/LandingPage/Testimonial.png";

const Testimonials = () => {
	return (
		<section className="w-full px-5 sm:px-6 lg:px-8 py-0 md:py-12">
			<div className="flex flex-col  justify-center items-center max-w-7xl mx-auto gap-6 md:gap-10">
				<div className="space-y-2 md:space-y-3 text-start sm:text-center">
					<h3 className="text-primary-purple text-xs md:text-base uppercase font-semibold text-center">
						Testimonials
					</h3>
					<h1 className="text-lg sm:text-3xl md:text-4xl font-semibold uppercase">
						What they're saying about PortfoliOOo
					</h1>
				</div>

				<div className="bg-white rounded-2xl transition-shadow duration-300">
					<div className="flex flex-col md:flex-row py-0 md:py-8 px-6 md:px-12 gap-6 md:gap-10 items-center">
						<div className="w-full md:w-auto flex-shrink-0">
							<img
								src={TestimonialImage}
								alt="Testimonial"
								className="w-full md:w-80 h-80 object-cover rounded-xl shadow-sm"
							/>
						</div>

						<div className="flex flex-col space-y-4 md:space-y-6 flex-grow">
							<p className="text-xs md:text-sm text-gray-600 font-medium">
								Kayo Honi (DNP, PMHNP-BC), Co-Founder | Google
							</p>
							<blockquote className="text-sm md:text-lg text-gray-800 relative">
								{/* <span className="absolute -left-4 top-0 text-4xl text-primary-purple opacity-50">"</span> */}
								<p className="italic">
									It's extremely exciting that PortfoliOOo has freelancers from
									all over the world — it broadens the talent pool. One of the
									best things about PortfoliOOo is that while we're sleeping,
									someone's working.
								</p>
								{/* <span className="absolute -bottom-4 right-0 text-4xl text-primary-purple opacity-50">"</span> */}
							</blockquote>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Testimonials;
