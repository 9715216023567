"use client";

import { Avatar, cn } from "@nextui-org/react";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { formatDate, GenerateProfileImg } from "../../../../common/ReusableFunctions";
import { Icon } from "@iconify/react";

export const NotificationItems = ({ data }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    // const contentByType = {
    //     default: null,
    //     request: (
    //         <div className="flex gap-2 pt-2">
    //             <Button color="primary" size="sm">
    //                 Accept
    //             </Button>
    //             <Button size="sm" variant="flat">
    //                 Decline
    //             </Button>
    //         </div>
    //     ),
    //     file: (
    //         <div className="flex items-center gap-2">
    //             <Icon
    //                 className="text-secondary"
    //                 icon="solar:figma-file-linear"
    //                 width={30}
    //             />
    //             <div className="flex flex-col">
    //                 <strong className="text-small font-medium">
    //                     Brand_Logo_v1.2.fig
    //                 </strong>
    //                 <p className="text-tiny text-default-400">3.4 MB</p>
    //             </div>
    //         </div>
    //     ),
    // };

    const dataToShow = useMemo(() => {
        let userData = data?.senderDetails;
        let message = data?.message;

        return {
            userData,
            message,
            time: formatDate({ dateString: data?.updatedAt }),
            readStatus: data?.readStatus,
            type: data?.type,
        };
    }, [data]);

    return (
        <div
            className={cn("flex gap-3 border-b border-divider px-6 py-4", {
                "bg-primary-50/50": !dataToShow?.readStatus,
            })}
            // {...props}
        >
            <div className="relative flex-none">
                {dataToShow?.type === "CREDITS_ADDED" || dataToShow?.type === "CREDITS_DEDUCTED" ? (
                    <Icon icon={"twemoji:coin"} className="size-10" />
                ) : dataToShow?.type === "GIG-CREATED" ? (
                    <Icon icon={"unjs:giget"} className="size-10" />
                ) : (
                    <Avatar src={dataToShow.userData?.imgUrl} />
                )}
            </div>
            <div className="flex flex-col gap-1">
                <p className="text-small text-foreground">
                    {/* <strong className="font-medium">
                        {dataToShow?.userData?.firstname} {dataToShow?.userData?.lastname}
                    </strong>{" "} */}

                    {dataToShow?.message}
                </p>
                <time className="text-tiny text-default-400">{dataToShow?.time}</time>
                {/* {type && contentByType[type]} */}
            </div>
        </div>
    );
};

NotificationItems.displayName = "NotificationItems";

export default NotificationItems;
