import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { FunnelIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { AddCircle } from "iconsax-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../../../common/components/CommonButton";
import InfiniteScrollComponent from "../../../common/components/InfiniteScrollComponent";
import NoPostFound from "../../../common/components/NoPostFound";
import Loader from "../../../common/Loader/Loader";
import { ROLES } from "../../../constant/constants";
import useGlobalInfiniteQuery from "../../../hooks/useGlobalInfiniteQuery";
import { ROUTES } from "../../../Routes";
import GigCard from "./GigCard";
import FormikFormGenerator from "../../../utils/FormikFormGenerator";
import FormikStateMonitor from "../../../common/FormikStateMonitor";
import FormInputField from "../../../common/components/FormInputField";
import FormCheckbox from "../../../common/components/FormFields/FormCheckbox";
import {
    JobExperienceLevel,
    Skills,
    TypeOfGigs,
} from "../../../constant/constants";
import { ProjectDurationOptions } from "../../Home/components/FilterList";
import { Slider } from "@nextui-org/react";
import FormMultiSelect from "../../../common/components/FormFields/FormMultiselect";

const GigListing = ({ type, context, showFilter }) => {
    const { filters, setFilters } = useContext(context);
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
    const { profileDetails } = useSelector((state) => state.profile);
    const [activeTab, setActiveTab] = useState("Best Matches");
    const [budget, setBudget] = useState(5000);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get("search");

    const refinedFilters = useMemo(() => {
        let matchFilter = {
            type: type === "gigs" ? "GIG" : "JOB",
        };

        if (search) {
            matchFilter.$or = [
                { jobTitle: { $regex: search, $options: "i" } },
                { jobCategory: { $regex: search, $options: "i" } },
            ];
        }

        if (filters?.jobTitle)
            matchFilter.jobTitle = { $regex: filters.jobTitle, $options: "i" };

        if (filters?.location)
            matchFilter.jobLocation = { $regex: filters.location, $options: "i" };

        if (filters?.jobCategory?.length)
            matchFilter.jobCategory = { $in: filters.jobCategory };

        if (filters?.skillsRequired?.length)
            matchFilter.skillsRequired = { $in: filters.skillsRequired };

        if (filters?.jobScope?.length)
            matchFilter.jobScope = { $in: filters.jobScope };

        if (filters?.jobExperienceLevel?.length)
            matchFilter.jobExperienceLevel = {
                $in: filters.jobExperienceLevel,
            };

        if (filters?.isContractToHire !== undefined)
            matchFilter.isContractToHire = filters.isContractToHire;

        if (filters?.jobCostEstimate)
            matchFilter.jobCostEstimate = { $lte: filters.jobCostEstimate };

        return matchFilter;
    }, [filters, type, search]);

    const { queryData, isLoading, fetchNextPage, hasNextPage, totalCount } =
        useGlobalInfiniteQuery({
            url: ROUTES.JOB.GET_ALL_JOBS,
            methodType: "POST",
            queryKey: [`all-${type}`, refinedFilters, activeTab],
            data:
                activeTab === "Saved Gigs"
                    ? {
                          ids: profileDetails?.savedGigs,
                          limit: 25,
                          filter: { ...refinedFilters },
                          email: profileDetails?.email,
                      }
                    : activeTab === "Saved Jobs"
                    ? {
                          ids: profileDetails?.savedJobs,
                          limit: 25,
                          filter: { ...refinedFilters },
                          email: profileDetails?.email,
                      }
                    : {
                          filter: {
                              ...refinedFilters,
                          },
                          limit: 25,
                          email: profileDetails?.email,
                      },
        });

    const navigate = useNavigate();

    const tabs = useMemo(() => {
        switch (profileDetails?.role?.[0]) {
            case ROLES.FREELANCER:
                if (type === "gigs") {
                    return ["Best Matches", "Most Recent", "Saved Gigs"];
                } else {
                    return ["Best Matches", "Most Recent"];
                }
            case ROLES.CLIENT:
                return ["Best Matches", "Most Recent"];
            case ROLES.HR:
                return ["Best Matches", "Most Recent"];
            case ROLES.EMPLOYEE:
                if (type === "jobs") {
                    return ["Best Matches", "Most Recent", "Saved Jobs"];
                } else {
                    return ["Best Matches", "Most Recent"];
                }
            default:
                return ["Best Matches", "Most Recent"];
        }
    }, [profileDetails?.role, type]);

    const renderComponent = useMemo(() => {
        switch (activeTab) {
            case "Saved Gigs": {
                return (
                    <div>
                        {profileDetails?.savedGigs?.length > 0 ? (
                            <InfiniteScrollComponent
                                fetchNextPage={fetchNextPage}
                                hasNextPage={hasNextPage}
                                isLoading={isLoading}
                                items={queryData}
                                props={{ type }}
                                className={"space-y-5"}
                            >
                                <GigCard />
                            </InfiniteScrollComponent>
                        ) : (
                            <NoPostFound />
                        )}
                    </div>
                );
            }
            case "Saved Jobs": {
                return (
                    <div>
                        {profileDetails?.savedJobs?.length > 0 ? (
                            <InfiniteScrollComponent
                                fetchNextPage={fetchNextPage}
                                hasNextPage={hasNextPage}
                                isLoading={isLoading}
                                items={queryData}
                                props={{ type }}
                                className={"space-y-5"}
                            >
                                <GigCard />
                            </InfiniteScrollComponent>
                        ) : (
                            <NoPostFound />
                        )}
                    </div>
                );
            }
            default: {
                return (
                    <InfiniteScrollComponent
                        fetchNextPage={fetchNextPage}
                        hasNextPage={hasNextPage}
                        isLoading={isLoading}
                        items={queryData}
                        props={{ type }}
                        className={"space-y-5"}
                    >
                        <GigCard />
                    </InfiniteScrollComponent>
                );
            }
        }
    }, [
        profileDetails,
        activeTab,
        fetchNextPage,
        hasNextPage,
        isLoading,
        queryData,
        type,
    ]);

    useEffect(() => {
        setFilters((prev) => {
            return {
                ...prev,
                jobCostEstimate: budget,
            };
        });
    }, [budget, setFilters]);

    console.log("filter", filters);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="space-y-5">
            <div className="flex flex-col gap-4">
                <div className="flex justify-between items-center">
                    <h1 className="text-lg sm:text-xl font-semibold text-center sm:text-start capitalize">
                        Popular {type}{" "}
                        <span className="text-gray-500 text-lg">
                            ({totalCount})
                        </span>
                    </h1>

                    <div className="flex items-center gap-2">
                        {((profileDetails?.role?.includes("client") &&
                            type === "gigs") ||
                            (profileDetails?.role?.includes("hr") &&
                                type === "jobs")) && (
                            <CommonButton
                                title={
                                    type === "gigs" ? "Post Gig" : "Post Job"
                                }
                                isGradient
                                isRounded
                                isWithIcon
                                Icon={AddCircle}
                                handleFunction={() =>
                                    navigate(
                                        type === "gigs"
                                            ? "/opportunity/gig"
                                            : "/opportunity/job"
                                    )
                                }
                                buttonStyle={"!w-32"}
                            />
                        )}

                        {showFilter && (
                            <button
                                type="button"
                                onClick={() => setMobileFiltersOpen(true)}
                                className=" text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                            >
                                <span className="sr-only">Filters</span>
                                <FunnelIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                />
                            </button>
                        )}
                    </div>
                </div>
                <div className="flex gap-4 sm:gap-4 min-w-max border-b-2">
                    {tabs.map((tab) => (
                        <button
                            key={tab}
                            onClick={() => setActiveTab(tab)}
                            className={`${
                                activeTab === tab
                                    ? "text-blue-600 border-b-4 border-blue-600"
                                    : "text-gray-600"
                            } p-2 sm:p-3 text-sm sm:text-base whitespace-nowrap transition-all duration-300 ease-in-out`}
                        >
                            {tab}
                            {tab === "Saved Gigs" && " (" +  (profileDetails?.savedGigs?.length ?? 0) + ")"}
                            {tab === "Saved Jobs" && " (" +  (profileDetails?.savedJobs?.length ?? 0) + ")"}
                        </button>
                    ))}
                </div>
            </div>

            {/* Mobile filter dialog */}
            <Dialog
                open={mobileFiltersOpen}
                onClose={setMobileFiltersOpen}
                className="relative z-50 lg:hidden"
            >
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                />

                <div className="fixed inset-0 z-40 flex">
                    <DialogPanel
                        transition
                        className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 sm:pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
                    >
                        <div className="flex items-center justify-between px-4">
                            <h2 className="text-lg font-medium text-gray-900">
                                Filters
                            </h2>
                            <button
                                type="button"
                                onClick={() => setMobileFiltersOpen(false)}
                                className="relative -mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                            >
                                <span className="absolute -inset-0.5" />
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        {/* Mobile Filters */}
                        <form className="mt-4 border-t border-gray-200">
                            <FormikFormGenerator
                                initialValues={{
                                    jobTitle: "",
                                    location: "",
                                    talentBadge: "",
                                    jobScope: "",
                                    skillsRequired: [],
                                    jobCostEstimate: "",
                                    jobExperienceLevel: "",
                                    jobCategory: "",
                                }}
                                formClassName={"space-y-5 p-5"}
                            >
                                <FormikStateMonitor onChange={setFilters} />

                                <FormInputField
                                    name="jobTitle"
                                    label={"Project Title"}
                                    placeholder={"Ex. Logo Design"}
                                />

                                <FormInputField
                                    name="location"
                                    label={"Preferred Job Location"}
                                    placeholder={"Enter location"}
                                />

                                <FormCheckbox
                                    name={"jobExperienceLevel"}
                                    label={"Experience Level"}
                                    options={JobExperienceLevel}
                                />

                                <Slider
                                    size="md"
                                    step={5000}
                                    color="primary"
                                    label="Budget Range"
                                    showSteps={true}
                                    maxValue={50000}
                                    minValue={0}
                                    defaultValue={5000}
                                    className="max-w-md"
                                    value={budget}
                                    onChange={setBudget}
                                />

                                <FormCheckbox
                                    name={"jobScope"}
                                    label={"Project Duration"}
                                    options={ProjectDurationOptions}
                                />

                                <div>
                                    <FormMultiSelect
                                        name="skillsRequired"
                                        label={"Skills Required"}
                                        placeholder={"Select required skills"}
                                        options={Skills}
                                        maxLabelCount={2}
                                    />
                                </div>

                                <FormCheckbox
                                    name={"jobCategory"}
                                    label={"All Categories"}
                                    options={TypeOfGigs}
                                />
                            </FormikFormGenerator>
                        </form>
                    </DialogPanel>
                </div>
            </Dialog>

            {renderComponent}
        </div>
    );
};

export default GigListing;
