import React from "react";
import { CloseCircle } from "iconsax-react";

const ErrorMessage = ({ message }) => {
	return (
		<p className="text-red-600 text-sm flex items-center gap-1">
			<CloseCircle className="size-4" />
			{message}
		</p>
	);
};

export default ErrorMessage;
