import { ListBulletIcon, NumberedListIcon } from "@heroicons/react/20/solid";
import { AddCircle, TextBold, TextItalic } from "iconsax-react";
import React, { useState } from "react";
import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router-dom";

const ProposalForm = () => {
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		bid: "",
		projectDuration: "",
		coverLetter: "",
	});

	const skills = [
		"UX/UI Design",
		"Web Application",
		"Graphic Design",
		"After Recovery",
		"Photo Editing",
	];

	const handleSubmit = (e) => {
		e.preventDefault();
		// Handle form submission
	};

	return (
		<div className="min-h-screen mb-10">
			<div>
				{/* Header */}
				<div className="text-white gradient-blue mb-0 p-4 sm:p-10 pb-24 sm:pb-32 pt-12 sm:pt-20 text-start sm:pl-40">
					<ArrowLeft
						className="bg-white text-blue-500 p-2 size-8 rounded-full cursor-pointer hidden sm:block absolute top-36 left-28"
						onClick={() => navigate(-1)}
					/>
					<h1 className="text-xl sm:text-2xl font-semibold mb-2">
						Submit a proposal
					</h1>
					<p className="opacity-90 text-sm sm:text-base">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor.
					</p>
				</div>

				{/* Main Form Card */}
				<div className="rounded-lg -mt-20 max-w-7xl mx-auto px-4 sm:px-6">
					<form onSubmit={handleSubmit} className="space-y-6 sm:space-y-10">
						{/* Proposal Requirements */}
						<div className="border bg-white p-4 rounded-xl text-start">
							<h2 className="text-lg sm:text-xl font-semibold mb-4">
								Proposal requirements
							</h2>
							<p className="text-gray-600 mb-2 text-sm sm:text-base">
								This proposal requires <b>10 Credits.</b>
							</p>
							<p className="text-gray-600 text-sm sm:text-base">
								When you will submit this proposal, you'll have{" "}
								<b>490 Credits </b>
								remain.
							</p>
						</div>

						{/* Job Description */}
						<div className="border bg-white rounded-xl text-start">
							<div className="border-b-2 p-4">
								<h2 className="text-lg sm:text-xl font-semibold mb-4">
									Job Description
								</h2>
								<h3 className="font-medium mb-2 text-sm sm:text-base">
									I am looking for a Animator/designer
								</h3>
								<p className="text-gray-600 mb-4 text-sm sm:text-base">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
								</p>
							</div>
							{/* Skills and Expertise */}
							<div className="p-4">
								<h2 className="text-lg sm:text-xl font-semibold mb-4">
									Skill and Expertise
								</h2>
								<div className="flex flex-wrap gap-2">
									{skills.map((skill, index) => (
										<span
											key={index}
											className="px-3 sm:px-4 py-1.5 sm:py-2 bg-gray-100 rounded-full text-gray-700 text-xs sm:text-sm"
										>
											{skill}
										</span>
									))}
								</div>
							</div>
						</div>

						{/* Terms */}
						<div className="border bg-white rounded-xl text-start p-4">
							<h2 className="text-lg sm:text-xl font-semibold mb-4">Terms</h2>
							<div className="mb-6">
								<label className="block text-gray-700 mb-2 text-sm sm:text-base">
									What is the full amount you want to bid for this job?
								</label>
								<input
									type="number"
									className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm sm:text-base"
									value={formData.bid}
									onChange={(e) =>
										setFormData({ ...formData, bid: e.target.value })
									}
									placeholder="Enter bid amount"
								/>
							</div>
						</div>

						{/* Project Duration */}
						<div className="border bg-white rounded-xl text-start p-4">
							<h2 className="text-lg font-semibold mb-4">
								How long will this project take you?
							</h2>
							<input
								type="text"
								className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm sm:text-base"
								value={formData.projectDuration}
								onChange={(e) =>
									setFormData({ ...formData, projectDuration: e.target.value })
								}
								placeholder="Estimated duration"
							/>
						</div>

						{/* Cover Letter */}
						<div className="border bg-white rounded-xl text-start p-4">
							<h2 className="text-lg font-semibold mb-4">Cover letter</h2>
							<div className="flex flex-col sm:flex-row justify-between bg-gray-100 rounded-xl p-2 sm:p-3 items-start sm:items-center border gap-2 sm:gap-0">
								<h1 className="text-sm sm:text-base">
									Use the help of AI to help you with the write up.
								</h1>
								<button className="flex items-center gap-2 px-3 py-2 rounded-lg border bg-white text-xs sm:text-base whitespace-nowrap">
									<AddCircle size={16} />
									Get Tips
								</button>
							</div>
							<div className="my-2 mt-5">
								<div className="flex gap-2 mb-2 border-b p-2">
									<button
										type="button"
										className="p-1 hover:bg-gray-100 rounded"
									>
										<TextBold className="w-4 h-4" />
									</button>
									<button
										type="button"
										className="p-1 hover:bg-gray-100 rounded"
									>
										<TextItalic className="w-4 h-4" />
									</button>
									<button
										type="button"
										className="p-1 hover:bg-gray-100 rounded"
									>
										<ListBulletIcon className="w-4 h-4" />
									</button>
									<button
										type="button"
										className="p-1 hover:bg-gray-100 rounded"
									>
										<NumberedListIcon className="w-4 h-4" />
									</button>
								</div>
								<textarea
									className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[200px] bg-gray-100 text-sm sm:text-base"
									value={formData.coverLetter}
									onChange={(e) =>
										setFormData({ ...formData, coverLetter: e.target.value })
									}
									placeholder="Use the help of AI to help you with the write up..."
								/>
							</div>
						</div>

						{/* Attachment */}
						<div className="border bg-white rounded-xl text-start p-4">
							<h2 className="text-lg font-semibold mb-4">Attachment</h2>
							<div className="border-2 border-dashed rounded-lg p-6 sm:p-8 text-center">
								<div className="flex flex-col items-center">
									<p className="text-gray-600 text-sm sm:text-base">
										Drag or <span className="text-blue-500">upload</span>{" "}
										project files
									</p>
								</div>
							</div>
						</div>

						{/* Form Actions */}
						<div className="flex flex-row gap-3 sm:gap-4 justify-end">
							<button
								type="submit"
								className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors text-sm sm:text-base"
							>
								Submit Proposal
							</button>
							<button
								type="button"
								className="px-6 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors text-sm sm:text-base"
							>
								Cancel
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ProposalForm;
