import React, { useState } from "react";
import { Document, Page } from "react-pdf";

const PDFViewer = ({ resumeUrl, className, scale, pagesToShow }) => {
    const [numPages, setNumPages] = useState(pagesToShow);
    return (
        <div>
            <Document
                file={resumeUrl}
                onLoadSuccess={({ numPages }) => setNumPages(pagesToShow ?? numPages)}
                className={className}
            >
                {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page) => (
                        <Page
                            pageNumber={page}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            scale={scale}
                        />
                    ))}
            </Document>
        </div>
    );
};

export default PDFViewer;
