import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PageWiseTimeSpent = () => {
    const ref = useRef();
    const options = {
        chart: {
            type: "pie",
        },
        title: {
            text: "Page wise time spent",
            align: "left",
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            },
        },

        legend: {
            layout: "vertical", // Keeps the legend items in a vertical list
            align: "right",
            verticalAlign: "middle",
            x: 0,
            y: 0,
            itemMarginTop: 5, // Adjust spacing between items
        },

        // Adding the vertical line using annotations
        annotations: [
            {
                labels: [],
                shapes: [
                    {
                        type: "path",
                        // Define a vertical line path
                        stroke: "black",
                        strokeWidth: 2,
                        d: [
                            ["M", 200, 50], // M = moveTo(x, y)
                            ["L", 200, 300], // L = lineTo(x, y)
                        ],
                    },
                ],
            },
        ],

        series: [
            {
                type: "pie",
                allowPointSelect: true,
                data: [
                    ["Apple", 27.16, true, true],
                    ["Samsung", 23.72, false],
                    ["Xiaomi", 11.92, false],
                    ["Unknown", 6.86, false],
                    ["Oppo", 6.12, false],
                    ["Vivo", 5.48, false],
                    ["Realme", 3.89, false],
                    ["Huawei", 3.49, false],
                ],
                showInLegend: true,
            },
        ],
    };

    return (
        <div className="w-full min-w-[30vw] sm:max-w-[30vw] h-[300px]  bg-white dark:bg-dark-normal dark:shadow-dark-light-gray dark:shadow-sm dark:text-white p-3 rounded-2xl drop-shadow-sm flex flex-col max-h-[445px] shadow-sm hover:shadow-md border dark:border-0">
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={ref}
            />
        </div>
    );
};

export default PageWiseTimeSpent;
