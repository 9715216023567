import { Formik, useFormikContext } from "formik";
import { AddCircle, User } from "iconsax-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import CommonButton from "../../../common/components/CommonButton";
import FormSelectMenu from "../../../common/components/FormFields/FormSelectMenu";
import FormTextarea from "../../../common/components/FormFields/FormTextarea";
import FormInputField from "../../../common/components/FormInputField";
import {
    getListOfExpectedGraduationYears,
    getListOfYears,
} from "../../../common/ReusableFunctions";
import { Degrees } from "../../../constant/constants";
import { ResumeBuilderContext } from "./ResumeBuilder";
import { PiGraduationCap } from "react-icons/pi";

const ResumeEducationInfo = () => {
    const { setFormData, formData } = useContext(ResumeBuilderContext);
    const [educationCount, setEducationCount] = useState(
        formData?.education?.length ?? 0
    );

    const addEducation = () => {
        setEducationCount((prevCount) => prevCount + 1);
        setFormData((prevData) => ({
            ...prevData,
            education: Array.isArray(prevData?.education)
                ? [...prevData.education, {}]
                : [{}],
        }));
    };

    return (
        <div>
            <div className="flex items-center gap-3">
                <div className="bg-[#5B8EFB33] text-blue-500 p-2 rounded-lg">
                    <PiGraduationCap className="size-6" />
                </div>
                <h1 className="text-xl font-semibold">
                    Educational Information
                </h1>
            </div>

            {Array.from({ length: educationCount }, (_, index) => (
                <IndividualEducation
                    key={index}
                    setEducationCount={setEducationCount}
                    index={index}
                />
            ))}

            <CommonButton
                className="w-full"
                handleFunction={addEducation}
                title="Add Education"
                isGradient={true}
                isRounded={true}
                Icon={AddCircle}
                isWithIcon={true}
                buttonStyle={"mt-5 max-w-40 mx-auto"}
                type={"button"}
            />
        </div>
    );
};

const IndividualEducation = ({ setEducationCount, index }) => {
    const { formData, setFormData } = useContext(ResumeBuilderContext);

    const educationData = useMemo(() => {
        return formData?.education?.[index];
    }, [formData, index]);

    const listOfYears = getListOfYears();
    const listOfExpectedGraduationYears = useMemo(() => {
        return educationData?.from
            ? getListOfExpectedGraduationYears(educationData?.from)
            : [];
    }, [educationData?.from]);

    const removeEducation = (index) => {
        if (formData?.education?.length > 0) {
            setEducationCount((prevCount) => prevCount - 1);
            setFormData((prevData) => {
                const updatedEducation = prevData?.education?.filter(
                    (_, i) => i !== index
                );
                return { ...prevData, education: updatedEducation };
            });
        }
    };

    const handleChangeFunction = (values) => {
        setFormData((prevData) => ({
            ...prevData,
            education: [
                ...prevData?.education?.slice(0, index),
                {
                    instituteName: values?.instituteName,
                    from: values?.from,
                    to: values?.to,
                    degree: values?.degree,
                    areaOfStudy: values?.areaOfStudy,
                    description: values?.description,
                },
                ...prevData?.education?.slice(index + 1),
            ],
        }));
    };

    return (
        <Formik
            initialValues={{
                instituteName: educationData?.instituteName ?? "",
                from: educationData?.from ?? "",
                to: educationData?.to ?? "",
                degree: educationData?.degree ?? "",
                areaOfStudy: educationData?.areaOfStudy ?? "",
                description: educationData?.description ?? "",
            }}
        >
            {({ values }) => (
                <div className="space-y-5 mt-5">
                    <ChangeHandler
                        handler={() => handleChangeFunction(values)}
                    />

                    <FormInputField
                        name="instituteName"
                        placeholder="Enter your institute name"
                        label="Institute"
                        labelStyle="block text-sm/6 font-medium text-gray-900"
                    />
                    <div>
                        <h1 className="block text-sm/6 font-medium text-gray-900">
                            Dates Attended (Optional)
                        </h1>
                        <div className="flex justify-between w-full gap-5">
                            <div className="w-full">
                                <FormSelectMenu
                                    placeholder="From"
                                    name={"from"}
                                    options={listOfYears}
                                />
                            </div>
                            <div className="w-full">
                                <FormSelectMenu
                                    placeholder="To"
                                    name={"to"}
                                    options={listOfExpectedGraduationYears}
                                    disabled={!educationData?.from}
                                />
                            </div>
                        </div>
                    </div>
                    <FormSelectMenu
                        label="Degree (Optional)"
                        placeholder="Select Degree"
                        name={"degree"}
                        options={Degrees}
                    />

                    <FormInputField
                        label="Area Of Study (Optional)"
                        placeholder="Select Area Of Study"
                        name={"areaOfStudy"}
                    />

                    <FormTextarea
                        name="description"
                        label="Description (Optional)"
                        placeholder="Add Description"
                        className={"!w-80 overflow-hidden !truncate"}
                    />

                    <div className="flex items-center gap-2 max-w-20 justify-end ml-auto">
                        {/* <CommonButton
                            buttonStyle="!bg-red-100 text-red-500 rounded-full"
                            title={"Save"}
                            isRounded={true}
                            type={"button"}
                            handleFunction={() => handleChangeFunction(value)}
                        /> */}
                        <CommonButton
                            buttonStyle="!bg-red-100 text-red-500 rounded-full"
                            title={"Delete"}
                            isRounded={true}
                            type={"button"}
                            handleFunction={() => removeEducation(index)}
                        />
                    </div>
                </div>
            )}
        </Formik>
    );
};

const ChangeHandler = ({ handler }) => {
    const { values } = useFormikContext();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handler();
        }, 100);
        return () => clearTimeout(timeoutId);
    }, [values, handler]);

    return null;
};

export default ResumeEducationInfo;
