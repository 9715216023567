import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import highchartsMore from "highcharts/highcharts-more";

// Initialize the SolidGauge module
highchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

const ActiveUser = () => {
    const chartSpeedRef = useRef(null);

    const gaugeOptions = {
        chart: {
            type: "solidgauge",
            height: "80%",
        },
        title: {
            text: null, // Correctly removes the chart title
        },
        tooltip: {
            borderWidth: 0,
            backgroundColor: "none",
            shadow: false,
            style: {
                fontSize: "14px",
            },
            valueSuffix: "%",
            pointFormat:
                "{series.name}<br>" +
                '<span style="font-size: 2em; color: {point.color}; ' +
                'font-weight: bold">{point.y}</span>',
            positioner: function (labelWidth) {
                return {
                    x: (this.chart.chartWidth - labelWidth) / 2,
                    y: this.chart.plotHeight / 2 + 15,
                };
            },
        },

        pane: {
            center: ["50%", "100%"],
            size: "140%",
            startAngle: -90, // Start at 12 o'clock
            endAngle: 90, // End at 6 o'clock
            background: {
                outerRadius: "90%",
                innerRadius: "70%",
                backgroundColor: Highcharts.getOptions().colors[0] + "33", // Slight opacity
                borderWidth: 0,
                shape: "arc",
            },
        },

        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: [], // No ticks for a cleaner look
            labels: {
                distance: -20, // Adjust label distance for better positioning
                style: {
                    fontSize: "12px",
                },
            },
        },

        plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: true,
                    format:
                        '<div style="text-align:center">' +
                        '<span style="font-size:20px">{y}%</span><br/>' +
                        '<span style="font-size:12px;opacity:0.7">Target</span>' +
                        "</div>",
                    y: 30, // Adjust vertical position for half-circle alignment
                },
                linecap: "round",
                stickyTracking: false,
                rounded: true,
            },
        },

        series: [
            {
                name: "KPI",
                data: [
                    {
                        color: Highcharts.getOptions().colors[0],
                        radius: "90%",
                        innerRadius: "70%",
                        y: 75, // Set the data value here
                    },
                ],
            },
        ],
        credits: {
            enabled: false, // Disables the Highcharts watermark
        },
    };

    return (
        <div className="w-full min-w-[20vw] sm:max-w-[20vw]  bg-white dark:bg-dark-normal dark:shadow-dark-light-gray dark:shadow-sm dark:text-white p-3 rounded-2xl drop-shadow-sm flex flex-col max-h-[445px] shadow-sm hover:shadow-md border dark:border-0">
            <HighchartsReact
                highcharts={Highcharts}
                options={gaugeOptions}
                ref={chartSpeedRef}
            />
        </div>
    );
};

export default ActiveUser;
