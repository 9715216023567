import React from "react";
import { Spinner } from "@nextui-org/react";

const CommonButton = ({
    type,
    title,
    handleFunction,
    disabled,
    loading,
    isGradient,
    buttonStyle,
    isWithIcon,
    Icon,
    isRounded,
}) => {
    return (
        <button
            type={type || "button"}
            className={`w-full flex items-center gap-2 justify-center px-2 py-2 sm:px-4 sm:py-2.5 border border-gray-300 ${
                isRounded ? "rounded-full" : "rounded-lg"
            } file:shadow-sm text-xs sm:text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 ${
                isGradient && "gradient-blue text-white"
            } ${buttonStyle}`}
            onClick={handleFunction}
            disabled={disabled}
        >
            {loading ? (
                <>
                    <Spinner
                        size="sm"
                        className="!text-white"
                        color="default"
                    />{" "}
                    {title}
                </>
            ) : (
                <>
                    {isWithIcon && <Icon className="size-4" />}
                    {title}
                </>
            )}
        </button>
    );
};

export default CommonButton;
