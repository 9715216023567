import { Formik, useFormikContext } from "formik";
import { AddCircle, MedalStar } from "iconsax-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import CommonButton from "../../../common/components/CommonButton";
import FormSelectMenu from "../../../common/components/FormFields/FormSelectMenu";
import FormTextarea from "../../../common/components/FormFields/FormTextarea";
import FormInputField from "../../../common/components/FormInputField";
import {
    getListOfExpectedGraduationYears,
    getListOfYears,
} from "../../../common/ReusableFunctions";
import { Degrees } from "../../../constant/constants";
import { ResumeBuilderContext } from "./ResumeBuilder";

const ResumeEducationInfo = () => {
    const { setFormData, formData } = useContext(ResumeBuilderContext);
    const [educationCount, setEducationCount] = useState(
        formData?.projects?.length ?? 0
    );

    const addEducation = () => {
        setEducationCount((prevCount) => prevCount + 1);
        setFormData((prevData) => ({
            ...prevData,
            projects: Array.isArray(prevData?.projects)
                ? [...prevData.projects, {}]
                : [{}],
        }));
    };

    return (
        <div>
            <div className="flex items-center gap-3">
                <div className="bg-[#5B8EFB33] text-blue-500 p-2 rounded-lg">
                    <MedalStar className="size-6" />
                </div>
                <h1 className="text-xl font-semibold">Project Information</h1>
            </div>

            {Array.from({ length: educationCount }, (_, index) => (
                <IndividualEducation
                    key={index}
                    setEducationCount={setEducationCount}
                    index={index}
                />
            ))}

            <CommonButton
                className="w-full"
                handleFunction={addEducation}
                title="Add Project"
                isGradient={true}
                isRounded={true}
                Icon={AddCircle}
                isWithIcon={true}
                buttonStyle={"mt-5 max-w-40 mx-auto"}
            />
        </div>
    );
};

const IndividualEducation = ({ setEducationCount, index }) => {
    const { formData, setFormData } = useContext(ResumeBuilderContext);

    const projectData = useMemo(() => {
        return formData?.projects?.[index];
    }, [formData, index]);

    const removeEducation = (index) => {
        if (formData?.projects?.length > 0) {
            setEducationCount((prevCount) => prevCount - 1);
            setFormData((prevData) => {
                const updatedEducation = prevData?.projects?.filter(
                    (_, i) => i !== index
                );
                return { ...prevData, projects: updatedEducation };
            });
        }
    };

    const handleChangeFunction = (values) => {
        setFormData((prevData) => ({
            ...prevData,
            projects: [
                ...prevData?.projects?.slice(0, index),
                {
                    title: values.title,
                    description: values.description,
                },
                ...prevData?.projects?.slice(index + 1),
            ],
        }));
    };

    return (
        <Formik
            initialValues={{
                title: projectData?.title ?? "",
                description: projectData?.description ?? "",
            }}
        >
            {({ values }) => (
                <div className="space-y-5 mt-5">
                    <ChangeHandler
                        handler={() => handleChangeFunction(values)}
                    />

                    <FormInputField
                        name="title"
                        placeholder="Enter your project title"
                        label="Title"
                        labelStyle="block text-sm/6 font-medium text-gray-900"
                    />

                    <FormTextarea
                        name="description"
                        label="Description"
                        placeholder="Add Project Description"
                        className={"!w-80 overflow-hidden !truncate"}
                    />

                    <div className="flex items-center gap-2 max-w-20 justify-end ml-auto">
                        <CommonButton
                            buttonStyle="!bg-red-100 text-red-500 rounded-full"
                            title={"Delete"}
                            isRounded={true}
                            type={"button"}
                            handleFunction={() => removeEducation(index)}
                        />
                    </div>
                </div>
            )}
        </Formik>
    );
};

const ChangeHandler = ({ handler }) => {
    const { values } = useFormikContext();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handler();
        }, 100);
        return () => clearTimeout(timeoutId);
    }, [values, handler]);

    return null;
};

export default ResumeEducationInfo;
