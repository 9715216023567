import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../constant/constants";
import useGetAccessToken from "../hooks/Authentication/useGetAccessToken";
import Loader from "../common/Loader/Loader";
import AdminLogin from "../pages/Authentication/AdminLogin";
import useCookie from "../hooks/useCookie";

export const GetRole = () => {
    const { profileDetails } = useSelector((state) => state.profile);
    return profileDetails?.role[0];
};

export const CheckRole = ({ roles, role }) => {
    if (!Array.isArray(roles) || !role) {
        return false;
    }

    return roles.some((r) => r.toLowerCase() === role.toLowerCase());
};

export const OpenRoute = ({ children }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const navigate = useNavigate();
    const { cookie } = useGetAccessToken();

    useEffect(() => {
        if (cookie?.accessToken) {
            if (!Object.keys(profileDetails)?.length > 0) {
                navigate("/unauthorized");
            }
        } else {
            navigate("/login");
        }
    }, [profileDetails, cookie.accessToken, navigate]);

    return (
        <>
            {cookie.accessToken &&
                Object.keys(profileDetails)?.length > 0 &&
                children}
        </>
    );
};

export const FreelancerRoute = ({ children }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const navigate = useNavigate();
    const { cookie } = useGetAccessToken();

    console.log("roles", profileDetails?.role);
    console.log("freelancer role", ROLES.FREELANCER);

    useEffect(() => {
        if (cookie?.accessToken) {
            if (
                !CheckRole({
                    role: ROLES.FREELANCER,
                    roles: profileDetails?.role,
                })
            ) {
                navigate("/unauthorized");
            }
        } else {
            navigate("/login");
        }
    }, [profileDetails, cookie.accessToken, navigate]);

    return (
        <>
            {cookie.accessToken &&
                Object.keys(profileDetails)?.length > 0 &&
                children}
        </>
    );
};

export const EmployeeRoute = ({ children }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const navigate = useNavigate();
    const { cookie } = useGetAccessToken();

    useEffect(() => {
        if (cookie?.accessToken) {
            if (
                !CheckRole({
                    role: ROLES.EMPLOYEE,
                    roles: profileDetails?.role,
                })
            ) {
                navigate("/unauthorized");
            }
        } else {
            navigate("/login");
        }
    }, [profileDetails, cookie.accessToken, navigate]);

    return (
        <>
            {cookie.accessToken &&
                Object.keys(profileDetails)?.length > 0 &&
                children}
        </>
    );
};

export const HRRoute = ({ children }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const navigate = useNavigate();
    const { cookie } = useGetAccessToken();

    useEffect(() => {
        if (cookie?.accessToken) {
            if (!CheckRole({ role: ROLES.HR, roles: profileDetails?.role })) {
                navigate("/unauthorized");
            }
        } else {
            navigate("/login");
        }
    }, [profileDetails, cookie.accessToken, navigate]);

    return (
        <>
            {cookie.accessToken &&
                Object.keys(profileDetails)?.length > 0 &&
                children}
        </>
    );
};

export const ClientRoute = ({ children }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const navigate = useNavigate();
    const { cookie } = useGetAccessToken();

    const isProfileValid = cookie?.accessToken && profileDetails?.role;

    useEffect(() => {
        if (cookie?.accessToken) {
            if (
                !CheckRole({
                    role: ROLES.CLIENT,
                    roles: profileDetails?.role,
                })
            ) {
                navigate("/unauthorized");
            }
        } else {
            navigate("/login");
        }
    }, [profileDetails, cookie?.accessToken, navigate]);

    if (!cookie?.accessToken || !isProfileValid) {
        return <Loader />;
    }

    return (
        <>
            {cookie.accessToken &&
                Object.keys(profileDetails)?.length > 0 &&
                children}
        </>
    );
};

export const AdminRoute = ({ children }) => {
    const { cookie } = useCookie({ cookieName: "isAdminLoggedIn" });
    const isAdminLoggedIn = Boolean(cookie?.isAdminLoggedIn);
    return <>{isAdminLoggedIn ? children : <AdminLogin />}</>;
};
