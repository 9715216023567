import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { XMarkIcon, FunnelIcon } from "@heroicons/react/20/solid";
import React, { useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import InfiniteScrollComponent from "../../../common/components/InfiniteScrollComponent";
import NoPostFound from "../../../common/components/NoPostFound";
import FormikStateMonitor from "../../../common/FormikStateMonitor";
import Loader from "../../../common/Loader/Loader";
import {
    Degrees,
    ExperienceLevel,
    Master,
    ROLES,
} from "../../../constant/constants";
import useGlobalInfiniteQuery from "../../../hooks/useGlobalInfiniteQuery";
import useMaster from "../../../hooks/useMaster";
import { ROUTES } from "../../../Routes";
import FormFieldsMapper from "../../../utils/FormFieldsMapper";
import FormikFormGenerator from "../../../utils/FormikFormGenerator";
import { JoiningAvailability } from "../../Home/components/UsersFilterList";
import PostTabs from "../../Post/components/PostTabs";
import FreelancerCard from "./FreelancerCard";

const FreelancerListing = ({ context, showFilter }) => {
    const { filters, setFilters } = useContext(context);
    const { profileDetails } = useSelector((state) => state.profile);
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

    const [activeTab, setActiveTab] = React.useState("Best Matches");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get("search");

    const refinedFilters = useMemo(() => {
        let matchFilter = {};

        matchFilter.profileCompletion = { $gte: 80 };

        if (search) {
            matchFilter.$or = [
                { firstname: { $regex: search, $options: "i" } },
                { lastname: { $regex: search, $options: "i" } },
                { title: { $regex: search, $options: "i" } },
            ];
        }

        if (filters?.location)
            matchFilter.location = { $regex: filters.location, $options: "i" };

        if (filters?.title)
            matchFilter.title = { $regex: filters.title, $options: "i" };

        if (filters?.name) {
            const nameParts = filters.name.split(" ");

            matchFilter.$or = nameParts.map((part) => ({
                $or: [
                    { firstname: { $regex: part, $options: "i" } },
                    { lastname: { $regex: part, $options: "i" } },
                ],
            }));
        }

        if (filters?.skills?.length)
            matchFilter.skills = { $in: filters.skills };

        if (filters?.experienceLevel?.length)
            matchFilter.experienceLevel = { $in: filters.experienceLevel };

        if (filters?.educationLevel?.length) {
            matchFilter.educationDetails = {
                $elemMatch: {
                    degree: { $in: filters.educationLevel },
                },
            };
        }

        if (filters?.canBeJoinIn) {
            matchFilter.canBeJoinIn = { $lte: filters?.canBeJoinIn };
        }

        return matchFilter;
    }, [filters, search]);

    const { queryData, isLoading, fetchNextPage, hasNextPage, totalCount } =
        useGlobalInfiniteQuery({
            url: ROUTES.AUTHENTICATION.ALL_USERS,
            methodType: "POST",
            data:
                activeTab === "Saved Freelancers"
                    ? {
                          ids: profileDetails?.savedUsers,
                          limit: 25,
                      }
                    : {
                          filter: { role: ["freelancer"], ...refinedFilters },
                          limit: 25,
                      },
            queryKey: ["all-users", refinedFilters, "freelancers", activeTab],
        });

    const renderComponent = useMemo(() => {
        switch (activeTab) {
            case "Saved Freelancers": {
                return (
                    <div>
                        {profileDetails?.savedUsers?.length > 0 ? (
                            <InfiniteScrollComponent
                                fetchNextPage={fetchNextPage}
                                hasNextPage={hasNextPage}
                                isLoading={isLoading}
                                items={queryData}
                                className={"space-y-5"}
                            >
                                <FreelancerCard />
                            </InfiniteScrollComponent>
                        ) : (
                            <NoPostFound />
                        )}
                    </div>
                );
            }
            default: {
                return (
                    <InfiniteScrollComponent
                        fetchNextPage={fetchNextPage}
                        hasNextPage={hasNextPage}
                        isLoading={isLoading}
                        items={queryData}
                        className={"space-y-5"}
                    >
                        <FreelancerCard />
                    </InfiniteScrollComponent>
                );
            }
        }
    }, [
        profileDetails,
        activeTab,
        fetchNextPage,
        hasNextPage,
        isLoading,
        queryData,
    ]);

    const tabs = useMemo(() => {
        switch (profileDetails?.role?.[0]) {
            case ROLES.CLIENT:
                return ["Best Matches", "Most Recent", "Saved Freelancers"];
            default:
                return ["Best Matches", "Most Recent"];
        }
    }, [profileDetails?.role]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div
            className={`w-full space-y-3 sm:space-y-5 -mt-3 sm:mt-0 ${showFilter && "mt-3"}`}
        >
            <div className="flex flex-row items-center justify-between gap-4">
                <h1 className="text-lg sm:text-xl font-semibold text-center sm:text-start">
                    Popular Freelancers{" "}
                    <span className="text-gray-500 text-lg">
                        ({totalCount ?? 0})
                    </span>
                </h1>

                {showFilter && (
                    <button
                        type="button"
                        onClick={() => setMobileFiltersOpen(true)}
                        className=" text-gray-400 hover:text-gray-500 lg:hidden"
                    >
                        <span className="sr-only">Filters</span>
                        <FunnelIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                )}
            </div>

            <PostTabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />

            {renderComponent}

            <MobileFilters
                isOpen={mobileFiltersOpen}
                setIsOpen={setMobileFiltersOpen}
            >
                <UserFilterForm context={context} />
            </MobileFilters>
        </div>
    );
};

export const UserFilterForm = ({ context }) => {
    const { setFilters } = useContext(context);

    const { data: skillsData, isLoading: skillsDataLoading } = useMaster({
        type: Master.Skills,
        queryKeys: ["master", Master.Skills],
    });

    const fields = [
        {
            fieldType: "input",
            name: "name",
            label: "Username",
            placeholder: "Enter name of the user you want to find",
        },
        {
            fieldType: "input",
            name: "title",
            label: "Role",
            placeholder: "Ex. Backend Developer",
        },
        {
            fieldType: "input",
            name: "location",
            label: "Preferred Job Location",
            placeholder: "Enter preferred job location",
        },
        {
            fieldType: "checkbox",
            name: "experienceLevel",
            label: "Experience Level",
            options: ExperienceLevel,
        },
        {
            fieldType: "multiselect",
            name: "skills",
            label: "Skills Required",
            placeholder: "Select Required Skills",
            options: skillsData,
            isLoading: skillsDataLoading,
        },
        {
            fieldType: "radio",
            name: "canBeJoinIn",
            label: "Available To Start",
            options: JoiningAvailability,
        },
        {
            fieldType: "checkbox",
            name: "educationLevel",
            label: "Education Level",
            options: Degrees,
        },
    ];

    return (
        <form className="mt-4 border-t border-gray-200">
            <FormikFormGenerator
                initialValues={{
                    name: "",
                    title: "",
                    location: "",
                    experienceLevel: "",
                    skills: [],
                    canBeJoinIn: "",
                    educationLevel: "",
                }}
                formClassName={"space-y-5 p-5"}
            >
                <FormikStateMonitor onChange={setFilters} />
                <FormFieldsMapper fields={fields} />
            </FormikFormGenerator>
        </form>
    );
};

export const MobileFilters = ({ isOpen, setIsOpen, children }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={setIsOpen}
            className="relative z-50 lg:hidden"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
            />

            <div className="fixed inset-0 z-40 flex">
                <DialogPanel
                    transition
                    className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 sm:pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
                >
                    <div className="flex items-center justify-between px-4">
                        <h2 className="text-lg font-medium text-gray-900">
                            Filters
                        </h2>
                        <button
                            type="button"
                            onClick={() => setIsOpen(false)}
                            className="relative -mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                        >
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    {children}
                </DialogPanel>
            </div>
        </Dialog>
    );
};

export default FreelancerListing;
