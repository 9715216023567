import React from "react";
import HeroImage from "../../../assets/Listing/HeroBg.png";

const Hero = ({ title }) => {
    return (
        <div className="relative center-vertical h-[30vh] sm:h-[50vh]">
            <h1 className="sm:text-5xl max-w-xs sm:max-w-full mx-auto text-center font-semibold z-10 text-white">
                {title ?? "Find the Jobs /Gigs from all around the world."}
            </h1>
            <img
                src={HeroImage}
                alt="Hero"
                className="w-screen h-[30vh] sm:h-[50vh] object-cover absolute top-0 -z-10"
            />
        </div>
    );
};

export default Hero;
