"use client";

import React from "react";
import {
    Chip,
    Button,
    Card,
    cn,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Tab,
    Tabs,
    Spacer,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
} from "recharts";

const data = [
    {
        key: "unique-visitors",
        title: "Unique Visitors",
        suffix: "visitors",
        value: 147000,
        type: "number",
        change: "12.8%",
        color: "success",
        chartData: [
            { month: "Jan", value: 98000 },
            { month: "Feb", value: 125000 },
            { month: "Mar", value: 89000 },
            { month: "Apr", value: 156000 },
            { month: "May", value: 112000 },
            { month: "Jun", value: 167000 },
            { month: "Jul", value: 138000 },
            { month: "Aug", value: 178000 },
            { month: "Sep", value: 129000 },
            { month: "Oct", value: 159000 },
            { month: "Nov", value: 147000 },
            { month: "Dec", value: 127000 },
        ],
    },
];

const formatValue = (value, type) => {
    if (type === "number") {
        if (value >= 1000000) {
            return (value / 1000000).toFixed(1) + "M";
        } else if (value >= 1000) {
            return (value / 1000).toFixed(0) + "k";
        }

        return value.toLocaleString();
    }
    if (type === "percentage") return `${value}%`;

    return value;
};

const formatMonth = (month) => {
    const monthNumber =
        {
            Jan: 0,
            Feb: 1,
            Mar: 2,
            Apr: 3,
            May: 4,
            Jun: 5,
            Jul: 6,
            Aug: 7,
            Sep: 8,
            Oct: 9,
            Nov: 10,
            Dec: 11,
        }[month] ?? 0;

    return new Intl.DateTimeFormat("en-US", { month: "long" }).format(
        new Date(2024, monthNumber, 1)
    );
};


export default function SubscriptionGrowth({ activeChartData = data?.[0] }) {
    const { chartData, color, suffix, type, title } = activeChartData;

    return (
        <Card
            as="dl"
            className="h-[250px] border border-transparent dark:border-default-100"
        >
            <section className="flex flex-col flex-nowrap">
                <div className="flex flex-col justify-between gap-y-2 p-4">
                    <dt className="text-small font-medium text-default-500">
                        {title}
                    </dt>
                </div>
                <ResponsiveContainer
                    className="min-h-[300px] [&_.recharts-surface]:outline-none"
                    height="100%"
                    width="100%"
                >
                    <AreaChart
                        accessibilityLayer
                        data={chartData}
                        height={300}
                        margin={{
                            left: 0,
                            right: 0,
                        }}
                        width={500}
                    >
                        <defs>
                            <linearGradient
                                id="colorGradient"
                                x1="0"
                                x2="0"
                                y1="0"
                                y2="1"
                            >
                                <stop
                                    offset="10%"
                                    stopColor={`hsl(var(--nextui-${color}-500))`}
                                    stopOpacity={0.3}
                                />

                                <stop
                                    offset="100%"
                                    stopColor={`hsl(var(--nextui-${color}-100))`}
                                    stopOpacity={0.1}
                                />
                            </linearGradient>
                        </defs>
                        <CartesianGrid
                            horizontalCoordinatesGenerator={() => [
                                0, 50, 500, 1000
                            ]}
                            stroke="hsl(var(--nextui-default-200))"
                            strokeDasharray="3 3"
                            vertical={false}
                        />

                        <XAxis
                            axisLine={false}
                            dataKey="month"
                            style={{
                                fontSize: "var(--nextui-font-size-tiny)",
                                transform: "translateX(-40px)",
                            }}
                            tickLine={false}
                        />

                        <Tooltip
                            content={({ label, payload }) => (
                                <div className="flex h-auto min-w-[120px] items-center gap-x-2 rounded-medium bg-foreground p-2 text-tiny shadow-small">
                                    <div className="flex w-full flex-col gap-y-0">
                                        {payload?.map((p, index) => {
                                            const name = p.name;
                                            const value = p.value;

                                            return (
                                                <div
                                                    key={`${index}-${name}`}
                                                    className="flex w-full items-center gap-x-2"
                                                >
                                                    <div className="flex w-full items-center gap-x-1 text-small text-background">
                                                        <span>
                                                            {formatValue(
                                                                value,
                                                                type
                                                            )}
                                                        </span>
                                                        <span>{suffix}</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <span className="text-small font-medium text-foreground-400">
                                            {formatMonth(label)}
                                        </span>
                                    </div>
                                </div>
                            )}
                            cursor={{
                                strokeWidth: 0,
                            }}
                        />

                        <Area
                            activeDot={{
                                stroke: `hsl(var(--nextui-${color}))`,
                                strokeWidth: 2,
                                fill: "hsl(var(--nextui-background))",
                                r: 5,
                            }}
                            animationDuration={1000}
                            animationEasing="ease"
                            dataKey="value"
                            fill="url(#colorGradient)"
                            stroke={`hsl(var(--nextui-${color}))`}
                            strokeWidth={2}
                            type="monotone"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </section>
        </Card>
    );
}
