import React, { createContext, useState } from "react";
import SearchBar from "../Landing/components/SearchBar";
import Filters from "./components/Filters";
import GigListing from "./components/GigListing";
import Hero from "./components/Hero";

export const GigListingPageContext = createContext({
    filters: {},
    setFilters: () => {},
});

const Page = ({ type }) => {
    const [filters, setFilters] = useState();

    return (
        <GigListingPageContext.Provider
            value={{
                filters,
                setFilters,
            }}
        >
            <Hero />
            <div className="bg-white drop-shadow-lg w-full py-4 sticky top-16 z-20">
                <div className="sm:max-w-6xl sm:mx-auto center-vertical px-5 sm:px-0">
                    <SearchBar containerStyle={"!sm:max-w-full"} />
                </div>
            </div>
            <div className="mt-2">
                <Filters
                    filterContainerClassname={"!sticky !top-40 sm:h-[75vh] overflow-auto no-scrollbar"}
                    context={GigListingPageContext}
                >
                    <GigListing context={GigListingPageContext} type={type} />
                </Filters>
            </div>
        </GigListingPageContext.Provider>
    );
};

export default Page;
