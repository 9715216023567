import React from "react";
import FormInputField from "../common/components/FormInputField";
import FormSelectMenu from "../common/components/FormFields/FormSelectMenu";
import FormTextarea from "../common/components/FormFields/FormTextarea";
import FormCheckbox from "../common/components/FormFields/FormCheckbox";
import FormRadioGroup from "../common/components/FormFields/FormRadioGroup";
import MUIFormDatePickerField from "../common/components/FormFields/MuiFormDatePicker";
import FormMultiSelect from "../common/components/FormFields/FormMultiselect";
import FormInputDescription from "../common/components/FormFields/FormInputDescription";

const FormFieldsMapper = ({ fields }) => {
    const renderComponents = ({
        name,
        fieldType,
        type,
        label,
        placeholder,
        options,
        disabled,
        required,
        hidden,
        className,
        main_type,
        inputClassName,
        labelStyle,
        maxLabelCount,
        defaultChecked,
        maxDate,
        minDate,
        isSearchOptionRemoved,
        subLabel,
        subLabelAtEnd,
        isLoading
    }) => {
        switch (fieldType) {
            case "input":
                return (
                    <FormInputField
                        key={name}
                        name={name}
                        label={label}
                        className={className}
                        disabled={disabled}
                        hidden={hidden}
                        inputClassName={inputClassName}
                        labelStyle={labelStyle}
                        main_type={main_type}
                        placeholder={placeholder}
                        required={required}
                        type={type}
                        subLabel={subLabel}
                        subLabelAtEnd={subLabelAtEnd}
                    />
                );
            case "description":
                return (
                    <FormInputDescription
                        key={name}
                        name={name}
                        label={label}
                        className={className}
                        disabled={disabled}
                        hidden={hidden}
                        inputClassName={inputClassName}
                        labelStyle={labelStyle}
                        main_type={main_type}
                        placeholder={placeholder}
                        required={required}
                        type={type}
                        subLabel={subLabel}
                        subLabelAtEnd={subLabelAtEnd}
                    />
                );
            case "select":
                return (
                    <FormSelectMenu
                        key={name}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        options={options}
                        required={required}
                        disabled={disabled}
                        isRemovedSearch={isSearchOptionRemoved}
                        hidden={hidden}
                        isLoading={isLoading}
                    />
                );
            case "multiselect":
                return (
                    <FormMultiSelect
                        key={name}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        disabled={disabled}
                        maxLabelCount={maxLabelCount}
                        options={options}
                        required={required}
                        isLoading={isLoading}
                    />
                );
            case "textarea":
                return (
                    <FormTextarea key={name} name={name} label={label} placeholder={placeholder} required={required} />
                );
            case "checkbox":
                return (
                    <FormCheckbox
                        key={name}
                        name={name}
                        label={label}
                        options={options}
                        className={className}
                        defaultChecked={defaultChecked}
                        disabled={disabled}
                        hidden={hidden}
                    />
                );
            case "radio":
                return <FormRadioGroup key={name} label={label} name={name} options={options} />;
            case "date":
                return (
                    <MUIFormDatePickerField
                        key={name}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        maxDate={maxDate}
                        minDate={minDate}
                        required={required}
                    />
                );

            default:
                return null;
        }
    };

    return fields?.map((field) => renderComponents({ ...field }));
};

export default FormFieldsMapper;
