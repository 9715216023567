import logo from "./logo";

const ROLES = {
    FREELANCER: "freelancer",
    HR: "hr",
    CLIENT: "client",
    EMPLOYEE: "employee",
};

export const RESUME_TYPE = {
    CUSTOM: "CUSTOM",
    FILE: "FILE",
};

export const STATUSES = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    OPEN: "OPEN",
    IN_PROGRESS: "IN_PROGRESS",
    DONE: "DONE",
    CLOSED: "CLOSED",
};

export const statusColorMap = {
    ACCEPTED: "bg-[#D5DFFF] text-blue-500",
    CLOSED: "bg-[#FFD5D5] text-[#E94040]",
    OPEN: "bg-[#D5DFFF] text-blue-500",
    IN_PROGRESS: "bg-[#EAFFF9] text-[#00A884] border border-[#00A884]",
};

const LANGUAGES = [
    { name: "English", value: "English" },
    { name: "Mandarin Chinese", value: "Mandarin Chinese" },
    { name: "Hindi", value: "Hindi" },
    { name: "Spanish", value: "Spanish" },
    { name: "French", value: "French" },
    { name: "Arabic", value: "Arabic" },
    { name: "Bengali", value: "Bengali" },
    { name: "Portuguese", value: "Portuguese" },
    { name: "Russian", value: "Russian" },
    { name: "Japanese", value: "Japanese" },
    { name: "Punjabi", value: "Punjabi" },
    { name: "German", value: "German" },
    { name: "Korean", value: "Korean" },
    { name: "Vietnamese", value: "Vietnamese" },
    { name: "Telugu", value: "Telugu" },
    { name: "Turkish", value: "Turkish" },
    { name: "Tamil", value: "Tamil" },
    { name: "Marathi", value: "Marathi" },
    { name: "Italian", value: "Italian" },
    { name: "Thai", value: "Thai" },
    { name: "Gujarati", value: "Gujarati" },
    { name: "Urdu", value: "Urdu" },
    { name: "Indonesian", value: "Indonesian" },
    { name: "Malay", value: "Malay" },
    { name: "Persian", value: "Persian" },
    { name: "Polish", value: "Polish" },
    { name: "Dutch", value: "Dutch" },
    { name: "Swahili", value: "Swahili" },
];

const LanguageProficiency = [
    {
        name: "Basic",
        value: "Basic",
    },
    {
        name: "Conversational",
        value: "Conversational",
    },
    {
        name: "Fluent",
        value: "Fluent",
    },
    {
        name: "Native or Bilingual",
        value: "Native or Bilingual",
    },
];

const ACTION_REQUIRED_VALUE_TYPE = [
    { name: "Percentage", value: "PERCENTAGE" },
    { name: "Coins", value: "COINS" },
];

const Degrees = [
    { name: "Bachelor of Arts", value: "Bachelor of Arts" },
    { name: "Bachelor of Science", value: "Bachelor of Science" },
    { name: "Bachelor of Commerce", value: "Bachelor of Commerce" },
    { name: "Bachelor of Engineering", value: "Bachelor of Engineering" },
    { name: "Bachelor of Technology", value: "Bachelor of Technology" },
    {
        name: "Bachelor of Business Administration",
        value: "Bachelor of Business Administration",
    },
    {
        name: "Bachelor of Medicine, Bachelor of Surgery",
        value: "Bachelor of Medicine, Bachelor of Surgery",
    },
    { name: "Master of Arts", value: "Master of Arts" },
    { name: "Master of Science", value: "Master of Science" },
    { name: "Master of Commerce", value: "Master of Commerce" },
    { name: "Master of Engineering", value: "Master of Engineering" },
    { name: "Master of Technology", value: "Master of Technology" },
    {
        name: "Master of Business Administration",
        value: "Master of Business Administration",
    },
    { name: "Doctor of Philosophy", value: "Doctor of Philosophy" },
    { name: "Doctor of Medicine", value: "Doctor of Medicine" },
];

const Skills = [
    { name: "JavaScript", value: "JavaScript" },
    { name: "Python", value: "Python" },
    { name: "Java", value: "Java" },
    { name: "React.js", value: "React.js" },
    { name: "Node.js", value: "Node.js" },
    { name: "TypeScript", value: "TypeScript" },
    { name: "AWS (Amazon Web Services)", value: "AWS (Amazon Web Services)" },
    { name: "DevOps", value: "DevOps" },
    { name: "Machine Learning", value: "Machine Learning" },
    { name: "Data Science", value: "Data Science" },
    { name: "Cybersecurity", value: "Cybersecurity" },
    { name: "SQL", value: "SQL" },
    { name: "Docker", value: "Docker" },
    { name: "Kubernetes", value: "Kubernetes" },
    { name: "Blockchain", value: "Blockchain" },
    { name: "Cloud Computing", value: "Cloud Computing" },
    { name: "Flutter", value: "Flutter" },
    { name: "Angular", value: "Angular" },
    { name: "Go (Golang)", value: "Go (Golang)" },
    { name: "PHP", value: "PHP" },
    { name: "C#", value: "C#" },
    { name: "Rust", value: "Rust" },
    {
        name: "AI (Artificial Intelligence)",
        value: "AI (Artificial Intelligence)",
    },
    { name: "Big Data", value: "Big Data" },
    { name: "Adobe Photoshop", value: "Adobe Photoshop" },
    { name: "Adobe Illustrator", value: "Adobe Illustrator" },
    { name: "Adobe InDesign", value: "Adobe InDesign" },
    { name: "Figma", value: "Figma" },
    { name: "Sketch", value: "Sketch" },
    { name: "UI/UX Design", value: "UI/UX Design" },
    { name: "Typography", value: "Typography" },
    { name: "3D Modeling", value: "3D Modeling" },
    { name: "Motion Graphics", value: "Motion Graphics" },
    { name: "Adobe After Effects", value: "Adobe After Effects" },
    { name: "Cinema 4D", value: "Cinema 4D" },
    { name: "Blender", value: "Blender" },
    { name: "Canva", value: "Canva" },
    { name: "Procreate", value: "Procreate" },
    { name: "Video Editing", value: "Video Editing" },
    { name: "Graphic Illustration", value: "Graphic Illustration" },
    { name: "Brand Identity Design", value: "Brand Identity Design" },
    { name: "Web Design", value: "Web Design" },
    { name: "Print Design", value: "Print Design" },
    { name: "Adobe XD", value: "Adobe XD" },
    { name: "CorelDRAW", value: "CorelDRAW" },
    { name: "Infographic Design", value: "Infographic Design" },
    { name: "Art Direction", value: "Art Direction" },
    { name: "Photography Editing", value: "Photography Editing" },
];

const Months = [
    { name: "January", value: "January" },
    { name: "February", value: "February" },
    { name: "March", value: "March" },
    { name: "April", value: "April" },
    { name: "May", value: "May" },
    { name: "June", value: "June" },
    { name: "July", value: "July" },
    { name: "August", value: "August" },
    { name: "September", value: "September" },
    { name: "October", value: "October" },
    { name: "November", value: "November" },
    { name: "December", value: "December" },
];

const PreferredWorkingHours = [
    { name: "Full-time (9 AM - 5 PM)", value: "Full-time (9 AM - 5 PM)" },
    { name: "Part-time (Morning)", value: "Part-time (Morning)" },
    { name: "Part-time (Afternoon)", value: "Part-time (Afternoon)" },
    { name: "Part-time (Evening)", value: "Part-time (Evening)" },
    { name: "Flexible Hours", value: "Flexible Hours" },
    { name: "Night Shift", value: "Night Shift" },
    { name: "Weekend Only", value: "Weekend Only" },
    { name: "Freelance/Project-Based", value: "Freelance/Project-Based" },
];

const TypeOfGigs = [
    { name: "Graphic Design", value: "Graphic Design" },
    { name: "Content Writing", value: "Content Writing" },
    { name: "Web Development", value: "Web Development" },
    { name: "Social Media Management", value: "Social Media Management" },
    { name: "Video Editing", value: "Video Editing" },
    { name: "Virtual Assistance", value: "Virtual Assistance" },
    { name: "Photography", value: "Photography" },
    { name: "Data Entry", value: "Data Entry" },
    { name: "Online Tutoring", value: "Online Tutoring" },
    { name: "SEO Optimization", value: "SEO Optimization" },
    { name: "App Development", value: "App Development" },
    { name: "Voice Acting", value: "Voice Acting" },
    { name: "Translation Services", value: "Translation Services" },
    { name: "Event Planning", value: "Event Planning" },
    { name: "Music Production", value: "Music Production" },
];

const ExperienceLevel = [
    { name: "No Experience", value: "No Experience" },
    { name: "Entry Level", value: "Entry Level" },
    { name: "Junior Level", value: "Junior Level" },
    { name: "Mid Level", value: "Mid Level" },
    { name: "Senior Level", value: "Senior Level" },
    { name: "Lead Level", value: "Lead Level" },
    { name: "Specialist", value: "Specialist" },
    { name: "Mentor/Trainer", value: "Mentor/Trainer" },
    { name: "Director Level", value: "Director Level" },
    { name: "Other", value: "Other" },
];

const PreferredJobLocations = [
    { name: "Delhi", value: "Delhi" },
    { name: "Mumbai", value: "Mumbai" },
    { name: "Bangalore", value: "Bangalore" },
    { name: "Hyderabad", value: "Hyderabad" },
    { name: "Chennai", value: "Chennai" },
    { name: "Kolkata", value: "Kolkata" },
    { name: "Pune", value: "Pune" },
    { name: "Ahmedabad", value: "Ahmedabad" },
    { name: "Jaipur", value: "Jaipur" },
    { name: "Surat", value: "Surat" },
    { name: "Lucknow", value: "Lucknow" },
    { name: "Indore", value: "Indore" },
    { name: "Nagpur", value: "Nagpur" },
    { name: "Coimbatore", value: "Coimbatore" },
    { name: "Chandigarh", value: "Chandigarh" },
    { name: "Thiruvananthapuram", value: "Thiruvananthapuram" },
    { name: "Patna", value: "Patna" },
    { name: "Bhopal", value: "Bhopal" },
    { name: "Visakhapatnam", value: "Visakhapatnam" },
    { name: "Vadodara", value: "Vadodara" },
    { name: "Noida", value: "Noida" },
    { name: "Gurgaon", value: "Gurgaon" },
    { name: "Faridabad", value: "Faridabad" },
    { name: "Mysore", value: "Mysore" },
    { name: "Kochi", value: "Kochi" },
    { name: "Varanasi", value: "Varanasi" },
    { name: "Rajkot", value: "Rajkot" },
    { name: "Amritsar", value: "Amritsar" },
    { name: "Jodhpur", value: "Jodhpur" },
];

const Industry = [
    {
        name: "Information Technology (IT)",
        value: "Information Technology (IT)",
    },
    { name: "Healthcare", value: "Healthcare" },
    { name: "Finance", value: "Finance" },
    { name: "Education", value: "Education" },
    { name: "Construction", value: "Construction" },
    { name: "Manufacturing", value: "Manufacturing" },
    { name: "Retail", value: "Retail" },
    { name: "Telecommunications", value: "Telecommunications" },
    { name: "Hospitality", value: "Hospitality" },
    { name: "Media and Entertainment", value: "Media and Entertainment" },
    { name: "Real Estate", value: "Real Estate" },
    { name: "Automotive", value: "Automotive" },
    { name: "Aerospace", value: "Aerospace" },
    { name: "Agriculture", value: "Agriculture" },
    { name: "Legal Services", value: "Legal Services" },
    { name: "Logistics and Supply Chain", value: "Logistics and Supply Chain" },
    {
        name: "Government and Public Sector",
        value: "Government and Public Sector",
    },
    { name: "Nonprofit and NGO", value: "Nonprofit and NGO" },
    { name: "Energy and Utilities", value: "Energy and Utilities" },
    { name: "Pharmaceuticals", value: "Pharmaceuticals" },
    { name: "E-commerce", value: "E-commerce" },
    { name: "Consulting", value: "Consulting" },
    { name: "Food and Beverage", value: "Food and Beverage" },
    { name: "Sports and Recreation", value: "Sports and Recreation" },
    { name: "Technology", value: "Technology" },
    { name: "Cybersecurity", value: "Cybersecurity" },
    { name: "Fashion and Apparel", value: "Fashion and Apparel" },
    { name: "Human Resources", value: "Human Resources" },
    { name: "Marketing and Advertising", value: "Marketing and Advertising" },
];

const IndustrySize = [
    { name: "Small (1-50 employees)", value: "Small (1-50 employees)" },
    { name: "Medium (51-200 employees)", value: "Medium (51-200 employees)" },
    { name: "Large (201-500 employees)", value: "Large (201-500 employees)" },
    {
        name: "Very Large (500+ employees)",
        value: "Very Large (500+ employees)",
    },
    { name: "Startup", value: "Startup" },
    { name: "Enterprise", value: "Enterprise" },
];

const JobScope = [
    {
        name: "Large",
        value: "Large",
    },
    {
        name: "Medium",
        value: "Medium",
    },
    {
        name: "Small",
        value: "Small",
    },
];

const JobExperienceLevel = [
    {
        name: "Entry",
        value: "Entry",
    },
    {
        name: "Intermediate",
        value: "Intermediate",
    },
    {
        name: "Expert",
        value: "Expert",
    },
];

const Master = {
    Skills: "Skills",
    Certificates: "Certificates",
    Languages: "Languages",
    Roles: "Roles",
    Countries: "Countries",
    "Work Location Type": "Work Location Type",
    "Work Location": "Work Location",
};

const CreditActionTypes = {
    "Review Invite": "Review Invite",
    "Apply Opportunity": "Apply Opportunity",
};

const CREDIT_ACTION = {
    "APPLY_JOB": "APPLY_JOB",
    "POST_JOB": "POST_JOB",
    "SHOW_CONTACT": "SHOW_CONTACT"
}

export {
    logo,
    ROLES,
    LANGUAGES,
    LanguageProficiency,
    Degrees,
    Skills,
    Months,
    PreferredWorkingHours,
    TypeOfGigs,
    ExperienceLevel,
    PreferredJobLocations,
    Industry,
    IndustrySize,
    JobScope,
    JobExperienceLevel,
    Master,
    CreditActionTypes,
    ACTION_REQUIRED_VALUE_TYPE,
    CREDIT_ACTION
};
