import React from "react";
import logos from "../../constant/logo";

const NoContent = () => {
    return (
        <div className="center-vertical space-y-5">
            <img
                src={logos.NoContent}
                className="size-80"
                alt="No Post Found"
            />
            <p className="text-xl font-light text-gray-500 text-center">
                Nothing to display at the moment.
            </p>
        </div>
    );
};

export default NoContent;
