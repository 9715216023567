import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import ExpandableDiv from "../../../common/components/ExpandableDiv";
import { ReviewProposalContext } from "./ReviewProposal";
import Link from "../../../common/components/Link";

const JobPost = () => {
    const { profileDetails } = useSelector((state) => state.profile);
    const { jobData } = useContext(ReviewProposalContext);

    const clientWebsite = useMemo(() => {
        return profileDetails?.webLinks?.filter(
            (link) => link?.type === "website"
        )?.[0]?.link;
    }, [profileDetails]);

    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-2 space-y-8">
                <div className="bg-white rounded-lg border p-4 sm:p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-base sm:text-lg font-semibold">
                            Job Title /Extra details
                        </h2>
                    </div>

                    <ExpandableDiv
                        text={jobData?.jobDescription}
                        lineClampCount={4}
                    />
                </div>

                {/* Requirements */}
                <div className="bg-white rounded-lg border p-4 sm:p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-base sm:text-lg font-semibold">
                            Requirements
                        </h2>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6 text-xs sm:text-base">
                        <div>
                            <div className="text-gray-600">
                                Estimated Budget: ₹{jobData?.jobCostEstimate}
                            </div>
                        </div>
                        <div className="text-gray-600">
                            Job Experience Level: {jobData?.jobExperienceLevel}
                        </div>
                        <div>
                            <div className="text-gray-600">
                                Job Scope: {jobData?.jobScope}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 text-xs sm:text-base">
                        <span className="text-gray-600">Credit required</span>
                        <div className="flex items-center gap-1">
                            <span>10</span>
                            <div className="w-4 h-4 bg-yellow-400 rounded-full" />
                        </div>
                    </div>
                </div>

                {/* Skills */}
                <div className="bg-white rounded-lg border p-4 sm:p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-base sm:text-lg font-semibold">
                            Skills and experience
                        </h2>
                    </div>
                    <div className="flex flex-wrap gap-2">
                        {jobData?.skillsRequired?.map((skill) => (
                            <span
                                key={skill}
                                className="px-4 py-2 bg-gray-100 rounded-full text-xs sm:text-sm text-gray-700"
                            >
                                {skill}
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            {/* Right Column */}
            <div className="space-y-6">
                <div className="bg-white rounded-lg border p-4">
                    <div className="flex justify-between items-center mb-2">
                        <h2 className="text-base sm:text-lg font-semibold">
                            About the client
                        </h2>
                    </div>

                    <ExpandableDiv
                        text={profileDetails?.description}
                        lineClampStyle={"line-clamp-4"}
                    />
                </div>

                <div className="bg-white rounded-lg border p-4">
                    <div className="space-y-3">
                        <h2 className="text-base sm:text-lg font-semibold">
                            Client website
                        </h2>
                        <Link
                            link={clientWebsite}
                            className={
                                "text-blue-600 underline text-xs sm:text-sm"
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobPost;
