import { useFormikContext } from "formik";
import { useEffect } from "react";

const FormikStateMonitor = ({ onChange }) => {
	const { values } = useFormikContext();

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			onChange((prevValues) => {
				return { ...prevValues, ...values };
			});
		}, 100);
		return () => clearTimeout(timeoutId);
	}, [values, onChange]);

	return null;
};

export default FormikStateMonitor;
