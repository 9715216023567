import React from "react";
import logos from "../../constant/logo";
import { Button } from "@nextui-org/react";
import ErrorMessage from "./ErrorMessage";

const LockedFeature = ({
    setIsOpen,
    title,
    description,
    handleFunction,
    submitButtonTitle = "Submit",
    error,
}) => {
    return (
        <div className="space-y-5 flex flex-col items-center  ">
            <img src={logos.LockGif} alt="lock img" className="size-40 " />

            <h1 className="text-2xl text-center font-semibold">{title}</h1>

            <p className="text-center  text-gray-600">{description}</p>

            <div className="flex w-full justify-end gap-5 border-t bg-white   mt-5 p-2 pt-5">
                <Button
                    className="w-full bg-white text-black border-2"
                    onPress={() => setIsOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    className="gradient-blue w-full"
                    type="submit"
                    onPress={handleFunction}
                >
                    {submitButtonTitle}
                </Button>
            </div>

            {error && <ErrorMessage message={error} />}
        </div>
    );
};

export default LockedFeature;
