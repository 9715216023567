import { default as React, useRef, useEffect } from "react";
import useGlobalQuery from "../../../hooks/useGlobalQuery";
import { ROUTES } from "../../../Routes";
import { ChatContext } from "../ChatLayout";
import ChatHeader from "./ChatHeader";
import ChatInput from "./ChatInput";
import ChatMessage from "./ChatMessage";
import ChatSkeletonLoader from "./ChatSkeletonLoader";
import { useDispatch, useSelector } from "react-redux";
import { messageActions } from "../../../store/message.slice";

const ChatContainer = ({ lastMessage, sendMessage }) => {
    const { activeChat, chatData } = useSelector((state) => state.message);
    // const { activeChat, lastMessage, chatData, setChatData } = React.useContext(ChatContext);
    const { profileDetails } = useSelector((state) => state.profile);

    const dispatch = useDispatch();

    const receiverEmail =
        activeChat?.receiverEmail !== profileDetails?.email
            ? activeChat?.receiverEmail
            : activeChat?.senderEmail;

    const { queryData, isLoading } = useGlobalQuery({
        url: ROUTES.CHAT.CHAT_HISTORY,
        methodType: "POST",
        data: {
            userEmail: receiverEmail,
            limit: 1000,
            sort: { createdAt: 1 },
        },
        queryKey: ["chat-history", receiverEmail],
        staleTime: 0,
        cacheTime: 0,
    });

    const messageBoxRef = useRef(null);

    const scrollToBottom = () => {
        if (messageBoxRef.current) {
            messageBoxRef.current.scrollTo({
                top: messageBoxRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        dispatch(messageActions.setChatData(queryData?.data || []));
        scrollToBottom();
    }, [queryData, dispatch]);

    useEffect(() => {
        if (lastMessage?.data) {
            try {
                const parsedData = JSON.parse(lastMessage.data);
                console.log("parsed data ", parsedData);
                const parsedReceiverEmail =
                    parsedData?.senderEmail !== profileDetails?.email
                        ? parsedData?.senderEmail
                        : parsedData?.receiverEmail;
                const activeChatReceiverEmail =
                    activeChat?.receiverEmail !== profileDetails?.email
                        ? activeChat?.receiverEmail
                        : activeChat?.senderEmail;

                if (parsedReceiverEmail === activeChatReceiverEmail) {
                    // setChatData((prevData) => [...(prevData ?? []), parsedData]);
                    dispatch(
                        messageActions.setChatData([
                            ...(chatData ?? []),
                            parsedData,
                        ])
                    );
                    scrollToBottom();
                }
            } catch (error) {
                console.error("Failed to parse JSON:", error);
            }
        }
    }, [lastMessage, activeChat, profileDetails?.email, chatData, dispatch]);

    useEffect(() => {
        scrollToBottom();
    }, [chatData, activeChat]);

    return (
        <div className="w-full border dark:border-dark-100 rounded-lg relative">
            <ChatHeader />
            {isLoading ? (
                <ChatSkeletonLoader />
            ) : (
                <div
                    className="p-4 pb-8 space-y-4 h-[68vh] overflow-auto"
                    ref={messageBoxRef}
                >
                    {chatData?.map(
                        (item) =>
                            item?.message && <ChatMessage chatData={item} />
                    )}
                </div>
            )}
            <div className="absolute w-full bottom-0 bg-white dark:bg-dark-100">
                <ChatInput sendMessage={sendMessage} />
            </div>
        </div>
    );
};

export default ChatContainer;
