import React, { useEffect, useState } from "react";
import { formatDate } from "../../../common/ReusableFunctions";
import { useSelector } from "react-redux";
import CommonButton from "../../../common/components/CommonButton";
import useGlobalQuery from "../../../hooks/useGlobalQuery";
import { ROUTES } from "../../../Routes";
import Loader from "../../../common/Loader/Loader";
import useGlobalMutation from "../../../hooks/useGlobalMutation";
import ErrorMessage from "../../../common/components/ErrorMessage";
import Spinner from "../../../common/components/Spinner";
import { useQueryClient } from "@tanstack/react-query";

const CommentSection = ({ postId, setCommentCount }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const [content, setContent] = useState();

    const queryClient = useQueryClient();

    const { queryData, isLoading } = useGlobalQuery({
        url: ROUTES.POST.GET_ALL_COMMENTS_OF_POST,
        methodType: "POST",
        queryKey: ["comments-of-post", postId],
        data: { id: postId },
    });

    const { runMutation, mutationLoading, mutationError, isMutationSucceeded } =
        useGlobalMutation({
            url: ROUTES.POST.COMMENT_ON_POST,
            methodType: "POST",
            data: { id: postId, content },
            queriesToInvalidate: ["comments-of-post", postId],
        });

    useEffect(() => {
        if (isMutationSucceeded) {
            setContent("");
            setCommentCount((prev) => prev + 1);
        }
    }, [isMutationSucceeded, queryClient, setCommentCount]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <section className="bg-white dark:bg-gray-900 antialiased">
            <div className="">
                <div className="flex items-center gap-2 mb-4">
                    <img
                        src={
                            profileDetails?.imgUrl
                                ? `${profileDetails?.imgUrl}?${Date.now()}`
                                : `https://ui-avatars.com/api/?name=${
                                      profileDetails?.firstname +
                                      " " +
                                      profileDetails?.lastname
                                  }&background=random`
                        }
                        className="size-7 rounded-full"
                        loading="lazy"
                        alt="Profile"
                    />
                    <p className="font-semibold">
                        {profileDetails?.firstname} {profileDetails?.lastname}
                    </p>
                </div>
                <div className="mb-6">
                    <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                        <label htmlFor="comment" className="sr-only">
                            Your comment
                        </label>
                        <textarea
                            id="comment"
                            rows="6"
                            className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                            placeholder="Write a comment..."
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        ></textarea>
                    </div>
                    <CommonButton
                        title={"Post Comment"}
                        isGradient
                        isRounded
                        buttonStyle={"!w-32 !text-xs !flex !ml-auto"}
                        handleFunction={() => {
                            runMutation();
                        }}
                        disabled={mutationLoading}
                        loading={mutationLoading}
                    />
                </div>

                {mutationError && <ErrorMessage message={mutationError} />}

                <div className="divide-y-1">
                    {queryData?.data?.map((comment) => (
                        <Comment
                            name={
                                comment?.userDetails?.firstname +
                                " " +
                                comment.userDetails?.lastname
                            }
                            date={formatDate({
                                dateString: comment?.createdAt,
                            })}
                            profilePicture={
                                comment?.userDetails?.imgUrl
                                    ? `${
                                          comment?.userDetails?.imgUrl
                                      }?${Date.now()}`
                                    : `https://ui-avatars.com/api/?name=${
                                          comment?.userDetails?.firstname +
                                          " " +
                                          comment?.userDetails?.lastname
                                      }&background=random`
                            }
                            content={comment?.content}
                            isDeletable={
                                comment?.userId === profileDetails?._id
                            }
                            commentId={comment?._id}
                            postId={postId}
                            setCommentCount={setCommentCount}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

const Comment = ({
    name,
    date,
    profilePicture,
    content,
    isDeletable,
    commentId,
    postId,
    setCommentCount
}) => {
    const { runMutation, mutationLoading, isMutationSucceeded } = useGlobalMutation({
        url: ROUTES.POST.DELETE_COMMENT,
        methodType: "DELETE",
        data: { id: commentId },
        queriesToInvalidate: ["comments-of-post", postId],
    });

    useEffect(() => {
        isMutationSucceeded && setCommentCount((prev) => prev - 1);
    }, [isMutationSucceeded, setCommentCount])

    return (
        <article className="py-5 text-base bg-white rounded-lg dark:bg-gray-900">
            <footer className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                    <p className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white font-semibold">
                        <img
                            className="mr-2 w-6 h-6 rounded-full"
                            src={profilePicture}
                            alt={name}
                            loading="lazy"
                        />
                        {name}
                    </p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                        <time dateTime={date}>{date}</time>
                    </p>
                </div>
                <div>
                    {isDeletable && (
                        <button
                            className="text-sm text-red-500 hover:text-red-700"
                            onClick={() => {
                                runMutation();
                            }}
                        >
                            {mutationLoading ? <Spinner /> : "Delete"}
                        </button>
                    )}
                </div>
            </footer>
            <p className="text-gray-500 dark:text-gray-400">{content}</p>
        </article>
    );
};

export default CommentSection;
