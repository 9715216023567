import React, { useRef, useCallback } from "react";
import Loader from "../Loader/Loader";

const InfiniteScrollComponent = ({
    items = [],
    isLoading,
    hasNextPage,
    fetchNextPage,
    children,
    noDataComponent = <div>No items to display</div>,
    className,
    props
}) => {
    const observer = useRef();

    const lastItemRef = useCallback(
        (node) => {
            if (isLoading) return;

            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasNextPage) {
                    fetchNextPage();
                }
            });

            if (node) observer.current.observe(node);
        },
        [isLoading, hasNextPage, fetchNextPage]
    );

    if (isLoading && items.length === 0) return <Loader />;

    if (!isLoading && items.length === 0) return noDataComponent;

    return (
        <div className={className}>
            {items?.map((data, index) => {
                const isLastItem = index === items.length - 1;

                const childWithProps = React.cloneElement(children, { data, index, ...props });

                return isLastItem ? (
                    <div ref={lastItemRef} key={index}>
                        {childWithProps}
                    </div>
                ) : (
                    <div key={index}>{childWithProps}</div>
                );
            })}

            {isLoading && <div>Loading ...</div>}
        </div>
    );
};

export default InfiniteScrollComponent;
