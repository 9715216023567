import { NextUIProvider } from "@nextui-org/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Store from "./store/store";
import { ToastContainer, Slide } from "react-toastify";
import { pdfjs } from "react-pdf";
import ScrollToTop from "./common/components/ScrollToTop";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
).toString();

// add your providers here
export function Providers({ children }) {
    const queryClient = new QueryClient();

    return (
        <BrowserRouter>
            <NextUIProvider>
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <ToastContainer
                        position="bottom-left"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick={false}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                        transition={Slide}
                    />
                    <ScrollToTop />
                    <Provider store={Store}>{children}</Provider>
                </QueryClientProvider>
            </NextUIProvider>
        </BrowserRouter>
    );
}
