import React from 'react'
import logos from '../../constant/logo'

const NoPostFound = () => {
  return (
    <div className='center-vertical space-y-5'>
        <img src={logos.NoPostFoundImage} className='size-80' alt='No Post Found'/>
        <p className='text-xl font-light text-gray-500'>No Posts Found</p>
    </div>
  )
}

export default NoPostFound