import React from "react";
import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import logos from "../../constant/logo";

const InsufficientCreditsModal = ({ setIsOpen }) => {
    const navigate = useNavigate();

    return (
        <div className="space-y-5 flex flex-col items-center  ">
            <img src={logos.CoinGif} alt="lock img" className="w-60 " />

            <h1 className="text-2xl text-center font-semibold">
                Insufficient Credits
            </h1>

            <p className="text-center  text-gray-600">
                You don’t have enough credits to perform this action. Please
                purchase additional credits or upgrade your plan to continue
            </p>

            <div className="flex w-full justify-end gap-5 border-t bg-white   mt-5 p-2 pt-5">
                <Button
                    className="w-full bg-white text-black border-2"
                    onPress={() => setIsOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    className="gradient-blue w-full"
                    onPress={() => navigate("/subscription")}
                >
                    Add Credits
                </Button>
            </div>
        </div>
    );
};

export default InsufficientCreditsModal;
