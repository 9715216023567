import React from "react";

const ChatSkeletonLoader = () => {
    return (
        <div className="p-4 space-y-4">
            {/* Left Message Skeleton */}
            <div className="flex space-x-2">
                <div className="w-10 h-10 bg-gray-300 rounded-full animate-pulse"></div>
                <div className="flex flex-col space-y-2">
                    <div className="w-40 h-6 bg-gray-300 rounded-lg animate-pulse"></div>
                    <div className="w-28 h-4 bg-gray-300 rounded-lg animate-pulse"></div>
                </div>
            </div>

            {/* Right Message Skeleton */}
            <div className="flex justify-end space-x-2">
                <div className="flex flex-col space-y-2 items-end">
                    <div className="w-40 h-6 bg-gray-300 rounded-lg animate-pulse"></div>
                    <div className="w-28 h-4 bg-gray-300 rounded-lg animate-pulse"></div>
                </div>
                <div className="w-10 h-10 bg-gray-300 rounded-full animate-pulse"></div>
            </div>

            {/* Left Message Skeleton */}
            <div className="flex space-x-2">
                <div className="w-10 h-10 bg-gray-300 rounded-full animate-pulse"></div>
                <div className="flex flex-col space-y-2">
                    <div className="w-32 h-6 bg-gray-300 rounded-lg animate-pulse"></div>
                    <div className="w-24 h-4 bg-gray-300 rounded-lg animate-pulse"></div>
                </div>
            </div>

            <div className="flex justify-end space-x-2">
                <div className="flex flex-col space-y-2 items-end">
                    <div className="w-40 h-6 bg-gray-300 rounded-lg animate-pulse"></div>
                    <div className="w-28 h-4 bg-gray-300 rounded-lg animate-pulse"></div>
                </div>
                <div className="w-10 h-10 bg-gray-300 rounded-full animate-pulse"></div>
            </div>

            {/* Left Message Skeleton */}
            <div className="flex space-x-2">
                <div className="w-10 h-10 bg-gray-300 rounded-full animate-pulse"></div>
                <div className="flex flex-col space-y-2">
                    <div className="w-32 h-6 bg-gray-300 rounded-lg animate-pulse"></div>
                    <div className="w-24 h-4 bg-gray-300 rounded-lg animate-pulse"></div>
                </div>
            </div>

            {/* Right Message Skeleton */}
            <div className="flex justify-end space-x-2">
                <div className="flex flex-col space-y-2 items-end">
                    <div className="w-40 h-6 bg-gray-300 rounded-lg animate-pulse"></div>
                    <div className="w-28 h-4 bg-gray-300 rounded-lg animate-pulse"></div>
                </div>
                <div className="w-10 h-10 bg-gray-300 rounded-full animate-pulse"></div>
            </div>
        </div>
    );
};

export default ChatSkeletonLoader;
