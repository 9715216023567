import React from "react";
import { useSelector } from "react-redux";

const PostTabs = ({ tabs, activeTab, setActiveTab }) => {
	const { profileDetails } = useSelector((state) => state.profile);

	return (
		<div className="flex items-center justify-between border-b overflow-x-auto scrollbar-hide">
			<div className="flex gap-4 sm:gap-4 min-w-max">
				{tabs.map((tab) => (
					<button
						key={tab}
						onClick={() => setActiveTab(tab)}
						className={`${
							activeTab === tab
								? "text-blue-600 border-b-4 border-blue-600"
								: "text-gray-600"
						} p-2 sm:p-3 text-sm sm:text-base whitespace-nowrap transition-all duration-300 ease-in-out`}
					>
						{tab} 
						{tab === "Saved Posts" && " (" +  (profileDetails?.savedPosts?.length ?? 0) + ")"}
					</button>
				))}
			</div>
		</div>
	);
};

export default PostTabs;
