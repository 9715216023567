export default function JobStats() {
    const stats = [
        { title: "Project Price", value: "$1100.00" },
        { title: "Project Price", value: "$00.00" },
        { title: "Miles stone paid(11)", value: "$1050.00" },
        { title: "Miles stone remaining(1)", value: "$50.00" },
        { title: "Project earning", value: "$1070.00" },
    ];

    return (
        <div className="flex justify-between p-6">
            {stats.map((stat, index) => (
                <div key={index} className="text-center">
                    <p className="text-gray-500 text-sm">{stat.title}</p>
                    <p className="text-2xl font-bold">{stat.value}</p>
                    <p className="text-gray-400 text-xs">Fixed Price</p>
                </div>
            ))}
        </div>
    );
}
