import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const LineChart = () => {
    const ref = useRef();
    const options = {
        title: {
            text: "U.S Solar Employment Growth",
            align: "left",
        },

        yAxis: {},

        xAxis: {
            accessibility: {
                rangeDescription: "Range: 2010 to 2022",
            },
        },

        legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "middle",
        },

        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false,
                },
                pointStart: 2010,
            },
        },
        // legend: {
        //     layout: "vertical", // Keeps the legend items in a vertical list
        //     align: "right",
        //     verticalAlign: "middle",
        //     x: 0,
        //     y: 0,
        //     itemMarginTop: 5, // Adjust spacing between items
        // },
        series: [
            {
                name: "Installation & Developers",
                data: [
                    43934, 48656, 65165, 81827, 112143, 142383, 171533, 165174,
                    155157, 161454, 154610, 168960, 171558,
                ],
            },
            {
                name: "Manufacturing",
                data: [
                    24916, 37941, 29742, 29851, 32490, 30282, 38121, 36885,
                    33726, 34243, 31050, 33099, 33473,
                ],
            },
            {
                name: "Sales & Distribution",
                data: [
                    11744, 30000, 16005, 19771, 20185, 24377, 32147, 30912,
                    29243, 29213, 25663, 28978, 30618,
                ],
            },
        ],
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: "horizontal",
                            align: "center",
                            verticalAlign: "bottom",
                        },
                    },
                },
            ],
        },
    };

    return (
        <div className="w-full min-w-[30vw] sm:max-w-[30vw] h-[300px]  bg-white dark:bg-dark-normal dark:shadow-dark-light-gray dark:shadow-sm dark:text-white p-3 rounded-2xl drop-shadow-sm flex flex-col max-h-[445px] shadow-sm hover:shadow-md border dark:border-0">
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={ref}
            />
        </div>
    );
};

export default LineChart;
