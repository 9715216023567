import { getLocalTimeZone, today } from "@internationalized/date";
import { RangeCalendar } from "@nextui-org/react";
import React, { useMemo, useState } from "react";
import Loader from "../../common/Loader/Loader";
import useGlobalQuery from "../../hooks/useGlobalQuery";
import { ROUTES } from "../../Routes";
import { BarChartCard } from "./Graphs/BarChart";
import { BarChartCard2 } from "./Graphs/BarChart2";
import { CircleChartCard } from "./Graphs/CircleChart";
import GraphChart from "./Graphs/GraphChart";
import SubscriptionGrowth from "./Graphs/SubscriptionGrowth";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    Button,
} from "@nextui-org/react";
import { Calendar } from "iconsax-react"

const monthMap = {
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec",
};

const AdminDashboard = () => {
    const [dateFilter, setDateFilter] = useState({
        start: today(getLocalTimeZone()),
        end: today(getLocalTimeZone()).add({ weeks: 1 }),
    });

    const { queryData, isLoading } = useGlobalQuery({
        url: ROUTES.DASHBOARD.GET_DASHBOARD_DATA,
        methodType: "GET",
        queryKey: [ROUTES.DASHBOARD.GET_DASHBOARD_DATA],
    });

    const usersStatusData = useMemo(() => {
        return queryData?.data?.userStatusCounts;
    }, [queryData]);

    const usersStatusChartData = {
        title: "Activity",
        color: "default",
        total: usersStatusData?.total,
        chartData: [
            {
                name: "Active Users",
                value: usersStatusData?.active,
                fill: "hsl(var(--nextui-primary))",
            },
        ],
    };

    const usersCountRoleWise = useMemo(() => {
        const input = queryData?.data?.usersByRoleMonthly;

        return {
            title: "Roles Distribution",
            categories: input?.rolesList.map(
                (role) => role.charAt(0).toUpperCase() + role.slice(1)
            ),
            color: "default",
            chartData: input?.data.map(({ monthName, roles }) => ({
                month: monthMap[monthName],
                ...roles,
            })),
        };
    }, [queryData]);

    const totalUsersByMonth = useMemo(() => {
        const input = queryData?.data?.totalUsersByMonth?.data;

        const chartData = input?.map((item) => ({
            month: monthMap[item?.monthName],
            value: item?.count,
        }));

        return {
            key: "unique-visitors",
            title: "Total Users",
            suffix: "users",
            value: chartData?.reduce((acc, cur) => acc + cur.value, 0),
            type: "number",
            color: "success",
            chartData,
        };
    }, [queryData]);

    const paymentByMonth = useMemo(() => {
        const input = queryData?.data?.paymentsByMonth?.data;

        const chartData = input?.map((item) => ({
            month: monthMap[item.monthName],
            Amount: item?.totalAmount,
        }));

        return {
            title: "Monthly Payments",
            value: `₹${chartData
                ?.reduce((acc, cur) => acc + cur.Amount, 0)
                .toFixed(2)}`,
            unit: "total",
            categories: ["Amount"],
            color: "default",
            chartData,
        };
    }, [queryData]);

    const jobsByMonth = useMemo(() => {
        const input = queryData?.data?.jobsByMonth?.data;

        const chartData = input?.map((item) => ({
            month: monthMap[item.monthName],
            value: item.count,
        }));

        return {
            key: "unique-visitors",
            title: "Jobs By Month",
            suffix: "Jobs",
            value: chartData?.reduce((acc, cur) => acc + cur.value, 0),
            type: "number",
            color: "success",
            chartData,
        };
    }, [queryData]);

    const gigsByMonth = useMemo(() => {
        const input = queryData?.data?.gigsByMonth?.data;

        const chartData = input?.map((item) => ({
            month: monthMap[item.monthName],
            value: item.count,
        }));

        return {
            key: "unique-visitors",
            title: "Gigs By Month",
            suffix: "Gigs",
            value: chartData?.reduce((acc, cur) => acc + cur.value, 0),
            type: "number",
            color: "success",
            chartData,
        };
    }, [queryData]);

    if (isLoading) {
        return <Loader />;
    }

    // console.log("date filter", dateFilter?.start?.toString());

    return (
        <div className="border-l p-5 relative">
            <div className="space-y-5 mt-8">
                <div className="grid grid-cols-4 gap-5">
                    <CircleChartCard {...usersStatusChartData} />
                    <BarChartCard2 {...paymentByMonth} />
                    <SubscriptionGrowth activeChartData={jobsByMonth} />
                    <SubscriptionGrowth activeChartData={gigsByMonth} />
                </div>

                <div className="grid grid-cols-2 gap-5 pb-10">
                    <BarChartCard {...usersCountRoleWise} />
                    <GraphChart activeChartData={totalUsersByMonth} />
                </div>
            </div>

            <div className="absolute top-1 right-2">
                <Popover placement="bottom" className="!p-0">
                    <PopoverTrigger>
                        <Button variant="faded" size="sm">
                            <Calendar size="20" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="!p-0">
                        <RangeCalendar
                            aria-label="Date (Controlled)"
                            showMonthAndYearPickers
                            value={dateFilter}
                            onChange={setDateFilter}
                        />
                    </PopoverContent>
                </Popover>
            </div>
        </div>
    );
};

export default AdminDashboard;
