import { ScrollShadow } from "@nextui-org/react";
import React from "react";

const PrivacyPolicy = () => {
    return (
        <ScrollShadow>
            <p className="mb-6 text-gray-700">
                Welcome to <strong>PortfoliOOo</strong>! This Privacy Policy
                explains how we collect, use, and protect your personal
                information when you use our Platform. By accessing or using
                PortfoliOOo, you agree to the terms of this Privacy Policy. If
                you do not agree, please do not use the Platform.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
                1. Information We Collect
            </h2>
            <p className="mb-4 text-gray-700">
                We collect the following types of information:
            </p>
            <ul className="list-disc list-inside mb-6 text-gray-700">
                <li>
                    <strong>Personal Information:</strong> Name, email address,
                    phone number, and payment details.
                </li>
                <li>
                    <strong>Account Information:</strong> Username, password,
                    and profile details.
                </li>
                <li>
                    <strong>Usage Data:</strong> Information about how you
                    interact with the Platform, such as IP address, browser
                    type, and pages visited.
                </li>
                <li>
                    <strong>Content:</strong> Files, messages, and other content
                    you upload or share on the Platform.
                </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
                2. How We Use Your Information
            </h2>
            <p className="mb-4 text-gray-700">
                We use your information for the following purposes:
            </p>
            <ul className="list-disc list-inside mb-6 text-gray-700">
                <li>To provide and improve the Platform.</li>
                <li>To process transactions and payments.</li>
                <li>
                    To communicate with you about your account and services.
                </li>
                <li>To enforce our Terms and Conditions and other policies.</li>
                <li>To comply with legal obligations.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
                3. Sharing Your Information
            </h2>
            <p className="mb-4 text-gray-700">
                We may share your information with:
            </p>
            <ul className="list-disc list-inside mb-6 text-gray-700">
                <li>
                    <strong>Service Providers:</strong> Third-party vendors who
                    help us operate the Platform (e.g., payment processors).
                </li>
                <li>
                    <strong>Legal Authorities:</strong> When required by law or
                    to protect our rights and safety.
                </li>
                <li>
                    <strong>Business Transfers:</strong> In the event of a
                    merger, acquisition, or sale of assets.
                </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">4. Data Security</h2>
            <p className="mb-6 text-gray-700">
                We implement industry-standard security measures to protect your
                information from unauthorized access, alteration, or
                destruction. However, no method of transmission over the
                internet is 100% secure, and we cannot guarantee absolute
                security.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">5. Your Rights</h2>
            <p className="mb-4 text-gray-700">
                You have the following rights regarding your personal
                information:
            </p>
            <ul className="list-disc list-inside mb-6 text-gray-700">
                <li>
                    <strong>Access:</strong> Request a copy of the information
                    we hold about you.
                </li>
                <li>
                    <strong>Correction:</strong> Update or correct inaccurate
                    information.
                </li>
                <li>
                    <strong>Deletion:</strong> Request deletion of your
                    information, subject to legal requirements.
                </li>
                <li>
                    <strong>Opt-Out:</strong> Opt out of marketing
                    communications.
                </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
                6. Cookies and Tracking
            </h2>
            <p className="mb-6 text-gray-700">
                We use cookies and similar technologies to enhance your
                experience on the Platform. You can manage your cookie
                preferences through your browser settings.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
                7. Third-Party Links
            </h2>
            <p className="mb-6 text-gray-700">
                The Platform may contain links to third-party websites. We are
                not responsible for the privacy practices or content of these
                websites.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
                8. Children’s Privacy
            </h2>
            <p className="mb-6 text-gray-700">
                PortfoliOOo is not intended for use by individuals under the age
                of 18. We do not knowingly collect personal information from
                children.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
                9. Changes to This Policy
            </h2>
            <p className="mb-6 text-gray-700">
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page, and your continued use of the
                Platform constitutes acceptance of the updated policy.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">10. Contact Us</h2>
            <p className="mb-6 text-gray-700">
                If you have any questions about this Privacy Policy, please
                contact us at:
            </p>
            <ul className="list-disc list-inside mb-6 text-gray-700">
                <li>Email: [Your Email Address]</li>
                <li>Address: [Your Physical Address]</li>
                <li>Phone: [Your Phone Number]</li>
            </ul>

            <p className="text-center text-gray-700">
                By using PortfoliOOo, you acknowledge that you have read,
                understood, and agreed to this Privacy Policy.
            </p>
        </ScrollShadow>
    );
};

export default PrivacyPolicy;
