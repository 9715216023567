"use client";
import { Dialog, DialogPanel, TransitionChild, DialogBackdrop } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function SideDrawer({ children, open, setOpen }) {
	return (
		<Dialog open={open} onClose={setOpen} className="relative z-40">
			<DialogBackdrop
				transition
				className="fixed inset-0 bg-gray-500/75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
			/>

			<div className="fixed inset-0 overflow-hidden">
				<div className="absolute inset-0 overflow-hidden">
					<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-sm sm:max-w-5xl pl-10">
						<DialogPanel
							transition
							className="pointer-events-auto w-screen max-w-6xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
						>
							<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
								<TransitionChild>
									<div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
										<button
											type="button"
											onClick={() => setOpen(false)}
											className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
										>
											<span className="absolute -inset-2.5" />
											<span className="sr-only">Close panel</span>
											<XMarkIcon aria-hidden="true" className="size-6" />
										</button>
									</div>
								</TransitionChild>
								<div className="relative flex-1 px-4 sm:px-6">{children}</div>
							</div>
						</DialogPanel>
					</div>
				</div>
			</div>
		</Dialog>
	);
}
