import React, { useContext } from "react";
import { ChatContext } from "../ChatLayout";
import { Avatar } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import useIsMobile from "../../../hooks/useIsMobile";
import { ArrowLeft } from "iconsax-react";
import { messageActions } from "../../../store/message.slice";

const ChatHeader = () => {
    const { activeChat } = useSelector((state) => state.message);
    const { isMobile } = useIsMobile();

    const dispatch = useDispatch();

    return (
        <div className="border-b dark:border-dark-100 w-full">
            <div className="flex items-center gap-4 border-b dark:border-dark-100 px-5 py-[11.5px]">
                {isMobile && (
                    <ArrowLeft
                        onClick={() =>
                            dispatch(messageActions.setActiveChat({}))
                        }
                    />
                )}
                <Avatar
                    // isDisabled={activeChat?.senderDetails?.imgUrl ? false : true}
                    src={activeChat?.senderDetails?.imgUrl}
                    key={activeChat?.senderDetails?.imgUrl}
                />
                <div>
                    <h1 className="text-base font-semibold">
                        {activeChat?.senderDetails?.firstname}{" "}
                        {activeChat?.senderDetails?.lastname}
                    </h1>
                    <p className="text-sm text-gray-400">
                        {activeChat?.senderDetails?.position}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ChatHeader;
