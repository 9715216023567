"use client";

import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import {
    Card,
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Divider,
    VisuallyHidden,
    useRadio,
    RadioGroup,
    cn,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";

const data = [
    {
        title: "Operating Systems",
        categories: ["Android", "iOS", "Web", "Windows"],
        color: "default",
        chartData: [
            {
                weekday: "Mon",
                android: 20,
                ios: 30,
                web: 20,
                windows: 10,
            },
            {
                weekday: "Tue",
                android: 35,
                ios: 35,
                web: 20,
                windows: 10,
            },
            {
                weekday: "Wed",
                android: 15,
                ios: 25,
                web: 20,
                windows: 10,
            },
            {
                weekday: "Thu",
                android: 12,
                ios: 35,
                web: 10,
                windows: 10,
            },
            {
                weekday: "Fri",
                android: 12,
                ios: 15,
                web: 20,
                windows: 10,
            },
            {
                weekday: "Sat",
                android: 35,
                ios: 25,
                web: 10,
                windows: 6,
            },
            {
                weekday: "Sun",
                android: 40,
                ios: 30,
                web: 20,
                windows: 10,
            },
        ],
    },
];

export default function BarGraph() {
    return (
        <dl className="w-full">
            {data.map((item, index) => (
                <BarChartCard key={index} {...item} />
            ))}
        </dl>
    );
}

const formatMonth = (monthName) => {
    const months = {
        January: "Jan",
        February: "Feb",
        March: "Mar",
        April: "Apr",
        May: "May",
        June: "Jun",
        July: "Jul",
        August: "Aug",
        September: "Sep",
        October: "Oct",
        November: "Nov",
        December: "Dec",
    };
    return months?.[monthName] || monthName;
};

export const BarChartCard = React.forwardRef(
    ({ className, title, categories, color, chartData, ...props }, ref) => {
        return (
            <Card
                ref={ref}
                className={cn(
                    "border border-transparent dark:border-default-100",
                    className
                )}
                {...props}
            >
                <div className="flex flex-col gap-y-4 p-4">
                    <dt>
                        <h3 className="text-small font-medium text-default-500">
                            {title}
                        </h3>
                    </dt>
                    <dd className="flex w-full justify-end gap-4 text-tiny text-default-500">
                        {categories?.map((category, index) => (
                            <div
                                key={index}
                                className="flex items-center gap-2"
                            >
                                <span
                                    className="h-2 w-2 rounded-full"
                                    style={{
                                        backgroundColor: `hsl(var(--nextui-${color}-${
                                            (index + 1) * 200
                                        }))`,
                                    }}
                                />

                                <span className="capitalize">{category}</span>
                            </div>
                        ))}
                    </dd>
                </div>
                <ResponsiveContainer
                    className="[&_.recharts-surface]:outline-none"
                    height="100%"
                    width="100%"
                >
                    <BarChart
                        accessibilityLayer
                        data={chartData}
                        margin={{
                            top: 20,
                            right: 14,
                            left: -8,
                            bottom: 5,
                        }}
                    >
                        <XAxis
                            dataKey="month"
                            strokeOpacity={0.25}
                            style={{
                                fontSize: "var(--nextui-font-size-tiny)",
                                color: "red",
                            }}
                            tickLine={false}
                        />

                        <YAxis
                            axisLine={false}
                            style={{ fontSize: "var(--nextui-font-size-tiny)" }}
                            tickLine={false}
                        />

                        <Tooltip
                            content={({ label, payload }) => (
                                <div className="flex h-auto min-w-[120px] items-center gap-x-2 rounded-medium bg-background p-2 text-tiny ">
                                    <div className="flex w-full flex-col gap-y-1">
                                        <span className="font-medium text-foreground">
                                            {formatMonth(label)}
                                        </span>
                                        {payload?.map((p, index) => {
                                            const name = p.name;
                                            const value = p.value;
                                            const category =
                                                categories.find(
                                                    (c) =>
                                                        c.toLowerCase() === name
                                                ) ?? name;

                                            return (
                                                <div
                                                    key={`${index}-${name}`}
                                                    className="flex w-full items-center gap-x-2"
                                                >
                                                    <div
                                                        className="h-2 w-2 flex-none rounded-full"
                                                        style={{
                                                            backgroundColor: `hsl(var(--nextui-${color}-${
                                                                (index + 1) *
                                                                200
                                                            }))`,
                                                        }}
                                                    />

                                                    <div className="flex w-full items-center justify-between gap-x-2 pr-1 text-xs text-default-700">
                                                        <span className="text-default-500">
                                                            {category}
                                                        </span>
                                                        <span className="font-mono font-medium text-default-700">
                                                            {value}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                            cursor={false}
                        />

                        {categories.map((category, index) => (
                            <Bar
                                key={`${category}-${index}`}
                                animationDuration={450}
                                animationEasing="ease"
                                barSize={24}
                                dataKey={category.toLowerCase()}
                                fill={`hsl(var(--nextui-${color}-${
                                    (index + 1) * 200
                                }))`}
                                radius={
                                    index === categories.length - 1
                                        ? [4, 4, 0, 0]
                                        : 0
                                }
                                stackId="bars"
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            </Card>
        );
    }
);

BarChartCard.displayName = "BarChartCard";

const ButtonRadioItem = React.forwardRef(
    ({ children, color, size = "sm", variant, ...props }, ref) => {
        const { Component, isSelected, getBaseProps, getInputProps } =
            useRadio(props);

        return (
            <Component {...getBaseProps()} ref={ref}>
                <VisuallyHidden>
                    <input {...getInputProps()} />
                </VisuallyHidden>
                <Button
                    disableRipple
                    className={cn("pointer-events-none text-default-500", {
                        "text-foreground": isSelected,
                    })}
                    color={color}
                    size={size}
                    variant={variant || isSelected ? "solid" : "flat"}
                >
                    {children}
                </Button>
            </Component>
        );
    }
);

ButtonRadioItem.displayName = "ButtonRadioItem";
