import React, { useState, useRef, useEffect } from "react";

const ExpandableDiv = ({ text, lineClampStyle, componentStyle, titleStyle }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current) {
            const isTextOverflowing = textRef.current.scrollHeight > textRef.current.clientHeight;
            setIsOverflowing(isTextOverflowing);
        }
    }, [text]);
  
    return (
        <div className={`w-full text-start ${componentStyle}`}>
            <p
                ref={textRef}
                dangerouslySetInnerHTML={{ __html: text }}
                className={`${titleStyle} text-xs sm:text-sm text-gray-600 text-start ${
                    !isExpanded ? `${lineClampStyle}` : ""
                } `}
            />
            {isOverflowing && (
                <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="text-blue-600 hover:text-blue-800 text-xs mt-2 focus:outline-none text-start"
                    type="button"
                >
                    {isExpanded ? "View Less" : "View More"}
                </button>
            )}
        </div>
    );
};

export default ExpandableDiv;
