import React from "react";
import ProfileDefaultImg from "../../../common/components/ProfileDefaultImg";

const LivePeople = () => {
    const livePeople = [
        { name: "Rajesh", loginTime: "10:49pm", color: "bg-green-500" },
        { name: "Sudesh", loginTime: "10:49pm", color: "bg-blue-500" },
        { name: "Basile", loginTime: "10:49pm", color: "bg-indigo-500" },
        { name: "Vaani", loginTime: "10:49pm", color: "bg-yellow-500" },
        { name: "Rohit", loginTime: "10:49pm", color: "bg-blue-400" },
        { name: "Sagar", loginTime: "10:49pm", color: "bg-blue-400" },
        { name: "Satyam", loginTime: "10:49pm", color: "bg-blue-400" },
        { name: "Vansh", loginTime: "10:49pm", color: "bg-blue-400" },
        { name: "Prasad", loginTime: "10:49pm", color: "bg-blue-400" },
    ];
    return (
        <div className="w-full min-w-[20vw] sm:max-w-[20vw]  bg-white dark:bg-dark-normal dark:shadow-dark-light-gray dark:shadow-sm dark:text-white p-3 rounded-2xl drop-shadow-sm flex flex-col shadow-sm hover:shadow-md border dark:border-0">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-gray-900">Live people</h3>
                <span className="text-xs text-gray-500">Refreshed 5 min ago</span>
            </div>
            <div className="space-y-2">
                <div className="flex justify-between items-center px-3 pl-4 text-xs">
                    <h1>People</h1>
                    <h1>Login time</h1>
                </div>
                {livePeople.map((person, index) => (
                    <PeopleCard person={person} />
                ))}
            </div>
        </div>
    );
};

export default LivePeople;

const PeopleCard = ({ person }) => {
    return (
        <div className="flex justify-between items-center py-2 px-3 rounded-md shadow-sm hover:bg-gray-100">
            <div className="flex items-center space-x-3">
                <ProfileDefaultImg firstname={person.name} textSize={"text-sm"} height={"h-8"} width={"w-8"} />
                <span className="text-gray-800 text-sm">{person.name}</span>
            </div>
            <span className=" text-gray-500 text-xs">{person.loginTime}</span>
        </div>
    );
};
