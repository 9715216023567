import React, { useState } from "react";
import Logo from "../../../assets/Logo.svg";
import { logo } from "../../../constant/constants";
import { useNavigate } from "react-router-dom";
import OverlayFragment from "../../../common/components/OverlayFragment";
import TermsAndConditions from "../../Authentication/TermsAndConditions";
import PrivacyPolicy from "../../Authentication/PrivacyPolicy";

const Footer = () => {
    const [termsAndConditionModalOpen, setIsTermsAndConditionModalOpen] =
        useState(false);
    const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] =
        useState(false);

    const navigate = useNavigate();

    return (
        <footer className="w-full py-6 md:py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-6xl mx-auto">
                {/* Mobile Layout */}
                <div className="block lg:hidden">
                    <div className="flex flex-col space-y-8">
                        {/* Logo and Copyright */}
                        <div className="flex flex-col items-center space-y-4">
                            <img src={Logo} alt="Logo" className="h-8" />
                            <div className="flex flex-wrap justify-center gap-4 text-sm text-gray-500">
                                <span className="hidden sm:inline">•</span>
                                <span>© 2025 PortfoliOOo</span>
                                <span
                                    onClick={() =>
                                        setIsTermsAndConditionModalOpen(true)
                                    }
                                >
                                    Terms
                                </span>
                                <span
                                    onClick={() =>
                                        setIsPrivacyPolicyModalOpen(true)
                                    }
                                >
                                    Privacy
                                </span>
                            </div>
                        </div>

                        {/* Navigation */}
                        <div className="flex flex-wrap justify-center sm:grid sm:grid-cols-2 gap-4 text-sm sm:text-center">
                            <p
                                className="text-gray-600 hover:text-gray-900"
                                onClick={() => navigate("/")}
                            >
                                Home
                            </p>
                            <p
                                className="text-gray-600 hover:text-gray-900"
                                onClick={() => navigate("/jobs")}
                            >
                                Jobs
                            </p>
                            <p
                                className="text-gray-600 hover:text-gray-900"
                                onClick={() => navigate("/freelancers")}
                            >
                                Freelancers
                            </p>
                            <p
                                className="text-gray-600 hover:text-gray-900"
                                onClick={() => navigate("/talents")}
                            >
                                Talents
                            </p>
                            <p
                                className="text-gray-600 hover:text-gray-900"
                                onClick={() => navigate("/gigs")}
                            >
                                Gigs
                            </p>
                        </div>

                        {/* Social Icons */}
                        <div className="flex justify-center space-x-6">
                            <a
                                href="https://x.com/PortfoliOOo_web?t=uir1OZD2_qCZJJPmm1LFSw&s=08"
                                className="text-gray-600 hover:text-gray-900"
                            >
                                <img
                                    src={logo.twitterLogo}
                                    className="size-4"
                                    alt="Twitter"
                                />
                            </a>
                            <a
                                href="https://www.facebook.com/share/18UeBftwgM/"
                                className="text-gray-600 hover:text-gray-900"
                            >
                                <img
                                    src={logo.fbLogo}
                                    className="size-4"
                                    alt="FB"
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/portfoliooo.official/?igsh=MXRhYXRwNmJ5bXlhaw%3D%3D#"
                                className="text-gray-600 hover:text-gray-900"
                            >
                                <img
                                    src={logo.instagramLogo}
                                    className="size-4"
                                    alt="Instagram"
                                />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/portfoliooo/"
                                className="text-gray-600 hover:text-gray-900 transition-colors cursor-pointer scale-animation"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                >
                                    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>

                {/* Desktop Layout */}
                <div className="hidden lg:block">
                    <div className="flex justify-between items-start">
                        {/* Left Section */}
                        <div className="flex flex-col justify-start items-start">
                            <img src={Logo} alt="Logo" className="h-14 w-72" />
                            <div className="flex items-center justify-start space-x-4 text-sm text-gray-500 mt-5">
                                <span>© 2025 PortfoliOOo</span>
                                <span
                                    className="cursor-pointer"
                                    onClick={() =>
                                        setIsTermsAndConditionModalOpen(true)
                                    }
                                >
                                    Terms
                                </span>
                                {/* <span>•</span> */}
                                <span
                                    className="cursor-pointer"
                                    onClick={() =>
                                        setIsPrivacyPolicyModalOpen(true)
                                    }
                                >
                                    Privacy
                                </span>
                            </div>
                        </div>

                        {/* Right Section */}
                        <div className="flex flex-col justify-between">
                            {/* Social Icons */}
                            <div className="flex space-x-4 justify-end h-[75px]">
                                <a
                                    href="https://x.com/PortfoliOOo_web?t=uir1OZD2_qCZJJPmm1LFSw&s=08"
                                    className="text-gray-600 hover:text-gray-900 transition-colors cursor-pointer scale-animation"
                                >
                                    <img
                                        src={logo.twitterLogo}
                                        className="size-4"
                                        alt="Twitter"
                                    />
                                </a>
                                <a
                                    href="https://www.facebook.com/share/18UeBftwgM/"
                                    className="text-gray-600 hover:text-gray-900 transition-colors cursor-pointer scale-animation"
                                >
                                    <img
                                        src={logo.fbLogo}
                                        className="size-4"
                                        alt="FB"
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/portfoliooo.official/?igsh=MXRhYXRwNmJ5bXlhaw%3D%3D#"
                                    className="text-gray-600 hover:text-gray-900 transition-colors cursor-pointer scale-animation"
                                >
                                    <img
                                        src={logo.instagramLogo}
                                        className="size-4"
                                        alt="Instagram"
                                    />
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/portfoliooo/"
                                    className="text-gray-600 hover:text-gray-900 transition-colors cursor-pointer scale-animation"
                                >
                                    <img
                                        src={logo.linkedinLogo}
                                        className="size-4"
                                        alt="LinkedIn"
                                    />
                                </a>
                            </div>

                            {/* Navigation */}
                            <div className="">
                                <div className="flex items-center space-x-4">
                                    <p
                                        className="text-sm text-gray-600 hover:text-gray-900 cursor-pointer"
                                        onClick={() => navigate("/")}
                                    >
                                        Home
                                    </p>
                                    <p
                                        className="text-sm text-gray-600 hover:text-gray-900 cursor-pointer"
                                        onClick={() => navigate("/jobs")}
                                    >
                                        Jobs
                                    </p>
                                    <p
                                        className="text-sm text-gray-600 hover:text-gray-900 cursor-pointer"
                                        onClick={() => navigate("/freelancers")}
                                    >
                                        Freelancers
                                    </p>
                                    <p
                                        className="text-sm text-gray-600 hover:text-gray-900 cursor-pointer"
                                        onClick={() => navigate("/talents")}
                                    >
                                        Talents
                                    </p>
                                    <p
                                        className="text-sm text-gray-600 hover:text-gray-900 cursor-pointer"
                                        onClick={() => navigate("/gigs")}
                                    >
                                        Gigs
                                    </p>
                                </div>
                                {/* <div className="flex flex-col space-y-4">
									<p className="text-sm text-gray-600 hover:text-gray-900">
										Tags
									</p>
									<p className="text-sm text-gray-600 hover:text-gray-900">
										Places
									</p>
									<p className="text-sm text-gray-600 hover:text-gray-900">
										Resources
									</p>
								</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <OverlayFragment
                isOpen={termsAndConditionModalOpen}
                setIsOpen={setIsTermsAndConditionModalOpen}
                children={<TermsAndConditions />}
                title={"Terms And Conditions"}
                size={"2xl"}
            />

            <OverlayFragment
                isOpen={isPrivacyPolicyModalOpen}
                setIsOpen={setIsPrivacyPolicyModalOpen}
                children={<PrivacyPolicy />}
                title={"Privacy Policy"}
                size={"2xl"}
            />
        </footer>
    );
};

export default Footer;
