import React, {
    createContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import FormikStateMonitor from "../../../common/FormikStateMonitor";
import FormikFormGenerator from "../../../utils/FormikFormGenerator";
import ResumeContactInfo from "./ResumeContactInfo";
import ResumeEducationInfo from "./ResumeEducationInfo";
import ResumeEmploymentInfo from "./ResumeEmploymentInfo";
import ResumePersonalInfo from "./ResumePersonalInfo";
import ResumeProjectInfo from "./ResumeProjectInfo";
import ResumeTemplate from "./ResumeTemplate";
import CommonButton from "../../../common/components/CommonButton";
import { DocumentUpload } from "iconsax-react";
import { useSelector } from "react-redux";
import useGlobalMutation from "../../../hooks/useGlobalMutation";
import { ROUTES } from "../../../Routes";
import { RESUME_TYPE } from "../../../constant/constants";
import { useNavigate, useParams } from "react-router-dom";
import useGlobalQuery from "../../../hooks/useGlobalQuery";
import Loader from "../../../common/Loader/Loader";
import { handleDownloadPdf, handleSavePdfToFile } from "../../../services/pdfService";
import { FiDownload } from "react-icons/fi";

export const ResumeBuilderContext = createContext();

const ResumeBuilder = ({ isUpdate }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const [formData, setFormData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [downloadingPdf, setDownloadingPdf] = useState(false);
    const { id } = useParams();

    const resumeRef = useRef();

    const navigate = useNavigate();

    const { queryData, isLoading: gettingAllResumes } = useGlobalQuery({
        url: ROUTES.RESUME.ALL,
        methodType: "POST",
        data: { id },
        queryKey: ["my-resume", id],
    });

    const { runMutation, mutationLoading, isMutationSucceeded } =
        useGlobalMutation({
            url: ROUTES.RESUME.CRUD,
            methodType: isUpdate ? "PUT" : "POST",
            isFormData: true,
            excludedIndexKeys: ["resume"],
        });

    const initialData = useMemo(() => {
        return queryData?.data?.[0];
    }, [queryData]);

    useEffect(() => {
        if (isMutationSucceeded) {
            setIsLoading(false);
            navigate(-1);
        }
    }, [isMutationSucceeded, navigate]);

    if (isUpdate && gettingAllResumes) {
        return <Loader />;
    }

    console.log("formData", formData);

    return (
        <ResumeBuilderContext.Provider
            value={{
                formData,
                setFormData,
            }}
        >
            <div className="text-white gradient-blue mb-0 p-4 sm:p-10 pb-24 sm:pb-32 pt-12 sm:pt-20 text-start">
                <h1 className="text-xl font-bold max-w-7xl mx-auto">
                    Build Your Resume
                </h1>
                <p className="max-w-7xl mx-auto">
                    We help you build the resume in just minutes
                </p>
            </div>
            <FormikFormGenerator
                formClassName="flex flex-col gap-5 h-full mb-10 bg-white max-w-7xl mx-auto -mt-20 py-8 px-10 rounded-xl border"
                initialValues={{
                    name: isUpdate
                        ? initialData?.others?.name
                        : profileDetails?.firstname +
                              " " +
                              profileDetails?.lastname ?? "",
                    title: isUpdate
                        ? initialData?.others?.title
                        : profileDetails?.title ?? "",
                    skills: isUpdate
                        ? initialData?.others?.skills
                        : profileDetails?.skills ?? [],
                    description: isUpdate
                        ? initialData?.others?.description
                        : profileDetails?.description ?? "",
                    email: isUpdate
                        ? initialData?.others?.email
                        : profileDetails?.email ?? "",
                    education: isUpdate
                        ? initialData?.others?.education ?? []
                        : profileDetails?.educationDetails ?? [],
                    employment: isUpdate
                        ? initialData?.others?.employment
                        : profileDetails?.employmentDetails ?? [],
                    projects: isUpdate ? initialData?.others?.projects : []
                }}
                handleSubmitFunction={async (values) => {
                    setIsLoading(true);
                    const pdfFile = await handleSavePdfToFile({ ref: resumeRef });
                    runMutation({
                        isPriorityDataAvailable: true,
                        priorityData: {
                            type: RESUME_TYPE.CUSTOM,
                            others: {
                                ...formData,
                            },
                            resume: pdfFile,
                            id,
                        },
                    });
                }}
            >
                <FormikStateMonitor onChange={setFormData} />

                <div className="flex gap-5">
                    <div className="h-full">
                        <div className="space-y-10 max-w-lg max-h-[118vh] overflow-auto pr-5">
                            <ResumePersonalInfo />
                            <ResumeContactInfo />
                            <ResumeEducationInfo />
                            <ResumeEmploymentInfo />
                            <ResumeProjectInfo />
                        </div>
                    </div>
                    <div className="bg-gray-100 p-10 rounded-lg">
                        <ResumeTemplate ref={resumeRef} />
                    </div>
                </div>

                <div className="mt-5 flex items-center justify-end gap-5">
                    <CommonButton
                        title={"Download Resume"}
                        isRounded={true}
                        Icon={FiDownload}
                        isWithIcon={true}
                        buttonStyle={"max-w-52"}
                        handleFunction={async () => {
                            setDownloadingPdf(true);
                            await handleDownloadPdf({ ref: resumeRef });
                            setDownloadingPdf(false);
                        }}
                        loading={downloadingPdf}
                        disabled={downloadingPdf}
                    />
                    <CommonButton
                        title={"Save Resume"}
                        isGradient={true}
                        isRounded={true}
                        Icon={DocumentUpload}
                        isWithIcon={true}
                        buttonStyle={"max-w-52 "}
                        type={"submit"}
                        disabled={mutationLoading || isLoading}
                        loading={mutationLoading || isLoading}
                    />
                </div>
            </FormikFormGenerator>
        </ResumeBuilderContext.Provider>
    );
};

export default ResumeBuilder;
