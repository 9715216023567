import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
} from "@nextui-org/react";
import { useQueryClient } from "@tanstack/react-query";
import { Like1, More } from "iconsax-react";
import {
    ArrowLeft,
    ArrowRight,
    Bookmark,
    MapPin,
    MessageSquare,
    Send,
} from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../common/components/DeleteModal";
import ExpandableDiv from "../../../common/components/ExpandableDiv";
import OverlayFragment from "../../../common/components/OverlayFragment";
import Spinner from "../../../common/components/Spinner";
import {
    formatDate,
    GenerateProfileImg,
    isVideo,
} from "../../../common/ReusableFunctions";
import { ROLES } from "../../../constant/constants";
import { useUpdateMyProfile } from "../../../hooks/Authentication/AuthenticationHooks";
import useGlobalMutation from "../../../hooks/useGlobalMutation";
import { ROUTES } from "../../../Routes";
import CommentSection from "../../Post/components/CommentSection";
import AddSocialPost from "../../Post/pages/AddSocialPost";
import { bgColor, badgeColor } from "../../../common/components/UserRoleBadge";
import AddArticle from "../../Post/pages/AddArticle";

const PostCard = ({ data }) => {
    const { profileDetails } = useSelector((state) => state.profile);
    const queryClient = useQueryClient();

    const [savedPostsIds, setSavedPostsIds] = useState(
        profileDetails?.savedPosts || []
    );
    const [currentIndex, setCurrentIndex] = useState(0);
    const [pendingMutation, setPendingMutation] = useState(false);
    const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLiked, setIsLiked] = useState(
        data?.likedBy?.includes(profileDetails?._id)
    );
    const [likedCount, setLikedCount] = useState(data?.likedBy?.length ?? 0);
    const [commentCount, setCommentCount] = useState(
        data?.comments?.length ?? 0
    );

    const isSaved = savedPostsIds?.includes(data?._id);
    const navigate = useNavigate();

    const { runMutation, mutationLoading } = useUpdateMyProfile({
        data: { savedPosts: savedPostsIds },
    });

    const userRole = useMemo(() => data?.userDetails?.role?.[0], [data]);

    const { runMutation: likePost, mutationLoading: likingPost } =
        useGlobalMutation({
            url: ROUTES.POST.LIKE_POST,
            methodType: "POST",
            data: { id: data?._id },
            queriesToInvalidate: ["all-posts"],
        });

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? data?.assetImages?.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === data?.assetImages?.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleSavePost = (postId) => {
        setSavedPostsIds((prevIds) => {
            if (prevIds && !prevIds?.includes(postId)) {
                setPendingMutation(true);
                return [...prevIds, postId];
            }
            return prevIds;
        });
    };

    const handleUnSavePost = (postId) => {
        setSavedPostsIds((prevIds) => {
            if (prevIds.includes(postId)) {
                setPendingMutation(true);
                return prevIds.filter((id) => id !== postId);
            }
            return prevIds;
        });
    };

    useEffect(() => {
        if (profileDetails?.savedPosts) {
            setSavedPostsIds(profileDetails.savedPosts);
        }
    }, [profileDetails]);

    useEffect(() => {
        if (pendingMutation) {
            runMutation();
            setPendingMutation(false);
        }
    }, [pendingMutation, runMutation, queryClient]);

    const handleRedirection = () => {
        switch (data?.userDetails?.role?.[0]) {
            case ROLES.FREELANCER:
                return navigate(
                    `/profile/freelancer/${data?.userDetails?._id}`
                );
            case ROLES.EMPLOYEE:
                return navigate(`/profile/employee/${data?.userDetails?._id}`);
            case ROLES.CLIENT:
                return navigate(`/profile/client/${data?.userDetails?._id}`);
            case ROLES.HR:
                return navigate(`/profile/hr/${data?.userDetails?._id}`);
            default:
                return null;
        }
    };

    return (
        <div className="bg-white rounded-xl border shadow-sm p-4">
            <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-3">
                    <div className="relative">
                        <img
                            src={GenerateProfileImg({
                                imgUrl: data?.userDetails?.imgUrl,
                                firstname: data?.userDetails?.firstname,
                                lastname: data?.userDetails?.lastname,
                            })}
                            alt="Profile"
                            className="w-12 h-12 rounded-full object-cover cursor-pointer"
                            onClick={handleRedirection}
                        />
                    </div>

                    <div>
                        <div className="flex items-center space-x-2">
                            <h2 className="font-medium flex gap-3">
                                <p
                                    className="cursor-pointer"
                                    onClick={handleRedirection}
                                >
                                    {data?.userDetails?.firstname}{" "}
                                    {data?.userDetails?.lastname}
                                </p>

                                <div
                                    className={`flex items-center gap-1.5 ${bgColor?.[userRole]} py-1 px-2 rounded-lg text-xs`}
                                >
                                    <div
                                        className={`size-1.5 ${badgeColor?.[userRole]} rounded-full`}
                                    ></div>
                                    <span className="text-xs capitalize">
                                        {userRole === "hr" ? "recruiter" : userRole}
                                    </span>
                                </div>
                            </h2>
                        </div>
                        <div className="flex items-center text-xs sm:text-sm text-gray-500 mt-0.5">
                            <MapPin className="w-3 h-3 mr-1" />
                            <span>
                                {data?.userDetails?.location ?? "Anywhere"}{" "}
                                {"•"}{" "}
                                {formatDate({ dateString: data?.createdAt })}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="flex items-center gap-3">
                    {/* <div className="flex items-center space-x-2">
                        <button
                            className="p-2 hover:bg-gray-100 rounded-full"
                            disabled={mutationLoading}
                            onClick={() => {
                                isSaved
                                    ? handleUnSavePost(data?._id)
                                    : handleSavePost(data?._id);
                            }}
                        >
                            <Bookmark
                                className={`w-5 h-5 text-gray-500 ${
                                    isSaved && "fill-blue-500"
                                }`}
                            />
                        </button>
                    </div> */}

                    {profileDetails?.email === data?.userEmail && (
                        <div>
                            <Dropdown>
                                <DropdownTrigger>
                                    <button className="hover:bg-gray-100 p-1.5 rounded-full">
                                        <More />
                                    </button>
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Static Actions">
                                    <DropdownItem
                                        key="new"
                                        onPress={() => setIsEditOpen(true)}
                                    >
                                        Edit Post
                                    </DropdownItem>
                                    <DropdownItem
                                        key="delete"
                                        className="text-danger"
                                        color="danger"
                                        onPress={() => setIsDeleteOpen(true)}
                                    >
                                        Delete Post
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    )}
                </div>
            </div>

            <div className="mb-4">
                <ExpandableDiv
                    text={data?.body}
                    lineClampStyle={"line-clamp-6"}
                />
            </div>

            {data?.type === "ARTICLES" ? (
                <img
                    src={data?.banner?.[currentIndex]}
                    alt="Post"
                    className="rounded-lg h-full w-full"
                    loading="lazy"
                />
            ) : (
                data?.assetImages?.length > 0 && (
                    <div className="bg-gray-200 w-full rounded-lg flex items-center justify-between relative">
                        {data?.assetImages?.length > 1 && (
                            <button
                                className={`absolute top-44 left-3 text-gray-400 bg-gray-100 rounded-full p-1 hover:text-gray-600 focus:outline-none ${
                                    currentIndex === 0
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                }`}
                                onClick={handlePrevious}
                                disabled={currentIndex === 0}
                            >
                                <ArrowLeft size={32} />
                            </button>
                        )}

                        {isVideo({
                            url: data?.assetImages?.[currentIndex],
                        }) ? (
                            <video
                                className="w-full h-full object-cover rounded-lg"
                                src={data?.assetImages?.[currentIndex]}
                                controls
                            />
                        ) : (
                            <img
                                src={data?.assetImages?.[currentIndex]}
                                alt="Post"
                                className="rounded-lg h-full w-full"
                                loading="lazy"
                            />
                        )}

                        {data?.assetImages?.length > 1 && (
                            <button
                                className={`absolute top-44 right-3 text-gray-400 bg-gray-100 rounded-full p-1 hover:text-gray-600 focus:outline-none ${
                                    currentIndex ===
                                    data?.assetImages?.length - 1
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                }`}
                                onClick={handleNext}
                                disabled={
                                    currentIndex ===
                                    data?.assetImages?.length - 1
                                }
                            >
                                <ArrowRight size={32} />
                            </button>
                        )}
                    </div>
                )
            )}

            {/* <div className="flex flex-wrap gap-2 mb-4">
				{[
					"icon",
					"lettering",
					"digital painting",
					"infographic design",
					"typography",
					"design",
				].map((skill) => (
					<span
						key={skill}
						className="px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-xs sm:text-sm"
					>
						{skill}
					</span>
				))}
				<span className="px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-xs sm:text-sm">
					+2 skills
				</span>
			</div> */}

            <div className="flex items-center space-x-5 pt-2 border-t text-sm">
                <div>
                    <button
                        className={`flex items-center space-x-2 text-gray-600  hover:text-gray-900 `}
                        onClick={() => {
                            if (isLiked) {
                                setIsLiked(false);
                                setLikedCount((prev) => prev - 1);
                            } else {
                                setIsLiked(true);
                                setLikedCount((prev) => prev + 1);
                            }
                            likePost();
                        }}
                    >
                        <Like1
                            className={`size-5 ${
                                isLiked && " fill-black text-white"
                            }`}
                        />
                        <span>{likedCount} Likes</span>
                    </button>
                </div>

                <button
                    className="flex items-center space-x-2 text-gray-600 hover:text-gray-900"
                    onClick={() => setSideDrawerOpen(true)}
                >
                    <MessageSquare className="size-4" />
                    <span>{commentCount ?? 0} Comments</span>
                </button>
                <button
                    className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
                    disabled={mutationLoading}
                    onClick={() => {
                        isSaved
                            ? handleUnSavePost(data?._id)
                            : handleSavePost(data?._id);
                    }}
                >
                    <Bookmark
                        className={`size-4 text-gray-500 ${
                            isSaved && "fill-black"
                        }`}
                    />
                    {isSaved ? "Saved" : "Save"}
                </button>
            </div>

            <CommentsSideDrawer
                isOpen={sideDrawerOpen}
                setIsOpen={setSideDrawerOpen}
                postId={data?._id}
                setCommentCount={setCommentCount}
            />

            <OverlayFragment
                isOpen={isEditOpen}
                setIsOpen={setIsEditOpen}
                children={
                    data?.type === "ARTICLES" ? (
                        <AddArticle
                            assets={data?.banner}
                            setIsOpen={setIsEditOpen}
                            data={data}
                            isUpdate={true}
                        />
                    ) : (
                        <AddSocialPost
                            isUpdate={true}
                            setIsOpen={setIsEditOpen}
                            assets={data?.assetImages}
                            data={data}
                        />
                    )
                }
                title={data?.type === "ARTICLES" ? "Edit Article" : "Edit Post"}
                size={"3xl"}
            />

            <OverlayFragment
                isOpen={isDeleteOpen}
                setIsOpen={setIsDeleteOpen}
                children={
                    <DeleteModal
                        url={ROUTES.POST.CREATE_POST}
                        data={{ id: data?._id }}
                        title={"Delete Post"}
                        type={"Post"}
                        setIsOpen={setIsDeleteOpen}
                        queriesToInvalidate={["all-posts"]}
                    />
                }
            />
        </div>
    );
};

const CommentsSideDrawer = ({ isOpen, setIsOpen, postId, setCommentCount }) => {
    return (
        <Drawer isOpen={isOpen} onOpenChange={setIsOpen}>
            <DrawerContent>
                {(onClose) => (
                    <>
                        <DrawerHeader className="flex flex-col gap-1">
                            Discussions
                        </DrawerHeader>
                        <DrawerBody>
                            <CommentSection
                                postId={postId}
                                setCommentCount={setCommentCount}
                            />
                        </DrawerBody>
                    </>
                )}
            </DrawerContent>
        </Drawer>
    );
};

export default PostCard;
