import React, { useRef, useEffect, createContext, useState } from "react";
import { gsap } from "gsap";
import SearchBar from "../../pages/Landing/components/SearchBar";
import TrendingSearches from "../../pages/Landing/components/TrendingSearches";
import Dot from "../Dot";
import { logo } from "../../constant/constants";

export const HeaderContext = createContext({
    searchKey: "",
    setSearchKey: () => {},
});

const EyeTracker = () => {
    const eyeRef = useRef([]);
    const pupilRef = useRef([]);
    const [searchKey, setSearchKey] = useState();

    useEffect(() => {
        const handleMouseMove = (e) => {
            const { clientX, clientY } = e;

            eyeRef.current.forEach((eye, index) => {
                const eyeBounds = eye?.getBoundingClientRect();
                const centerX = eyeBounds?.left + eyeBounds?.width / 2;
                const centerY = eyeBounds?.top + eyeBounds?.height / 2;

                const angle = Math.atan2(clientY - centerY, clientX - centerX);
                const distance = Math.min(
                    eyeBounds?.width / 4,
                    Math.hypot(clientX - centerX, clientY - centerY) / 2
                );

                const pupilX = Math?.cos(angle) * distance;
                const pupilY = Math?.sin(angle) * distance;

                gsap.to(pupilRef.current[index], {
                    x: pupilX,
                    y: pupilY,
                    duration: 0.2,
                });
            });
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    return (
        <HeaderContext.Provider
            value={{
                searchKey,
                setSearchKey
            }}
        >
            <div className="relative p-4 sm:p-6 md:p-8 lg:p-10 border rounded-lg flex flex-col items-center justify-between bg-white/95 backdrop-blur-sm shadow-md">
                <div className="flex items-center text-5xl sm:text-8xl font-bold">
                    {/* Text */}
                    <span>Portfoli</span>

                    {/* Eyes Container */}
                    <div className="relative flex items-center ml-1 sm:ml-0">
                        {[0, 1].map((_, i) => (
                            <div
                                key={i}
                                className="relative"
                                ref={(el) => (eyeRef.current[i] = el)}
                            >
                                {/* Eye */}
                                <div className="size-9 sm:size-14 mt-2 sm:mt-5 bg-white border-black border-3 rounded-full flex justify-center items-center">
                                    {/* Pupil */}
                                    <div
                                        ref={(el) => (pupilRef.current[i] = el)}
                                        className="relative size-5 sm:size-6 bg-black rounded-full"
                                    >
                                        {/* White Highlight */}
                                        <div className="absolute top-[2px] left-[3px] w-1.5 h-1.5 bg-white rounded-full"></div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <img src={logo.LeftEyebrow} alt="Left Eyebrow" className="absolute top-1 left-3 max-w-7" />
                        <img src={logo.RightEyebrow} alt="Right Eyebrow" className="absolute -top-1 right-0 max-w-7" />
                    </div>
                    <Dot className={"size-3 sm:size-4 mt-8 sm:mt-12"} />
                </div>
                <div className="space-y-4">
                    <p className="text-xs sm:text-sm text-center text-gray-600 max-w-lg mx-auto px-4">
                        Browse talent, discover projects, and turn ideas into
                        realities with a community that celebrates creativity.
                    </p>

                    <div className="w-full space-y-4 sm:space-y-6 center-vertical">
                        <SearchBar maxWidth={"max-w-72 sm:max-w-xl"} />
                        <TrendingSearches />
                    </div>
                </div>
            </div>
        </HeaderContext.Provider>
    );
};

export default EyeTracker;
