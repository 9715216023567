import React from "react";
import { Edit } from "iconsax-react";
import LogoWhite from "../../../../assets/LogoWhite.svg";
import FeatureMainImage from "../../../../assets/LandingPage/FeatureSectionMainImage.png";
import WhiteDots from "../../../../assets/LandingPage/FeatureSectionBgCircles.svg";
import { featureData } from "./featureData";
import { useNavigate } from "react-router-dom";

const Features = () => {
	const navigate = useNavigate();

	return (
		<div className="gradient-blue mx-5 sm:max-w-7xl sm:mx-auto p-2 md:p-10 rounded-lg flex flex-col relative -mt-20">
			<img
				src={LogoWhite}
				alt="Logo"
				className="w-32 md:w-40 px-2 pt-2 sm:px-0 sm:pt-0 md:mb-6 lg:mb-0"
			/>
			<div className="w-full sm:max-w-7xl mx-auto mt-4 md:mt-10 p-4 sm:p-8">
				<h1 className="text-lg sm:text-3xl md:text-4xl text-start text-white font-semibold">
					Features we are known for...
				</h1>
				<div className="flex flex-col-reverse lg:flex-row justify-between items-start mt-8 md:mt-10">
					<div className="flex flex-col gap-8 justify-center items-start">
						<FeatureList />

						<div className="flex justify-start gap-5 text-sm w-80">
							<button className="bg-white w-40 text-center text-blue-500 px-5 py-1.5 rounded-full font-medium"
							onClick={() => navigate("/register")}
							>
								Sign Up
							</button>
							{/* <button className="bg-white text-blue-500 px-5 py-1.5 rounded-full font-medium">
								Learn More
							</button> */}
						</div>
					</div>

					<img
						src={FeatureMainImage}	
						alt="Feature Main"
						className="w-full max-w-[80%] sm:max-w-[50%] lg:max-w-[30vw] h-auto -mt-12 md:-mt-20 z-10 mb-4 lg:mb-0"
					/>
				</div>
			</div>
			<DotsCollection className="absolute top-24 md:top-32 right-10 md:right-28 opacity-50 hidden lg:block" />
			<DotsCollection className="absolute -bottom-12 md:-bottom-16 left-[50%] md:left-[600px] opacity-50 hidden lg:block" />
		</div>
	);
};

const FeatureList = () => {
	return (
		<div className="mt-4 md:mt-5 text-start flex flex-col gap-4 sm:gap-5 max-w-xs sm:max-w-full w-full">
			{featureData.map((data) => (
				<div key={data.title} className="flex items-start gap-3 sm:gap-4">
					<div className="text-blue-500 bg-[#EEEEEE] p-1 sm:p-1.5 rounded-full">
						<Edit className="size-3 sm:size-4 w-full" />
					</div>
					<div className="flex flex-col gap-1">
						<p className="text-white text-sm sm:text-base">{data.title}</p>
						<p className="text-[#DFDFDF] text-xs sm:text-sm">
							{data.description}
						</p>
					</div>
				</div>
			))}
		</div>
	);
};

const DotsCollection = ({ className }) => {
	return (
		<div className={className}>
			<div className="flex gap-2 sm:gap-3">
				<img src={WhiteDots} alt="Dots" className="w-3 sm:w-auto" />
				<img src={WhiteDots} alt="Dots" className="w-3 sm:w-auto" />
			</div>
			<div className="flex gap-2 sm:gap-3 mt-2 sm:mt-3">
				<img src={WhiteDots} alt="Dots" className="w-3 sm:w-auto" />
				<img src={WhiteDots} alt="Dots" className="w-3 sm:w-auto" />
			</div>
		</div>
	);
};

export default Features;
