"use client";

import { Icon } from "@iconify/react";
import { Button, Card, CardBody, CardHeader, Chip, ScrollShadow, Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import NotificationItems from "./NotificatoinItems";
import Loader from "../../../../common/Loader/Loader";
import { ROUTES } from "../../../../Routes";
import useGlobalMutation from "../../../../hooks/useGlobalMutation";

const NotificationCard = ({ data, activeTab, setActiveTab, isLoading }) => {
    const [activeNotifications, setActiveNotifications] = useState();

    const { runMutation: markAllAsRead, mutationLoading: markingAllAsRead } = useGlobalMutation({
        url: ROUTES.NOTIFICATION.READ_ALL,
        methodType: "GET",
        queriesToInvalidate: ["all-notifications"],
    });

    useEffect(() => {
        if (activeTab === "unread") {
            const unreadData = data?.data?.filter((item) => item.readStatus === false);
            setActiveNotifications(unreadData ?? []);
        } else {
            setActiveNotifications(data?.data);
        }
    }, [data, activeTab]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Card className="w-full max-w-[520px] min-w-[320px]">
            <CardHeader className="flex flex-col px-0 pb-0">
                <div className="flex w-full items-center justify-between px-5 py-2">
                    <div className="inline-flex items-center gap-1">
                        <h4 className="inline-block align-middle text-large font-medium">Notifications</h4>
                        <Chip size="sm" variant="flat">
                            {data?.totalCount}
                        </Chip>
                    </div>
                    <Button
                        className="h-8 px-3"
                        color="primary"
                        radius="full"
                        variant="light"
                        isLoading={markingAllAsRead}
                        isDisabled={markingAllAsRead}
                        onPress={() => markAllAsRead()}
                    >
                        Mark all as read
                    </Button>
                </div>
                <Tabs
                    aria-label="Notifications"
                    classNames={{
                        base: "w-full",
                        tabList: "gap-6 px-6 py-0 w-full relative rounded-none border-b border-divider",
                        cursor: "w-full",
                        tab: "max-w-fit px-2 h-12",
                    }}
                    color="primary"
                    selectedKey={activeTab}
                    variant="underlined"
                    onSelectionChange={(selected) => setActiveTab(selected)}
                >
                    <Tab
                        key="all"
                        title={
                            <div className="flex items-center space-x-2">
                                <span>All</span>
                                <Chip size="sm" variant="flat">
                                    {data?.totalCount}
                                </Chip>
                            </div>
                        }
                    />
                    <Tab
                        key="unread"
                        title={
                            <div className="flex items-center space-x-2">
                                <span>Unread</span>
                                <Chip size="sm" variant="flat">
                                    {data?.unreadCount}
                                </Chip>
                            </div>
                        }
                    />
                    <Tab
                        key="transactions"
                        title={
                            <div className="flex items-center space-x-2">
                                <span>Transactions</span>
                            </div>
                        }
                    />
                </Tabs>
            </CardHeader>
            <CardBody className="w-full gap-0 p-0">
                <ScrollShadow className="h-[400px] w-full">
                    {activeNotifications?.length > 0 ? (
                        activeNotifications.map((notification) => (
                            <NotificationItems key={notification._id} data={notification} />
                        ))
                    ) : (
                        <div className="flex h-full w-full flex-col items-center justify-center gap-2">
                            <Icon className="text-default-400" icon="solar:bell-off-linear" width={40} />
                            <p className="text-small text-default-400">No notifications yet.</p>
                        </div>
                    )}
                </ScrollShadow>
            </CardBody>
        </Card>
    );
};

export default NotificationCard;
