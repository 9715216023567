import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/react";
import { Add, Minus } from "iconsax-react";

const faqs = [
    // For Freelancers
    {
        question: "How do I create a freelancer profile?",
        answer: "Simply sign up, add your skills, portfolio, and set your availability.",
    },
    {
        question: "How do I get projects on PortfoliOOo?",
        answer: "Browse available projects, apply, or get hired directly by clients.",
    },
    {
        question: "How does the payment system work?",
        answer: "Payments are secured and released upon project completion as per agreed milestones.",
    },
    {
        question: "Can I set my own rates?",
        answer: "Yes! You have complete flexibility in setting your pricing for services.",
    },
    {
        question: "How do I build credibility on PortfoliOOo?",
        answer: "Deliver quality work, get good ratings, and showcase a strong portfolio.",
    },

    // For Clients
    {
        question: "How do I hire a freelancer?",
        answer: "Post a project, browse freelancers, or invite specific talent to apply.",
    },
    {
        question: "What types of freelancers are available?",
        answer: "PortfoliOOoo has designers, developers, writers, marketers, and more!",
    },
    {
        question: "How are payments handled?",
        answer: "Funds are securely held in escrow and released once work is approved.",
    },
    {
        question: "Can I manage multiple freelancers in a project?",
        answer: "Yes! You can create teams, assign roles, and track progress seamlessly.",
    },
    {
        question: "How does PortfoliOOo ensure quality?",
        answer: "Verified profiles, ratings, and AI-driven recommendations help you find top talent.",
    },
];

export default function FAQ() {
    return (
        <div className="px-5 sm:px-0">
            <div className="mx-auto max-w-7xl">
                <div className="mx-auto max-w-4xl divide-y divide-black">
                    <div className="space-y-2 md:space-y-3 text-start sm:text-center">
                        <h3 className="text-primary-purple text-xs md:text-base uppercase font-semibold text-center">
                            FAQ'S
                        </h3>
                        <h2 className="text-lg sm:text-3xl md:text-4xl font-semibold uppercase text-center">
                            Have Some Doubts To Get Started ?
                        </h2>
                    </div>

                    <dl className="mt-5 space-y-6 divide-y divide-black">
                        {faqs.map((faq) => (
                            <Disclosure
                                key={faq.question}
                                as="div"
                                className="pt-6"
                            >
                                <dt>
                                    <DisclosureButton className="group flex w-full items-start justify-between text-left">
                                        <span className="text-base/7 font-semibold">
                                            {faq.question}
                                        </span>
                                        <span className="ml-6 flex h-7 items-center">
                                            <Add
                                                aria-hidden="true"
                                                className="h-6 w-6 group-data-[open]:hidden"
                                            />
                                            <Minus
                                                aria-hidden="true"
                                                className="h-6 w-6 [.group:not([data-open])_&]:hidden"
                                            />
                                        </span>
                                    </DisclosureButton>
                                </dt>
                                <DisclosurePanel as="dd" className="mt-2">
                                    <p className="text-xs sm:text-base/7 text-gray-500 flex gap-2 text-start">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="size-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m16.49 12 3.75 3.75m0 0-3.75 3.75m3.75-3.75H3.74V4.499"
                                            />
                                        </svg>
                                        {faq.answer}
                                    </p>
                                </DisclosurePanel>
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
}
