import { cn, ScrollShadow } from "@nextui-org/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import { storeDataInLocalStorage } from "../../hooks/useLocalStorage";
import { customActions } from "../../store/custom.slice";
import NextSidebar from "./NextSidebar";
import { sectionItemsWithTeams } from "./SidebarItems";

export default function Sidebar() {
    const { isSidebarOpen } = useSelector((state) => state.custom);

    const isMobile = useMediaQuery("(max-width: 768px)");

    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const allPaths = pathname.split("/");

    useEffect(() => {
        if (isMobile) {
            storeDataInLocalStorage({ key: "isSidebarOpen", value: true });
            dispatch(customActions.setIsSidebarOpen(true));
        }
    }, [isMobile, dispatch]);

    return (
        <div className="flex   w-full  bg-white m-5 border-2 h-[85vh] overflow-hidden rounded-lg  dark:bg-dark-900">
            <div
                className={cn(
                    `relative h-[95vh] bg-primary-gray-50  flex  my-auto max-h-screen overflow-hidden  flex-1 flex-col   rounded-lg  border-divider p-0 transition-[transform,opacity,margin] duration-250 ease-in-out ${
                        isSidebarOpen
                            ? "min-w-60 max-w-[125px] ml-2"
                            : "max-w-[100px]"
                    }`,
                    {
                        "": !isSidebarOpen,
                    }
                )}
            >
                <div className="flex items-center gap-2 px-2 pt-4">
                    {/* <div className="flex h-8 w-8 items-center justify-center rounded-full bg-foreground">
                        <BrandIcon className="text-background" />
                    </div> */}
                    {isSidebarOpen && (
                        <span className=" font-medium uppercase tracking-widest">
                            Admin CMS
                        </span>
                    )}
                </div>

                <ScrollShadow
                    hideScrollBar
                    className="-mr-6 h-full max-h-full py-6 pr-6"
                >
                    <NextSidebar
                        defaultSelectedKey={allPaths[2]}
                        items={sectionItemsWithTeams}
                    />
                </ScrollShadow>
            </div>
            <div className="w-full flex-1 flex-col px-4  max-h-screen overflow-hidden">
                {/* <Navbar /> */}
                <main className="mt-4  w-full  h-[90vh] overflow-auto ">
                    <div className="flex w-full flex-col gap-4 rounded-medium pb-10 ">
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    );
}
