import React, { createContext, useMemo, useState } from "react";
import Stepper from "../../../common/components/Stepper";
import JobPost from "./JobPost";
import InviteFreelancers from "./InviteFreelancers";
import ReviewProposalsList from "./ReviewProposalsList";
import Hiring from "./Hire";
import Footer from "../../Landing/components/Footer";
import { useParams } from "react-router-dom";
import Loader from "../../../common/Loader/Loader";
import useGlobalQuery from "../../../hooks/useGlobalQuery";
import { ROUTES } from "../../../Routes";
import { useSelector } from "react-redux";
import { ROLES } from "../../../constant/constants";

export const ReviewProposalContext = createContext({
    jobData: {},
});

const ReviewProposal = () => {
    const {profileDetails} = useSelector((state) => state.profile)
    const [activeStep, setActiveStep] = useState("VIEW JOB POST");
    const { id } = useParams();

    const { queryData, isLoading } = useGlobalQuery({
        url: ROUTES.JOB.GET_ALL_JOBS,
        methodType: "POST",
        data: { id },
        queryKey: ["job", id],
    });

    const steps = [
        "VIEW JOB POST",
        `INVITE ${profileDetails?.role?.[0] === ROLES.CLIENT ? "FREELANCERS" : "EMPLOYEES"}`,
        "REVIEW INTERESTS",
        // "SELECTED FREELANCERS",
    ];

    const jobData = useMemo(() => {
        return queryData?.data?.[0];
    }, [queryData]);

    const renderComponent = ({ activeStep }) => {
        switch (activeStep) {
            case "VIEW JOB POST":
                return <JobPost />;
            case `INVITE ${profileDetails?.role?.[0] === ROLES.CLIENT ? "FREELANCERS" : "EMPLOYEES"}`:
                return <InviteFreelancers />;
            case "REVIEW INTERESTS":
                return <ReviewProposalsList />;
            // case "SELECTED FREELANCERS":
            //     return <Hiring />;
            default:
                return null;
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <ReviewProposalContext.Provider value={{ jobData }}>
            <div className="w-11/12 mx-auto sm:p-6 py-4 space-y-10 text-start">
                <div className="mb-8">
                    <h1 className="text-lg sm:text-2xl font-semibold text-gray-900 mb-2">
                        {jobData?.jobTitle}
                    </h1>
                </div>

                <Stepper
                    steps={steps}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                />

                {renderComponent({ activeStep })}
            </div>
        </ReviewProposalContext.Provider>
    );
};

export default ReviewProposal;
