import { Input } from "@nextui-org/react";
import { useQueryClient } from "@tanstack/react-query";
import { ArrowRight2, SearchNormal1 } from "iconsax-react";
import React, { useContext, useEffect, useState } from "react";
import Loader from "../../../common/Loader/Loader";
import useGlobalQuery from "../../../hooks/useGlobalQuery";
import { ROUTES } from "../../../Routes";
import { ChatContext } from "../ChatLayout";
import { useSelector } from "react-redux";

const StartNewChat = ({ setIsOpen }) => {
    const { sendMessage, setUserList, userList, setActiveChat, activeChat } = useContext(ChatContext);
    const { profileDetails } = useSelector((state) => state.profile);

    const [search, setSearch] = useState();
    const [debouncedSearch, setDebouncedSearch] = useState();

    const queryClient = useQueryClient();

    const { queryData, isLoading } = useGlobalQuery({
        url: ROUTES.AUTHENTICATION.ALL_USERS,
        methodType: "POST",
        queryKey: ["all-employees", debouncedSearch],
        data: {
            limit: 10,
            search: debouncedSearch,
        },
    });

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    const handleStartNewChat = (user) => {
        const existingChat = userList.find(
            (chat) => chat.senderEmail === user.email || chat.receiverEmail === user.email
        );

        if (existingChat) {
            setActiveChat(existingChat);
            // You can use existingChat as needed, such as setting it as active
        } else {
            console.log("No existing chat, creating a new one...");

            const newMessage = {
                _id: `${profileDetails.email}_${user.email}_${Date.now()}`,
                createdAt_EP: Date.now() / 1000,
                createdAt: new Date().toISOString(),
                receiverEmail: profileDetails.email,
                senderEmail: user.email,
                readByEmails: [profileDetails.email, user.email],
                updatedAt: new Date().toISOString(),
                updatedAt_EP: Date.now() / 1000,
                senderDetails: {
                    _id: user?._id,
                    firstname: user?.firstname,
                    lastname: user?.lastname,
                    imgUrl: user?.imgUrl,
                    position: user?.position,
                },
                readStatus: true,
            };

            setUserList((prev) => [newMessage, ...prev]);
            setActiveChat(newMessage);
        }

        setIsOpen(false);
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="w-full">
                <Input
                    isClearable
                    classNames={{
                        base: "w-full",
                        inputWrapper: "border-1",
                    }}
                    placeholder="Search by name..."
                    size="lg"
                    startContent={<SearchNormal1 className=" size-5" />}
                    value={search}
                    variant="bordered"
                    onClear={() => setSearch("")}
                    onValueChange={setSearch}
                />
            </div>

            {isLoading ? (
                <Loader />
            ) : (
                <div className="space-y-5">
                    {queryData?.data?.map((member, index) => (
                        <div
                            key={index}
                            className="flex justify-between items-center cursor-pointer"
                            onClick={() => handleStartNewChat(member)}
                        >
                            <div className="flex items-center space-x-4">
                                <img
                                    src={
                                        member?.imgUrl ??
                                        `https://avatar.iran.liara.run/username?username=${member?.firstname}+${member?.lastname}`
                                    }
                                    alt={member?.firstname}
                                    className="w-12 h-12 rounded-full"
                                />
                                <div>
                                    <h3 className="text-base font-semibold">
                                        {member?.firstname} {""} {member?.lastname}
                                    </h3>
                                    <p className="text-gray-500 text-xs">{member?.position}</p>
                                </div>
                            </div>
                            <ArrowRight2 className="flex justify-end ml-auto size-4" />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default StartNewChat;
