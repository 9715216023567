import * as Yup from "yup";

export const commonValidations = ({
    maxLength = 100,
    minLength = 1,
    fieldName,
    customErrorMessage,
}) => {
    const stringValidation = Yup.string()
        .required(
            `${
                customErrorMessage
                    ? customErrorMessage
                    : fieldName + " is required"
            }`
        )
        .trim(`${fieldName} cannot be empty`)
        .min(minLength, `Minimum ${minLength} characters required`)
        .max(maxLength, `Maximum ${maxLength} characters are allowed`);

    const arrayValidation = Yup.array()
        .required(
            `${
                customErrorMessage
                    ? customErrorMessage
                    : fieldName + " is required"
            }`
        )
        .min(minLength, `Minimum ${minLength} items required`)
        .max(maxLength, `Maximum ${maxLength} items are allowed`);

    return { stringValidation, arrayValidation };
};

export const passwordValidation = Yup.string()
    .required("Password is required")
    .test("password-strength", (value, context) => {
        const errors = [];
        const hasUpperCase = /[A-Z]/.test(value || "");
        const hasLowerCase = /[a-z]/.test(value || "");
        const hasNumber = /\d/.test(value || "");
        const hasSpecialChar = /[@$!%*?&#]/.test(value || "");
        const hasMinLength = (value || "").length >= 8;

        if (!hasUpperCase) errors.push("at least one uppercase letter");
        if (!hasLowerCase) errors.push("at least one lowercase letter");
        if (!hasNumber) errors.push("at least one number");
        if (!hasSpecialChar) errors.push("at least one special character");
        if (!hasMinLength) errors.push("minimum 8 characters");

        if (errors.length > 0) {
            return context.createError({
                message: `Password must contain ${errors.join(", ")}.`,
            });
        }

        return true;
    });

export const emailValidation = Yup.string()
    .email("Invalid email")
    .required("Email is required");
