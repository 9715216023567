import React, { useState } from "react";
import InfiniteScrollComponent from "../../../common/components/InfiniteScrollComponent";
import Loader from "../../../common/Loader/Loader";
import useGlobalInfiniteQuery from "../../../hooks/useGlobalInfiniteQuery";
import { ROUTES } from "../../../Routes";
import InviteFreelancerCard from "./InviteFreelancerCard";
import { useSelector } from "react-redux";
import { ROLES } from "../../../constant/constants";

const tabs = ["Search", "Invite Freelancers", "My Hires", "Saved"];

const InviteFreelancers = () => {
    const { profileDetails } = useSelector((state) => state.profile);
    const [search, setSearch] = useState();
    const [keyToSearch, setKeyToSearch] = useState();

    const { queryData, isLoading, fetchNextPage, hasNextPage } =
        useGlobalInfiniteQuery({
            url: ROUTES.AUTHENTICATION.ALL_USERS,
            methodType: "POST",
            data: { filter: { role: [profileDetails?.role?.[0] === ROLES.CLIENT ? "freelancer" : "employee"] }, search: keyToSearch },
            queryKey: ["all-freelancers", keyToSearch],
        });

    const handleSearch = () => {
       setKeyToSearch(search)
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="space-y-5">
            <div
                className={`flex items-center bg-gray-100 rounded-full px-3 py-1 sm:px-4 sm:py-1 max-w-lg`}
            >
                <input
                    type="text"
                    placeholder="Search user..."
                    className="bg-transparent flex-1 focus:outline-none text-gray-700 placeholder-gray-400 pr-2 sm:pr-4 text-xs sm:text-sm"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                />

                <button
                    className="gradient-blue text-white rounded-full p-1.5 sm:p-2 ml-2 flex items-center justify-center"
                    onClick={handleSearch}
                >
                    <svg
                        className="w-5 h-5 sm:w-6 sm:h-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                </button>
            </div>
            <InfiniteScrollComponent
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isLoading={isLoading}
                items={queryData}
                className={"space-y-5"}
            >
                <InviteFreelancerCard />
            </InfiniteScrollComponent>
        </div>
    );
};

export default InviteFreelancers;
