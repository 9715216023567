export function storeDataInLocalStorage({ key, value }) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function getDataFromLocalStorage({ key }) {
    const storedValue = localStorage.getItem(key);

    // Check if the key exists in localStorage
    if (!storedValue) {
        console.warn(`Key "${key}" not found in localStorage.`);
        return null; // Return null if the key doesn't exist
    }

    try {
        return JSON.parse(storedValue);
    } catch (error) {
        console.error(`Error parsing JSON for key "${key}":`, error);
        return null; // Return null if parsing fails
    }
}
