import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { cn, formatTimeFromTimestamp } from "../../../common/ReusableFunctions";
import { RiCheckDoubleFill } from "react-icons/ri";

const ChatMessage = ({ chatData }) => {
    const { profileDetails } = useSelector((state) => state.profile);

    const isMe = useMemo(() => {
        return chatData.senderEmail === profileDetails.email;
    }, [profileDetails?.email, chatData?.senderEmail]);

    const Message = () => (
        <div className={`flex max-w-[70%]  flex-col  `}>
            <div
                className={`rounded-medium  ${isMe ? "text-left  bg-[#007AFF] text-white" : "text-right bg-content2"}`}
            >
                <div className={cn("relative w-full   px-4 py-2")}>
                    <div className="mt-1 ">
                        <div className="whitespace-pre-line">{chatData?.message}</div>
                    </div>
                </div>
            </div>
            <div className="w-fit ml-auto text-[10px] flex items-center gap-1">
                {formatTimeFromTimestamp({
                    timestamp: chatData?.sender?.updatedAt ?? chatData?.updatedAt,
                })}
                {isMe && (
                    <RiCheckDoubleFill
                        className={`size-4 ${
                            chatData?.readByEmails?.includes(profileDetails?.email) && "text-blue-500"
                        } `}
                    />
                )}
            </div>
        </div>
    );

    return (
        <div className={cn("flex gap-3", { "flex-row-reverse": isMe })}>
            <Message />
        </div>
    );
};

export default ChatMessage;
