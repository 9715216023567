import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CommonButton from "../../common/components/CommonButton";
import ErrorMessage from "../../common/components/ErrorMessage";
import FormInputField from "../../common/components/FormInputField";
import useCookie from "../../hooks/useCookie";
import { emailValidation } from "../../utils/formValidations";

const AdminLogin = () => {
    const { setCookie } = useCookie({ cookieName: "isAdminLoggedIn"})
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        email: emailValidation,
    });

    return (
        <div className="h-[90vh] center-vertical py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8 p-6 sm:p-8 rounded-2xl shadow-sm border">
                <div className="text-center sm:text-left">
                    <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">Login to PortfoliOOo as Admin</h2>
                </div>

                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        setIsLoading(true);
                        if (values?.email === "admin@gmail.com" && values?.password === "admin@123") {
                            setIsLoading(false);
                            setCookie("isAdminLoggedIn", true, { path: "/"});
                            navigate("/dashboard/overview");
                        } else {
                            setIsLoading(false);
                            if (values?.email !== "admin@gmail.com") {
                                setError("Invalid email address.");
                            } else {
                                setError("Invalid password.");
                            }
                        }
                    }}
                >
                    {({ handleSubmit }) => (
                        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                            <div className="space-y-4">
                                <div>
                                    <FormInputField name="email" type="email" label="Email" required />
                                </div>
                                <div>
                                    <FormInputField name="password" type="password" label="Password" required />
                                </div>
                            </div>


                            <div className="space-y-3">
                                <CommonButton
                                    title="Submit"
                                    type="submit"
                                    isGradient
                                    disabled={isLoading}
                                    loading={isLoading}
                                />
                            </div>

                            {error && <ErrorMessage message={error} />}
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AdminLogin;
