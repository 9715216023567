import React from "react";
import FreelancerFilters from "../../FreelancerListing/components/FreelancerFilters";
import Hero from "../../GigListing/components/Hero";
import SearchBar from "../../Landing/components/SearchBar";
import AllTalents from "./AllTalents";
import { FreelancerListingContext } from "../../FreelancerListing/page";

const TalentListing = () => {
    return (    
        <div>
            <Hero
                title={"Connect with top talents from all around the world."}
            />

            <div className="bg-white drop-shadow-lg w-full py-4 sticky top-16 z-20">
                <div className="sm:max-w-6xl sm:mx-auto center-vertical px-5 sm:px-0">
                    <SearchBar containerStyle={"!sm:max-w-full"} />
                </div>
            </div>

            <FreelancerFilters
                context={FreelancerListingContext}
                filterContainerClassname={"sticky top-40 h-[75vh] overflow-auto no-scrollbar"}
            >
                <AllTalents context={FreelancerListingContext} />
            </FreelancerFilters>
        </div>
    );
};

export default TalentListing;
