// navlinks.js
import { Briefcase, Coin1, Home2, People, Teacher, UserSearch } from "iconsax-react";

const navlinks = [
    {
        name: "Home",
        icon: Home2,
        status: "Active",
        href: "/"
    },
    {
        name: "Jobs",
        icon: Teacher,
        status: "Inactive",
        href: "/jobs"
    },
    {
        name: "Freelancers",
        icon: UserSearch,
        status: "Inactive",
        href: "/freelancers"
    },
    {
        name: "Talents",
        icon: People,
        status: "Inactive",
        href: "/talents"
    },
    {
        name: "Gigs",
        icon: Briefcase,
        status: "Inactive",
        href: "/gigs"
    },
];

export default navlinks;