import React from "react";
import Wok1 from "../../../assets/LandingPage/Sofa.jpeg";
import Wok2 from "../../../assets/LandingPage/Planning.jpeg";
import Wok3 from "../../../assets/LandingPage/VideoEditing.jpeg";
import Wok4 from "../../../assets/LandingPage/Book.jpeg";
import Wok5 from "../../../assets/LandingPage/WorkOffer5.png";
import Wok6 from "../../../assets/LandingPage/Letters.jpeg";
import Wok7 from "../../../assets/LandingPage/Man.jpeg";
import Branding from "../../../assets/LandingPage/Branding.jpg";
import Graphics_Design from "../../../assets/LandingPage/Graphics_Design.jpg";
import MotionGraphics from "../../../assets/LandingPage/Youtube.jpeg";
import WebDevelopment from "../../../assets/LandingPage/Web_Development.jpg";
import { useNavigate } from "react-router-dom";

const TypeOfWorkOffer = () => {
    return (
        <section className="w-full px-5 sm:px-6 lg:px-8 py-0 md:py-12">
            <div className="max-w-7xl mx-auto flex flex-col gap-6 md:gap-10">
                <h1 className="text-lg sm:text-3xl md:text-4xl font-semibold uppercase text-center">
                    Type of work portfolio offers
                </h1>

                <div className="columns-2 sm:columns-4 space-y-5">
                    <Card img={Wok1} title={"Interior Design"} className="!w-80 !h-[150px] sm:!h-[220px] !rounded-lg" />
                    <Card img={WebDevelopment} title={"Web Development"} className="!h-40 sm:!h-64 !w-80 !rounded-lg" />
                    <Card
                        img={Graphics_Design}
                        title={"Graphics Design"}
                        className="!h-40 sm:!h-64 !w-80 !rounded-lg"
                    />


                    <Card img={Wok4} title={"Cover Design"} className={"!rounded-lg !h-80 !w-80"} />
                    <Card img={Wok2} title={"Architecture"}f className={"!rounded-lg"} />
                    <Card img={Wok6} title={"Logo Design"} className={"!rounded-lg"} />


                    <Card img={Wok3} title={"Video Editing"} className={"!rounded-lg"} />
                    <Card
                        img={MotionGraphics}
                        title={"Product Design"}
                        className="!rounded-lg !h-80 !object-cover"
                    />


                    <Card img={Branding} title={"Branding"} className="!rounded-lg sm:!h-[365px] !w-full" />
                    <Card img={Wok7} title={"VFX & Media"} className={"!rounded-lg !h-64 sm:!h-full"} />
                </div>
            </div>
        </section>
    );
};

const Card = ({ img, title, className }) => {
    const navigate = useNavigate();

    return (
        <div
            className={`relative group scale-animation `}
            onClick={() => navigate(`/gigs?search=${title}`)}
        >
            <img
                src={img}
                alt=""
                className={`cursor-pointer group-hover:opacity-30 ${className} `}
            />
            <p className="text-animation !text-black text-center text-xs">
                {title}
            </p>
        </div>
    );
};

export default TypeOfWorkOffer;
