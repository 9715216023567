import React, { useState, useMemo, forwardRef } from "react";
import { RadioGroup } from "@nextui-org/react";
import { cn } from "@nextui-org/react";
import RatingRadioItem from "./RatingRadioItem";

const RatingStarComponents = forwardRef(({ className, label, hideStarsText, value, setValue, ...props }, ref) => {
    return (
        <div className={cn("flex items-center gap-3", className)}>
            <RadioGroup
                ref={ref}
                value={value}
                {...props}
                defaultValue="1"
                orientation="horizontal"
                onValueChange={setValue}
            >
                <RatingRadioItem value={1} color="warning" />
                <RatingRadioItem value={2} color="warning" />
                <RatingRadioItem value={3} color="warning" />
                <RatingRadioItem value={4} color="warning" />
                <RatingRadioItem value={5} color="warning" />
            </RadioGroup>
            {label && <span>{label}</span>}
        </div>
    );
});

export default RatingStarComponents;
