import React, { createContext, useState } from "react";
import Hero from "../GigListing/components/Hero";
import SearchBar from "../Landing/components/SearchBar";
import FreelancerFilters from "./components/FreelancerFilters";
import FreelancerListing from "./components/FreelancerListing";

export const FreelancerListingContext = createContext({
    filters: {},
    setFilters: () => {},
});

const Page = () => {
    const [filters, setFilters] = useState();

    return (
        <FreelancerListingContext.Provider
            value={{
                filters,
                setFilters,
            }}
        >
            <Hero
                title={
                    "Connect with top freelancers from all around the world."
                }
            />
            <div className="bg-white drop-shadow-lg w-full py-4 sticky top-16 z-20">
                <div className="sm:max-w-6xl sm:mx-auto center-vertical px-5 sm:px-0">
                    <SearchBar containerStyle={"!sm:max-w-full"} />
                </div>
            </div>

            <FreelancerFilters context={FreelancerListingContext} filterContainerClassname={"sticky top-40 h-[75vh] overflow-auto no-scrollbar"}>
                <FreelancerListing context={FreelancerListingContext} />
            </FreelancerFilters>
        </FreelancerListingContext.Provider>
    );
};

export default Page;
