import React, { useState } from "react";
import CommonButton from "../../common/components/CommonButton";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import { More } from "iconsax-react";
import { Plus } from "lucide-react";
import AddCreditActionCost from "./AddCreditActionCost";
import OverlayFragment from "../../common/components/OverlayFragment";
import useGlobalQuery from "../../hooks/useGlobalQuery";
import { ROUTES } from "../../Routes";
import Loader from "../../common/Loader/Loader";
import DeleteModal from "../../common/components/DeleteModal";
import { formatText } from "../../utils/common";

const CreditActions = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { queryData, isLoading } = useGlobalQuery({
        url: ROUTES.SUBSCRIPTION.CREDIT_ACTION.ALL,
        methodType: "POST",
        queryKey: ["all-credit-actions"],
    });

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="border-l p-5">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-semibold text-gray-900">Credit Action Costs</h1>

                {/* <CommonButton
                    title={"Add New Credit Action Cost"}
                    isGradient={true}
                    isRounded={true}
                    isWithIcon={true}
                    Icon={Plus}
                    buttonStyle={"!w-60 !text-xs"}
                    handleFunction={() => setIsOpen(true)}
                /> */}
            </div>

            <div className="w-full max-w-lg rounded-lg border border-gray-200 bg-white">
                <div className="grid grid-cols-4 text-nowrap  justify-center  border-b items-center gap-4 px-6 py-3 text-sm font-medium text-gray-500">
                    <div>ACTION</div>
                    <div>CREDIT TYPE</div>
                    <div>CREDITS REQUIRED</div>
                </div>

                <div className="divide-y divide-gray-200">
                    {queryData?.data?.map((action, index) => (
                        <Card data={action} key={index} />
                    ))}
                </div>
            </div>

            <OverlayFragment
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                children={<AddCreditActionCost setIsOpen={setIsOpen} />}
                title={"Add Subscription Plan"}
                size={"xl"}
            />
        </div>
    );
};

const Card = ({ data }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div key={data?._id} className="grid grid-cols-4 items-center gap-4 px-6 py-4 text-sm">
            <div className="text-gray-600">{formatText(data?.actionName)}</div>
            <div className="text-gray-600">{formatText(data?.creditRequiredType)}</div>
            <div className="text-gray-600 mx-auto">{data?.creditRequired}</div>

            <Dropdown>
                <DropdownTrigger>
                    <button className="p-1.5 rounded-full bg-gray-100 size-8 ml-auto">
                        <More className="size-5 text-gray-500 hover:text-black" />
                    </button>
                </DropdownTrigger>
                <DropdownMenu aria-label="Static Actions">
                    <DropdownItem key="edit" onPress={() => setIsOpen(true)}>
                        Edit Plan
                    </DropdownItem>
                    {/* <DropdownItem
                        key="delete"
                        className="text-danger"
                        color="danger"
                        onPress={() => setIsDeleteModalOpen(true)}
                    >
                        Delete Plan
                    </DropdownItem> */}
                </DropdownMenu>
            </Dropdown>

            <OverlayFragment
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                children={<AddCreditActionCost isUpdate={true} data={data} setIsOpen={setIsOpen} />}
                title={"Update Credit Cost Plan"}
                size={"xl"}
            />

            <OverlayFragment
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                children={
                    <DeleteModal
                        url={ROUTES.SUBSCRIPTION.CREDIT_ACTION.CRUD}
                        data={{ id: data?._id }}
                        queriesToInvalidate={["all-credit-actions"]}
                        title={"Delete Credit Plan"}
                        type={"Credit Plan"}
                        setIsOpen={setIsDeleteModalOpen}
                    />
                }
            />
        </div>
    );
};

export default CreditActions;
