import React from "react";
import LeftHeroImage from "../../../assets/LandingPage/LeftHeroImage.svg";
import RightHeroImage from "../../../assets/LandingPage/RightHeroImage.svg";
import SearchBar from "./SearchBar";
import TrendingSearches from "./TrendingSearches";

const Hero = () => {
	return (
		<div
			className="w-full center-vertical h-[65vh] sm:h-[90vh] px-4 py-0 sm:pb-0 md:py-0 
                    flex flex-col sm:flex-row items-center justify-between gap-6 md:gap-10 
                    relative overflow-hidden"
		>
			<img
				src={LeftHeroImage}
				className=" md:w-[40%] lg:max-w-sm 
                   absolute top-10 sm:top-0 left-0 opacity-20 md:opacity-100 
                   md:relative z-0 md:z-auto"
				alt=""
			/>

			<div className="center-vertical gap-4 md:gap-5 z-10 md:mx-0 sm:mt-0">
				<h1
					className="text-3xl md:text-4xl lg:text-5xl 
                       leading-tight md:leading-[74px] 
                       text-center font-semibold text-[#294057]"
				>
					Your Platform for Gigs, Jobs, and{" "}
					<span className="blue-gradient-text">Creative Collaborations</span>
				</h1>

				<p
					className="text-sm md:text-base text-center 
                       mx-auto opacity-60 max-w-lg"
				>
					Browse talent, discover projects, and turn ideas into realities with a
					community that celebrates creativity.
				</p>

				<SearchBar maxWidth={"sm:max-w-lg"} />
				<TrendingSearches />
			</div>

			<img
				src={RightHeroImage}
				className="hidden md:block w-[40%] lg:max-w-sm"
				alt=""
			/>
		</div>
	);
};

export default Hero;
