"use client";

import { Icon } from "@iconify/react";
import {
    cn,
    Listbox,
    ListboxItem,
    ListboxSection,
    Tooltip
} from "@nextui-org/react";
import React, { forwardRef, useCallback, useState } from "react";
import { useSelector } from "react-redux";

const SidebarItemType = {
    Nest: "nest",
};

const NextSidebar = forwardRef(
    (
        {
            items,
            isCompact,
            defaultSelectedKey,
            onSelect,
            hideEndContent,
            sectionClasses: sectionClassesProp = {},
            itemClasses: itemClassesProp = {},
            iconClassName,
            classNames,
            className,
            ...props
        },
        ref
    ) => {
        const [selected, setSelected] = useState(defaultSelectedKey);

        const sectionClasses = {
            ...sectionClassesProp,
            base: cn(sectionClassesProp?.base, "w-full", {
                "p-0 max-w-[44px]": isCompact,
            }),
            group: cn(sectionClassesProp?.group, {
                "flex flex-col gap-1": isCompact,
            }),
            heading: cn(sectionClassesProp?.heading, {
                hidden: isCompact,
            }),
        };

        const itemClasses = {
            ...itemClassesProp,
            base: cn(itemClassesProp?.base, {
                "w-11 h-11 gap-0 p-0": isCompact,
            }),
        };

        const renderItem = useCallback(
            (item) => {

                return (
                    <ListboxItem
                        {...item}
                        classNames={{
                            base: " data-[hover=true]:border-l-3 data-[selected=true]:border-l-3 data-[selected=true]:bg-[#F4F8FF]  data-[hover=true]:bg-[#F4F8FF]  border-[#4069E9] rounded-r-lg rounded-l-none  py-3",
                            wrapper:
                                "data-[selected=true]:bg-[#F4F8FF] data-[selected=true]:text-white",
                            title: " group-data-[selected=true]:text-blue-500",
                        }}
                        className=""
                        key={item.key}
                        startContent={
                            isCompact ? null : item.icon ? (
                                <Icon
                                    className={cn(
                                        "text-default-500 group-data-[selected=true]:text-blue-500",
                                        iconClassName
                                    )}
                                    icon={item.icon}
                                    width={20}
                                />
                            ) : (
                                item.startContent ?? null
                            )
                        }
                    >
                        {isCompact ? (
                            <Tooltip content={item.title} placement="right">
                                <div className="flex w-full items-center justify-center ">
                                    {item.icon ? (
                                        // <Icon
                                        //     className={cn(
                                        //         "text-default-500 group-data-[selected=true]:text-foreground",
                                        //         iconClassName
                                        //     )}
                                        //     icon={item.icon}
                                        //     width={20}
                                        // />
                                        ""
                                    ) : (
                                        // item.startContent ?? null
                                        ""
                                    )}
                                </div>
                            </Tooltip>
                        ) : null}
                    </ListboxItem>
                );
            },
            [isCompact, iconClassName]
        );

        return (
            <Listbox
                key={isCompact ? "compact" : "default"}
                ref={ref}
                hideSelectedIcon
                as="nav"
                className={cn("list-none", className)}
                classNames={{
                    ...classNames,
                    list: cn("items-center ", classNames?.list),
                }}
                color="default"
                itemClasses={{
                    ...itemClasses,
                    base: cn(
                        "px-3 min-h-11 h-[44px] data-[selected=true]:bg-default-100 ",
                        itemClasses?.base
                    ),
                    title: cn(
                        " font-medium text-default-500 group-data-[selected=true]:text-foreground",
                        itemClasses?.title
                    ),
                }}
                items={items}
                selectedKeys={[selected]}
                selectionMode="single"
                variant="flat"
                onSelectionChange={(keys) => {
                    const key = Array.from(keys)[0];
                    console.log("key: " + key);
                    setSelected(key);
                    onSelect?.(key);
                }}
                {...props}
            >
                {(item) => {
                    return item.items &&
                        item.items?.length > 0 &&
                        item?.type ===
                            SidebarItemType.Nest ? null : item.items &&
                      item.items?.length > 0 ? (
                        <ListboxSection
                            key={item.key}
                            classNames={sectionClasses}
                            showDivider={isCompact}
                            title={item.title}
                        >
                            {item.items.map(renderItem)}
                        </ListboxSection>
                    ) : (
                        renderItem(item)
                    );
                }}
            </Listbox>
        );
    }
);

NextSidebar.displayName = "NextSidebar";

export default NextSidebar;
