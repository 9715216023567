import { useQuery } from "@tanstack/react-query";
import React, { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
import JobListing from "../src/pages/GigListing/page";
import LandingPage from "../src/pages/Landing/page";
import "./App.css";
import Loader from "./common/Loader/Loader";
import useGetAccessToken from "./hooks/Authentication/useGetAccessToken";
import useGlobalQuery from "./hooks/useGlobalQuery";
import AdminDashboard from "./pages/AdminPanel/AdminDashboard";
import Sidebar from "./pages/AdminPanel/AdminSidebar";
import CreditActions from "./pages/AdminPanel/CreditActions";
import RequestTable from "./pages/AdminPanel/RequestTable";
import SubscriptionPlans from "./pages/AdminPanel/SubscriptionPlan";
import VerifyAccount from "./pages/Authentication/VerifyAccount";
import ChatLayout from "./pages/Chat/ChatLayout";
import Footer from "./pages/Landing/components/Footer";
import NavigationBar from "./pages/Landing/components/Navbar/Navbar";
import MonitoringDashboard from "./pages/MonitoringDashboard/page";
import ProposalForm from "./pages/ProposalSubmission";
import ResumeBuilder from "./pages/ResumeBuilder/components/ResumeBuilder";
import TalentListing from "./pages/TalentListing/components/TalentListing";
import Timeline from "./pages/Timeline/Timeline";
import { ROUTES } from "./Routes";
import { APIHandler } from "./services/apiHandler";
import { customActions } from "./store/custom.slice";
import { profileActions } from "./store/profile.slice";
import { AdminRoute } from "./utils/AuthorizedRoutes";
import NotFound from "./common/components/404";

const FreelancerProfilePage = lazy(() =>
    import("./pages/FreelancerProfile/page")
);
const ClientProfilePage = lazy(() => import("./pages/ClientProfile/page"));
const LoginPage = lazy(() => import("./pages/Authentication/Login"));
const FreelancerListing = lazy(() => import("./pages/FreelancerListing/page"));
const ProposalReviewPage = lazy(() =>
    import("./pages/Proposal/components/ReviewProposal")
);
const ForgotPasswordPage = lazy(() =>
    import("./pages/Authentication/ForgotPassword")
);
const RegistrationPage = lazy(() => import("./pages/Authentication/Register"));
const CreateProjectPage = lazy(() => import("./pages/CreateProject/Page"));
const DynamicHomePage = lazy(() => import("./pages/Home/Page"));
const MessagePage = lazy(() => import("./pages/MessageModule/MessagePage"));
const FreelancerOnboarding = lazy(() =>
    import("./pages/Onboarding/pages/FreelancerOnboarding")
);
const EmployeeOnboarding = lazy(() =>
    import("./pages/Onboarding/pages/EmployeeOnboarding")
);
const ClientOnboarding = lazy(() =>
    import("./pages/Onboarding/pages/ClientOnboarding")
);
const HrOnboarding = lazy(() =>
    import("./pages/Onboarding/pages/HrOnboarding")
);
const CreateJob = lazy(() => import("./pages/Jobs/pages/CreateJob"));
const ApplyJob = lazy(() => import("./pages/Jobs/pages/ApplyJob"));
const SubscriptionPage = lazy(() =>
    import("./pages/Subscription/SubscriptionPage")
);

function App() {
    const { profileDetails, userProfileLoading } = useSelector(
        (state) => state.profile
    );
    const dispatch = useDispatch();
    const { cookie } = useGetAccessToken();

    const { data, isLoading } = useQuery({
        queryKey: ["userProfile"],
        queryFn: async () => {
            if (!cookie?.accessToken) return null;

            const { data, status } = await APIHandler({
                action: "GET",
                url: ROUTES.AUTHENTICATION.MY_PROFILE,
                data: {},
                headers: {
                    Authorization: "Bearer " + cookie.accessToken,
                },
            });

            return status ? data : null;
        },
        enabled: !!cookie?.accessToken,
        staleTime: 0,
        refetchOnWindowFocus: false,
    });

    const { queryData: allCreditActions, isLoading: creditActionsLoading } =
        useGlobalQuery({
            url: ROUTES.SUBSCRIPTION.CREDIT_ACTION.ALL,
            methodType: "POST",
            queryKey: ["all-credit-actions"],
        });

    useEffect(() => {
        if (data) {
            dispatch(profileActions.setProfileDetails(data?.userProfile));
            dispatch(profileActions.setUserProfileLoading(false));
        } else if (!cookie?.accessToken) {
            dispatch(profileActions.setUserProfileLoading(false));
        }
    }, [data, dispatch, cookie?.accessToken]);

    useEffect(() => {
        if (allCreditActions) {
            dispatch(customActions.setCreditActionData(allCreditActions?.data));
        }
    }, [allCreditActions, dispatch]);

    if (isLoading || userProfileLoading || creditActionsLoading) {
        return <Loader />;
    }

    return (
        <div className="App">
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <div>
                                <NavigationBar />
                                <Outlet />
                                <Footer />
                            </div>
                        }
                    >
                        <Route
                            path="/"
                            element={
                                Object.keys(profileDetails)?.length > 0 ? (
                                    <DynamicHomePage tabName={"home"} />
                                ) : (
                                    <LandingPage />
                                )
                            }
                        />

                        <Route path="/chats" element={<ChatLayout />} />
                        <Route
                            path="/home/:accessToken"
                            element={
                                Object.keys(profileDetails)?.length > 0 ? (
                                    <DynamicHomePage tabName={"home"} />
                                ) : (
                                    <LandingPage />
                                )
                            }
                        />
                        <Route
                            path="/jobs"
                            element={
                                Object.keys(profileDetails)?.length > 0 ? (
                                    <DynamicHomePage tabName={"jobs"} />
                                ) : (
                                    <JobListing type={"jobs"} />
                                )
                            }
                        />
                        <Route
                            path="/gigs"
                            element={
                                Object.keys(profileDetails)?.length > 0 ? (
                                    <DynamicHomePage tabName={"gigs"} />
                                ) : (
                                    <JobListing type={"gigs"} />
                                )
                            }
                        />
                        <Route
                            path="/freelancers"
                            element={
                                Object.keys(profileDetails)?.length > 0 ? (
                                    <DynamicHomePage tabName={"freelancers"} />
                                ) : (
                                    <FreelancerListing />
                                )
                            }
                        />
                        <Route
                            path="/talents"
                            element={
                                Object.keys(profileDetails)?.length > 0 ? (
                                    <DynamicHomePage tabName={"talents"} />
                                ) : (
                                    <TalentListing />
                                )
                            }
                        />
                        <Route
                            path="/profile/freelancer"
                            element={
                                // <FreelancerRoute>
                                <FreelancerProfilePage />
                                // </FreelancerRoute>
                            }
                        />
                        <Route
                            path="/profile/freelancer/:id"
                            element={
                                <FreelancerProfilePage isReadOnly={true} />
                            }
                        />
                        <Route
                            path="/profile/employee"
                            element={
                                // <FreelancerRoute>
                                <FreelancerProfilePage />
                                // </FreelancerRoute>
                            }
                        />
                        <Route
                            path="/profile/employee/:id"
                            element={
                                // <FreelancerRoute>
                                <FreelancerProfilePage isReadOnly={true} />
                                // </FreelancerRoute>
                            }
                        />
                        <Route
                            path="/profile/client"
                            element={
                                // <ClientRoute>
                                <ClientProfilePage />
                                // </ClientRoute>
                            }
                        />
                        <Route
                            path="/profile/client/:id"
                            element={
                                // <ClientRoute>
                                <ClientProfilePage isReadOnly={true} />
                                // </ClientRoute>
                            }
                        />
                        <Route
                            path="/profile/hr"
                            element={
                                // <ClientRoute>
                                <ClientProfilePage />
                                // </ClientRoute>
                            }
                        />
                        <Route
                            path="/profile/hr/:id"
                            element={
                                // <ClientRoute>
                                <ClientProfilePage isReadOnly={true} />
                                // </ClientRoute>
                            }
                        />
                        <Route path="/proposal" element={<ProposalForm />} />

                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/verify" element={<VerifyAccount />} />
                        <Route
                            path="/register"
                            element={<RegistrationPage />}
                        />
                        <Route
                            path="/forgot-password"
                            element={<ForgotPasswordPage />}
                        />
                        <Route
                            path="/create-project"
                            element={<CreateProjectPage />}
                        />
                        <Route
                            path="/update-project/:id"
                            element={<CreateProjectPage isUpdate={true} />}
                        />
                        <Route path="/messages" element={<MessagePage />} />
                        <Route
                            path="/monitoring-dashboard"
                            element={<MonitoringDashboard />}
                        />

                        {/* Onboarding pages  */}
                        <Route path="/onboarding">
                            <Route
                                path="freelancer"
                                element={<FreelancerOnboarding />}
                            />
                            <Route
                                path="employee"
                                element={<EmployeeOnboarding />}
                            />
                            <Route
                                path="client"
                                element={<ClientOnboarding />}
                            />
                            <Route path="hr" element={<HrOnboarding />} />
                        </Route>

                        {/* Job Section  */}
                        <Route
                            path="/opportunity/:type"
                            element={<CreateJob />}
                        />
                        <Route path="/apply/:id" element={<ApplyJob />} />
                        <Route
                            path="/create-resume"
                            element={<ResumeBuilder />}
                        />
                        <Route
                            path="/update-resume/:id"
                            element={<ResumeBuilder isUpdate={true} />}
                        />
                        <Route
                            path="/subscription"
                            element={<SubscriptionPage />}
                        />
                        <Route path="/timeline/:id" element={<Timeline />} />

                        {/* Admin Dashboard Routes  */}
                        <Route
                            path="/dashboard"
                            element={
                                <AdminRoute>
                                    <Sidebar />
                                </AdminRoute>
                            }
                        >
                            <Route
                                path="overview"
                                element={<AdminDashboard />}
                            />
                            <Route
                                path="subscription-plans"
                                element={<SubscriptionPlans />}
                            />
                            <Route
                                path="credit-actions"
                                element={<CreditActions />}
                            />
                            <Route path="requests" element={<RequestTable />} />
                        </Route>
                    </Route>

                    <Route
                        path="/"
                        element={
                            <div>
                                <NavigationBar />
                                <Outlet />
                            </div>
                        }
                    >
                        <Route
                            path="/proposal/review/:id"
                            element={<ProposalReviewPage />}
                        />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </div>
    );
}

export default App;
