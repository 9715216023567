import React, { useEffect, useState } from "react";

const ProfileDefaultImg = ({ firstname, height, width, textSize, profileImg }) => {
    const [color, setColor] = useState("bg-[#ffffff]");

    const generateRandomColor = () => {
        const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        setColor(randomColor);
    };

    useEffect(() => {
        generateRandomColor();
    }, []);

    return (
        <div>
            {profileImg && profileImg?.length > 0 ? (
                <img
                    src={profileImg}
                    alt="Profile"
                    className={`inline-flex  ${width || "w-9"} ${height || "h-9"} ${
                        textSize || "text-lg"
                    } items-center justify-center rounded-full bg-gray-500 text-white uppercase object-cover`}
                />
            ) : (
                <span
                    className={`inline-flex ${width || "w-9"} ${height || "h-9"} ${
                        textSize || "text-lg"
                    } items-center justify-center rounded-full text-white uppercase object-cover`}
                    style={{
                        backgroundColor: color,
                    }}
                >
                    {firstname?.[0] ?? "A"}
                </span>
            )}
        </div>
    );
};

export default ProfileDefaultImg;
