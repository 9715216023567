import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../common/Loader/Loader";
import useGetAccessToken from "../../hooks/Authentication/useGetAccessToken";

const VerifyAccount = () => {
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get("accessToken");
    const { setCookie } = useGetAccessToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (accessToken) {
            setCookie("accessToken", accessToken, { path: "/" });
            navigate("/");
        }
    }, [accessToken, setCookie, navigate]);

    return <Loader />;
};

export default VerifyAccount;
