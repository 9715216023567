import { Button } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import ErrorMessage from "../../../common/components/ErrorMessage";
import FormikStateMonitor from "../../../common/FormikStateMonitor";
import useGlobalMutation from "../../../hooks/useGlobalMutation";
import { ROUTES } from "../../../Routes";
import FormikFormGenerator from "../../../utils/FormikFormGenerator";
import { commonValidations } from "../../../utils/formValidations";
import RatingStarComponents from "./RatingStarComponents";
import FormFieldsMapper from "../../../utils/FormFieldsMapper";

const AddRating = ({ setIsOpen, receiverEmail }) => {
    const [isError, setIsError] = useState(false);
    const [commonError, setCommonError] = useState();
    const [rating, setRating] = useState(1);

    const [formData, setFormData] = useState();

    const fields = [
        {
            fieldType: "input",
            name: "message",
            label: "Title",
            placeholder: "Title of your review",
            required: true,
        },
        {
            fieldType: "description",
            name: "subMessage",
            label: "Comment",
            placeholder: "Enter you comment",
        },
    ];

    const { runMutation, mutationData, mutationError, mutationLoading, isMutationSucceeded } = useGlobalMutation({
        url: ROUTES.RATING.CRUD,
        methodType: "POST",
        data: {
            ...formData,
            rating: rating,
            receiverEmail: receiverEmail,
        },
    });

    useEffect(() => {
        if (isMutationSucceeded) {
            setIsOpen(false);
        }
    }, [mutationData, setIsOpen, isMutationSucceeded]);

    const validationSchema = Yup.object().shape({
        message: commonValidations({ fieldName: "Title" }).stringValidation,
    });

    return (
        <div className="space-y-6">
            <div className="flex-col  ">
                <h1 className="text-large font-semibold text-center">Write a review</h1>
                <p className="text-small font-normal text-default-400 text-center">
                    Share your experience with this project
                </p>
            </div>
            <FormikFormGenerator
                initialValues={{
                    message: "",
                }}
                formClassName={"my-5 space-y-6"}
                validationSchema={validationSchema}
                handleSubmitFunction={() => {
                    console.log("form data", formData);
                    runMutation();
                }}
            >
                <div className="w-fit mx-auto">
                    <RatingStarComponents value={rating} setValue={setRating} />
                </div>
                <FormikStateMonitor onChange={setFormData} />
                <FormFieldsMapper fields={fields} />

                {isError && (
                    <div className="mt-5">
                        <ErrorMessage message={commonError} />
                    </div>
                )}

                {mutationError && (
                    <div className="mt-5">
                        <ErrorMessage message={mutationError} />
                    </div>
                )}

                <div className="flex  justify-end gap-5 border-t bg-white   mt-5 p-2 pt-5">
                    <Button
                        color="danger"
                        size="sm"
                        className=""
                        isDisabled={mutationLoading}
                        onPress={() => setIsOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        size="sm"
                        className="gradient-blue "
                        type="submit"
                        isLoading={mutationLoading}
                        isDisabled={mutationLoading}
                    >
                        Submit
                    </Button>
                </div>
            </FormikFormGenerator>
        </div>
    );
};

export default AddRating;
