import React, { useContext, useEffect, useState } from "react";
import FormikFormGenerator from "../../../utils/FormikFormGenerator";
import FormInputField from "../../../common/components/FormInputField";
import FormCheckbox from "../../../common/components/FormFields/FormCheckbox";
import FormMultiSelect from "../../../common/components/FormFields/FormMultiselect";
import {
    JobExperienceLevel,
    Master,
    Skills,
    TypeOfGigs,
} from "../../../constant/constants";
import { Slider } from "@nextui-org/react";
import { MainPageContext } from "../Page";
import FormikStateMonitor from "../../../common/FormikStateMonitor";
import useMaster from "../../../hooks/useMaster";

import {FunnelIcon} from "@heroicons/react/20/solid";

export const ProjectDurationOptions = [
    {
        name: "Short Term (10-15 days)",
        value: "Small",
    },
    {
        name: "Medium Term (2-3 Months)",
        value: "Medium",
    },
    {
        name: "Long Term (More than 6 months)",
        value: "Large",
    },
];

const FilterList = ({ isSticky }) => {
    const { setFilters } = useContext(MainPageContext);
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
    const [budget, setBudget] = React.useState(5000);

    const { data: skillsData, isLoading: skillsLoading } = useMaster({
        type: Master.Skills,
        queryKeys: ["master", Master.Skills],
    });

    useEffect(() => {
        setFilters((prev) => {
            return {
                ...prev,
                jobCostEstimate: budget,
            };
        });
    }, [budget, setFilters]);

    return (
        <div
            className={`p-4 bg-white border max-h-[85vh] no-scrollbar rounded-lg max-w-[292px] min-w-[292px] overflow-auto ${
                isSticky && "sticky bg-white top-20"
            }`}
        >
            <FormikFormGenerator
                initialValues={{
                    jobTitle: "",
                    location: "",
                    talentBadge: "",
                    jobScope: "",
                    skillsRequired: [],
                    jobCostEstimate: "",
                    jobExperienceLevel: "",
                    jobCategory: "",
                }}
                formClassName={"space-y-5"}
            >
                <FormikStateMonitor onChange={setFilters} />

                <FormInputField
                    name="jobTitle"
                    label={"Project Title"}
                    placeholder={"Ex. Logo Design"}
                />

                <FormInputField
                    name="location"
                    label={"Preferred Job Location"}
                    placeholder={"Enter location"}
                />

                <FormCheckbox
                    name={"jobExperienceLevel"}
                    label={"Experience Level"}
                    options={JobExperienceLevel}
                />

                <Slider
                    size="md"
                    step={5000}
                    color="primary"
                    label="Budget Range"
                    showSteps={true}
                    maxValue={50000}
                    minValue={0}
                    defaultValue={5000}
                    className="max-w-md"
                    value={budget}
                    onChange={setBudget}
                />

                <FormCheckbox
                    name={"jobScope"}
                    label={"Project Duration"}
                    options={ProjectDurationOptions}
                />

                <div>
                    <FormMultiSelect
                        name="skillsRequired"
                        label={"Skills Required"}
                        placeholder={"Select required skills"}
                        options={skillsData}
                        maxLabelCount={2}
                        isLoading={skillsLoading}
                    />
                </div>

                <FormCheckbox
                    name={"jobCategory"}
                    label={"All Categories"}
                    options={TypeOfGigs}
                />
            </FormikFormGenerator>

            <button
                type="button"
                onClick={() => setMobileFiltersOpen(true)}
                className=" text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden absolute right-5 mt-7"
            >
                <span className="sr-only">Filters</span>
                <FunnelIcon aria-hidden="true" className="h-5 w-5" />
            </button>
        </div>
    );
};

export default FilterList;
