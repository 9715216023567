import { Formik, useFormikContext } from "formik";
import { AddCircle, Briefcase } from "iconsax-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { PiGraduationCap } from "react-icons/pi";
import CommonButton from "../../../common/components/CommonButton";
import FormSelectMenu from "../../../common/components/FormFields/FormSelectMenu";
import FormTextarea from "../../../common/components/FormFields/FormTextarea";
import FormInputField from "../../../common/components/FormInputField";
import { ResumeBuilderContext } from "./ResumeBuilder";
import {
    getListOfYears,
    getListOfExpectedGraduationYears,
} from "../../../common/ReusableFunctions";
import { Months } from "../../../constant/constants";
import FormCheckbox from "../../../common/components/FormFields/FormCheckbox";

const ResumeEmploymentInfo = () => {
    const { setFormData, formData } = useContext(ResumeBuilderContext);
    const [educationCount, setEducationCount] = useState(
        formData?.employment?.length ?? 0
    );

    const addEducation = () => {
        setEducationCount((prevCount) => prevCount + 1);
        setFormData((prevData) => ({
            ...prevData,
            employment: Array.isArray(prevData?.employment)
                ? [...prevData?.employment, {}]
                : [{}],
        }));
    };

    return (
        <div>
            <div className="flex items-center gap-3">
                <div className="bg-[#5B8EFB33] text-blue-500 p-2 rounded-lg">
                    <Briefcase className="size-6" />
                </div>
                <h1 className="text-xl font-semibold">
                    Employment Information
                </h1>
            </div>

            {Array.from({ length: educationCount }, (_, index) => (
                <IndividualEducation
                    key={index}
                    setEducationCount={setEducationCount}
                    index={index}
                />
            ))}

            <CommonButton
                className="w-full"
                handleFunction={addEducation}
                title="Add Employment Details"
                isGradient={true}
                isRounded={true}
                Icon={AddCircle}
                isWithIcon={true}
                buttonStyle={"mt-5 max-w-60 mx-auto"}
            />
        </div>
    );
};

const IndividualEducation = ({ setEducationCount, index }) => {
    const { formData, setFormData } = useContext(ResumeBuilderContext);

    const employmentData = useMemo(() => {
        return formData?.employment?.[index];
    }, [formData, index]);

    const listOfYears = getListOfYears();

    const listOfExpectedGraduationYearsFrom = useMemo(() => {
        return employmentData?.from_year
            ? getListOfExpectedGraduationYears(employmentData?.from_year)
            : [];
    }, [employmentData?.from_year]);

    const removeEducation = (index) => {
        if (formData?.employment?.length > 0) {
            setEducationCount((prevCount) => prevCount - 1);
            setFormData((prevData) => {
                const updatedEducation = prevData?.employment?.filter(
                    (_, i) => i !== index
                );
                return { ...prevData, employment: updatedEducation };
            });
        }
    };

    const handleChangeFunction = (values) => {
        setFormData((prevData) => ({
            ...prevData,
            employment: [
                ...prevData?.employment?.slice(0, index),
                {
                    companyName: values?.companyName ?? "",
                    city: values?.city ?? "",
                    country: values?.country ?? "",
                    title: values?.title ?? "",
                    from_month: values?.from_month ?? "",
                    from_year: values?.from_year ?? "",
                    to_month: values?.to_month ?? "",
                    to_year: values?.to_year ?? "",
                    isCurrentlyWorking: values?.isCurrentlyWorking?.includes(
                        true
                    )
                        ? true
                        : false,
                    description: values?.description ?? "",
                },
                ...prevData?.employment?.slice(index + 1),
            ],
        }));
    };

    return (
        <Formik
            initialValues={{
                companyName: employmentData?.companyName ?? "",
                city: employmentData?.city ?? "",
                country: employmentData?.country ?? "",
                title: employmentData?.title ?? "",
                from_month: employmentData?.from_month ?? "",
                from_year: employmentData?.from_year ?? "",
                to_month: employmentData?.to_month ?? "",
                to_year: employmentData?.to_year ?? "",
                isCurrentlyWorking: employmentData?.isCurrentlyWorking
                    ? [true]
                    : [],
                description: employmentData?.description ?? "",
            }}
        >
            {({ values }) => (
                <div className="space-y-5 mt-5">
                    <ChangeHandler
                        handler={() => handleChangeFunction(values)}
                    />

                    <FormInputField
                        name="companyName"
                        placeholder="Enter your company name"
                        label="Company"
                        labelStyle="block text-sm/6 font-medium text-gray-900"
                    />

                    <div className="flex justify-between w-full gap-5">
                        <div className="w-full">
                            <FormInputField
                                label="City"
                                placeholder="Enter city"
                                name="city"
                            />
                        </div>
                        <div className="w-full">
                            <FormInputField
                                label={"Country"}
                                placeholder="Enter country"
                                name="country"
                            />
                        </div>
                    </div>

                    <FormInputField
                        label="Title"
                        placeholder="Enter your title"
                        name="title"
                    />

                    <div className="flex justify-between w-full gap-5">
                        <div className="w-full">
                            <FormSelectMenu
                                label="From Month"
                                placeholder={"Select Month"}
                                name={"from_month"}
                                options={Months}
                            />
                        </div>
                        <div className="w-full">
                            <FormSelectMenu
                                label="From Year"
                                placeholder={"Select Year"}
                                name={"from_year"}
                                options={listOfYears}
                            />
                        </div>
                    </div>

                    {!values?.isCurrentlyWorking?.includes(true) && (
                        <div className="flex justify-between w-full gap-5">
                            <div className="w-full">
                                <FormSelectMenu
                                    label="To Month"
                                    placeholder={"Select Month"}
                                    name={"to_month"}
                                    options={Months}
                                />
                            </div>
                            <div className="w-full">
                                <FormSelectMenu
                                    label="To Year"
                                    placeholder={"Select Year"}
                                    name={"to_year"}
                                    options={listOfExpectedGraduationYearsFrom}
                                />
                            </div>
                        </div>
                    )}

                    <FormCheckbox
                        name="isCurrentlyWorking"
                        options={[
                            { name: "Is Currently Working", value: true },
                        ]}
                    />

                    <FormTextarea
                        name="description"
                        label="Description (Optional)"
                        placeholder="Add Description"
                        className={"!w-80 overflow-hidden !truncate"}
                    />

                    <div className="flex items-center gap-2 max-w-20 justify-end ml-auto">
                        <CommonButton
                            buttonStyle="!bg-red-100 text-red-500 rounded-full"
                            title={"Delete"}
                            isRounded={true}
                            type={"button"}
                            handleFunction={() => removeEducation(index)}
                        />
                    </div>
                </div>
            )}
        </Formik>
    );
};

const ChangeHandler = ({ handler }) => {
    const { values } = useFormikContext();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handler();
        }, 100);
        return () => clearTimeout(timeoutId);
    }, [values, handler]);

    return null;
};

export default ResumeEmploymentInfo;
