import { Dialog, DialogBackdrop, DialogPanel, Menu } from "@headlessui/react";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Slider } from "@nextui-org/react";
import { default as React, useContext, useEffect, useState } from "react";
import FormikStateMonitor from "../../../common/FormikStateMonitor";
import FormCheckbox from "../../../common/components/FormFields/FormCheckbox";
import FormMultiSelect from "../../../common/components/FormFields/FormMultiselect";
import FormInputField from "../../../common/components/FormInputField";
import {
    PreferredWorkingHours,
    Skills,
    TypeOfGigs
} from "../../../constant/constants";
import FormikFormGenerator from "../../../utils/FormikFormGenerator";
import FormFieldsMapper from "../../../utils/FormFieldsMapper";
import useMaster from "../../../hooks/useMaster";
import { Master } from "../../../constant/constants";
import { ExperienceLevel, Degrees } from "../../../constant/constants";
import { JoiningAvailability } from "../../Home/components/UsersFilterList";

const FreelancerFilters = ({ children, filterContainerClassname, context }) => {
    const { setFilters } = useContext(context);

    const [budget, setBudget] = React.useState(5000);
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

    const { data: skillsData, isLoading: skillsDataLoading} = useMaster({
        type: Master.Skills,
        queryKeys: ["master", Master.Skills],
    })


    const fields = [
        {
            fieldType: "input",
            name: "name",
            label: "Username",
            placeholder: "Enter name of the user you want to find",
        },
        {
            fieldType: "input",
            name: "title",
            label: "Role",
            placeholder: "Ex. Backend Developer",
        },
        {
            fieldType: "input",
            name: "location",
            label: "Preferred Job Location",
            placeholder: "Enter preferred job location",
        },
        {
            fieldType: "checkbox",
            name: "experienceLevel",
            label: "Experience Level",
            options: ExperienceLevel
        },
        {
            fieldType: "multiselect",
            name: "skills",
            label: "Skills Required",
            placeholder: "Select Required Skills",
            options: skillsData,
            isLoading: skillsDataLoading
        },
        {
            fieldType: "radio",
            name: "canBeJoinIn",
            label: "Available To Start",
            options: JoiningAvailability
        },
        {
            fieldType: "checkbox",
            name: "educationLevel",
            label: "Education Level",
            options: Degrees
        }
    ]

    return (
        <div className="p-4">
            <div>
                {/* Mobile filter dialog */}
                <Dialog
                    open={mobileFiltersOpen}
                    onClose={setMobileFiltersOpen}
                    className="relative z-50 lg:hidden"
                >
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                    />

                    <div className="fixed inset-0 z-40 flex">
                        <DialogPanel
                            transition
                            className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 sm:pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
                        >
                            <div className="flex items-center justify-between px-4">
                                <h2 className="text-lg font-medium text-gray-900">
                                    Filters
                                </h2>
                                <button
                                    type="button"
                                    onClick={() => setMobileFiltersOpen(false)}
                                    className="relative -mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                >
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="h-6 w-6"
                                    />
                                </button>
                            </div>

                            {/* Mobile Filters */}
                            <form className="mt-4 border-t border-gray-200 p-5">
                                <FormikFormGenerator
                                    initialValues={{
                                        location: "",
                                        name: "",
                                        preferredWorkingHours: "",
                                        skills: [],
                                        hourlyRates: "",
                                        typeOfGigsInterested: "",
                                    }}
                                    formClassName={"space-y-5"}
                                >
                                    <FormikStateMonitor onChange={setFilters} />

                                    <FormInputField
                                        name="name"
                                        label={"Name Of the user"}
                                        placeholder={
                                            "Enter the name of the user"
                                        }
                                    />

                                    <FormInputField
                                        name="location"
                                        label={"Location"}
                                        placeholder={"Enter location"}
                                    />

                                    {/* <FormCheckbox
                                            name={"languageKnown"}
                                            label={"Language Known"}
                                            options={LANGUAGES}
                                        /> */}

                                    <FormCheckbox
                                        name={"preferredWorkingHours"}
                                        label={"Preferred Working Hours"}
                                        options={PreferredWorkingHours}
                                    />

                                    <div>
                                        <FormMultiSelect
                                            name="skillsRequired"
                                            label={"Skills Required"}
                                            placeholder={
                                                "Select required skills"
                                            }
                                            options={Skills}
                                            maxLabelCount={2}
                                        />
                                    </div>

                                    <Slider
                                        size="md"
                                        step={5000}
                                        color="primary"
                                        label="Hourly Rates"
                                        showSteps={true}
                                        maxValue={50000}
                                        minValue={0}
                                        defaultValue={5000}
                                        className="max-w-md"
                                        value={budget}
                                        onChange={setBudget}
                                    />

                                    <FormCheckbox
                                        name={"typeOfGigsInterested"}
                                        label={"Type of gigs interested in"}
                                        options={TypeOfGigs}
                                    />
                                </FormikFormGenerator>
                            </form>
                        </DialogPanel>
                    </div>
                </Dialog>

                <main className="mx-auto max-w-[90vw] relative">
                    <div className="flex items-baseline justify-between border-gray-200">
                        <div className="flex items-center justify-end">
                            <button
                                type="button"
                                onClick={() => setMobileFiltersOpen(true)}
                                className=" text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden absolute right-5 top-3"
                            >
                                <span className="sr-only">Filters</span>
                                <FunnelIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                />
                            </button>
                        </div>
                    </div>

                    <section
                        aria-labelledby="products-heading"
                        className="min-h-screen"
                    >
                        <div className="flex gap-x-8 py-5">
                            {/* Filters */}
                            <div className="hidden lg:block  max-w-xs w-full">
                                <h3 className="sr-only">Categories</h3>

                                <div
                                    className={`border p-4 rounded-xl ${filterContainerClassname}`}
                                >
                                    <FormikFormGenerator
                                        initialValues={{
                                            name: "",
                                            title: "",
                                            location: "",
                                            experienceLevel: "",
                                            skills: [],
                                            canBeJoinIn: "",
                                            educationLevel: "",
                                        }}
                                        formClassName={`space-y-5 ${filterContainerClassname}`}
                                    >
                                        <FormikStateMonitor
                                            onChange={setFilters}
                                        />
                                        <FormFieldsMapper fields={fields} />
                                    </FormikFormGenerator>
                                </div>
                            </div>

                            {children}
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
};

export default FreelancerFilters;
