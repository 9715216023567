import React, { useContext, useEffect } from "react";
import FormikStateMonitor from "../../../common/FormikStateMonitor";
import {
    Degrees,
    ExperienceLevel,
    Master
} from "../../../constant/constants";
import useMaster from "../../../hooks/useMaster";
import FormFieldsMapper from "../../../utils/FormFieldsMapper";
import FormikFormGenerator from "../../../utils/FormikFormGenerator";
import { MainPageContext } from "../Page";

export const JoiningAvailability = [
    {
        name: "Immediate",
        value: 0
    },
    {
        name: "15 days",
        value: 15
    },
    {
        name: "30 days",
        value: 30
    },
    {
        name: "45 days",
        value: 45
    }
]


const UsersFilterList = ({ isSticky }) => {
    const { setFilters } = useContext(MainPageContext);

    const { data: skillsData, isLoading: skillsDataLoading} = useMaster({
        type: Master.Skills,
        queryKeys: ["master", Master.Skills],
    })

    const fields = [
        {
            fieldType: "input",
            name: "name",
            label: "Username",
            placeholder: "Enter name of the user you want to find",
        },
        {
            fieldType: "input",
            name: "title",
            label: "Role",
            placeholder: "Ex. Backend Developer",
        },
        {
            fieldType: "input",
            name: "location",
            label: "Preferred Job Location",
            placeholder: "Enter preferred job location",
        },
        {
            fieldType: "checkbox",
            name: "experienceLevel",
            label: "Experience Level",
            options: ExperienceLevel
        },
        {
            fieldType: "multiselect",
            name: "skills",
            label: "Skills Required",
            placeholder: "Select Required Skills",
            options: skillsData,
            isLoading: skillsDataLoading
        },
        {
            fieldType: "radio",
            name: "canBeJoinIn",
            label: "Available To Start",
            options: JoiningAvailability
        },
        {
            fieldType: "checkbox",
            name: "educationLevel",
            label: "Education Level",
            options: Degrees
        }
    ]
   
    return (
        <div className={`p-4 bg-white border max-h-[85vh] no-scrollbar rounded-lg max-w-[292px] min-w-[292px] overflow-auto ${isSticky && "sticky bg-white top-20"}`}>
            <FormikFormGenerator
                initialValues={{
                    name: "",
                    title: "",
                    location: "",
                    experienceLevel: "",
                    skills: [],
                    canBeJoinIn: "",
                    educationLevel: "",
                }}
                formClassName={"space-y-5"}
            >
                <FormikStateMonitor onChange={setFilters} />

                <FormFieldsMapper fields={fields} />
            </FormikFormGenerator>
        </div>
    );
};

export default UsersFilterList;
