import { useNavigate } from "react-router-dom";
import { cn } from "../ReusableFunctions";
import React, { useEffect, useRef, useState } from "react";

const InfiniteMovingCards = ({
    items,
    direction = "left",
    speed = "fast",
    pauseOnHover = true,
    className,
}) => {
    const containerRef = useRef(null);
    const scrollerRef = useRef(null);
    const navigate = useNavigate();

    const [start, setStart] = useState(false);

    // Set CSS variables for animation dynamically
    useEffect(() => {
        if (containerRef.current) {
            const animationDirection = direction === "left" ? "forwards" : "reverse";
            containerRef.current.style.setProperty("--animation-direction", animationDirection);

            const animationDuration = speed === "fast" ? "20s" : speed === "normal" ? "40s" : "120s";
            containerRef.current.style.setProperty("--animation-duration", animationDuration);
        }
        setStart(true);
    }, [direction, speed]);

    const handleClick = (title) => {
        navigate(`/gigs?search=${title}`);
    };

    return (
        <div className="rounded-md flex flex-col antialiased bg-white dark:bg-black dark:bg-grid-white/[0.05] items-center justify-center overflow-hidden">
            <div ref={containerRef} className={cn("scroller overflow-hidden", className)}>
                <ul
                    ref={scrollerRef}
                    className={cn(
                        "flex shrink-0 gap-4 py-4 w-max flex-nowrap",
                        start && "animate-scroll",
                        pauseOnHover && "hover:[animation-play-state:paused]"
                    )}
                >
                    {[...items, ...items, ...items, ...items].map((item, idx) => ( // Duplicate items 4 times
                        <li
                            key={`${item.title}-${idx}`}
                            className="cursor-pointer z-50"
                            onClick={() => handleClick(item.title)}
                        >
                            <div>
                                <img src={item.img} alt="Cover" className="hover:scale-105 duration-300" />
                                <h1 className="mt-1 text-sm">{item.title}</h1>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default InfiniteMovingCards;
